import styled from "styled-components";
import {Button} from "@material-ui/core";

export const ContentMainStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;    
`;

export const ContentSizeStyled = styled.div`  
  width: 40%;    
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export const ButtonReleaseStyled = styled(Button)`  
  background-color: orangered;
`;

export const ImgAwardReleaseStyled = styled.img`  
  width: 300px;
  height: 300px;
  border-radius: 10px;
  object-fit: cover;
`;
