import React, {Component} from 'react';
import TextFieldDefault from "../../../Components/TextFieldDefaultComponent/TextFieldDefault";
import SpacerComponent from "../../../Components/SpacerComponent/SpacerComponent";
import {Button, Link, Typography} from "@material-ui/core";
import {ContentButton} from "./styled";
import ForgotPassword from "../../PJAccess/ForgotPassword/ForgotPassword";

class FormLoginScreen extends Component {
    constructor(props) {
        super(props);

        this.state = {};
    }

    async doLogin() {
        this.props.doLogin(this.state);

    }

    handleChange(event) {
        const {name, value} = event.target;
        this.setState({[name]: value});
    }

    render() {
        return (
            <div>
                <SpacerComponent height={40}/>
                <TextFieldDefault label={'Email'}
                                  name={'email'}
                                  type={'email'}
                                  erros={this.props.erros}
                                  onChange={(event) => this.handleChange(event)}/>
                <TextFieldDefault label={'Senha'}
                                  name={'password'}
                                  type={'password'}
                                  erros={this.props.erros}
                                  onChange={(event) => this.handleChange(event)}/>
                <Typography variant={'body1'}
                            color={'primary'}>
                    <Link href={ForgotPassword.ROUTE}>
                        Esqueci minha senha
                    </Link>
                </Typography>
                <SpacerComponent height={30}/>
                <ContentButton>
                    <Button variant={'contained'}
                            color={'primary'}
                            fullWidth={true}
                            size={"large"}
                            onClick={() => this.doLogin()}>
                        Entrar
                    </Button>
                </ContentButton>
            </div>
        );
    }
}

export default FormLoginScreen;