import {Paper} from "@material-ui/core";
import styled from "styled-components";

export const ContentChartStyled = styled(Paper)`
  padding: 20px;
  border-radius: 10px;
`;

export const ContentTotalStyled = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ContentHeaderCardStyled = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  
  .MuiFormControl-root {
    width: 100px;
  }
`;