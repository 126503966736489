import React, {Component} from 'react';
import {Box, Grid, IconButton, Typography} from "@material-ui/core";
import ItemFidelity from "./ItemFidelity";
import {AddCircleOutline} from "@material-ui/icons";
import CompanyRegistrationFidelityScreen from "../CompanyRegistrationFidelityScreen/CompanyRegistrationFidelityScreen";
import {ManageFidelityContext} from "../../../Contexts/ManageFidelityContext";
import MessageUtil from "../../../Utils/MessageUtil";
import ContactUsScreen from "../ContactUsScreen/ContactUsScreen";

class ContentListFidelity extends Component {
    static contextType = ManageFidelityContext;

    makeItemFidelity() {

        let itens = [];

        this.props.fidelities.forEach(function (value, index) {
            itens.push(
                <ItemFidelity fidelity={value}/>
            );
        });

        return itens;
    }

    toCreateFidelity() {

        const fidelities = this.context.parentState.state.fidelities;

        if (fidelities.length > 0) {
            this.context.parentState.setState({
                inLoad: false,
                messagens: MessageUtil.make({
                    title: 'Atenção',
                    body: 'O seu usuário não pode criar mais de um programa de fidelidade no momento. Para saber mais, entre em contato com a nossa equipe.',
                    cancelButtonText: 'Contatar equipe',
                    cancelButtonFunction: () => {
                        window.location.href = ContactUsScreen.ROUTE;
                    }
                }),
            });
            return;
        }

        window.location.href = CompanyRegistrationFidelityScreen.ROUTE
            + '/create';
    }

    render() {
        return (
            <div>
                <Grid container={true}
                      spacing={3}>
                    {this.makeItemFidelity()}
                </Grid>
            </div>
        );
    }
}

export default ContentListFidelity;