import React, {Component} from 'react';
import {ShowPlanStyled} from "./styled";
import {Box, Typography} from "@material-ui/core";
import SpacerComponent from "../../../Components/SpacerComponent/SpacerComponent";
import CheckShowPlanPaymentScreen from "./CheckShowPlanPaymentScreen";

class ShowPlanPaymentScreen extends Component {

    getTextToCLient() {

        if (this.props.plan.clients_limit === null) {
            return 'Clientes ilimitados';
        }

        return `Até ${this.props.plan.clients_limit} Clientes`;

    }

    render() {
        return (
            <ShowPlanStyled variant={'outlined'}>
                <Typography variant={'h5'}>
                    {this.props.plan.name}
                </Typography>
                <SpacerComponent height={35}/>
                <Typography variant={'h4'}>
                    R$ {this.props.plan.price}
                </Typography>
                <Typography variant={'subtitle1'}>
                    mensal
                </Typography>
                <SpacerComponent height={30}/>
                <Box display={'flex'}
                     flexDirection={'column'}
                     justifyContent={'flex-start'}
                     alignItems={'flex-start'}>
                    <CheckShowPlanPaymentScreen title={this.getTextToCLient()}/>
                    <CheckShowPlanPaymentScreen title={`Até ${this.props.plan.companies_limit} CNPJ\`(s) cadastrado${this.props.plan.companies_limit > 1 ? 's' : ''}`}/>
                </Box>
            </ShowPlanStyled>
        );
    }
}

export default ShowPlanPaymentScreen;