import ApiClient from "./ApiClient";
import ApiToken from "./ApiToken";

class AuthService {
    constructor() {
        this.apiClient = new ApiClient('v1');
    }

    async doLogin(form) {
        try {

            const response = await this.apiClient.doRequest({
                method: ApiClient.POST,
                uri: 'login/web',
                auth: false,
                expectedStatus: 200,
                body: form
            });

            const token = response.data.access_token;
            ApiToken.saveToken(token);

            return response;

        } catch (e) {
            throw e
        }
    }

    async doRefreshToken({token}) {
        try {

            return await this.apiClient.doRequest({
                method: ApiClient.GET,
                uri: 'login/refresh-token',
                token: token
            });

        } catch (e) {
            throw e;
        }
    }

    async me() {
        try {

            return await this.apiClient.doRequest({
                method: ApiClient.GET,
                uri: 'me',
                auth: true,
            });

        } catch (e) {
            throw e;
        }

    }

}

export default AuthService;