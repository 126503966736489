import ApiClient from "./Api/ApiClient";
import MeUtil from "../Utils/MeUtil";

class OperatorService {
    constructor() {
        this.uri = '';
        this.apiClient = new ApiClient();
    }

    async get(search = null) {
        try {

            const me = await MeUtil.get();

            let filter = '?filters[][companies.uuid@equal]=' + me.data.data.company.uuid;

            if (!search) {
                search = null;
            }

            if (search !== null) {
                filter += '&text=' + search;
            }

            return await this.apiClient.doRequest({
                method: ApiClient.GET,
                uri: '/store-operador' + filter,
                expectedStatus: 200,
            });

        } catch (e) {
            throw e;
        }
    }

    async getByMatrix(search = null) {
        try {

           const uuid = await MeUtil.getUuidMatrix();

            let filter = '';

            if (!search) {
                search = null;
            }

            if (search !== null) {
                filter += '?text=' + search;
            }

            return await this.apiClient.doRequest({
                method: ApiClient.GET,
                uri: 'store-operador/getByMatrix/' + uuid + filter,
                expectedStatus: 200,
            });

        } catch (e) {
            throw e;
        }
    }

    async create(object) {
        try {

            return await this.apiClient.doRequest({
                method: ApiClient.POST,
                uri: '/store-operador/create',
                expectedStatus: 201,
                body: object,
            });

        } catch (e) {
            throw e;
        }
    }

    async delete(uuid) {
        try {

            return await this.apiClient.doRequest({
                method: ApiClient.DELETE,
                uri: 'user/' + uuid,
                expectedStatus: 201,
            });

        } catch (e) {
            throw e;
        }
    }


}

export default OperatorService;