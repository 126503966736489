import React, {Component} from 'react';
import {ContentFlagsStyled, ContentMainFlagsStyled} from "./styled";
import {Typography} from "@material-ui/core";
import {ReactComponent as VisaLogo} from "../../Assets/visa-logo.svg";
import {ReactComponent as MasterCardLogo} from "../../Assets/mastercard-logo.svg";
import {ReactComponent as AmericanCardLogo} from "../../Assets/amex-american-express-logo.svg";
import {ReactComponent as DinnerClubCardLogo} from "../../Assets/diners-club.svg";
import {ReactComponent as EloCardlogo} from "../../Assets/elo-servicos.svg";
import {ReactComponent as HiperCardLogo} from "../../Assets/hipercard.svg";

class FlagsAcceptedCardComponent extends Component {
    render() {
        return (
            <ContentMainFlagsStyled>
                <Typography variant={"h6"}>
                    Serão aceitas somente as bandeiras:
                </Typography>
                <ContentFlagsStyled>
                    <VisaLogo/>
                    <MasterCardLogo/>
                    <AmericanCardLogo/>
                    {/*<DinnerClubCardLogo/>
                    <EloCardlogo/>
                    <HiperCardLogo/>*/}
                </ContentFlagsStyled>
            </ContentMainFlagsStyled>
        );
    }
}

export default FlagsAcceptedCardComponent;