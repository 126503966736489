import styled from "styled-components";
import {Table} from "@material-ui/core";

export const ContentFilterStyled = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  padding: 20px;
`;

export const ContentMainFooterTotalStyled = styled.div`
  padding: 20px;
`;

export const ContentFooterTotalStyled = styled.div`
  display: flex;
  flex-direction: row;
`;

export const ContentInputText = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`;

export const ContentDateInput = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`;

export const ContentCNPJInput = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`;

export const ContentCheckStyled = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
`;