import styled from "styled-components";
import {IconButton, Paper, Typography} from "@material-ui/core";

export const ContentMainStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ContentSizeStyled = styled.div`
  width: 400px;
  padding: 20px;
`;

export const ContentSegmentStyled = styled(Paper)`
  padding: 10px;
  border: 2px solid rgba(0, 0, 0, 0.20);
`;

export const ModalTermStyled = styled(Paper)`
  width: 815px;
  height: 600px;
  background: white;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 9999;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
`;

export const ModalTermCloseStyled = styled(IconButton)`
  align-self: flex-end;
  z-index: 999999;
  position: relative;
`;

export const ModalTermContentStyled = styled.div`
  position: relative;
  height: 600px;
  overflow-y: auto;
  overflow-x: hidden;
  margin-bottom: 10px;
`;

export const ModalTermControlPageStyled = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

export const TypographyTermStyled = styled(Typography)`
  span {
    font-weight: bold;
    cursor: pointer;
  }
`;

export const ContentFileCSVStyled = styled.div`
  display: flex;
  justify-items: center;
  justify-content: center;
  align-content: center;
  align-items: center;
  flex-direction: column;
`;

export const CSVImgStyle = styled.img`
  width: 100px;
  height: 100px;
`;
