import React, {Component} from 'react';
import {Box, Checkbox, Grid, Typography} from "@material-ui/core";
import TextFieldDefault from "../../../../Components/TextFieldDefaultComponent/TextFieldDefault";
import {AwardContext} from "../../../../Contexts/AwardContext";

class ItemBranchAmount extends Component {
    static contextType = AwardContext;

    constructor(props) {
        super(props);

        this.state = {
            acceptTerm: false,
            saveValue: undefined,
        };
    }

    componentDidMount() {
        if (
            this.props.branches.length === 1 &&
            this.context.isEdit === false
        ) {
            this.forceCheck();
            return
        }

        this.forceCheck(false);

    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (
            prevProps.branches.length !== this.props.branches.length &&
            this.props.branches.length === 1 &&
            this.context.isEdit === false
        ) {
            this.forceCheck();
        }
        if (
            prevProps.branches.length !== this.props.branches.length &&
            this.props.branches.length !== 1 &&
            this.context.isEdit === false
        ) {
            this.forceCheck(false);
        }
        if (this.props.saveAmount === prevProps.saveAmount) {
            return;
        }

        this.updateValue();
    }

    updateValue() {

        let check = this.state.acceptTerm;

        if (this.props.saveAmount !== undefined) {
            check = true;
        }

        this.setState({
            saveValue: this.props.saveAmount,
            acceptTerm: check,
        });

        this.props.setValueAmountBranch(
            this.props.branch.uuid,
            this.props.saveAmount
        );
    }

    forceCheck = (value = true) => {
        this.setState({
            acceptTerm: value,
        });
    }

    handleCheckTerm = () => {
        this.setState({
            acceptTerm: !this.state.acceptTerm,
        });

        if (this.props.onChange !== undefined) {
            this.props.onChange(!this.state.acceptTerm);
        }

        if (this.state.acceptTerm === true) {

            this.setState({
                saveValue: '',
            });

            this.props.deleteValueAmountBranch(
                this.props.branch.uuid
            )
        }
    }

    handleChange(event) {
        const {value} = event.target;
        const NON_DIGIT = '/[^\d]/g';

        let newValue = value.replace(/\D/g, "");
        newValue = parseInt(newValue.toString().replace(NON_DIGIT, ''))

        if (isNaN(newValue)) {
            newValue = 0;
        }

        this.setState({
            saveValue: newValue,
        });

        this.props.setValueAmountBranch(
            this.props.branch.uuid,
            value
        );
    }

    render() {
        return (
            <Grid container={true}
                  spacing={0}
                  direction="row"
                  justify="center"
                  alignItems="center">
                <Grid item={true}
                      xs={8}>
                    <Box display={'flex'}
                         flexDirection={'row'}
                         alignItems="center">
                        <Checkbox checked={this.state.acceptTerm}
                                  disabled={this.props.disabled}
                                  onChange={() => this.handleCheckTerm()}
                                  color={'primary'}/>
                        <Typography variant={'body2'}
                                    color={'primary'}>
                            {this.props.branch.fantasy_name}
                        </Typography>
                    </Box>
                </Grid>
                <Grid item={true}
                      xs={4}>
                    <TextFieldDefault label={''}
                                      size={'small'}
                                      disabled={!this.state.acceptTerm || this.props.disabled}
                                      type={'number'}
                                      value={this.state.saveValue}
                                      onChange={(event) => this.handleChange(event)}/>
                </Grid>
            </Grid>
        );
    }
}

export default ItemBranchAmount;