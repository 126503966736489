import React, {Component} from 'react';
import HeaderComponent from "../../../Components/HeaderComponent/HeaderComponent";
import {Typography} from "@material-ui/core";

import FormCompanyRegistrationFidelityScreen from "./FormCompanyRegistrationFidelityScreen";
import MessageUtil from "../../../Utils/MessageUtil";
import FidelityValidation from "../../../FormValidations/FidelityValidation";
import FidelityService from "../../../Services/FidelityService";
import CompanyRegistrationEstablishment from "../CompanyRegistrationEstablishment/CompanyRegistrationEstablishment";
import CompanyService from "../../../Services/CompanyService";
import FidelityEditValidation from "../../../FormValidations/FidelityEditValidation";
import ManageFidelityScreen from "../ManageFidelityScreen/ManageFidelityScreen";
import ErrorUtil from "../../../Utils/ErrorUtil";
import {FidelityContext} from "../../../Contexts/FidelityContext";
import RegulationFidelityScreen from "../RegulationFidelity/RegulationFidelityScreen";
import MeUtil from "../../../Utils/MeUtil";
import GeneralTranslation from "../../../Translations/FormValidations/GeneralTranslation";

class CompanyRegistrationFidelityScreen extends Component {
    static ROUTE = '/company-registration-fidelity';

    fidelityService;
    companyService;

    constructor(props) {
        super(props);

        this.state = {
            inLoad: false,
            erros: [],
            isEditUuid: null,
            formEdit: null,
            isEdit: false,
            isCreateAlone: false,
            btnAlterDisabled: true,
        };

        this.fidelityService = new FidelityService();
        this.companyService = new CompanyService();
    }

    componentDidMount() {
        this.load();
    }

    async load() {
        try {

            const incomplete = this.props.match.params.incomplete;

            if (this.props.match.params.uuid === 'create') {
                this.setState({
                    isCreateAlone: true,
                });
                return;
            }

            if (this.props.match.params.uuid !== 'edit') {
                if (incomplete === 'true') {
                    this.setState({
                        messagens: MessageUtil.make({
                            title: 'Atenção',
                            body: 'Verificamos que sua conta não foi finaliza, por favor finalize o formulário atual para continuar.',
                        }),
                    });
                }

                return;
            }

            const uuid = this.props.match.params.edit;

            const response = await this.fidelityService.show(uuid);

            this.setState({
                isEditUuid: uuid,
                isEdit: true,
                formEdit: response.data.data,
            });
        } catch (e) {
            this.setState({
                inLoad: false,
                messagens: MessageUtil.make({
                    title: 'Atenção',
                    body: e,
                }),
            });
        }
    }

    doCreate = async (object) => {
        try {
            const validade = await FidelityValidation.validate(object);

            if (validade !== true) {
                this.setState({
                    erros: validade,
                    messagens: MessageUtil.makeToValidationField(
                        new GeneralTranslation(validade).translate()
                    ),
                });
                return;
            }

            this.setState({
                inLoad: true,
            });

            const response = await this.fidelityService.create(object);
            await this.companyService.update(
                this.props.match.params.uuid,
                {
                    'fidelity_program': response.data.data.uuid,
                }
            );

            if (object.createEstablishment === true) {
                this.setState({
                    inLoad: false,
                    messagens: MessageUtil.make({
                        title: 'Sucesso',
                        body: 'Seu programa de fidelidade foi criado com sucesso, clique em ok para ser levado para ' +
                            'tela de cadastro de estabelecimentos',
                        okBtnToRoute: CompanyRegistrationEstablishment.ROUTE
                            + '/'
                            + this.props.match.params.uuid
                            + '/'
                            + response.data.data.uuid,
                    }),
                });
                return;
            }

            this.setState({
                inLoad: false,
                messagens: MessageUtil.make({
                    title: 'Sucesso',
                    body: 'Clique em OK para ser levado ao regulamento do seu programa de fidelidade',
                    okBtnToRoute: RegulationFidelityScreen.ROUTE
                        + '/'
                        + this.props.match.params.uuid
                        + '/'
                        + response.data.data.uuid,
                }),
            });

        } catch (e) {
            this.setState({
                inLoad: false,
                messagens: MessageUtil.make({
                    title: 'Atenção',
                    body: e,
                }),
            });
        }

    }

    doEdit = async (object) => {
        try {
            const validade = await FidelityEditValidation.validate(object);

            if (validade !== true) {
                this.setState({
                    erros: validade,
                    messagens: MessageUtil.makeToValidationField(
                        new GeneralTranslation(validade).translate()
                    ),
                });
                return;
            }

            this.setState({
                inLoad: true,
            });

            await this.doConnectEstablishment(object);

            const newForm = this.makeFormEdit(object);

            await this.fidelityService.update(
                newForm,
                this.state.isEditUuid
            );

            this.setState({
                inLoad: false,
                messagens: MessageUtil.make({
                    title: 'Sucesso',
                    body: 'Seu programa de fidelidade foi alterado com sucesso',
                    okBtnToRoute: ManageFidelityScreen.ROUTE,
                }),
            });

        } catch (e) {
            this.setState({
                inLoad: false,
                messagens: MessageUtil.make({
                    title: 'Atenção',
                    body: e,
                }),
            });
        }
    }

    async doConnectEstablishment(object) {
        try {
            if (object.participating_establishments === undefined) {
                return;
            }

            if (object.participating_establishments.length <= 0) {
                return;
            }
            let arrayCompanies = [];
            object.participating_establishments.map(async (value) => {
                arrayCompanies.push(
                    value
                );
            });

            await this.companyService.attachCompanies(
                object.uuid,
                arrayCompanies,
            );

        } catch (e) {
            throw e;
        }
    }

    makeFormEdit(object) {

        return {

            name: object.name,
            image: object.image,
            points_validity: object.points_validity,
            expiration_period: object.expiration_period,
            award_limit_time: object.award_limit_time,
            booking_limit_period: object.booking_limit_period,
            regulation: object.regulation,
            concurrent_campaign: object.concurrent_campaign,
            inactivity_notice_amount: object.inactivity_notice_amount,
            inactivity_notice_period: object.inactivity_notice_period,
            person_invoices_only_owner: object.person_invoices_only_owner,
            blocked_company_invoices: object.blocked_company_invoices,
            blocked_user_allowed_company_invoices: object.blocked_user_allowed_company_invoices,
            block_invoices_no_owner: object.block_invoices_no_owner,
            allow_manual_scoring_only: object.allow_manual_scoring_only,

        };

    }

    doEditConfirm = async (object) => {

        const context = this;

        this.setState({
            messagens: MessageUtil.make({
                title: 'Atenção',
                body: 'Deseja Salvar as alterações?',
                okButtonFunction: function (value) {
                    context.doEdit(object);
                },
                cancelButtonText: 'Cancelar',
            }),
        });

    }

    doCreateAlone = async (object) => {
        try {

            const validade = await FidelityValidation.validate(object);

            if (validade !== true) {
                this.setState({
                    erros: validade,
                    messagens: MessageUtil.makeToValidationField(
                        new GeneralTranslation(validade).translate()
                    ),
                });
                return;
            }

            this.setState({
                inLoad: true,
            });

            this.validCreateAlone(object);

            const response = await this.fidelityService.create(object);
            object.uuid = response.data.data.uuid;

            await this.doConnectEstablishment(object);

            this.setState({
                inLoad: false,
                messagens: MessageUtil.make({
                    title: 'Sucesso',
                    body: 'Seu programa de fidelidade foi criado com sucesso',
                    okBtnToRoute: ManageFidelityScreen.ROUTE,
                }),
            });

        } catch (e) {
            this.setState({
                inLoad: false,
                messagens: MessageUtil.make({
                    title: 'Atenção',
                    body: e,
                }),
            });
        }

    }

    validCreateAlone(object) {

        if (object.participating_establishments === undefined) {
            throw ErrorUtil.make(
                'Você deve adicionar pelo menos um estabelecimento ao seu programa de fidelidade. Se não há um ' +
                'estabelecimento, você pode criar um <a href="/edit-establishment">aqui</a>'
            );
        }

        if (object.participating_establishments.length <= 0) {
            throw ErrorUtil.make(
                'Você deve adicionar pelo menos um estabelecimento ao seu programa de fidelidade. Se não há um ' +
                'estabelecimento, você pode criar um <a href="/edit-establishment">aqui</a>'
            );
        }

    }

    isMenu() {

        if (this.state.isCreateAlone === false && this.state.isEdit === false) {
            return false
        }

        return true;
    }

    detachCompany = (uuid) => {
        const context = this;

        this.setState({
            messagens: MessageUtil.make({
                title: 'Atenção',
                body: 'Deseja realmente remover esse estabelecimento desse programa de fidelidade ?',
                okButtonFunction: function (value) {
                    context.doDetachCompany(uuid);
                },
                cancelButtonText: 'Cancelar',
            }),
        });
    }

    async doDetachCompany(uuid) {
        try {
            this.setState({
                inLoad: true,
            });

            await this.fidelityService.detachCompany(uuid);

            this.setState({
                inLoad: false,
            });

            this.setState({
                inLoad: false,
                btnAlterDisabled: false,
                messagens: MessageUtil.make({
                    title: 'Sucesso',
                    body: 'O estabelecimento foi removido do programa de fidelidade',
                }),
            });

            this.load();

        } catch (e) {
            this.setState({
                inLoad: false,
                messagens: MessageUtil.make({
                    title: 'Atenção',
                    body: e,
                }),
            });
        }
    }

    toEditRegulation = async () => {
        try {

            const companyUuid = await MeUtil.getUuidMatrix();

            window.location.href = RegulationFidelityScreen.ROUTE
                + '/'
                + companyUuid
                + '/'
                + this.state.isEditUuid
                + '/back'
                + '/true';

        } catch (e) {
            this.setState({
                inLoad: false,
                messagens: MessageUtil.make({
                    title: 'Atenção',
                    body: e,
                }),
            });
        }
    }

    render() {
        return (
            <FidelityContext.Provider value={{
                doDetachCompany: this.detachCompany,
            }}>
                <div>
                    <HeaderComponent messagens={this.state?.messagens}
                                     title={'Cadastro Programa'}
                                     inLoad={this.state.inLoad}
                                     menu={this.isMenu()}
                                     metaDescription={'Registre-se para criar uma conta e aproveitar as vantagens exclusivas do Meu APP de Fidelidade. É rápido, fácil e seguro. Junte-se à nossa comunidade hoje mesmo.'}/>
                    <Typography align={'center'}
                                variant={'h6'}
                                color={'primary'}>
                        Cadastro de Programa de Fidelidade
                    </Typography>
                    <br/>
                    <FormCompanyRegistrationFidelityScreen doCreate={this.doCreate}
                                                           doEdit={this.doEditConfirm}
                                                           doCreateAlone={this.doCreateAlone}
                                                           toEditRegulation={this.toEditRegulation}
                                                           erros={this.state.erros}
                                                           formEdit={this.state.formEdit}
                                                           isEdit={this.state.isEdit}
                                                           isCreateAlone={this.state.isCreateAlone}
                                                           btnAlterDisabled={this.state.btnAlterDisabled}/>
                </div>
            </FidelityContext.Provider>
        );
    }
}

export default CompanyRegistrationFidelityScreen;