import styled from "styled-components"
import {Paper, Typography} from "@material-ui/core";

export const ContentGridStyled = styled.div`
  padding: 20px;
`;

export const PaperItemChoiceStyled = styled(Paper)`
  padding: 35px;
  background: transparent;
  border-radius: 10px !important;
  border-color: #555555;
  border-width: 2px;
`;

export const FooterStyled = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const CurrentPlanStyled = styled(Typography)`
  color: grey;
  font-weight: bold;
  font-size: 18px;
`;

export const ModalChangePlanStyled = styled.div`
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.63);
  position: fixed;
  z-index: 9;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ContentMainStyled = styled(Paper)`
  width: 700px;
  background: white;
  position: relative;
  z-index: 99999999999;
  padding: 20px;

  @media only screen and (max-width: 600px) {
    width: 90%;
  }
`;

export const ContentRangeStyled = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  
  div {
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
    background: orangered;
    color: white;
    font-weight: bold;
    font-size: 18px;
    border-radius: 5px;
  }
`;