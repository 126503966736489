import moment from "moment";
import {Year} from "@material-ui/pickers/views/Year/Year";

var dateUtil = null;

const FORMAT_DATE = 'DD/MM/YYYY';
const FORMAT_DATE_TIME = 'DD/MM/YYYY hh:mm:ss';
const FORMAT_DATE_TIME_24 = 'DD/MM/YYYY HH:mm:ss';
const FORMAT_DATE_API = 'YYYY-MM-DD';
const FORMAT_DATE_CARD_EXPIRATION = 'MM/YYYY';
const YEAR = 'YYYY';

class DateUtil {
    constructor(dateObject) {
        this.dateObject = moment(dateObject);
    }

    static raw(date) {
        dateUtil = new DateUtil(date);
        return dateUtil;
    }

    static getYear() {
        return moment().format(YEAR);
    }

    toDate() {
        return this.dateObject.format(FORMAT_DATE);
    }

    toDateTime() {
        return this.dateObject.format(FORMAT_DATE_TIME);
    }

    toDateTime24() {
        return this.dateObject.format(FORMAT_DATE_TIME_24);
    }

    toDateCardExpiration() {
        return this.dateObject.format(FORMAT_DATE_CARD_EXPIRATION);
    }

    toApiFormat() {
        return this.dateObject.format(FORMAT_DATE_API);
    }

    static getDateRangeFormat(range) {

        const now = moment();
        const initial = now.subtract(range, 'days').format('YYYY-MM-DD');
        const final = moment().format('YYYY-MM-DD');

        return {initial, final};

    }

    static getDateRangeToDate(range) {

        const now = moment();
        const initial = now.subtract(range, 'days').toDate();
        const final = moment().toDate();

        return {initial, final};

    }

}

export default DateUtil;