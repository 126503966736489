import creditCardType from 'credit-card-type';

const codes_vindi = {
    'mastercard': 'mastercard',
    'visa': 'visa',
    'american-express': 'american_express',
    /*'diners-club': 'diners_club',
    'elo': 'elo',
    'hipercard': 'hipercard',*/
    'jcb': 'jcb',
};

class PaymentUtil {
    static getCode(cardNumber) {
        const size = cardNumber.replace(/\D/g, "");

        if (size.length < 4) {
            return null;
        }

        const type = creditCardType(cardNumber)[0]?.type;

        return PaymentUtil.vindiCodes(type);
    }

    static vindiCodes(code) {
        return codes_vindi[code] ?? undefined;
    }
}

export default PaymentUtil;