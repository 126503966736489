import {Redirect, Route} from "react-router-dom";
import ApiToken from "../Services/Api/ApiToken";
import React, {useContext} from "react";
import ManagePaymentScreen from "../Screens/PJAccess/ManagePaymentScreen/ManagePaymentScreen";
import {SuperContext} from "../Contexts/SuperContext";
import RoleUtil from "../Utils/RoleUtil";
import RouteNotFound from "../Screens/PJAccess/RouteNotFoundScreen/RouteNotFound";
import LoadPageScreen from "../Screens/CommonAccess/LoadPageScreen/LoadPageScreen";
import CompanyRegistrationFidelityScreen
    from "../Screens/PJAccess/CompanyRegistrationFidelityScreen/CompanyRegistrationFidelityScreen";

export const PrivateRoute = ({component: Component, ...rest}) => {

    const {me, isAdmin, fidelity} = useContext(SuperContext);

    function toCurrentRoute({component: Component, rest, props}) {
        const {needRole} = rest;

        if (isAdmin === null) {
            return <LoadPageScreen/>;
        }

        if (needRole !== undefined) {
            if (needRole.includes(RoleUtil.ADMIN_NAME) && isAdmin === false) {
                return <Redirect to={{pathname: RouteNotFound.ROUTE}}/>;
            }

            if (needRole.includes(RoleUtil.PJ_NAME) && isAdmin === true) {
                return <Redirect to={{pathname: RouteNotFound.ROUTE}}/>;
            }

            if (needRole.includes(RoleUtil.PJ_NAME) && isAdmin === false) {
                if (!fidelity.length) {
                    return <Redirect to={{
                        pathname: CompanyRegistrationFidelityScreen.ROUTE
                            + '/'
                            + me.company.uuid
                            + '/null'
                            + '/true',
                    }}/>
                }
            }
        }

        if (me?.company?.blocked_access && !window.location.href.includes(ManagePaymentScreen.ROUTE)) {
            return <Redirect to={{pathname: ManagePaymentScreen.ROUTE}}/>;
        }


        return <Component {...props} />;
    }

    return (
        <Route
            {...rest}
            render={(props) => {
                if (ApiToken.get().token === null) {
                    return <Redirect to={{pathname: '/'}}/>
                }
                if (ApiToken.get().token !== null) {
                    return toCurrentRoute({
                        component: Component,
                        rest,
                        props,
                    });
                }
            }}
        />
    );
};