import styled from "styled-components"
import {Button, Paper, Typography} from "@material-ui/core";
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';

export const ImgItemStyled = styled.img`
  width: 100%;
  height: 150px;
  object-fit: contain;
`;

export const PaperItemStyled = styled(Paper)`
  border-radius: 20px;
  overflow: hidden;
  position: relative;
  border-style: ${props => props.select === true ? 'solid' : undefined};
  border-color: ${props => props.select === true ? '#' + process.env.REACT_APP_PRIMARY_COLOR : undefined};
  border-width: ${props => props.select === true ? '5px' : undefined};
`;

export const PaperItemImgStyled = styled(Paper)`
  height: 150px;
  border-radius: 20px;
  overflow: hidden;
  position: relative;
  border-style: ${props => props.select === true ? 'solid' : undefined};
  border-color: ${props => props.select === true ? '#' + process.env.REACT_APP_PRIMARY_COLOR : undefined};
  border-width: ${props => props.select === true ? '5px' : undefined};
`;

export const BtnEditItemAwardStyled = styled(Button)`
  position: absolute;
  top: 5px;
  right: 5px;
  background: white;
  border-radius: 10px;
  padding: 0;
  z-index: 999;
  transition: background-color 250s cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
`;

export const StatusIconStyled = styled(FiberManualRecordIcon)`
  color: ${props => props.colorCustom === true ? '#07d00f' : '#d41111'};
  font-size: 0.95rem !important;
`;

export const ContentSKUStyled = styled(Paper)`
  padding: 20px;
  background: transparent;
  border-width: 2px;
`;

export const InfoSKUStyled = styled(Typography)`
    font-size: 12.9px;
`;