import React, {Component} from 'react';
import HeaderComponent from "../../../Components/HeaderComponent/HeaderComponent";
import {ContentButtonUpgradePlanStyled, ContentMainUpgradePlanStyled} from "./styled";
import logo from "../../../Assets/logo_login.png";
import {Logo} from "../../CommonAccess/LoginScreen/styled";
import {Button, Typography} from "@material-ui/core";
import SpacerComponent from "../../../Components/SpacerComponent/SpacerComponent";
import CheckBoxDefaultComponent from "../../../Components/CheckBoxDefaultComponent/CheckBoxDefaultComponent";
import PlanService from "../../../Services/PlanService";
import MessageUtil from "../../../Utils/MessageUtil";
import MyAccountScreen from "./MyAccountScreen";
import {SuperContext} from "../../../Contexts/SuperContext";
import LoginScreen from "../../CommonAccess/LoginScreen/LoginScreen";
import {Route} from "react-router-dom";

class AcceptUpgradePlanMyAccoutScreen extends Component {
    static ROUTE = '/accept-upgrade-plan';
    static contextType = SuperContext;

    constructor(props) {
        super(props);

        this.state = {
            inLoad: false,
            messagens: null,
            termChecked: false,
        };

        this.planService = new PlanService();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        this.load();
    }

    load() {
        try {

            if (this.context.me !== null) {
                return;
            }

            window.location.href = LoginScreen.ROUTE + '?redirect=' + AcceptUpgradePlanMyAccoutScreen.ROUTE;

        } catch (e) {
            this.setState({
                inLoad: false,
                messagens: MessageUtil.make({
                    title: 'Atenção',
                    body: e,
                }),
            });
        }
    }

    handleTerm = () => {
        this.setState({
            termChecked: !this.state.termChecked,
        });
    }

    doUpgrade = async () => {
        try {

            this.setState({
                inLoad: true,
            });

            await this.planService.upgradeAutoPlan();

            this.setState({
                inLoad: false,
                messagens: MessageUtil.make({
                    title: 'Sucesso',
                    body: 'Upgrade de plano realizado.',
                    okBtnToRoute: MyAccountScreen.ROUTE,
                }),
            });

        } catch (e) {
            this.setState({
                inLoad: false,
                messagens: MessageUtil.make({
                    title: 'Atenção',
                    body: e,
                }),
            });
        }
    }


    render() {
        return (
            <>
                <HeaderComponent menu={true}
                                 title={'Atualizar plano'}
                                 messagens={this.state.messagens}
                                 inLoad={this.state.inLoad}/>
                <ContentMainUpgradePlanStyled>
                    <Logo src={logo}/>
                    <SpacerComponent height={50}/>
                    <Typography variant={'h5'}
                                align={'center'}
                                color={'primary'}>
                        Tem certeza que quer fazer o upgrade do seu plano?
                    </Typography>
                    <SpacerComponent height={20}/>
                    <Typography variant={'subtitle1'}
                                align={'center'}>
                        Ao autorizar a realização do upgrade do seu plano você concorda com a cobrança proporcional
                        relativa a essa mudança no decorrer do seu ciclo de cobrança.
                    </Typography>
                    <SpacerComponent height={20}/>
                    <Typography variant={'subtitle1'}
                                align={'center'}>
                        Caso deseje aderir a um plano de capacidade maior ao imediatamente acima do seu, basta acessar a
                        opção <b>Alterar Plano</b>, na tela de <b>Meu Plano</b> da sua plataforma Meu App WEB.
                    </Typography>
                    <SpacerComponent height={40}/>
                    <CheckBoxDefaultComponent onChange={this.handleTerm}
                                              checked={this.state.termChecked}
                                              name={'term'}
                                              label={'Estou ciente das alteracoes de capacidade e valor referente ao meu plano.'}/>
                    <SpacerComponent height={50}/>
                    <ContentButtonUpgradePlanStyled>
                        <Button variant={'contained'}>
                            Cancelar
                        </Button>
                        <SpacerComponent width={50}/>
                        <Button color={'primary'}
                                variant={'contained'}
                                onClick={this.doUpgrade}
                                disabled={!this.state.termChecked}>
                            Fazer Upgrade
                        </Button>
                    </ContentButtonUpgradePlanStyled>
                </ContentMainUpgradePlanStyled>
            </>
        );
    }
}

export default AcceptUpgradePlanMyAccoutScreen;