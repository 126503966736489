import styled from "styled-components";


export const ContentStyled = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

export const ContentMainStyled = styled.div`
  width: 600px;

  @media only screen and (max-width: 600px) {
    width: 90%;
  }
`;

export const ContentTopicsStyled = styled.div`
  margin-left: 20px;
`;

export const ContentButtonStyled = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  
  button {
    width: 180px;
  }
`;