import React, {Component} from 'react';
import {Typography} from "@material-ui/core";
import PropTypes from 'prop-types';
import ContentHCenterVStartComponent from "../ContentHCenterVStartComponent/ContentHCenterVStartComponent";
import {FixSelectStyled} from "./styled";

class FixSelectComponent extends Component {
    constructor(props) {
        super(props);

        this.state = {
            show: false,
        };
    }

    componentDidMount() {
        const context = this;
        setTimeout(() => {
            context.setState({
                show: true,
            });
        }, this.props.time);
    }

    render() {
         if (this.state.show) {
             return this.props.children;
         }
        return (
            <FixSelectStyled>
                <Typography variant={'body1'}>
                    Carregando...
                </Typography>
            </FixSelectStyled>
        );
    }
}

FixSelectComponent.defaultProps = {
    time: 2500,
};

FixSelectComponent.propTypes = {
    time: PropTypes.number,
};

export default FixSelectComponent;