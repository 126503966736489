import React, {Component} from 'react';
import SpacerComponent from "../../../Components/SpacerComponent/SpacerComponent";
import {Title} from "./styled";
import CompanyRegistrationScreen from "../../PJAccess/CompanyRegistrationScreen/CompanyRegistrationScreen";
import {Link} from "@material-ui/core";

class RegisterElementLoginScreen extends Component {
    render() {
        return (
            <div>
                <SpacerComponent height={20}/>
                <Title variant={'body1'}
                       align={'center'}
                       color={'primary'}>
                    Ainda não tem conta?
                </Title>
                <Title variant={'h6'}
                       align={'center'}
                       color={'primary'}>
                    <Link href={CompanyRegistrationScreen.ROUTE}>
                        Cadastre-se
                    </Link>
                </Title>
            </div>
        );
    }
}

export default RegisterElementLoginScreen;