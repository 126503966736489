import ApiClient from "./Api/ApiClient";

class GiftCardService {
    constructor() {
        this.apiClient = new ApiClient();
    }

    async retrieveToBuy(search = '') {
        try {
            return await this.apiClient.doRequest({
                method: ApiClient.GET,
                uri: '/gift-cards' + search,
                expectedStatus: 200,
            });
        } catch (e) {
            throw e;
        }
    }

    async doBuy(object) {
        try {
            return await this.apiClient.doRequest({
                method: ApiClient.POST,
                uri: '/gift-cards/order',
                expectedStatus: 201,
                body: object,
            });
        } catch (e) {
            throw e;
        }
    }

    async getPurchased() {
        try {
            return await this.apiClient.doRequest({
                method: ApiClient.GET,
                uri: '/gift-cards/purchased',
                expectedStatus: 200,
            });
        } catch (e) {
            throw e;
        }
    }

    async getCategories() {
        try {
            return await this.apiClient.doRequest({
                method: ApiClient.GET,
                uri: '/gift-cards/categories',
                expectedStatus: 200,
            });
        } catch (e) {
            throw e;
        }
    }
}

export default GiftCardService;