import React, {Component} from 'react';
import {Box} from "@material-ui/core";
import {Logo, Title} from "./styled";
import logo from "../../../Assets/logo_login.png";

class StaticElementLoginScreen extends Component {
    render() {
        return (
            <div>
                <Box display={'flex'}
                     justifyContent={'center'}>
                    <Logo src={logo}/>
                </Box>
                <br/>
                <Title variant={'h6'}
                       align={'center'}
                       color={'primary'}>
                    Bem vindo ao
                </Title>
                <Title variant={'h6'}
                       align={'center'}
                       color={'primary'}>
                    Meu app de fidelidade
                </Title>
            </div>
        );
    }
}

export default StaticElementLoginScreen;