import React, {Component} from 'react';
import {Box, Grid, Typography} from "@material-ui/core";
import {ManageEstablishmentContext} from "../../../Contexts/ManageEstablishmentContext";
import {
    BtnDeleteItemAwardStyled,
    BtnEditItemAwardStyled,
    ContentAddressStyled,
    ImgItemStyled,
    PaperItemImgStyled,
    PaperItemStyled
} from "./styled";
import SpacerComponent from "../../../Components/SpacerComponent/SpacerComponent";
import ApiImageUtil from "../../../Utils/ApiImageUtil";

class ItemEstablishment extends Component {
    static contextType = ManageEstablishmentContext;

    render() {
        return (
            <Grid item={true}
                  xs={12}
                  sm={12}
                  md={6}
                  lg={3}
                  xl={2}>
                <Box height={300}>
                    <PaperItemStyled elevation={3}>
                        <PaperItemImgStyled elevation={3}>
                            <BtnEditItemAwardStyled color="primary"
                                                    size={'small'}
                                                    onClick={() => this.context.doEdit(
                                                        this.props.establishment.uuid
                                                    )}>
                                Editar
                            </BtnEditItemAwardStyled>
                            <BtnDeleteItemAwardStyled color={'primary'}
                                                      size={'small'}
                                                      onClick={() => this.context.doDelete(
                                                          this.props.establishment.uuid
                                                      )}>
                                Deletar
                            </BtnDeleteItemAwardStyled>
                            <ImgItemStyled
                                src={ApiImageUtil.tryGet(this.props.establishment.logo)}/>
                        </PaperItemImgStyled>
                        <ContentAddressStyled>
                            <SpacerComponent height={10}/>
                            <Typography variant={'subtitle2'}
                                        color={'primary'}
                                        align={'center'}>
                                {this.props.establishment.fantasy_name}
                            </Typography>
                            <SpacerComponent height={10}/>
                            <Typography variant={'subtitle1'}
                                        color={'primary'}
                                        align={'center'}>
                                {this.props.establishment.address}{', '}
                                {this.props.establishment.number}{', '}
                                {this.props.establishment.neighborhood}{', '}
                                {this.props.establishment.city}{', '}
                                {this.props.establishment.state}
                            </Typography>
                            <SpacerComponent height={10}/>
                        </ContentAddressStyled>
                    </PaperItemStyled>
                </Box>
            </Grid>
        );
    }
}

export default ItemEstablishment;