import React, {Component} from 'react';
import LoginScreen from "../../CommonAccess/LoginScreen/LoginScreen";
import StaticElementLoginScreen from "../../CommonAccess/LoginScreen/StaticElementLoginScreen";
import {ContentMainStyled, ContentSizeStyled} from "../../CommonAccess/LoginScreen/styled";
import {Box, CircularProgress, Typography} from "@material-ui/core";
import SpacerComponent from "../../../Components/SpacerComponent/SpacerComponent";

class ShareScreen extends Component {
    static ROUTE = '/share'

    constructor(props) {
        super(props);

        this.state = {

            availableIos: true,

        };
    }

    componentDidMount() {
        if (navigator.userAgent.toLowerCase().includes('android')) {
            window.location.href = 'http://play.google.com/store/apps/details?id=com.meuapp.fidelidade';
            return;
        }

        if (navigator.userAgent.toLowerCase().includes('iphone')) {
            window.location.href = 'https://apps.apple.com/us/app/meu-app-de-fidelidade/id1572333343';
            return;
        }

        if (navigator.userAgent.toLowerCase().includes('ipad')) {
            window.location.href = 'https://apps.apple.com/us/app/meu-app-de-fidelidade/id1572333343';
            return;
        }

        window.location.href = LoginScreen.ROUTE;

    }

    render() {
        return (
            <>
                <ContentMainStyled>
                    <ContentSizeStyled>
                        <StaticElementLoginScreen/>
                        <SpacerComponent height={60}/>
                        {this.state.availableIos === true &&
                        <>
                            <Typography variant={'subtitle1'}
                                        align={'center'}>
                                Você sera redirecionado para sua loja
                            </Typography>
                            <SpacerComponent height={20}/>
                            <Box display={'flex'}
                                 justifyContent={'center'}
                                 alignItems={'center'}>
                                <CircularProgress/>
                            </Box>
                        </>
                        }
                        {this.state.availableIos === false &&
                        <>
                            <SpacerComponent height={30}/>
                            <Typography variant={'h6'}
                                        align={'center'}>
                                Versão para IOS em breve.
                            </Typography>
                        </>
                        }
                    </ContentSizeStyled>
                </ContentMainStyled>
            </>
        );
    }
}

export default ShareScreen;