import React, {Component} from 'react';
import {Box, Button, Typography, MenuItem} from "@material-ui/core";
import TextFieldDefault from "../../../Components/TextFieldDefaultComponent/TextFieldDefault";
import AvatarComponent from "../../../Components/AvatarComponent/AvatarComponent";
import InputFileDefault from "../../../Components/InputFileDefault/InputFileDefault";
import {ContentMainStyled, ContentSizeStyled} from "./styled";
import SpacerComponent from "../../../Components/SpacerComponent/SpacerComponent";
import TermCompanyRegistrationScreen from "./TermCompanyRegistrationScreen";
import FormMask from "../../../FromMask/FormMask";
import AddressUtil from "../../../Utils/AddressUtil";
import MobileStepper from "@material-ui/core/MobileStepper";
import PasswordStrengthComponent from "../../../Components/PasswordStrengthComponent/PasswordStrengthComponent";
import ApiImageUtil from "../../../Utils/ApiImageUtil";
import SegmentShowEditCompanyRegistration from "./SegmentShowEditCompanyRegistration";
import CertificateFormComponent from "../../../Components/CertificateFormComponent/CertificateFormComponent";
import {CompanyRegistrationContext} from "../../../Contexts/CompanyRegistrationContext";
import MessageUtil from "../../../Utils/MessageUtil";
import IntegrationTokenFormComponent
    from "../../../Components/IntegrationTokenFormComponent/IntegrationTokenFormComponent";
import {GoogleMap} from "@react-google-maps/api";
import Maps from "../../../Components/Maps/Maps";

class FormCompanyRegistrationScreen extends Component {
    static contextType = CompanyRegistrationContext;

    constructor(props) {
        super(props);

        this.state = {

            imagePrevios: '',
            term: false,
            state: '',
            segment_name_aux: null,
            segment_type_aux: null,
            btnAlterDisabled: true,
            editSegment: false,
            setCertificate: false,

        };
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.formEdit === prevProps.formEdit) {
            return;
        }
        this.setFormEdit();
    }

    async setFormEdit() {
        let newState = Object.assign(this.state, this.props.formEdit);
        newState.imagePrevios = ApiImageUtil.tryGet(this.props.formEdit.logo);
        newState.email = this.props.formEdit?.admin?.email;
        newState.name = this.props.formEdit?.admin?.name;
        newState.cpf = this.props.formEdit?.admin?.cpf;

        this.setState(newState);
    }

    async handlerSubmit() {
        this.props.doCreate(this.state);
    }

    async handlerSubmitEdit() {
        this.props.doEdit(this.state);
    }

    handleChange = (event) => {
        const {name, value} = event.target;
        this.setAddressByCEP(name, value);
        this.setState({
            [name]: value,
            btnAlterDisabled: false,
        });
        if (name === 'segment_name') {
            this.props.getSegmentBySegmentName(value);
        }
        if (name === 'codigo_consultor' && value === '') {
            this.setState({
                codigo_consultor: undefined,
            });
        }
    }

    handleChangeMap = (latLng) => {
        this.setState(latLng);
    }

    async setAddressByCEP(inputName, value) {
        if (inputName !== 'zip_code') {
            return;
        }

        if (value.length !== 8) {
            return;
        }

        const addressUtil = await AddressUtil.translateZipCode(value);

        this.setState({
            'state': addressUtil.state,
            'city': addressUtil.city,
            'neighborhood': addressUtil.neighborhood,
            'address': addressUtil.streetName,
            'complement': addressUtil.complement,
            btnAlterDisabled: false,
        });
    }

    handleTermChange(value) {
        this.setState({
            term: value,
        });
    }

    setPreviosImage = (image) => {
        this.setState({
            imagePrevios: image,
        });
    }

    toBase64Image = (base64) => {
        this.setState({
            'logo_base64': base64.split(',')[1],
            btnAlterDisabled: false,
        });
    }

    setPreviosImageCertificate = (image) => {
        this.setState({
            setCertificate: true,
        });
    }

    toBase64Certificate = (base64) => {
        this.setState({
            'certificate_file': base64.split(',')[1],
            btnAlterDisabled: false,
        });
    }

    makeItensSelect() {
        let itens = [];
        for (let [key, value] of Object.entries(AddressUtil.states)) {
            itens.push(
                <MenuItem key={key} value={value}>{value}</MenuItem>
            );
        }
        return itens;
    }

    makeSegmentName() {
        let itens = [];
        this.props.segmentName.forEach(function (value, index) {
            itens.push(
                <MenuItem key={index} value={value.id}>{value.name}</MenuItem>
            );
        })

        return itens;
    }

    makeSegment() {
        let itens = [];
        this.props.segment.forEach(function (value, index) {
            itens.push(
                <MenuItem key={index} value={value.uuid}>{value.name}</MenuItem>
            );
        })

        return itens;
    }

    setScore(score) {
        this.state.score = score;
    }

    tryGetUserData(property) {
        try {

            return this.state.users[0][property];

        } catch (e) {
            return null;
        }
    }

    doEditSegment = () => {
        this.setState({
            editSegment: !this.state.editSegment,
        })
    }

    showOurNotSegment() {

        if (this.props.isEdit === false) {
            return true;
        }

        if (this.state.editSegment === true) {
            return true;
        }
    }

    render() {
        return (
            <ContentMainStyled>
                <ContentSizeStyled>
                    <Typography align={'center'}
                                variant={'h6'}
                                color={'primary'}>
                        Cadastro de usuário
                    </Typography>
                    <SpacerComponent height={10}/>
                    <TextFieldDefault label={'Nome *'}
                                      name={'name'}
                                      value={this.state.name}
                                      onChange={(event) => this.handleChange(event)}
                                      erros={this.props.erros}/>
                    <TextFieldDefault label={'CPF *'}
                                      name={'cpf'}
                                      mask={FormMask.cpf}
                                      forceLabel={true}
                                      value={this.state.cpf}
                                      onChange={(event) => this.handleChange(event)}
                                      erros={this.props.erros}/>
                    <TextFieldDefault label={'Email *'}
                                      name={'email'}
                                      value={this.state.email}
                                      onChange={(event) => this.handleChange(event)}
                                      erros={this.props.erros}/>
                    <TextFieldDefault label={'Senha *'}
                                      name={'password'}
                                      type={'password'}
                                      onChange={(event) => this.handleChange(event)}
                                      value={this.state.password}
                                      erros={this.props.erros}/>
                    <PasswordStrengthComponent password={this.state.password}
                                               onChange={(score) => this.setScore(score)}/>
                    {this.props.isEdit === false &&
                    <TextFieldDefault label={'Confirmar Senha *'}
                                      name={'password_confirm'}
                                      type={'password'}
                                      onChange={(event) => this.handleChange(event)}
                                      value={this.state.password_confirm}
                                      erros={this.props.erros}/>
                    }
                    <SpacerComponent height={20}/>
                    <Typography align={'center'}
                                variant={'h6'}
                                color={'primary'}>
                        Cadastro de empresa
                    </Typography>
                    <TextFieldDefault label={'CNPJ *'}
                                      name={'cnpj'}
                                      onChange={(event) => this.handleChange(event)}
                                      mask={FormMask.cnpj}
                                      forceLabel={true}
                                      value={this.state.cnpj}
                                      erros={this.props.erros}
                                      disabled={this.props.isEdit}/>
                    <TextFieldDefault label={'Razão Social *'}
                                      name={'social_reason'}
                                      onChange={(event) => this.handleChange(event)}
                                      value={this.state.social_reason}
                                      erros={this.props.erros}/>
                    <TextFieldDefault label={'Nome Fantasia *'}
                                      name={'fantasy_name'}
                                      onChange={(event) => this.handleChange(event)}
                                      value={this.state.fantasy_name}
                                      erros={this.props.erros}/>
                    <SegmentShowEditCompanyRegistration value={this.state}
                                                        isEdit={this.props.isEdit}
                                                        doEditSegment={this.doEditSegment}/>
                    {this.showOurNotSegment() &&
                    <div>
                        <TextFieldDefault label={'Área de atuação'}
                                          name={'segment_name'}
                                          onChange={(event) => this.handleChange(event)}
                                          select={true}
                                          erros={this.props.erros}>
                            {this.makeSegmentName()}
                        </TextFieldDefault>
                        {this.props.segment.length > 0 &&
                        <TextFieldDefault label={'Segmento'}
                                          name={'segment_type_uuid'}
                                          select={true}
                                          onChange={(event) => this.handleChange(event)}
                                          erros={this.props.erros}>
                            {this.makeSegment()}
                        </TextFieldDefault>
                        }
                    </div>
                    }
                    <SpacerComponent height={10}/>
                    <Typography variant={'body1'}
                                color={'primary'}>
                        Logo da Empresa Matriz
                    </Typography>
                    <SpacerComponent height={10}/>
                    <Box display={'flex'}
                         flexDirection={'row'}
                         alignItems={'center'}>
                        <AvatarComponent
                            src={this.state.imagePrevios}/>
                        <SpacerComponent width={20}/>
                        <InputFileDefault setPreviosImage={this.setPreviosImage}
                                          toBase64Image={this.toBase64Image}
                                          onMaxSize={(value) => {
                                              this.context.parent.setState({
                                                  messagens: MessageUtil.make({
                                                      title: 'Atenção',
                                                      body: `Arquivo não pode ter mais que ${value} mb`
                                                  }),
                                              })
                                          }}/>
                    </Box>
                    <Box display={'flex'}
                         flexDirection={'row'}
                         alignItems={'center'}>
                        <TextFieldDefault label={'Telefone *'}
                                          name={'telephone'}
                                          onChange={(event) => this.handleChange(event)}
                                          value={this.state.telephone}
                                          erros={this.props.erros}
                                          forceLabel={true}
                                          phone={true}/>
                        <SpacerComponent width={40}/>
                        <TextFieldDefault label={'CEP *'}
                                          name={'zip_code'}
                                          type={'number'}
                                          value={this.state.zip_code}
                                          onChange={(event) => this.handleChange(event)}
                                          erros={this.props.erros}/>
                    </Box>
                    <TextFieldDefault label={'Endereço *'}
                                      name={'address'}
                                      value={this.state.address}
                                      onChange={(event) => this.handleChange(event)}
                                      erros={this.props.erros}/>
                    <Box display={'flex'}
                         flexDirection={'row'}>
                        <TextFieldDefault label={'Número *'}
                                          name={'number'}
                                          value={this.state.number}
                                          onChange={(event) => this.handleChange(event)}
                                          erros={this.props.erros}/>
                        <SpacerComponent width={40}/>
                        <TextFieldDefault label={'Complemento'}
                                          name={'complement'}
                                          value={this.state.complement}
                                          onChange={(event) => this.handleChange(event)}
                                          erros={this.props.erros}/>
                    </Box>
                    <TextFieldDefault label={'Bairro *'}
                                      name={'neighborhood'}
                                      value={this.state.neighborhood}
                                      onChange={(event) => this.handleChange(event)}
                                      erros={this.props.erros}/>
                    <Box display={'flex'}
                         flexDirection={'row'}
                         alignItems={'center'}>
                        <TextFieldDefault label={'Cidade *'}
                                          name={'city'}
                                          value={this.state.city}
                                          onChange={(event) => this.handleChange(event)}
                                          erros={this.props.erros}/>
                        <SpacerComponent width={40}/>
                        <TextFieldDefault label={'Estado *'}
                                          select={true}
                                          name={'state'}
                                          value={this.state.state}
                                          onChange={(event) => this.handleChange(event)}
                                          erros={this.props.erros}>
                            {this.makeItensSelect()}
                        </TextFieldDefault>
                    </Box>
                    <TextFieldDefault label={'Código do Consultor (opcional)'}
                                      name={'codigo_consultor'}
                                      onChange={(event) => this.handleChange(event)}
                                      value={this.state.codigo_consultor}
                                      erros={this.props.erros}
                                      disabled={this.props.isEdit}
                                      type={'number'}/>
                    <SpacerComponent height={20}/>
                    <Maps onChange={(latLng) => this.handleChangeMap(latLng)}
                          saveLatLog={{
                              lat: this.state.lat,
                              long: this.state.long,
                          }}/>
                    <SpacerComponent height={20}/>
                    <CertificateFormComponent certificate_password={this.state.certificate_password}
                                              handleChange={this.handleChange}
                                              erros={this.props.erros}
                                              setPreviosImageCertificate={this.setPreviosImageCertificate}
                                              toBase64Certificate={this.toBase64Certificate}
                                              setCertificate={this.state.setCertificate}/>
                    <SpacerComponent height={20}/>
                    {this.props.isEdit === true &&
                    <>
                        <IntegrationTokenFormComponent/>
                        <SpacerComponent height={20}/>
                    </>
                    }
                    {this.props.isEdit === false &&
                    <div>
                        <TermCompanyRegistrationScreen onChange={(value) => this.handleTermChange(value)}/>
                        <SpacerComponent height={20}/>
                        <Box display={'flex'} flexDirection={'column'} alignItems={'center'}>
                            <MobileStepper
                                variant="dots"
                                steps={3}
                                position="static"
                                activeStep={0}/>
                        </Box>
                        <SpacerComponent height={20}/>
                    </div>
                    }
                    <SpacerComponent height={20}/>
                    <Box display="flex" justifyContent="center">
                        {this.props.isEdit === false &&
                        <Button variant={'contained'}
                                color={'primary'}
                                size={"large"}
                                fullWidth={true}
                                onClick={() => this.handlerSubmit()}>
                            Continuar
                        </Button>
                        }
                        {this.props.isEdit === true &&
                        <Button variant={'contained'}
                                color={'primary'}
                                size={"large"}
                                fullWidth={true}
                                onClick={() => this.handlerSubmitEdit()}
                                disabled={this.state.btnAlterDisabled}>
                            Alterar
                        </Button>
                        }
                    </Box>
                </ContentSizeStyled>
            </ContentMainStyled>
        );
    }
}

export default FormCompanyRegistrationScreen;