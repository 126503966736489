import React, {Component} from 'react';
import {ContentChartStyled, ContentTotalStyled} from "./styled";
import {Grid, Typography} from "@material-ui/core";

class ShowNumber extends Component {
    render() {
        return (
            <Grid item={true}
                  xs={6}
                  sm={6}
                  md={2}
                  lg={3}
                  xl={3}>
                <ContentChartStyled onClick={() => window.location.href = this.props.toRoute ?? '#'}>
                    <ContentTotalStyled>
                        <Typography variant={'h5'}>{this.props.total}</Typography>
                    </ContentTotalStyled>
                    <Typography variant={'h6'}
                                align={'center'}
                                color={'primary'}>
                        {this.props.text}
                    </Typography>
                </ContentChartStyled>
            </Grid>
        );
    }
}

export default ShowNumber;