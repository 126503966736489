import React, {Component} from 'react';
import {AvatarCircleStyled} from "./styled";
import MeUtil from "../../Utils/MeUtil";
import {CircularProgress} from "@material-ui/core";
import ApiImageUtil from "../../Utils/ApiImageUtil";

class AvatarCircleComponent extends Component {
    constructor(props) {
        super(props);

        this.state = {
            logo: null,
        };
    }

    componentDidMount() {
        this.load();
    }

    async load() {
        try {

            const response = await MeUtil.get();

            this.setState({
                logo: ApiImageUtil.tryGet(response.data.data.company.logo),
            })

        } catch (e) {
        }
    }

    render() {

        if (this.state.logo === null) {
            return (
                <div>
                    <CircularProgress/>
                </div>
            );
        }

        return (
            <div>
                <AvatarCircleStyled src={this.state.logo}/>
            </div>
        );
    }
}

export default AvatarCircleComponent;