import * as yup from 'yup';
import ValidationUtil from "../Utils/ValidationUtil";

class CompanyValidation {

    static async validate(form) {
        try {

            if (form.cpf === "   .   .   -  ") {
                form.cpf = "";
            }

            const objectValidation = {
                name: yup
                    .string()
                    .required(ValidationUtil.MSG_FIELD_REQUIRED),
                cpf: yup
                    .string()
                    .required(ValidationUtil.MSG_FIELD_REQUIRED),
                cnpj: yup
                    .string()
                    .required(ValidationUtil.MSG_FIELD_REQUIRED),
                social_reason: yup
                    .string()
                    .required(ValidationUtil.MSG_FIELD_REQUIRED),
                fantasy_name: yup
                    .string()
                    .required(ValidationUtil.MSG_FIELD_REQUIRED),
                telephone: yup
                    .string()
                    .required(ValidationUtil.MSG_FIELD_REQUIRED),
                zip_code: yup
                    .number(ValidationUtil.MSG_FIELD_NUMBER)
                    .typeError(ValidationUtil.MSG_FIELD_NUMBER)
                    .required(ValidationUtil.MSG_FIELD_REQUIRED),
                address: yup
                    .string()
                    .required(ValidationUtil.MSG_FIELD_REQUIRED),
                number: yup
                    .string()
                    .required(ValidationUtil.MSG_FIELD_REQUIRED),
                email: yup
                    .string()
                    .email(ValidationUtil.MSG_FIELD_EMAIL)
                    .required(ValidationUtil.MSG_FIELD_REQUIRED),
                city: yup
                    .string()
                    .required(ValidationUtil.MSG_FIELD_REQUIRED),
                neighborhood: yup
                    .string()
                    .required(ValidationUtil.MSG_FIELD_REQUIRED),
                state: yup
                    .string()
                    .required(ValidationUtil.MSG_FIELD_REQUIRED),
                password: yup
                    .string()
                    .required(ValidationUtil.MSG_FIELD_REQUIRED),
                password_confirm: yup
                    .string()
                    .required(ValidationUtil.MSG_FIELD_REQUIRED),
                segment_type_uuid: yup
                    .string()
                    .required(ValidationUtil.MSG_FIELD_REQUIRED),
                segment_name: yup
                    .string()
                    .required(ValidationUtil.MSG_FIELD_REQUIRED),
                codigo_consultor: yup
                    .number(ValidationUtil.MSG_FIELD_NUMBER)
                    .typeError(ValidationUtil.MSG_FIELD_NUMBER)
                    .positive(ValidationUtil.MSG_FIELD_POSITIVE)
                    .integer(ValidationUtil.MSG_INTERGER),
                logo_base64: yup
                    .string()
                    .required(ValidationUtil.MSG_FIELD_REQUIRED),
            };

            if (form.certificate_file !== undefined) {
                objectValidation['certificate_password'] = yup
                    .string()
                    .required(ValidationUtil.MSG_FIELD_REQUIRED);
            }

            let validate = yup.object().shape(objectValidation);

            validate.validateSync(form, {abortEarly: false})

            return true;

        } catch (e) {
            let erros = [];
            e.inner.forEach(function (value, index) {
                erros[value.path] = value.message;
            });

            return erros;
        }
    }
}

export default CompanyValidation;