import React, {Component} from 'react';
import {ItemPagination, ParentPagination, SubParentPagination} from "./styled";
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import PropTypes from "prop-types";
import SpacerComponent from "../SpacerComponent/SpacerComponent";


class PaginationComponent extends Component {
    constructor(props) {
        super(props);
    }

    makeItens() {
        const total = this.props.total;
        const perPage = this.props.perPage;
        let itensNeed = total / perPage;
        const currentPage = this.props.currentPage;
        const itensVisible = this.props.itensVisible;


        const itens = [];

        for (let i = 1; i <= itensNeed; i++) {
            if ((currentPage - i) > itensVisible) {
                continue;
            }

            if ((i - currentPage) > itensVisible) {
                continue;
            }

            itens.push(
                <ItemPagination current={i === currentPage}
                                onClick={() => this.onChangePage(i)}>
                    {i}
                </ItemPagination>
            );
        }

        if (itens.length <= 0) {
            itens.push(
                <ItemPagination current={true}>
                    1
                </ItemPagination>
            );
        }

        return itens;
    }

    onChangePage(page) {
        this.props.onChangePage(page);
    }

    render() {
        return (
            <ParentPagination>
                <SubParentPagination>
                    <ItemPagination>
                        <KeyboardArrowLeftIcon onClick={() => this.onChangePage(1)}/>
                    </ItemPagination>

                    {this.makeItens()}

                    <ItemPagination>
                        <KeyboardArrowRightIcon onClick={() => this.onChangePage(this.props.lastPage)}/>
                    </ItemPagination>
                </SubParentPagination>
            </ParentPagination>
        );
    }
}

PaginationComponent.propTypes = {
    total: PropTypes.number,
    perPage: PropTypes.number,
    currentPage: PropTypes.number,
    itensVisible: PropTypes.number,
    lastPage: PropTypes.number,
    onChangePage: PropTypes.func,
};

export default PaginationComponent;