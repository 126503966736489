import styled from "styled-components";
import {Paper, Typography} from "@material-ui/core";

export const ContainerCurrentPlanStyled = styled(Paper)`
  padding: 20px;
  background: transparent;
  border-width: 2px;
`;

export const FooterTextStyled = styled(Typography)`
  color: grey;    
`;