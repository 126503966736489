import React, {Component} from 'react';
import {Box, Grid, IconButton, Typography} from "@material-ui/core";
import {AddCircleOutline} from "@material-ui/icons";
import ItemEstablishment from "./ItemEstablishment";
import {ManageEstablishmentContext} from "../../../Contexts/ManageEstablishmentContext";
import {ContentNewEstablishmentStyled} from "./styled";

class ContentListEstablishment extends Component {
    static contextType = ManageEstablishmentContext;

    makeItemFidelity() {

        let itens = [];

        this.props.establishments.forEach(function (value, index) {
            itens.push(
                <ItemEstablishment establishment={value}/>
            );
        });

        return itens;
    }

    render() {
        return (
            <div>
                <Grid container={true}
                      spacing={3}>
                    {this.makeItemFidelity()}
                </Grid>
                <Grid container={true}
                      spacing={3}>
                    <ContentNewEstablishmentStyled display={'flex'}
                                                   alignItems="center"
                                                   onClick={() => this.context.toCreate()}>
                        <IconButton>
                            <AddCircleOutline/>
                        </IconButton>
                        <Typography>
                            Adicionar Novo Estabelecimento
                        </Typography>
                    </ContentNewEstablishmentStyled>
                </Grid>
            </div>
        );
    }
}

export default ContentListEstablishment;