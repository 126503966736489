import React, {Component} from 'react';
import {Box, Button, Typography} from "@material-ui/core";
import logo from "../../../Assets/logo_login.png";
import {LogoStyled404} from "./styled";
import SpacerComponent from "../../../Components/SpacerComponent/SpacerComponent";
import {Helmet} from "react-helmet";
import LoginScreen from "../../CommonAccess/LoginScreen/LoginScreen";

class RouteNotFound extends Component {
    static ROUTE = '/404'

    render() {
        return (
            <div>
                <Helmet>
                    <title>404</title>
                </Helmet>
                <Box display={'flex'}
                     flexDirection={'column'}
                     height={'100vh'}
                     justifyContent="center"
                     alignItems="center"
                     padding={'20px'}>
                    <LogoStyled404 src={logo}/>
                    <SpacerComponent height={20}/>
                    <Typography variant={'h6'}>
                        <b>404</b>
                    </Typography>
                    <Typography variant={'h6'}
                                align={'center'}>
                        A pagina que você tentou acessar não existe ou não esta disponível.
                    </Typography>
                    <SpacerComponent height={50}/>
                    <Button variant={'contained'}
                            color={'primary'}
                            href={LoginScreen.ROUTE}>
                        Voltar
                    </Button>
                </Box>
            </div>
        );
    }
}

export default RouteNotFound;