import React, {Component} from 'react';
import HeaderComponent from "../../../Components/HeaderComponent/HeaderComponent";
import {ContentMainStyled} from "./styled";
import {Grid, Typography} from "@material-ui/core";
import CardMyAccountScreen from "./CardMyAccountScreen";
import MyPlanAccountScreen from "./MyPlanAccountScreen";
import UserService from "../../../Services/UserService";
import MessageUtil from "../../../Utils/MessageUtil";
import {MyAccountContext} from "../../../Contexts/MyAccountContext";

class MyAccountScreen extends Component {
    static ROUTE = '/my-account';

    constructor(props) {
        super(props);

        this.state = {
            messagens: null,
            inLoad: false,
            myPlan: null,
        };

        this.userService = new UserService();
    }

    render() {
        return (
            <>
                <HeaderComponent title={'Minha Conta'}
                                 messagens={this.state?.messagens}
                                 inLoad={this.state.inLoad}
                                 menu={true}/>
                <ContentMainStyled>
                    <Grid container={true}
                          spacing={3}>
                        <CardMyAccountScreen/>
                        <MyPlanAccountScreen/>
                    </Grid>
                </ContentMainStyled>
            </>
        );
    }
}

export default MyAccountScreen;