import React, {Component} from 'react';
import { Typography} from "@material-ui/core";
import CheckItemChoicePlanScreen from "../ChoicePlanScreen/CheckItemChoicePlanScreen";
import {ContainerCurrentPlanStyled, FooterTextStyled} from "./styled";
import SpacerComponent from "../../../Components/SpacerComponent/SpacerComponent";

class CurrentPlanBillingDatailScreen extends Component {
    getTextToCLient(valueClients) {
        if (valueClients === null) {
            return 'Clientes ilimitados';
        }
        return `Até ${valueClients} Clientes`;
    }

    render() {
        if (this.props.payment === null) {
            return <></>;
        }
        return (
            <div>
                <ContainerCurrentPlanStyled variant={'outlined'}>
                    <Typography variant={'h6'}>
                        Plano Atual: Plano Cobre
                    </Typography>
                    <CheckItemChoicePlanScreen
                        label={this.getTextToCLient(this.props.payment.payment_plan.clients_limit)}
                        variant={'subtitle1'}/>
                    <CheckItemChoicePlanScreen
                        label={`Ate ${this.props.payment.payment_plan.companies_limit} CNPJ\`(s) cadastrado${this.props.payment.payment_plan.companies_limit > 1 ? 's' : ''}`}
                        variant={'subtitle1'}/>
                    <SpacerComponent height={20}/>
                    <Typography variant={'subtitle1'}>
                        Sua próxima fatura será dia {this.props.payment.next_billing_date}
                    </Typography>
                </ContainerCurrentPlanStyled>
                <FooterTextStyled variant={'subtitle2'}>
                    A mensalidade é cobrada no início de cada período de cobrança. Alguns dias podem transcorrer antes
                    da cobrança ser exibida na sua conta.
                </FooterTextStyled>
            </div>
        );
    }
}

export default CurrentPlanBillingDatailScreen;