import ApiClient from "./Api/ApiClient";

class PlanService {
    constructor() {
        this.apiClient = new ApiClient();
    }

    async retriveAll() {
        try {

            return await this.apiClient.doRequest({
                method: ApiClient.GET,
                uri: '/payment-plan',
                expectedStatus: 200,
            });

        } catch (e) {
            throw e;
        }
    }

    async getByCompany(uuid) {
        try {

            return await this.apiClient.doRequest({
                method: ApiClient.GET,
                uri: '/payment-plan/get-available-by-company/' + uuid,
                expectedStatus: 200,
            });

        } catch (e) {
            throw e;
        }
    }

    async subscribePlan({uuid, form = null}) {
        try {

            return await this.apiClient.doRequest({
                method: ApiClient.POST,
                uri: 'company/subscribe-plan/' + uuid,
                expectedStatus: 200,
                body: form,
            });

        } catch (e) {
            throw e;
        }
    }

    async updateSubscribePlan({uuid, form = null}) {
        try {

            return await this.apiClient.doRequest({
                method: ApiClient.POST,
                uri: 'company/update-payment-profile/' + uuid,
                expectedStatus: 200,
                body: form,
            });

        } catch (e) {
            throw e;
        }
    }

    async payments() {
        try {

            return await this.apiClient.doRequest({
                method: ApiClient.GET,
                uri: 'payment-plan/payments',
                expectedStatus: 200,
            });

        } catch (e) {
            throw e;
        }
    }

    async cancel() {
        try {

            return await this.apiClient.doRequest({
                method: ApiClient.GET,
                uri: 'company/cancel/current-plan',
            });

        } catch (e) {
            throw e;
        }
    }

    async upgradeAutoPlan() {
        try {

            return await this.apiClient.doRequest({
                expectedStatus: 200,
                method: ApiClient.POST,
                uri: 'company/upgrade-plan',
            });

        } catch (e) {
            throw e;
        }
    }

}

export default PlanService;