import * as yup from 'yup';
import ValidationUtil from "../Utils/ValidationUtil";

class GoalClientValidation {

    static async validate(form) {
        try {

            let objectValidade = {
                consultant_uuid: yup
                    .string()
                    .required(ValidationUtil.MSG_FIELD_REQUIRED),
                meta: yup
                    .number(ValidationUtil.MSG_FIELD_NUMBER)
                    .max(999999999, ValidationUtil.MSG_FIELD_MAX_NUMBER)
                    .typeError(ValidationUtil.MSG_FIELD_NUMBER)
                    .positive(ValidationUtil.MSG_FIELD_POSITIVE)
                    .required(ValidationUtil.MSG_FIELD_REQUIRED),
                payment_plan_meta_uuid: yup
                    .string()
                    .required(ValidationUtil.MSG_FIELD_REQUIRED),
                sobremeta: yup
                    .number(ValidationUtil.MSG_FIELD_NUMBER)
                    .max(999999999, ValidationUtil.MSG_FIELD_MAX_NUMBER)
                    .typeError(ValidationUtil.MSG_FIELD_NUMBER)
                    .positive(ValidationUtil.MSG_FIELD_POSITIVE),
                payment_plan_sobremeta_uuid: yup
                    .string(),
                supermeta: yup
                    .number(ValidationUtil.MSG_FIELD_NUMBER)
                    .max(999999999, ValidationUtil.MSG_FIELD_MAX_NUMBER)
                    .typeError(ValidationUtil.MSG_FIELD_NUMBER)
                    .positive(ValidationUtil.MSG_FIELD_POSITIVE),
                payment_plan_supermeta_uuid: yup
                    .string(),
                start_date: yup
                    .string()
                    .required(ValidationUtil.MSG_FIELD_REQUIRED),
                end_date: yup
                    .date()
                    .when(
                        'start_validity',
                        (startDate, schema) => (startDate && schema.min(
                            startDate,
                            ValidationUtil.MSG_DATE_RANGE_END
                        )),
                    ).required(ValidationUtil.MSG_FIELD_REQUIRED),
            };

            let validate = yup.object().shape(objectValidade);

            validate.validateSync(form, {abortEarly: false})

            return true;

        } catch (e) {
            let erros = [];
            e.inner.forEach(function (value, index) {
                erros[value.path] = value.message;
            });

            return erros;
        }
    }

}

export default GoalClientValidation;