import React, {Component} from 'react';
import HeaderComponent from "../../../Components/HeaderComponent/HeaderComponent";
import {ContentChartStyled, ContentMainStyled, ModalChart} from "./styled";
import MessageUtil from "../../../Utils/MessageUtil";
import SpacerComponent from "../../../Components/SpacerComponent/SpacerComponent";
import ConsultantService from "../../../Services/ConsultantService";
import TableConsultantScreen from "./TableConsultantScreen";
import FiltersConsultantScreen from "./FiltersConsultantScreen";
import {Chart} from "react-google-charts";
import {IconButton, Typography} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import TextFieldDefault from "../../../Components/TextFieldDefaultComponent/TextFieldDefault";
import SearchTextFieldComponent from "./SearchTextFieldComponent/SearchTextFieldComponent";
import DetailsConsultantModal from "./DetailsConsultantModal";

class ConsultantScreen extends Component {
    static ROUTE = '/consultant';

    constructor(props) {
        super(props);

        this.state = {
            inLoad: false,
            messagens: null,
            data: [],
            dataChart: [],
            showChart: false,
            noDataChart: false,
            noDataChartValor: false,
            filterRole: undefined,
            consultValue: null,
        };

        this.consultantService = new ConsultantService();
    }

    componentDidMount() {
        this.load();
    }

    load = async (withFilter = false) => {
        try {
            this.setState({
                inLoad: true,
            });

            let filters = '';
            let role = undefined;

            if (withFilter === true) {
                const filters_role = this.getFilters();
                filters = filters_role.filter;
                role = filters_role.role;
            }

            const response = await this.consultantService.get(filters);

            this.setState({
                inLoad: false,
                data: response.data.data,
                filterRole: role,
            });
        } catch (e) {
            this.setState({
                inLoad: false,
                messagens: MessageUtil.make({
                    title: 'Atenção',
                    body: e,
                }),
            });
        }
    }

    filtersPaymentStatusScreen = (context) => {
        this.filtersPaymentStatusScreenContext = context;
    }

    getFilters = () => {

        const object = this.filtersPaymentStatusScreenContext.state;

        let filter = '&';
        let role = undefined;

        for (var key in object) {
            if (object.hasOwnProperty(key)) {
                if (object[key] === null || object[key] === undefined) {
                    continue;
                }

                if (key === 'role') {
                    role = object[key];
                    continue;
                }

                filter += `${key}=${object[key]}&`
            }
        }

        return {filter, role};
    }

    showModal = async (value, initial = undefined, final = undefined) => {
        try {

            const response = await this.consultantService.getDataChart({
                uuid: value?.uuid ?? this.state.selectUuid,
                final,
                initial,
            });

            this.setState({
                noDataChart: false,
                noDataChartValor: false,
                dataChart: response.data,
                showChart: true,
                selectUuid: value?.uuid ?? this.state.selectUuid,
                consultValue: value,
            });

        } catch (e) {
            this.setState({
                inLoad: false,
                messagens: MessageUtil.make({
                    title: 'Atenção',
                    body: e,
                }),
            });
        }
    }

    formatData() {

        const formatData = [
            ['Data', 'Meta', 'Sobre Meta', 'Super Meta'],
        ];

        const metasClientes = this.state.dataChart.meta_clientes;

        if (metasClientes.length === 0) {
            this.setState({
                noDataChart: true,
            });
            return;
        }

        Object.keys(metasClientes).forEach(function (key) {
            const arrayData = [];

            arrayData.push(key);
            arrayData.push(parseInt(metasClientes[key].meta.value));
            arrayData.push(parseInt(metasClientes[key].sobremeta.value));
            arrayData.push(parseInt(metasClientes[key].supermeta.value));

            formatData.push(arrayData);
        });

        return formatData;
    }

    formatDataValor() {

        const formatData = [
            ['Data', 'Meta', 'Sobre Meta', 'Super Meta'],
        ];

        const meta_valores = this.state.dataChart.meta_valor;

        if (meta_valores.length === 0) {
            this.setState({
                noDataChartValor: true,
            });
            return;
        }

        Object.keys(meta_valores).forEach(function (key) {
            const arrayData = [];

            arrayData.push(key);
            arrayData.push(parseInt(meta_valores[key].meta));
            arrayData.push(parseInt(meta_valores[key].sobremeta));
            arrayData.push(parseInt(meta_valores[key].supermeta));

            formatData.push(arrayData);
        });

        return formatData;
    }

    closeModal = () => {
        this.setState({
            showChart: false,
        });
    }

    render() {
        return (
            <div>
                <HeaderComponent title={'Consultores'}
                                 menu={true}
                                 messagens={this.state.messagens}
                                 inLoad={this.state.load}/>
                <ContentMainStyled>
                    {this.state.showChart &&
                        <ModalChart elevation={3}>
                            <IconButton onClick={this.closeModal}>
                                <CloseIcon/>
                            </IconButton>
                            <SearchTextFieldComponent showModal={this.showModal}/>
                            <SpacerComponent height={20}/>
                            <ContentChartStyled>
                                {this.state.noDataChart === false &&
                                    <Chart
                                        width={500}
                                        height={500}
                                        chartType="ColumnChart"
                                        loader={<div>Loading Chart</div>}
                                        data={this.formatData()}
                                        options={{
                                            title: 'Metas por cliente',
                                        }}
                                    />
                                }
                                {this.state.noDataChart === true &&
                                    <Typography variant={'h6'}
                                                align={'center'}>
                                        Nao existe dados para o grafico de <b>Metas por cliente</b>
                                    </Typography>
                                }
                                {this.state.noDataChartValor === false &&
                                    <Chart
                                        width={500}
                                        height={500}
                                        chartType="ColumnChart"
                                        loader={<div>Loading Chart</div>}
                                        data={this.formatDataValor()}
                                        options={{
                                            title: 'Metas por valor',
                                        }}
                                    />
                                }
                                {this.state.noDataChartValor === true &&
                                    <Typography variant={'h6'}
                                                align={'center'}>
                                        Nao existe dados para o grafico de <b>Metas por valor</b>
                                    </Typography>
                                }
                            </ContentChartStyled>
                            <DetailsConsultantModal value={this.state.consultValue}/>
                            <SpacerComponent height={40}/>
                        </ModalChart>
                    }
                    <SpacerComponent height={50}/>
                    <FiltersConsultantScreen filtersPaymentStatusScreen={this.filtersPaymentStatusScreen}
                                             load={this.load}/>
                    <TableConsultantScreen data={this.state.data}
                                           showModal={this.showModal}
                                           filterRole={this.state.filterRole}/>
                </ContentMainStyled>
            </div>
        );
    }
}

export default ConsultantScreen;