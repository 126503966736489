import React, {Component} from 'react';
import HeaderComponent from "../../../Components/HeaderComponent/HeaderComponent";
import {ContentMainStyled, ContentSizeStyled} from "./styled";
import StaticElementForgotPasswordScreen from "./StaticElementForgotPasswordScreen";
import FormForgotPasswordScreen from "./FormForgotPasswordScreen";
import UserService from "../../../Services/UserService";
import ForgotValidation from "../../../FormValidations/ForgotValidation";
import MessageUtil from "../../../Utils/MessageUtil";

class ForgotPassword extends Component {
    static ROUTE = '/forgot-password';

    userService;

    constructor(props) {
        super(props);

        this.state = {
            inLoad: false,
            erros: [],
        };

        this.userService = new UserService();
    }

    doForgot = async (form) => {
        try {

            const validate = await ForgotValidation.validate(form);

            if (validate !== true) {
                this.setState({
                    erros: validate,
                });
                return;
            }

            this.setState({
                inLoad: true,
            });

            await this.userService.forgotPassword(form);

            this.setState({
                inLoad: false,
                erros: [],
                messagens: MessageUtil.make({
                    title: 'Sucesso',
                    body: 'Um email com instruções de recuperação foi enviado para '
                        + form.email,
                }),
            });

        } catch (e) {
            this.setState({
                inLoad: false,
                messagens: MessageUtil.make({
                    title: 'Atenção',
                    body: e,
                }),
            });
        }
    }

    render() {
        return (
            <div>
                <HeaderComponent messagens={this.state?.messagens}
                                 inLoad={this.state.inLoad}
                                 title={'Meu App | Esqueci minha senha'}/>
                <ContentMainStyled>
                    <ContentSizeStyled>
                        <StaticElementForgotPasswordScreen/>
                        <FormForgotPasswordScreen doForgot={this.doForgot}
                                                  erros={this.state.erros}/>
                    </ContentSizeStyled>
                </ContentMainStyled>
            </div>
        );
    }
}

export default ForgotPassword;