class RoleUtil {

    static OPERADOR_LOJA = '62461086-644a-4db1-b2a3-fecc74d7aafe';
    static APP_WEB = 'app_web';
    static CLIENTE_PF = 'c2787a3a-48ce-4177-90f7-69adb84b3362';
    static ADMIN_UUID = '88e86360-14d2-11ec-882b-7fa95d44d862';
    static ADMIN_NAME = 'ADMIN';
    static PJ_NAME = 'PJ';
    static CONSULTOR = '9d51a372-1d6d-11ec-9621-0242ac130002';
    static COORDENADOR = 'b90f99fc-1d6d-11ec-9621-0242ac130002';
    static CONSULTOR_REGIONAL = 'b65175a0-1d6d-11ec-9621-0242ac130002';

}

export default RoleUtil;