import React, {Component} from 'react';
import {IconButton, InputAdornment, TextField} from "@material-ui/core";
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import FormMask from "../../FromMask/FormMask";
import InputMask from 'react-input-mask';
import CurrencyInput from "react-currency-input-field";


class TextFieldDefault extends Component {
    constructor(props) {
        super(props);

        this.state = {
            showPassword: false,
            maskPhone: FormMask.phoneFix,
        };
    }

    tryGetErro() {
        try {

            return this.props.erros[this.props.name];

        } catch (e) {
            return undefined;
        }
    }

    isErro() {
        try {

            const value = this.props.erros[this.props.name];

            if (value !== undefined) {
                return true;
            }

        } catch (e) {
            return undefined;
        }
    }

    shrink() {

        if (
            this.props.forceLabel !== undefined
            &&
            this.props.forceLabel === true) {
            return true;
        }

        if (this.props.value !== undefined) {
            return true;
        }

        return undefined;
    }

    getPasswordVisibility() {

        if (this.props.type !== 'password') {
            return undefined;
        }

        return (
            <InputAdornment position={'end'}>
                <IconButton onClick={this.showPassword}>
                    {!this.state.showPassword ? <VisibilityIcon/> : <VisibilityOffIcon/>}
                </IconButton>
            </InputAdornment>
        );
    }

    showPassword = () => {

        this.setState({
            showPassword: !this.state.showPassword,
        });

    }

    getType() {
        if (this.props.type === 'password' && this.state.showPassword) {
            return 'text';
        }

        return this.props.type;
    }

    getMask() {
        if (this.props.phone === true) {
            return this.state.maskPhone;
        }

        return this.props.mask;
    }

    onChange() {
        this.logicMask();
        return this.props.onChange;
    }

    logicMask() {
        if (this.props.phone === true) {
            const newValue = this.props.value?.replace(/\D/g, "");

            if (newValue === undefined) {
                return;
            }
            if (newValue.length > 10 && this.state.maskPhone !== FormMask.phone) {
                this.setState({
                    maskPhone: FormMask.phone,
                });
            }

            if (newValue.length <= 10 && this.state.maskPhone !== FormMask.phoneFix) {
                this.setState({
                    maskPhone: FormMask.phoneFix,
                });
            }
        }
    }

    getTextField() {
        return (
            <TextField label={this.props.label}
                       value={this.props.value}
                       onChange={this.onChange()}
                       disabled={this.props.disabled}
                       variant={'outlined'}
                       fullWidth={(this.props.fullWidth ?? true)}
                       select={this.props.select}
                       margin={this.props.margin ?? 'normal'}
                       name={this.props.name}
                       inputProps={this.props.inputProps}
                       type={this.getType()}
                       multiline={this.props.multiline}
                       rows={this.props.rows}
                       defaultValue={this.props.defaultValue}
                       error={this.isErro()}
                       helperText={this.tryGetErro()}
                       size={this.props.size}
                       InputLabelProps={{
                           shrink: this.shrink()
                       }}
                       InputProps={{
                           endAdornment: this.getPasswordVisibility(),
                       }}>
                {this.props.children}
            </TextField>
        );
    }

    getMoneyTextField() {
        return (
            <TextField label={this.props.label}
                       value={this.props.value}
                       onChange={this.onChange()}
                       disabled={this.props.disabled}
                       variant={'outlined'}
                       fullWidth={(this.props.fullWidth ?? true)}
                       margin={'normal'}
                       name={this.props.name}
                       inputProps={this.props.inputProps}
                       error={this.isErro()}
                       helperText={this.tryGetErro()}
                       size={this.props.size}
                       InputLabelProps={{
                           shrink: this.shrink()
                       }}
                       InputProps={{
                           inputComponent: this.moneyTextField,
                       }}/>
        );
    }

    moneyTextField = ({inputRef, onChange, ...props}) => {
        return (
            <CurrencyInput
                {...props}
                decimalsLimit={2}
                disableGroupSeparators={false}
                decimalSeparator={','}
                groupSeparator={'.'}
                decimalScale={2}
                /*fixedDecimalLength={2}*/
                allowNegativeValue={false}
                prefix={this.props?.moneyPrefix ?? ''}
                onValueChange={(value, name) =>
                    onChange({
                        target: {value, name}
                    })
                }
            />
        );
    }

    render() {
        if (this.props.multiline === true) {
            return this.getTextField();
        }

        if (this.props.money === true) {
            return this.getMoneyTextField();
        }

        return (
            <InputMask mask={this.getMask()}
                       onChange={this.onChange()}
                       disabled={this.props.disabled}
                       value={this.props.value}
                       multiline={this.props.multiline}>
                {() => this.getTextField()}
            </InputMask>
        );
    }
}

export default TextFieldDefault;