import React, {Component} from 'react';
import HeaderComponent from "../../../Components/HeaderComponent/HeaderComponent";
import {Box, Button, Typography} from "@material-ui/core";
import GalleryStory from "./GalleryStory";
import SpacerComponent from "../../../Components/SpacerComponent/SpacerComponent";
import {StoryContext} from "../../../Contexts/StoryContext";
import StoryService from "../../../Services/StoryService";
import MessageUtil from "../../../Utils/MessageUtil";
import {SuperContext} from "../../../Contexts/SuperContext";

class StoryScreen extends Component {
    static ROUTE = '/story';
    static contextType = SuperContext;

    constructor(props) {
        super(props);

        this.state = {
            inLoad: false,
            erros: [],
            images: [],
            imagesSaves: [],
        };

        this.storyService = new StoryService();
    }

    componentDidMount() {
        this.load();
    }

    load = async () => {
        try {
            this.setState({
                inLoad: true,
            });
            const companyUuid = this.context.me.company.uuid;

            const response = await this.storyService.get(companyUuid);

            this.setState({
                imagesSaves: response.data.data,
            });

            this.setState({
                inLoad: false,
            });

        } catch (e) {
            this.setState({
                inLoad: false,
                messagens: MessageUtil.make({
                    title: 'Atenção',
                    body: e,
                }),
            });
        }
    }

    setImage = (index, image) => {
        const newImages = this.state.images;
        newImages[index] = image;
        this.setState({
            images: newImages,
        })
    }

    createConfirm = () => {
        this.setState({
            inLoad: false,
            messagens: MessageUtil.make({
                title: 'Atenção',
                body: 'Deseja realmente publicar esse story?',
                okButtonFunction: this.create,
                okButtonText: 'Sim',
                cancelButtonText: 'Não',
            }),
        });
    }

    create = async () => {
        try {
            this.setState({
                inLoad: true,
            });

            const companyUuid = this.context.me.company.uuid;

            for (const image of this.state.images) {
                if (!image) {
                    return;
                }
                await this.storyService.create(
                    companyUuid,
                    image
                );
            }

            this.load();

            this.setState({
                inLoad: false,
                messagens: MessageUtil.make({
                    title: 'Sucesso',
                    body: 'Story foi publicado',
                }),
            });
        } catch (e) {
            this.setState({
                inLoad: false,
                messagens: MessageUtil.make({
                    title: 'Atenção',
                    body: e,
                }),
            });
        }
    }

    doDeleteConfirm = (uuid) => {
        this.setState({
            messagens: MessageUtil.make({
                title: 'Excluir Story?',
                body: 'Essa ação não poderá ser desfeita. Deseja continuar?',
                cancelButtonText: 'Não',
                okButtonText: 'Sim',
                okButtonFunction: () => this.doDelete(uuid),
            }),
        });
    }

    doDelete = async (uuid) => {
        try {
            this.setState({
                inLoad: true,
            });

            await this.storyService.delete(
                uuid
            );

            this.setState({
                inLoad: false,
                messagens: MessageUtil.make({
                    title: 'Sucesso',
                    body: 'Story foi excluido',
                    okBtnToRoute: StoryScreen.ROUTE,
                }),
            });
        } catch (e) {
            this.setState({
                inLoad: false,
                messagens: MessageUtil.make({
                    title: 'Atenção',
                    body: e,
                }),
            });
        }
    }

    render() {
        return (
            <StoryContext.Provider value={{
                setImage: this.setImage,
                doDeleteConfirm: this.doDeleteConfirm,
            }}>
                <HeaderComponent title={'Gerenciamento de Story'}
                                 messagens={this.state?.messagens}
                                 inLoad={this.state.inLoad}
                                 menu={true}/>
                <Box display={'flex'}
                     justifyContent={'center'}
                     alignItems={'center'}>
                    <Box width={'80%'}>
                        <Typography align={'center'}
                                    variant={'h6'}
                                    color={'primary'}>
                            Gerenciamento de Story
                        </Typography>
                        <SpacerComponent height={20}/>
                        <Typography align={'center'}
                                    variant={'subtitle1'}>
                            Clique no centro para fazer o upload das imagens
                        </Typography>
                        <SpacerComponent height={20}/>
                        <Typography align={'center'}
                                    variant={'body1'}
                                    color={'error'}>
                            *Para que o seu conteúdo seja exibido em tela cheia, certifique-se de utilizar o formato de
                            1.080px (largura) por 1.920px (altura) em uma proporção de 9:16.
                        </Typography>
                        <Typography align={'center'}
                                    variant={'body1'}
                                    color={'error'}>
                            *Serão aceitas imagens com até 200k
                        </Typography>
                        <SpacerComponent height={50}/>
                        <SpacerComponent height={20}/>
                        <GalleryStory imagesSaves={this.state.imagesSaves}/>
                        <SpacerComponent height={50}/>
                    </Box>
                </Box>
                <Box display={'flex'}
                     justifyContent={'center'}
                     alignItems={'center'}>
                    <Button variant={'contained'}
                            color={'primary'}
                            onClick={this.createConfirm}>Salvar</Button>
                </Box>
            </StoryContext.Provider>
        );
    }
}

export default StoryScreen;