import styled from 'styled-components';
import {Typography} from "@material-ui/core";
import TextFieldDefault from "../../../Components/TextFieldDefaultComponent/TextFieldDefault";

export const ContentMainStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;    
`;

export const ContentSizeStyled = styled.div`  
  width: 400px;    
  padding: 20px;
`;

export const Logo = styled.img`
  width: 120px;
  height: 122px;
  border-radius: 20px;
  margin-top: 50px;
`;

export const Title = styled(Typography)`
  line-height: 1.0;
`;

export const ContentButton = styled.div`
  padding-left: 40px;
  padding-right: 40px;
`;

export const ContentQtdStyled = styled(TextFieldDefault)`
    width: 20%;
`;

export  const ContentLinkStyled = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  a {
    font-size: 15px;
    color: orangered;
  }
`;