import React, {Component} from 'react';
import {ContentMainStyled, ContentSizeStyled} from "../../CommonAccess/LoginScreen/styled";
import CheckBoxDefaultComponent from "../../../Components/CheckBoxDefaultComponent/CheckBoxDefaultComponent";
import TextFieldDefault from "../../../Components/TextFieldDefaultComponent/TextFieldDefault";
import SpacerComponent from "../../../Components/SpacerComponent/SpacerComponent";
import {Box, Button, MenuItem, Typography} from "@material-ui/core";
import CheckValidDateNewAwardScreen from "../NewAward/CheckValidDateNewAwardScreen";
import FidelityService from "../../../Services/FidelityService";
import FormMask from "../../../FromMask/FormMask";
import ApiImageUtil from "../../../Utils/ApiImageUtil";
import {CashbackContext} from "../../../Contexts/CashbackContext";
import MessageUtil from "../../../Utils/MessageUtil";
import InputFileDefault from "../../../Components/InputFileDefault/InputFileDefault";
import AvatarComponent from "../../../Components/AvatarComponent/AvatarComponent";
import {ButtonDelete, ShowSelectData} from "./styled";
import FixSelectComponent from "../../../Components/FixSelect/FixSelect";

class FormCampaignScreen extends Component {
    static contextType = CashbackContext;

    constructor(props) {
        super(props);

        this.state = {
            type: 2,
            value_type: null,
            fidelity: [],
            readyPage: false,
            birthday: true,
            day: false,
            month: false,
        };

        this.fidelityService = new FidelityService();
    }

    componentDidMount() {
        this.load();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.formEdit === prevProps.formEdit) {
            return;
        }
        this.setFormEdit();
    }

    setFormEdit() {
        const newState = Object.assign(this.state, this.props.formEdit);
        newState.imagePrevios = ApiImageUtil.tryGet(this.props.formEdit.image);
        newState.birthday = this.props.formEdit.birthday;
        newState.action_to_active = this.props.formEdit.day === true ? 1 : 2;
        newState.start_at = (
            this.props.formEdit.start_at === null ? undefined : this.props.formEdit.start_at
        );
        newState.end_at = (
            this.props.formEdit.end_at === null ? undefined : this.props.formEdit.end_at
        );
        if (this.props.formEdit.start_at === null) {
            newState.withoutValidDate = true;
        }
        newState.readyPage = true;
        this.setState(newState);
    }

    async load() {
        try {

            const response = await this.fidelityService.getByUser();

            this.setState({
                fidelity: response.data.data
            });

        } catch (e) {
            throw e;
        }
    }

    handleChange(event) {
        const {name, value} = event.target;
        if (name === 'reward_factor') {
            if (value < 0 || value > 100) {
                this.context.parent.setState({
                    messagens: MessageUtil.make({
                        title: 'Atenção',
                        body: 'Os valores aceitos devem estrar entre 0 e 100',
                    }),
                });
                return;
            }
        }
        if (name === 'action_to_active') {
            if (value === 1) {
                this.setState({
                    day: true,
                    month: false,
                });
            }
            if (value === 2) {
                this.setState({
                    day: false,
                    month: true,
                });
            }
        }

        this.setState({[name]: value});
    }

    handlerSubmit = () => {
        this.props.doCreate(this.state);
    }

    renderSelect() {

        if (this.props.isEdit === null) {
            return <div></div>;
        }

        if (this.props.isEdit === false) {
            return this.componentSelect();
        }

        if (this.props.isEdit === true && this.state.fidelity_program_uuid === undefined) {
            return <div></div>;
        }

        return this.componentSelect();

    }

    componentSelect() {
        return (
            <TextFieldDefault select={true}
                              name={'fidelity_program_uuid'}
                              value={this.state.fidelity_program_uuid}
                              onChange={(event) => this.handleChange(event)}
                              erros={this.props.erros}
                              disabled={this.props.isEdit}>
                {this.makeFidelity()}
            </TextFieldDefault>
        );
    }

    makeFidelity() {
        let itens = [];
        this.state.fidelity.forEach(function (value, index) {
            itens.push(
                <MenuItem key={index} value={value.uuid}>{value.name}</MenuItem>
            );
        })

        return itens;
    }

    setPreviosImage = (image) => {
        this.setState({
            imagePrevios: image,
        });
    }

    toBase64Image = (base64) => {
        this.setState({
            'image': base64.split(',')[1],
            btnAlterDisabled: false,
        });
    }

    handleValidDateChange(value) {
        this.setState({
            withoutValidDate: value,
        });
    }

    render() {
        if (!this.state.readyPage && this.props.isEdit) {
            return <></>;
        }
        return (
            <ContentMainStyled>
                <ContentSizeStyled>
                    <TextFieldDefault label={'Título *'}
                                      name={'title'}
                                      value={this.state.title}
                                      onChange={(event) => this.handleChange(event)}
                                      erros={this.props.erros}
                                      disabled={this.props.isEdit}/>
                    <FixSelectComponent>
                        <TextFieldDefault label={'Ação para ativar o fator de premiação*'}
                                          name={'action_to_active'}
                                          select={true}
                                          value={this.state.action_to_active}
                                          onChange={(event) => this.handleChange(event)}
                                          erros={this.props.erros}
                                          disabled={this.props.isEdit}>
                            <MenuItem key={'1'} value={1}>Dia do aniversário</MenuItem>
                            <MenuItem key={'2'} value={2}>Mês de aniversário</MenuItem>
                        </TextFieldDefault>
                    </FixSelectComponent>
                    <TextFieldDefault label={'Fator de premiação *'}
                                      name={'reward_factor'}
                                      type={'number'}
                                      value={this.state.reward_factor}
                                      onChange={(event) => this.handleChange(event)}
                                      erros={this.props.erros}
                                      disabled={this.props.isEdit}/>
                    <Typography variant={'caption'}
                                color={'error'}>Pedimos atenção ao cadastro do fator de multiplicação, pois cada
                        nota
                        escaneada terá seu
                        valor multiplicado pelo mesmo. Ex: Uma nota de R$500,00 multiplicada por um fator 100
                        (limite
                        máximo), dará ao seu cliente uma pontuação de 50.000 pontos.</Typography>
                    <TextFieldDefault label={'Regras'}
                                      name={'rules'}
                                      value={this.state.rules}
                                      type={'number'}
                                      multiline={true}
                                      rows={5}
                                      onChange={(event) => this.handleChange(event)}
                                      erros={this.props.erros}
                                      disabled={this.props.isEdit}/>
                    <Box display={'flex'}
                         flexDirection={'row'}
                         alignItems={'center'}>
                        <AvatarComponent
                            src={this.state.imagePrevios}/>
                        <SpacerComponent width={20}/>
                        <InputFileDefault setPreviosImage={this.setPreviosImage}
                                          toBase64Image={this.toBase64Image}
                                          disabled={this.props.isEdit}
                                          onMaxSize={(value) => {
                                              this.context.parent.setState({
                                                  messagens: MessageUtil.make({
                                                      title: 'Atenção',
                                                      body: `Arquivo não pode ter mais que ${value} mb`
                                                  }),
                                              })
                                          }}/>
                    </Box>
                    <SpacerComponent height={20}/>
                    <Typography variant={'body1'}
                                color={'primary'}>
                        Período de Validade
                    </Typography>
                    <Box display={'flex'}
                         flexDirection={'row'}>
                        <TextFieldDefault label={'Data Inicial *'}
                                          name={'start_at'}
                                          value={this.state.start_at}
                                          onChange={(event) => this.handleChange(event)}
                                          type={'date'}
                                          erros={this.props.erros}
                                          forceLabel={true}/>
                        <SpacerComponent width={40}/>
                        <TextFieldDefault label={'Data Final *'}
                                          name={'end_at'}
                                          value={this.state.end_at}
                                          type={'date'}
                                          onChange={(event) => this.handleChange(event)}
                                          erros={this.props.erros}
                                          forceLabel={true}/>
                    </Box>
                    <CheckValidDateNewAwardScreen onChange={(value) => this.handleValidDateChange(value)}
                                                  withoutValidDate={this.state.withoutValidDate}
                                                  disabled={this.props.isEdit}/>
                    <SpacerComponent height={20}/>
                    <Typography variant={'body1'}
                                color={'primary'}>
                        Programa de Fidelidade
                    </Typography>
                    {this.renderSelect()}
                    <SpacerComponent height={20}/>
                    {this.props.isEdit &&
                        <>
                            <SpacerComponent height={40}/>
                            <Box display="flex" justifyContent="center">
                                <Button variant={'contained'}
                                        color={'primary'}
                                        size={"large"}
                                        fullWidth={true}
                                        onClick={this.handlerSubmit}>
                                    Salvar
                                </Button>
                            </Box>
                            <SpacerComponent height={20}/>
                        </>
                    }
                    {this.props.isEdit === false &&
                        <Box display="flex" justifyContent="center">
                            <Button variant={'contained'}
                                    color={'primary'}
                                    size={"large"}
                                    fullWidth={true}
                                    onClick={this.handlerSubmit}>
                                {this.context.parent.state.action === 'create' ? 'Registrar Campanha' : 'Alterar Campanha'}
                            </Button>
                        </Box>
                    }
                    {this.props.isEdit &&
                        <Box display="flex" justifyContent="center">
                            <ButtonDelete variant={'contained'}
                                          size={"large"}
                                          fullWidth={true}
                                          onClick={() => this.props.doDelete(this.state.uuid)}>
                                Deletar
                            </ButtonDelete>
                        </Box>
                    }
                </ContentSizeStyled>
            </ContentMainStyled>
        );
    }
}

export default FormCampaignScreen;