class ApiImageUtil {

    static tryGet(images) {
        try{

            return images[images.length - 1].url;

        }catch (e){
            return '';
        }
    }

}

export default ApiImageUtil;