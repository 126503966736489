import React, {Component} from 'react';
import {Box, Link, Menu, MenuItem, Typography} from "@material-ui/core";
import SpacerComponent from "../SpacerComponent/SpacerComponent";
import AccountCircleOutlinedIcon from '@material-ui/icons/AccountCircleOutlined';
import {AvatarHeaderStyled, ContentConfigIcon, MenuStyled, TextConfigStyled} from "./styled";
import NewAward from "../../Screens/PJAccess/NewAward/NewAward";
import DashboardScreen from "../../Screens/PJAccess/DashboardScreen/DashboardScreen";
import ListNewAwardScreen from "../../Screens/PJAccess/NewAward/ListNewAwardScreen";
import ExtractScreen from "../../Screens/PJAccess/ExtractScreen/ExtractScreen";
import SacScreen from "../../Screens/PJAccess/SACScreen/SACScreen";
import OperatorScreen from "../../Screens/PJAccess/OperatorScreen/OperatorScreen";
import CompanyRegistrationScreen from "../../Screens/PJAccess/CompanyRegistrationScreen/CompanyRegistrationScreen";
import MeUtil from "../../Utils/MeUtil";
import ManageFidelityScreen from "../../Screens/PJAccess/ManageFidelityScreen/ManageFidelityScreen";
import ManageEstablishmentScreen from "../../Screens/PJAccess/ManageEstablishmentScreen/ManageEstablishmentScreen";
import ApiToken from "../../Services/Api/ApiToken";
import ChoicePlanScreen from "../../Screens/PJAccess/ChoicePlanScreen/ChoicePlanScreen";
import MyAccountScreen from "../../Screens/PJAccess/MyAccountScreen/MyAccountScreen";
import {HeaderContext} from "../../Contexts/HeaderContext";
import CashbackScreen from "../../Screens/PJAccess/CashbackScreen/CashbackScreen";
import SettingsIcon from '@material-ui/icons/Settings';
import CreateConsultantScreen from "../../Screens/AdminAccess/CreateConsultantScreen/CreateConsultantScreen";
import ConsultantScreen from "../../Screens/AdminAccess/ConsultantScreen/ConsultantScreen";
import CreateGoalsScreen from "../../Screens/AdminAccess/CreateGoalsScreen/CreateGoalsScreen";
import DashboardAdminScreen from "../../Screens/AdminAccess/DashboardAdminScreen/DashboardAdminScreen";
import InstantNewAward from "../../Screens/PJAccess/InstantAward/InstantNewAward";
import PaymentStatusScreen from "../../Screens/AdminAccess/PaymentStatusScreen/PaymentStatusScreen";
import CreateGoalsClientScreen from "../../Screens/AdminAccess/CreateGoalsClientScreen/CreateGoalsClientScreen";
import StoryScreen from "../../Screens/PJAccess/StoryScreen/StoryScreen";
import CampaignScreen from "../../Screens/PJAccess/CampaignScreen/CampaignScreen";
import ListCampaingScreen from "../../Screens/PJAccess/NewAward/ListCampaingScreen";
import SelectAwardScreen from "../../Screens/PJAccess/SelectAwards/SelectAwardScreen";
import CompanyNotRegisteredScreen
    from "../../Screens/AdminAccess/CompanyNotRegisteredScreen/CompanyNotRegisteredScreen";
import SelectCampaign from "../../Screens/PJAccess/SelectCampaign/SelectAwardScreen";
import AccountDeleteScreen from "../../Screens/PJAccess/AccountDelete/AccountDeleteScreen";
import CampaignNameScreen from "../../Screens/PJAccess/CampaignNameScreen/CampaignNameScreen";
import CampaignBirthdayScreen from "../../Screens/PJAccess/CampaignBirthdayScreen/CampaignScreen";
import CompanyRegistrationFidelityScreen
    from "../../Screens/PJAccess/CompanyRegistrationFidelityScreen/CompanyRegistrationFidelityScreen";
import ExportClientDataScreen from "../../Screens/PJAccess/ExportClientData/ExportClientDataScreen";
import ClickAreaComponent from "../ClickAreaComponent/ClickAreaComponent";
import GiftCardPurchaseScreen from "../../Screens/PJAccess/GiftCardPurchase/GiftCardPurchaseScreen";
import GiftCardScreen from "../../Screens/PJAccess/GiftCardScreen/GiftCardScreen";
import ReleaseAwardScreen from "../../Screens/PJAccess/release_award/ReleaseAwardScreen";
import ManualScoringScreen from "../../Screens/PJAccess/ManualScoringScreen/ManualScoringScreen";

class MenuHeader extends Component {
    static contextType = HeaderContext;

    constructor(props) {
        super(props);

        this.state = {
            anchorEl: null,
            companyUuid: '',
            anchorElAward: null,
            anchorElPremio: null,
            anchorElCampaign: null,
        };
    }

    componentDidMount() {
        this.context.parent.setState({
            goToMyPlan: this.goToMyPlan,
            logout: this.logout,
        });
        /*this.load();*/
    }

    async load() {
        try {

            const response = await MeUtil.get()

            this.setState({
                companyUuid: response.data.data?.company?.uuid,
                meUtil: response.data.data,
            });

        } catch (e) {
            throw e;
        }
    }

    handleClick = (event) => {
        this.setState({
            anchorEl: event.currentTarget
        })
    }

    handleClickPremio = (event) => {
        this.setState({
            anchorElPremio: event.currentTarget,
            anchorElCampaign: null,
        })
    }

    handleClickCampaing = (event) => {
        this.setState({
            anchorElCampaign: event.currentTarget,
            anchorElPremio: null,
        })
    }

    handleClose = (route) => {

        if (typeof route === "object") {
            this.setState({
                anchorEl: null
            })
            return;
        }

        window.location.href = route;

        this.setState({
            anchorEl: null
        })
    };

    handleClosePremio = (route) => {

        if (typeof route === "object") {
            this.setState({
                anchorElPremio: null
            })
            return;
        }

        window.location.href = route;

        this.setState({
            anchorElPremio: null
        })
    };

    handleCloseCampaign = (route) => {

        if (typeof route === "object") {
            this.setState({
                anchorElCampaign: null
            })
            return;
        }

        window.location.href = route;

        this.setState({
            anchorElCampaign: null
        })
    };

    goToMyPlan = async () => {
        try {
            if (this.context.me.company.payment_plan?.is_default) {
                window.location.href = ChoicePlanScreen.ROUTE;
                return;
            }

            window.location.href = MyAccountScreen.ROUTE;

        } catch (e) {

        }
    }

    logout() {
        ApiToken.logout();
        window.location.href = '/';
    }

    openMenuAward = (event) => {
        this.setState({
            anchorElAward: event.currentTarget,
        });
    };

    handleCloseAwarMenu = (route) => {
        this.setState({
            anchorElAward: null,
        });

        if (route === undefined) {
            return
        }

        window.location.href = route;

    };

    tryGetFidelity = () => {
        try {
            return this.context.fidelity[0].uuid;
        } catch (e) {
            return null;
        }
    }

    render() {
        if (this.context.me === null) {
            return <></>;
        }

        if (!this.context.isAdmin) {
            /*document.getElementById('').style.display = what_to_do;*/
            return (
                <div>
                    <Box display={{xs: 'none', md: 'block',}}>
                        <Box display={'flex'}
                             flexDirection={'row'}
                             alignItems="center">
                            <Typography variant={'subtitle1'}>
                                <Link href={GiftCardPurchaseScreen.ROUTE}
                                      color={'inherit'}>Vouchers</Link>
                            </Typography>
                            <SpacerComponent width={20}/>
                            <Typography variant={'subtitle1'}>
                                <Link href={DashboardScreen.ROUTE}
                                      color={'inherit'}>Dashboard</Link>
                            </Typography>
                            <SpacerComponent width={20}/>
                            <Typography variant={'subtitle1'}>
                                <Link href={SelectAwardScreen.ROUTE}
                                      onMouseOver={this.handleClickPremio}
                                      color={'inherit'}>Prêmios</Link>
                            </Typography>
                            <MenuStyled id="header-menu-premio"
                                        anchorEl={this.state.anchorElPremio}
                                        open={Boolean(this.state.anchorElPremio)}
                                        onClose={this.handleClosePremio}
                                        MenuListProps={{onMouseLeave: this.handleClosePremio}}
                                        PaperProps={{
                                            style: {
                                                marginTop: "45px",
                                            }
                                        }}>
                                <MenuItem onClick={() => this.handleClose(NewAward.ROUTE)}>
                                    Prêmio Simples
                                </MenuItem>
                                <hr/>
                                <MenuItem onClick={() => this.handleClose(CashbackScreen.ROUTE)}>
                                    Cashback
                                </MenuItem>
                                <hr/>
                                {/*<MenuItem onClick={() => this.handleClose(InstantNewAward.ROUTE)}>
                                    Prêmio SKU
                                </MenuItem>
                                <hr/>
                                <MenuItem onClick={() => this.handleClose(CampaignNameScreen.ROUTE)}>
                                    Prêmio Nome
                                </MenuItem>
                                <hr/>*/}
                                <MenuItem onClick={() => this.handleClose(GiftCardScreen.ROUTE)}>
                                    Voucher
                                </MenuItem>
                                <hr/>
                                <MenuItem onClick={() => this.handleClose(ReleaseAwardScreen.ROUTE)}>
                                    Liberar Prêmio
                                </MenuItem>
                                <hr/>
                                <MenuItem onClick={() => this.handleClose(ListNewAwardScreen.ROUTE)}>
                                    Lista de Prêmio Salvos
                                </MenuItem>
                            </MenuStyled>
                            <SpacerComponent width={20}/>
                            <Typography variant={'subtitle1'}>
                                <Link href={SelectCampaign.ROUTE}
                                      onMouseOver={this.handleClickCampaing}
                                      color={'inherit'}>Campanhas</Link>
                            </Typography>
                            <MenuStyled id="header-menu-campaing"
                                        anchorEl={this.state.anchorElCampaign}
                                        open={Boolean(this.state.anchorElCampaign)}
                                        onClose={this.handleCloseCampaign}
                                        MenuListProps={{onMouseLeave: this.handleCloseCampaign}}
                                        PaperProps={{
                                            style: {
                                                marginTop: "45px"
                                            }
                                        }}>
                                <MenuItem onClick={() => this.handleClose(CampaignScreen.ROUTE)}>
                                    Periodo
                                </MenuItem>
                                <hr/>
                                <MenuItem onClick={() => this.handleClose(CampaignBirthdayScreen.ROUTE)}>
                                    Aniversário
                                </MenuItem>
                                <hr/>
                                <MenuItem onClick={() => this.handleClose(ListCampaingScreen.ROUTE)}>
                                    Lista de campanhas
                                </MenuItem>
                            </MenuStyled>
                            <SpacerComponent width={20}/>
                            <Typography variant={'subtitle1'}>
                                <Link href={ExtractScreen.ROUTE}
                                      color={'inherit'}>Extrato</Link>
                            </Typography>
                            <SpacerComponent width={20}/>
                            <AvatarHeaderStyled onClick={this.handleClick}>
                                <AccountCircleOutlinedIcon/>
                            </AvatarHeaderStyled>
                            <ClickAreaComponent>
                                <Typography variant={'subtitle2'}
                                            onClick={this.handleClick}>
                                    ADM
                                </Typography>
                            </ClickAreaComponent>
                            <Menu id="header-menu"
                                  anchorEl={this.state.anchorEl}
                                  keepMounted
                                  open={Boolean(this.state.anchorEl)}
                                  onClose={this.handleClose}>
                                <MenuItem onClick={() => this.handleClose(StoryScreen.ROUTE)}>
                                    Story
                                </MenuItem>
                                <hr/>
                                <MenuItem onClick={() => this.handleClose(OperatorScreen.ROUTE)}>
                                    Operadores de Loja
                                </MenuItem>
                                <hr/>
                                <MenuItem onClick={() => this.handleClose(ManualScoringScreen.ROUTE)}>
                                    Pontuar Manualmente
                                </MenuItem>
                                <hr/>
                                <MenuItem onClick={() => this.handleClose(ExportClientDataScreen.ROUTE)}>
                                    Exportar Dados de Clientes
                                </MenuItem>
                                <hr/>
                                <ContentConfigIcon>
                                    <SpacerComponent width={12}/>
                                    <SettingsIcon fontSize={'small'}
                                                  color={'primary'}/>
                                    <TextConfigStyled variant={'caption'}
                                                      color={'primary'}>
                                        Configurações
                                    </TextConfigStyled>
                                </ContentConfigIcon>
                                <MenuItem onClick={() => this.handleClose(
                                    CompanyRegistrationScreen.ROUTE
                                    + '/'
                                    + this.context.me.company.uuid
                                )}>
                                    Dados da Empresa
                                </MenuItem>
                                <MenuItem onClick={() => this.handleClose(ManageEstablishmentScreen.ROUTE)}>
                                    Estabelecimentos
                                </MenuItem>
                                <MenuItem
                                    onClick={() => this.handleClose(CompanyRegistrationFidelityScreen.ROUTE + '/edit/' + this.tryGetFidelity())}>
                                    Programa de Fidelidade
                                </MenuItem>
                                <hr/>
                                <MenuItem onClick={this.goToMyPlan}>
                                    Meu plano
                                </MenuItem>
                                <hr/>
                                <MenuItem onClick={() => this.handleClose(SacScreen.ROUTE)}>
                                    FAQ
                                </MenuItem>
                                <hr/>
                                <MenuItem onClick={() => this.handleClose(AccountDeleteScreen.ROUTE)}>
                                    Excluir Conta
                                </MenuItem>
                                <hr/>
                                <MenuItem onClick={() => this.logout()}>
                                    Sair
                                </MenuItem>
                            </Menu>
                        </Box>
                    </Box>
                </div>
            );
        }

        if (this.context.isAdmin) {
            return (
                <div>
                    <Box display={{xs: 'none', md: 'block',}}>
                        <Box display={'flex'}
                             flexDirection={'row'}
                             alignItems="center">
                            <AvatarHeaderStyled onClick={this.handleClick}>
                                <AccountCircleOutlinedIcon/>
                            </AvatarHeaderStyled>
                            <Menu id="header-menu"
                                  anchorEl={this.state.anchorEl}
                                  keepMounted
                                  open={Boolean(this.state.anchorEl)}
                                  onClose={this.handleClose}>
                                <MenuItem onClick={() => this.handleClose(DashboardAdminScreen.ROUTE)}>
                                    Administrativo
                                </MenuItem>
                                <hr/>
                                <MenuItem onClick={() => this.handleClose(PaymentStatusScreen.ROUTE)}>
                                    Controle de Clientes
                                </MenuItem>
                                <hr/>
                                <ContentConfigIcon>
                                    <SpacerComponent width={12}/>
                                    <SettingsIcon fontSize={'small'}
                                                  color={'primary'}/>
                                    <TextConfigStyled variant={'caption'}
                                                      color={'primary'}>
                                        Comercial
                                    </TextConfigStyled>
                                </ContentConfigIcon>
                                <MenuItem onClick={() => this.handleClose(CreateConsultantScreen.ROUTE)}>
                                    Cadastrar Consultor
                                </MenuItem>
                                <MenuItem onClick={() => this.handleClose(ConsultantScreen.ROUTE)}>
                                    Consultores
                                </MenuItem>
                                <MenuItem onClick={() => this.handleClose(CreateGoalsScreen.ROUTE)}>
                                    Cadastro de Metas Valor
                                </MenuItem>
                                <MenuItem onClick={() => this.handleClose(CreateGoalsClientScreen.ROUTE)}>
                                    Cadastro de Metas por Cliente
                                </MenuItem>
                                <hr/>
                                <MenuItem onClick={() => this.handleClose(CompanyNotRegisteredScreen.ROUTE)}>
                                    Empresas Não Cadastadas
                                </MenuItem>
                                <hr/>
                                <MenuItem>
                                    Usuários Pessoa Física
                                </MenuItem>
                                <hr/>
                                <MenuItem>
                                    Usuários Pessoa Jurídica
                                </MenuItem>
                                <hr/>
                                <MenuItem>
                                    FAQ
                                </MenuItem>
                                <hr/>
                                <MenuItem onClick={() => this.logout()}>
                                    Sair
                                </MenuItem>
                            </Menu>
                        </Box>
                    </Box>
                </div>
            );
        }
    }
}

export default MenuHeader;