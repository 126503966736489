import React, {Component} from 'react';
import {PrivateRoute} from "../Utils/RouterUtil";
import DashboardScreen from "../Screens/PJAccess/DashboardScreen/DashboardScreen";
import NewAward from "../Screens/PJAccess/NewAward/NewAward";
import ListNewAwardScreen from "../Screens/PJAccess/NewAward/ListNewAwardScreen";
import ExtractScreen from "../Screens/PJAccess/ExtractScreen/ExtractScreen";
import SacScreen from "../Screens/PJAccess/SACScreen/SACScreen";
import ContactUsScreen from "../Screens/PJAccess/ContactUsScreen/ContactUsScreen";
import OperatorScreen from "../Screens/PJAccess/OperatorScreen/OperatorScreen";
import ManageFidelityScreen from "../Screens/PJAccess/ManageFidelityScreen/ManageFidelityScreen";
import ManageEstablishmentScreen from "../Screens/PJAccess/ManageEstablishmentScreen/ManageEstablishmentScreen";
import EditEstablishment from "../Screens/PJAccess/CompanyRegistrationEstablishment/EditEstablishment";
import ChoicePlanScreen from "../Screens/PJAccess/ChoicePlanScreen/ChoicePlanScreen";
import PaymentScreen from "../Screens/PJAccess/PaymentScreen/PaymentScreen";
import BillingDetailScreen from "../Screens/PJAccess/BillingDetailScreen/BillingDetailScreen";
import ManagePaymentScreen from "../Screens/PJAccess/ManagePaymentScreen/ManagePaymentScreen";
import CancellationPlanScreen from "../Screens/PJAccess/CancellationPlanScreen/CancellationPlanScreen";
import MyAccountScreen from "../Screens/PJAccess/MyAccountScreen/MyAccountScreen";
import {Route} from "react-router-dom";
import AcceptUpgradePlanMyAccoutScreen from "../Screens/PJAccess/MyAccountScreen/AcceptUpgradePlanMyAccoutScreen";
import CashbackScreen from "../Screens/PJAccess/CashbackScreen/CashbackScreen";
import RoleUtil from "../Utils/RoleUtil";
import InstantNewAward from "../Screens/PJAccess/InstantAward/InstantNewAward";
import StoryScreen from "../Screens/PJAccess/StoryScreen/StoryScreen";
import CampaignScreen from "../Screens/PJAccess/CampaignScreen/CampaignScreen";
import ListCampaingScreen from "../Screens/PJAccess/NewAward/ListCampaingScreen";
import SelectAwardScreen from "../Screens/PJAccess/SelectAwards/SelectAwardScreen";
import CampaignNameScreen from "../Screens/PJAccess/CampaignNameScreen/CampaignNameScreen";
import SelectCampaign from "../Screens/PJAccess/SelectCampaign/SelectAwardScreen";
import CampaignBirthdayScreen from "../Screens/PJAccess/CampaignBirthdayScreen/CampaignScreen";
import AccountDeleteScreen from "../Screens/PJAccess/AccountDelete/AccountDeleteScreen";
import ExportClientDataScreen from "../Screens/PJAccess/ExportClientData/ExportClientDataScreen";
import GiftCardScreen from "../Screens/PJAccess/GiftCardScreen/GiftCardScreen";
import GiftCardPurchaseScreen from "../Screens/PJAccess/GiftCardPurchase/GiftCardPurchaseScreen";
import PdfExtractPage from "../Screens/PJAccess/ExtractScreen/PdfExtractPage";
import ManualScoringScreen from "../Screens/PJAccess/ManualScoringScreen/ManualScoringScreen";

export default [
    <PrivateRoute exact={true}
                  path={DashboardScreen.ROUTE}
                  component={DashboardScreen}
                  needRole={[RoleUtil.PJ_NAME,]}
                  key={'pj-1'}/>,
    <PrivateRoute exact={true}
                  path={NewAward.ROUTE + '/:edit?'}
                  component={NewAward}
                  needRole={[RoleUtil.PJ_NAME,]}
                  key={'pj-2'}/>,
    <PrivateRoute exact={true}
                  path={ListNewAwardScreen.ROUTE}
                  component={ListNewAwardScreen}
                  needRole={[RoleUtil.PJ_NAME,]}
                  key={'pj-3'}/>,
    <PrivateRoute exact={true}
                  path={ExtractScreen.ROUTE}
                  component={ExtractScreen}
                  needRole={[RoleUtil.PJ_NAME,]}
                  key={'pj-4'}/>,
    <PrivateRoute exact={true}
                  path={PdfExtractPage.ROUTE + '/:key'}
                  component={PdfExtractPage}
                  needRole={[RoleUtil.PJ_NAME,]}
                  key={'pj-4.1'}/>,
    <PrivateRoute exact={true}
                  path={SacScreen.ROUTE}
                  component={SacScreen}
                  needRole={[RoleUtil.PJ_NAME,]}
                  key={'pj-5'}/>,
    <PrivateRoute exact={true}
                  path={ContactUsScreen.ROUTE}
                  component={ContactUsScreen}
                  needRole={[RoleUtil.PJ_NAME,]}
                  key={'pj-6'}/>,
    <PrivateRoute exact={true}
                  path={OperatorScreen.ROUTE}
                  component={OperatorScreen}
                  needRole={[RoleUtil.PJ_NAME,]}
                  key={'pj-7'}/>,
    <PrivateRoute exact={true}
                  path={ManageFidelityScreen.ROUTE}
                  component={ManageFidelityScreen}
                  needRole={[RoleUtil.PJ_NAME,]}
                  key={'pj-8'}/>,
    <PrivateRoute exact={true}
                  path={ManageEstablishmentScreen.ROUTE}
                  component={ManageEstablishmentScreen}
                  needRole={[RoleUtil.PJ_NAME,]}
                  key={'pj-9'}/>,
    <PrivateRoute exact={true}
                  path={EditEstablishment.ROUTE + '/:uuid?'}
                  component={EditEstablishment}
                  needRole={[RoleUtil.PJ_NAME,]}
                  key={'pj-10'}/>,
    <PrivateRoute exact={true}
                  path={ChoicePlanScreen.ROUTE + '/:edit?'}
                  component={ChoicePlanScreen}
                  needRole={[RoleUtil.PJ_NAME,]}
                  key={'pj-11'}/>,
    <PrivateRoute exact={true}
                  path={PaymentScreen.ROUTE + '/:uuid/:plan?'}
                  component={PaymentScreen}
                  needRole={[RoleUtil.PJ_NAME,]}
                  key={'pj-12'}/>,
    <PrivateRoute exact={true}
                  path={BillingDetailScreen.ROUTE}
                  component={BillingDetailScreen}
                  needRole={[RoleUtil.PJ_NAME,]}
                  key={'pj-13'}/>,
    <PrivateRoute exact={true}
                  path={ManagePaymentScreen.ROUTE}
                  component={ManagePaymentScreen}
                  needRole={[RoleUtil.PJ_NAME,]}
                  key={'pj-14'}/>,
    <PrivateRoute exact={true}
                  path={CancellationPlanScreen.ROUTE}
                  component={CancellationPlanScreen}
                  needRole={[RoleUtil.PJ_NAME,]}
                  key={'pj-15'}/>,
    <PrivateRoute exact={true}
                  path={MyAccountScreen.ROUTE}
                  component={MyAccountScreen}
                  needRole={[RoleUtil.PJ_NAME,]}
                  key={'pj-16'}/>,
    <Route exact={true}
           path={AcceptUpgradePlanMyAccoutScreen.ROUTE}
           component={AcceptUpgradePlanMyAccoutScreen}
           needRole={[RoleUtil.PJ_NAME,]}
           key={'pj-17'}/>,
    <PrivateRoute exact={true}
                  path={CashbackScreen.ROUTE + '/:edit?'}
                  component={CashbackScreen}
                  needRole={[RoleUtil.PJ_NAME,]}
                  key={'pj-18'}/>,
    <PrivateRoute exact={true}
                  path={InstantNewAward.ROUTE + '/:edit?'}
                  component={InstantNewAward}
                  needRole={[RoleUtil.PJ_NAME]}
                  key={'pj-19'}/>,
    <PrivateRoute exact={true}
                  path={StoryScreen.ROUTE}
                  component={StoryScreen}
                  needRole={[RoleUtil.PJ_NAME]}
                  key={'pj-20'}/>,
    <PrivateRoute exact={true}
                  path={CampaignScreen.ROUTE + '/:edit?'}
                  component={CampaignScreen}
                  needRole={[RoleUtil.PJ_NAME]}
                  key={'pj-21'}/>,
    <PrivateRoute exact={true}
                  path={ListCampaingScreen.ROUTE}
                  component={ListCampaingScreen}
                  needRole={[RoleUtil.PJ_NAME]}
                  key={'pj-22'}/>,
    <PrivateRoute exact={true}
                  path={SelectAwardScreen.ROUTE}
                  component={SelectAwardScreen}
                  needRole={[RoleUtil.PJ_NAME]}
                  key={'pj-23'}/>,
    <PrivateRoute exact={true}
                  path={CampaignNameScreen.ROUTE}
                  component={CampaignNameScreen}
                  needRole={[RoleUtil.PJ_NAME]}
                  key={'pj-24'}/>,
    <PrivateRoute exact={true}
                  path={CampaignNameScreen.ROUTE + '/:edit?'}
                  component={CampaignNameScreen}
                  needRole={[RoleUtil.PJ_NAME]}
                  key={'pj-25'}/>,
    <PrivateRoute exact={true}
                  path={SelectCampaign.ROUTE}
                  component={SelectCampaign}
                  needRole={[RoleUtil.PJ_NAME]}
                  key={'pj-26'}/>,
    <PrivateRoute exact={true}
                  path={CampaignBirthdayScreen.ROUTE}
                  component={CampaignBirthdayScreen}
                  needRole={[RoleUtil.PJ_NAME]}
                  key={'pj-27'}/>,
    <PrivateRoute exact={true}
                  path={CampaignBirthdayScreen.ROUTE + '/:edit?'}
                  component={CampaignBirthdayScreen}
                  needRole={[RoleUtil.PJ_NAME]}
                  key={'pj-28'}/>,
    <PrivateRoute exact={true}
                  path={AccountDeleteScreen.ROUTE}
                  component={AccountDeleteScreen}
                  needRole={[RoleUtil.PJ_NAME]}
                  key={'pj-29'}/>,
    <PrivateRoute exact={true}
                  path={ExportClientDataScreen.ROUTE}
                  component={ExportClientDataScreen}
                  needRole={[RoleUtil.PJ_NAME]}
                  key={'pj-30'}/>,
    <PrivateRoute exact={true}
                  path={GiftCardScreen.ROUTE}
                  component={GiftCardScreen}
                  needRole={[RoleUtil.PJ_NAME]}
                  key={'pj-31'}/>,
    <PrivateRoute exact={true}
                  path={GiftCardScreen.ROUTE + '/:edit?'}
                  component={GiftCardScreen}
                  needRole={[RoleUtil.PJ_NAME]}
                  key={'pj-32'}/>,
    <PrivateRoute exact={true}
                  path={GiftCardPurchaseScreen.ROUTE}
                  component={GiftCardPurchaseScreen}
                  needRole={[RoleUtil.PJ_NAME]}
                  key={'pj-33'}/>,
    <PrivateRoute exact={true}
                  path={ManualScoringScreen.ROUTE}
                  component={ManualScoringScreen}
                  needRole={[RoleUtil.PJ_NAME]}
                  key={'pj-34'}/>,
]