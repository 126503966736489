import React, {Component} from 'react';
import HeaderComponent from "../../../Components/HeaderComponent/HeaderComponent";
import {Box, Grid} from "@material-ui/core";
import SubHeaderDashboard from "./SubHeaderDashboard/SubHeaderDashboard";
import {DashboardContext} from "../../../Contexts/DashboardContext";
import ChartDefault from "./ChartDefault";
import DashboardService from "../../../Services/DashboardService";
import MessageUtil from "../../../Utils/MessageUtil";
import DateUtil from "../../../Utils/DateUtil";
import ErrorUtil from "../../../Utils/ErrorUtil";
import ShowNumber from "./ShowNumber";
import SpacerComponent from "../../../Components/SpacerComponent/SpacerComponent";
import ListNewAwardScreen from "../NewAward/ListNewAwardScreen";
import ListCampaingScreen from "../NewAward/ListCampaingScreen";
import PrizesReserved from "./PrizesReserved";

class DashboardScreen extends Component {
    static ROUTE = '/dashboard';

    constructor(props) {
        super(props);

        this.state = {
            dataRaw: [],
            prizesReverved: [],
        };

        this.dashboardService = new DashboardService();
    }

    componentDidMount() {
        this.load();
    }

    async load(
        initial = DateUtil.getDateRangeFormat(7).initial,
        final = DateUtil.getDateRangeFormat(7).final
    ) {
        try {

            this.setState({
                inLoad: true,
            })

            if (initial >= final) {
                throw ErrorUtil.make(
                    'A data inicial não pode ser maior que a final'
                );
            }

            const response = await this.dashboardService.get({
                inital: initial,
                final: final,
            });

            const prizesReverved = await this.dashboardService.getPrizesReserved({
                inital: initial,
                final: final,
            });

            this.setState({
                inLoad: false,
                dataRaw: response.data,
                prizesReverved: prizesReverved.data,
            })

        } catch (e) {
            this.setState({
                inLoad: false,
                messagens: MessageUtil.make({
                    title: 'Atenção',
                    body: e,
                }),
            });
        }
    }

    doSearch = (initial, final) => {

        this.load(
            initial,
            final
        );

    }

    render() {
        return (
            <DashboardContext.Provider value={{
                doSearch: this.doSearch,
            }}>
                <HeaderComponent title={'Dashboard'}
                                 menu={true}
                                 inLoad={this.state.inLoad}
                                 messagens={this.state.messagens}/>
                <Box display={'flex'}
                     justifyContent={'center'}
                     alignItems={'center'}>
                    <Box width={'80%'}>
                        <SubHeaderDashboard data={this.state.dataRaw}/>
                        <Grid container={true}
                              spacing={3}>
                            <ShowNumber total={this.state.dataRaw.userWithPoints}
                                        text={'Usuários ativos'}/>
                            <ShowNumber total={this.state.dataRaw.totalPoints}
                                        text={'Total de pontos'}/>
                            <ShowNumber total={this.state.dataRaw.totalAwards}
                                        toRoute={ListNewAwardScreen.ROUTE}
                                        text={'Prêmios ativos'}/>
                            <ShowNumber total={this.state.dataRaw.totalCampaigns}
                                        toRoute={ListCampaingScreen.ROUTE}
                                        text={'Campanhas ativas'}/>
                        </Grid>
                        <SpacerComponent height={20}/>
                        <Grid container={true}
                              spacing={3}>
                            <PrizesReserved data={this.state.prizesReverved}/>
                            <ChartDefault title={'Usuários Cadastrados'}
                                          data={this.state.dataRaw.createdUsers}/>
                            <ChartDefault title={'Usuários Ativos'}
                                          data={this.state.dataRaw.activeUsers}/>
                            <ChartDefault title={'Prêmios cadastrados'}
                                          data={this.state.dataRaw.createdAwards}/>
                            <ChartDefault title={'Prêmios resgatados '}
                                          data={this.state.dataRaw.redeemedAwards}/>
                        </Grid>
                    </Box>
                </Box>
            </DashboardContext.Provider>
        );
    }
}

export default DashboardScreen;