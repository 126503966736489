import React, {Component} from 'react';
import HeaderComponent from "../../../Components/HeaderComponent/HeaderComponent";
import SubHeaderComponent from "../../../Components/SubHeaderDefault/SubHeaderComponent";
import SpacerComponent from "../../../Components/SpacerComponent/SpacerComponent";
import {Box} from "@material-ui/core";
import FormContactUs from "./FormContactUs";
import MessageUtil from "../../../Utils/MessageUtil";
import ContactUsValidation from "../../../FormValidations/ContactUsValidation";
import DashboardScreen from "../DashboardScreen/DashboardScreen";
import ContactService from "../../../Services/ContactService";
import GeneralTranslation from "../../../Translations/FormValidations/GeneralTranslation";

class ContactUsScreen extends Component {
    static ROUTE = '/contact';

    contactService;

    constructor(props) {
        super(props);

        this.state = {
            inLoad: false,
            erros: [],
        };

        this.contactService = new ContactService();

    }

    doCreate = async (object) => {
        try {
            const validate = await ContactUsValidation.validate(object);

            if (validate !== true) {
                this.setState({
                    erros: validate,
                    messagens: MessageUtil.makeToValidationField(
                        new GeneralTranslation(validate).translate()
                    ),
                });
                return;
            }

            this.setState({
                inLoad: true,
            })

            await this.contactService.create(object);

            this.setState({
                inLoad: false,
                erros: [],
                messagens: MessageUtil.make({
                    title: 'Sucesso',
                    body: 'Sua mensagem foi enviada com sucesso',
                    okBtnToRoute: DashboardScreen.ROUTE,
                }),
            });

        } catch (e) {
            this.setState({
                inLoad: false,
                messagens: MessageUtil.make({
                    title: 'Atenção',
                    body: e,
                }),
            });
        }
    }

    render() {
        return (
            <div>
                <HeaderComponent title={'Fale conosco'}
                                 menu={true}
                                 messagens={this.state?.messagens}
                                 inLoad={this.state.inLoad}/>
                <Box display={'flex'}
                     justifyContent={'center'}
                     alignItems={'center'}>
                    <Box width={'80%'}>
                        <SubHeaderComponent title={'Fale Conosco'}/>
                        <SpacerComponent height={30}/>
                        <FormContactUs doCreate={this.doCreate}
                                       erros={this.state.erros}/>
                    </Box>
                </Box>
            </div>
        );
    }
}

export default ContactUsScreen;