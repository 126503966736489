import React, {Component} from 'react';
import HeaderComponent from "../../../Components/HeaderComponent/HeaderComponent";
import {Typography} from "@material-ui/core";
import SpacerComponent from "../../../Components/SpacerComponent/SpacerComponent";
import MessageUtil from "../../../Utils/MessageUtil";
import {CashbackContext} from "../../../Contexts/CashbackContext";
import GeneralTranslation from "../../../Translations/FormValidations/GeneralTranslation";
import FormCampaignScreen from "./FormCampaignScreen";
import CampaignValidation from "../../../FormValidations/CampaignValidation";
import CampaignService from "../../../Services/CampaignService";
import ListCampaingScreen from "../NewAward/ListCampaingScreen";
import CampaignBirthdayValidation from "../../../FormValidations/CampaignBirthdayValidation";

class CampaignBirthdayScreen extends Component {
    static ROUTE = '/campaign-birthday';

    constructor(props) {
        super(props);

        this.state = {
            inLoad: false,
            messagens: null,
            fidelity: [],
            companies: [],
            action: 'create',
            actionToHuman: 'cadastrada',
            actionCurrent: 'Registrar Nova Campanha de Aniversário do Cliente',
        };

        this.campaignService = new CampaignService();
    }

    componentDidMount() {
        this.isEdit();
    }

    doCreate = async (object) => {
        try {
            const validate = await CampaignBirthdayValidation.validate(object);
            if (validate !== true) {
                this.setState({
                    erros: validate,
                    messagens: MessageUtil.makeToValidationField(
                        new GeneralTranslation(validate).translate()
                    ),
                });
                return;
            }

            this.setState({
                inLoad: true,
            });

            if (!this.state.isEdit) {
                object.active = 1;
                await this.campaignService.create(object);
            }

            if (this.state.isEdit) {
                object.active = 1;
                await this.campaignService.edit(object.uuid, object);
            }

            this.setState({
                inLoad: false,
                messagens: MessageUtil.make({
                    title: 'Sucesso',
                    body: `Campanha ${this.state.actionToHuman}, clique em ok para voltar.`,
                    okBtnToRoute: ListCampaingScreen.ROUTE,
                }),
            });

        } catch (e) {
            this.setState({
                inLoad: false,
                messagens: MessageUtil.make({
                    title: 'Atenção',
                    body: e,
                }),
            });
        }
    }

    async isEdit() {

        try {
            if (this.props.match.params.edit === undefined) {
                this.setState({
                    isEdit: false,
                });
                return;
            }

            this.setState({
                inLoad: true,
            });

            const response = await this.campaignService.show(this.props.match.params.edit);

            this.setState({
                isEdit: true,
                inLoad: false,
                formEdit: response.data.data,
                action: 'edit',
                actionToHuman: 'alterada',
                actionCurrent: 'Alterar campanha de aniversário do cliente',
            });

        } catch (e) {
            this.setState({
                inLoad: false,
                messagens: MessageUtil.make({
                    title: 'Atenção',
                    body: e,
                }),
            });
        }
    }

    doDelete = async (uuid) => {
        try {
            const message = MessageUtil.make({
                title: 'Atencao',
                body: 'Deseja realmente deletar essa campanha, essa ação não pode ser recuperada.',
                okButtonText: 'Sim',
                okButtonFunction: async () => {
                    this.setState({
                        inLoad: true,
                    });

                    await this.campaignService.delete(uuid);

                    this.setState({
                        inLoad: false,
                        messagens: MessageUtil.make({
                            title: 'Sucesso',
                            body: 'Campanha foi deletada',
                            okBtnToRoute: ListCampaingScreen.ROUTE,
                        }),
                    });

                    return;
                },
                cancelButtonText: 'Não',
                cancelButtonFunction: () => {
                }
            });

            this.setState({
                messagens: message,
            });

        } catch (e) {
            this.setState({
                inLoad: false,
                messagens: MessageUtil.make({
                    title: 'Atenção',
                    body: e,
                }),
            });
        }
    }

    render() {
        return (
            <CashbackContext.Provider value={{
                parent: this,
            }}>
                <HeaderComponent title={'Registrar Campanha'}
                                 menu={true}
                                 inLoad={this.state.inLoad}
                                 messagens={this.state.messagens}/>
                <Typography align={'center'}
                            variant={'h6'}
                            color={'primary'}>
                    {this.state.actionCurrent}
                </Typography>
                <SpacerComponent height={40}/>
                <FormCampaignScreen doCreate={this.doCreate}
                                    erros={this.state.erros}
                                    formEdit={this.state.formEdit}
                                    isEdit={this.state.isEdit}
                                    doDelete={this.doDelete}/>
            </CashbackContext.Provider>
        );
    }
}

export default CampaignBirthdayScreen;