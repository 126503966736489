import React, {Component} from 'react';
import {Button, IconButton, MenuItem, Typography} from "@material-ui/core";
import {SearchTextFieldStyled} from "./styled";
import {SearchOutlined} from "@material-ui/icons";
import {DatePicker} from "@material-ui/pickers";
import {
    ContentColumnSearhExtract,
    ContentColumnSearhExtractSelect,
    ContentParentSearhExtract,
    ContentSearhExtract
} from "../styled";
import FixSelectComponent from "../../../../Components/FixSelect/FixSelect";
import TextFieldDefault from "../../../../Components/TextFieldDefaultComponent/TextFieldDefault";
import {ExtractContext} from "../../../../Contexts/ExtractContext";
import SpacerComponent from "../../../../Components/SpacerComponent/SpacerComponent";
import DateUtil from "../../../../Utils/DateUtil";


class SearchTextFieldComponent extends Component {
    static contextType = ExtractContext;

    constructor(props) {
        super(props);

        this.state = {
            filter: null,
            selectedDateInitial: DateUtil.getDateRangeToDate(7).initial,
            selectedDateInitialApi: DateUtil.getDateRangeFormat(7).initial,
            selectedDateFinal: DateUtil.getDateRangeToDate(7).final,
            selectedDateFinalApi: DateUtil.getDateRangeFormat(7).final,
            client: null,
            key: null,
            type: null,
            companies: null,
            user_name: null,
        };
    }

    handleChange = (event) => {
        const {name, value} = event.target;

        this.setState({[name]: value});
    }

    handleChangeDate(name, value) {

        const apiState = name + 'Api';

        this.setState({
            [name]: value.toDate(),
            [apiState]: value.format('YYYY-MM-DD'),
        });
    }

    makeTypes = () => {
        const itens = [
            <MenuItem key={null} value={null}>{'Nenhum'}</MenuItem>
        ];

        const object = this.context.parent.state?.dataFilter?.types;

        if (!object) {
            return itens;
        }

        Object.keys(object).map((key) => {
            itens.push(
                <MenuItem key={key} value={key}>{object[key]}</MenuItem>
            );
        });

        return itens;
    }

    makeCompanies = () => {
        const itens = [
            <MenuItem key={null} value={null}>{'Nenhum'}</MenuItem>
        ];

        const object = this.context.parent.state?.dataFilter?.companies;

        if (!object) {
            return itens;
        }

        Object.keys(object).map((key) => {
            itens.push(
                <MenuItem key={key} value={key}>{object[key]}</MenuItem>
            );
        });

        return itens;
    }

    makeUsers = () => {
        const itens = [
            <MenuItem key={null} value={null}>{'Nenhum'}</MenuItem>
        ];

        const object = this.context.parent.state?.dataFilter?.users;

        if (!object) {
            return itens;
        }

        Object.keys(object).map((key) => {
            itens.push(
                <MenuItem key={key} value={key}>{object[key]}</MenuItem>
            );
        });

        return itens;
    }

    render() {
        return (
            <ContentParentSearhExtract>
                <ContentSearhExtract>
                    <SearchTextFieldStyled id="outlined-basic"
                                           label="Buscar"
                                           variant="outlined"
                                           name={'term'}
                                           onChange={this.handleChange}/>
                    <SpacerComponent width={20}/>
                    <DatePicker label={'Data Inicial'}
                                value={this.state.selectedDateInitial}
                                onChange={(date) => this.handleChangeDate('selectedDateInitial', date)}
                                animateYearScrolling={true}
                                format="DD/MM/yyyy"/>
                    <SpacerComponent width={20}/>
                    <DatePicker label={'Data Final'}
                                value={this.state.selectedDateFinal}
                                onChange={(date) => this.handleChangeDate('selectedDateFinal', date)}
                                animateYearScrolling={true}
                                format="DD/MM/yyyy"/>
                </ContentSearhExtract>
                <SpacerComponent height={40}/>
                <ContentSearhExtract>
                    <SearchTextFieldStyled id="outlined-basic"
                                           label="Cliente"
                                           variant="outlined"
                                           name={'client'}
                                           onChange={this.handleChange}/>
                    <SpacerComponent width={20}/>
                    <SearchTextFieldStyled id="outlined-basic"
                                           label="Documento de Liberação"
                                           variant="outlined"
                                           name={'key'}
                                           onChange={this.handleChange}/>
                </ContentSearhExtract>
                <SpacerComponent height={40}/>
                <ContentSearhExtract>
                    <ContentColumnSearhExtractSelect>
                        <ContentColumnSearhExtract>
                            <Typography variant={'body1'}
                                        color={'primary'}
                                        style={{marginRight: 5}}>
                                Tipo
                            </Typography>
                            <FixSelectComponent>
                                <TextFieldDefault select={true}
                                                  fullWidth={true}
                                                  defaultValue={'days'}
                                                  name={'type'}
                                                  value={this.state.type}
                                                  onChange={(event) => this.handleChange(event)}>
                                    {this.makeTypes()}
                                </TextFieldDefault>
                            </FixSelectComponent>
                        </ContentColumnSearhExtract>
                    </ContentColumnSearhExtractSelect>
                    <SpacerComponent width={40}/>
                    <ContentColumnSearhExtractSelect>
                        <ContentColumnSearhExtract>
                            <ContentColumnSearhExtract>
                                <Typography variant={'body1'}
                                            color={'primary'}
                                            style={{marginRight: 5}}>
                                    Operador
                                </Typography>
                                <FixSelectComponent>
                                    <TextFieldDefault select={true}
                                                      defaultValue={'days'}
                                                      name={'user_name'}
                                                      value={this.state.user_name}
                                                      onChange={(event) => this.handleChange(event)}>
                                        {this.makeUsers()}
                                    </TextFieldDefault>
                                </FixSelectComponent>
                            </ContentColumnSearhExtract>
                        </ContentColumnSearhExtract>
                    </ContentColumnSearhExtractSelect>
                    <SpacerComponent width={40}/>
                    <ContentColumnSearhExtractSelect>
                        <ContentColumnSearhExtract>
                            <Typography variant={'body1'}
                                        color={'primary'}
                                        style={{marginRight: 5}}>
                                Estabelecimento
                            </Typography>
                            <FixSelectComponent>
                                <TextFieldDefault select={true}
                                                  defaultValue={'days'}
                                                  name={'companies'}
                                                  value={this.state.companies}
                                                  onChange={(event) => this.handleChange(event)}>
                                    {this.makeCompanies()}
                                </TextFieldDefault>
                            </FixSelectComponent>
                        </ContentColumnSearhExtract>
                    </ContentColumnSearhExtractSelect>
                    <SpacerComponent width={40}/>
                    <ContentColumnSearhExtract>
                        <SpacerComponent height={40} width={40}/>
                        <Button variant={'contained'}
                                color={'primary'}
                                size={"large"}
                                onClick={() => this.context.parent.doSearch(
                                    this.state,
                                )}>
                            Filtrar
                        </Button>
                    </ContentColumnSearhExtract>
                </ContentSearhExtract>
            </ContentParentSearhExtract>
        );
    }
}

export default SearchTextFieldComponent;