import React, {Component} from 'react';
import {Box, Button, Typography} from "@material-ui/core";
import SpacerComponent from "../SpacerComponent/SpacerComponent";
import imageCompression from "browser-image-compression";
import InputFileDefault from "./InputFileDefault";

class InputFileWithCountDefault extends Component {

    static ONE_MB = 1050000;
    static TWO_MB = 2050000;
    static THREE_MB = 3050000;
    static FOUR_MB = 4050000;
    static FIVE_MB = 5050000;

    handleImage = async (event) => {

        let file = event.target.files[0];
        const sizeSelect = (this.props.maxSize ?? InputFileDefault.FIVE_MB);

        if (file.size > sizeSelect) {

            const sizeCurrent = Math.trunc(
                Math.round(sizeSelect / 1000000)
                    .toFixed(1)
            );

            if (this.props.onMaxSize !== undefined) {
                this.props.onMaxSize(sizeCurrent);
                return;
            }
            alert(`Arquivo não pode ter mais que ${sizeCurrent} mb`);
            return;
        }

        const options = {
            maxSizeMB: 0.7,
            maxWidthOrHeight: 1920,
            useWebWorker: true
        };

        file = await imageCompression(file, options);

        this.setPreviosImage(file);
        this.toBase64(file);

    }

    setPreviosImage(file) {
        const image = URL.createObjectURL(file);

        if (this.props.setPreviosImage === undefined) {
            return;
        }

        this.props.setPreviosImage(image);
    }

    toBase64(file) {

        if (this.props.toBase64Image === undefined) {
            return;
        }

        let reader = new FileReader();
        reader.readAsDataURL(file);
        return reader.onloadend = async () => {
            this.props.toBase64Image(reader.result);
        };
    }

    isValidate() {
        try {

            return this.props.erros.image;

        } catch (e) {

            return true

        }
    }

    render() {
        return (
            <div>
                <Box display={'flex'}
                     flexDirection={'column'}
                     alignItems={'center'}>
                    <input
                        accept="image/*"
                        style={{display: 'none'}}
                        id={'input-file-' + this.props.count}
                        multiple
                        type="file"
                        onChange={(event) => this.handleImage(event)}/>
                    <label htmlFor={'input-file-' + this.props.count}>
                        <Button component="span"
                                variant={"contained"}
                                size={"small"}>
                            Selecionar novo arquivo
                        </Button>
                    </label>
                    {this.isValidate() !== true &&
                    <div>
                        <SpacerComponent height={10}/>
                        <Typography variant={'caption'}
                                    color={'error'}>{this.isValidate()}</Typography>
                    </div>
                    }
                </Box>
            </div>
        );
    }
}

export default InputFileWithCountDefault;