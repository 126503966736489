import React, {Component} from 'react';
import DateUtil from "../../../Utils/DateUtil";
import {TableContentStyled} from "./styled";
import DataTable from 'react-data-table-component';
import CheckBoxDefaultComponent from "../../../Components/CheckBoxDefaultComponent/CheckBoxDefaultComponent";

class TablePaymentStatusScreen extends Component {
    constructor(props) {
        super(props);
    }

    makeColumns() {

        return [
            {
                name: 'CNPJ',
                selector: row => row.cnpj,
                sortable: true,
            },
            {
                name: 'Fantasia',
                selector: row => row.name,
                sortable: true,
            },
            {
                name: 'Plano Atual',
                selector: row => row.payment_plan,
                sortable: true,
            },
            {
                name: 'Valor',
                selector: row => row.value,
                sortable: true,
            },
            {
                name: 'Data Cadastro',
                selector: row => row.created_at,
                sortable: true,
            }, {
                name: 'Data Inadimplencia',
                selector: row => row.charge_rejected_at,
                sortable: true,
            },
            {
                name: 'Motivo',
                selector: row => row.charge_rejected_reason,
                sortable: true,
            },
            {
                name: 'Último acesso',
                selector: row => row.last_login,
                sortable: true,
                allowOverflow: false,
            },
            {
                name: 'Status',
                selector: row => row.status,
                sortable: true,
            },
            {
                name: 'Consultor',
                selector: row => row.consultant,
                sortable: true,
            },
            {
                name: 'Habilitar Empresa Modelo',
                selector: row => row.enabled_model,
                sortable: true,
            }
        ];
    }

    makeItens() {
        const itens = [];

        this.props.data.map((value, index) => {
            itens.push({
                cnpj: value.cnpj,
                name: value.name,
                payment_plan: value.payment_plan,
                value: value.value,
                created_at: DateUtil.raw(value.created_at).toDate(),
                charge_rejected_at: DateUtil.raw(value.charge_rejected_at).toDate(),
                charge_rejected_reason: value.charge_rejected_reason,
                last_login: DateUtil.raw(value.last_login).toDateTime(),
                status: value.status,
                consultant: value.consultant,
                enabled_model: <CheckBoxDefaultComponent
                    onChange={() => this.props.changeModel(value.uuid, value.is_model)}
                    checked={value.is_model}/>
            });
        });

        return itens;
    }

    render() {
        return (
            <DataTable columns={this.makeColumns()}
                       data={this.makeItens()}
                       fixedHeader={true}
                       fixedHeaderScrollHeight="500px"/>
        );
    }
}

export default TablePaymentStatusScreen;