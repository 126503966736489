import axios from 'axios';




class AddressUtil {

    static get states() {
        return {
            'AC': 'Acre',
            'AL': 'Alagoas',
            'AP': 'Amapá',
            'AM': 'Amazonas',
            'BA': 'Bahia',
            'CE': 'Ceará',
            'DF': 'Distrito Federal',
            'ES': 'Espírito Santo',
            'GO': 'Goiás',
            'MA': 'Maranhão',
            'MT': 'Mato Grosso',
            'MS': 'Mato Grosso do Sul',
            'MG': 'Minas Gerais',
            'PA': 'Pará',
            'PB': 'Paraíba',
            'PR': 'Paraná',
            'PE': 'Pernambuco',
            'PI': 'Piauí',
            'RJ': 'Rio de Janeiro',
            'RN': 'Rio Grande do Norte',
            'RS': 'Rio Grande do Sul',
            'RO': 'Rondônia',
            'RR': 'Roraima',
            'SC': 'Santa Catarina',
            'SP': 'São Paulo',
            'SE': 'Sergipe',
            'TO': 'Tocantins',
        }
    }

    static async tryViaCep(zipCode) {
        let address = {};
        try {
            let response = await axios.get(`https://viacep.com.br/ws/${zipCode}/json/`);
            address.state = this.states[response.data.uf];
            address.city = response.data.localidade;
            address.streetName = response.data.logradouro;
            address.neighborhood = response.data.bairro;
            address.complement = response.data.complemento;
        } catch {
            address = false;
        }
        return address;
    }

    static async tryPostmon(zipCode) {
        let address = {};
        try {
            let response = await axios.get(`https://api.postmon.com.br/v1/cep/${zipCode}`);
            address.state = this.states[response.data.estado];
            address.city = response.data.cidade;
            address.streetName = response.data.logradouro;
            address.neighborhood = response.data.bairro;
            address.complement = response.data.complemento;
        } catch {
            address = false;
        }
        return address;
    }

    static async translateZipCode(zipCode) {
        let address = false;
        address = await this.tryViaCep(zipCode);
        if (!address) {
            address = await this.tryPostmon(zipCode);
        }
        return address;
    }

}

export default AddressUtil;
