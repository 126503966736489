import React, {Component} from 'react';
import {Grid, Typography} from "@material-ui/core";
import ItemBranchAmount from "./ItemBranchAmount";
import CompanyService from "../../../../Services/CompanyService";
import AwardService from "../../../../Services/AwardService";

class BranchAmount extends Component {

    companyService;
    awardService;

    constructor(props) {
        super(props);

        this.state = {
            branches: [],
            branchAmount: [],
            getBranchAmount: [],
        };

        this.companyService = new CompanyService();
        this.awardService = new AwardService();
    }

    componentDidMount() {
        this.props.getLoadBranchAmount(this.load);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.parentState === prevProps.parentState) {
            return;
        }

        if (this.props.amountForce) {
            this.getAmountSave(this.props.amountForce);
            return;
        }

        if (this.props.fidelityUuid === prevProps.fidelityUuid) {
            return;
        }

        if (this.props.fidelityUuid === undefined) {
            return;
        }
        this.getAmountSave();
    }

    async getAmountSave(amount = null) {
        try {
            await this.load(this.props.fidelityUuid);

            if (amount) {
                this.setState({
                    getBranchAmount: amount,
                });
                return;
            }

            const uuid = this.props.parentState.uuid;
            if (uuid === undefined) {
                return;
            }
            const response = await this.awardService.getAmountByAward(uuid);

            this.setState({
                getBranchAmount: response.data,
            });

        } catch (e) {
            throw e;
        }
    }

    load = async (fidelityUuid) => {
        try {

            const response = await this.companyService.getBranch(fidelityUuid);

            this.setState({
                branchAmount: [],
                branches: response.data.data
            });

        } catch (e) {
            throw e;
        }
    }

    makeBranches() {
        let itens = [];
        const context = this;

        this.state.branches.forEach(function (value, index) {

            itens.push(
                <ItemBranchAmount branch={value}
                                  branches={context.state.branches}
                                  saveAmount={context.tryGetAmountByArray(value.uuid)}
                                  setValueAmountBranch={context.setValueAmountBranch}
                                  deleteValueAmountBranch={context.deleteValueAmountBranch}
                                  disabled={context.props.disabled}/>
            );

        });

        return itens;
    }

    tryGetAmountByArray(uuid) {
        try {

            return this.state.getBranchAmount[uuid];

        } catch (e) {
            return;
        }
    }

    setValueAmountBranch = (uuid, amount) => {
        let newArray = this.state.branchAmount;

        newArray[uuid] = amount;

        this.setState({
            'branchAmount': newArray,
        });

        this.props.setBranchAmount(this.state.branchAmount);
    }

    deleteValueAmountBranch = (uuid) => {

        let newArray = this.state.branchAmount;

        delete newArray[uuid];

        this.setState({
            'branchAmount': newArray,
        });

        this.props.setBranchAmount(this.state.branchAmount);

    }

    render() {
        if (this.state.branches.length <= 0) {
            return <div></div>;
        }
        return (
            <div>
                <Grid container={true}
                      spacing={3}
                      justify="space-between">
                    <Grid item={true}
                          xs={8}>
                        <Typography variant={'body2'}
                                    color={'primary'}>
                            Estabelecimentos disponíveis para retirada:
                        </Typography>
                    </Grid>
                    <Grid item={true}
                          xs={4}>
                        <Typography variant={'body2'}
                                    color={'primary'}>
                            Qtd. por filial:
                        </Typography>
                    </Grid>
                </Grid>
                {this.makeBranches()}
            </div>
        );
    }
}

export default BranchAmount;