import React, {Component} from 'react';
import TextFieldDefault from "../../../Components/TextFieldDefaultComponent/TextFieldDefault";
import FormMask from "../../../FromMask/FormMask";
import InputFileDefault from "../../../Components/InputFileDefault/InputFileDefault";
import MessageUtil from "../../../Utils/MessageUtil";
import {Box, Button, MenuItem} from "@material-ui/core";
import SpacerComponent from "../../../Components/SpacerComponent/SpacerComponent";
import AddressUtil from "../../../Utils/AddressUtil";
import RoleUtil from "../../../Utils/RoleUtil";
import {CreateAdminContext} from "../../../Contexts/CreateAdminContext";

class FormCreateConsultantScreen extends Component {
    static  contextType = CreateAdminContext;

    constructor(props) {
        super(props);

        this.state = {};
    }

    handleChange = (event) => {
        const {name, value} = event.target;
        this.setAddressByCEP(name, value);
        this.setState({
            [name]: value,
        });

        if (name === 'discount') {
            this.rulesToValue(value);
        }
    }

    rulesToValue(value) {
        if (value > 100) {
            this.setState({
                discount: 100,
            });
            this.context.parent.setState({
                messagens: MessageUtil.make({
                    title: 'Atenção',
                    body: 'Desconto so pode aceitar valores entre 0% e 100%',
                }),
            });
            return;
        }
        if (value < 0) {
            this.setState({
                discount: 0,
            });
            this.context.parent.setState({
                messagens: MessageUtil.make({
                    title: 'Atenção',
                    body: 'Desconto so pode aceitar valores entre 0% e 100%',
                }),
            });
            return;
        }
    }

    setPreviosImage = (image) => {
        this.setState({
            imagePrevios: image,
        });
    }

    toBase64Image = (base64) => {
        this.setState({
            'photo': base64.split(',')[1],
        });
    }

    async setAddressByCEP(inputName, value) {
        if (inputName !== 'zip_code') {
            return;
        }

        if (value.length !== 8) {
            return;
        }

        const addressUtil = await AddressUtil.translateZipCode(value);

        this.setState({
            'state': addressUtil.state,
            'city': addressUtil.city,
            'neighborhood': addressUtil.neighborhood,
            'address': addressUtil.streetName,
            'complement': addressUtil.complement,
            btnAlterDisabled: false,
        });
    }

    makeItensSelect() {
        let itens = [];
        for (let [key, value] of Object.entries(AddressUtil.states)) {
            itens.push(
                <MenuItem key={key} value={value}>{value}</MenuItem>
            );
        }
        return itens;
    }

    handlerSubmitEdit = () => {
        this.props.doCreate(
            this.state,
        );
    }

    render() {
        return (
            <>
                <TextFieldDefault label={'Nome *'}
                                  name={'name'}
                                  value={this.state.name}
                                  onChange={(event) => this.handleChange(event)}
                                  erros={this.props.erros}/>
                <TextFieldDefault label={'Identidade *'}
                                  name={'id_card'}
                                  value={this.state.identity}
                                  mask={FormMask.idCard}
                                  onChange={(event) => this.handleChange(event)}
                                  erros={this.props.erros}/>
                <TextFieldDefault label={'CPF *'}
                                  name={'cpf'}
                                  mask={FormMask.cpf}
                                  value={this.state.cpf}
                                  onChange={(event) => this.handleChange(event)}
                                  erros={this.props.erros}/>
                <TextFieldDefault label={'E-mail *'}
                                  name={'email'}
                                  value={this.state.email}
                                  onChange={(event) => this.handleChange(event)}
                                  erros={this.props.erros}/>
                <InputFileDefault setPreviosImage={this.setPreviosImage}
                                  toBase64Image={this.toBase64Image}
                                  imagePrevios={this.state.imagePrevios}
                                  withPreview={true}
                                  title={'Foto Consultor'}
                                  onMaxSize={(value) => {
                                      this.context.parent.setState({
                                          messagens: MessageUtil.make({
                                              title: 'Atenção',
                                              body: `Arquivo não pode ter mais que ${value} mb`
                                          }),
                                      })
                                  }}/>
                <SpacerComponent height={20}/>
                <Box display={'flex'}
                     flexDirection={'row'}
                     alignItems={'center'}>
                    <TextFieldDefault label={'Telefone *'}
                                      name={'telephone'}
                                      onChange={(event) => this.handleChange(event)}
                                      value={this.state.telephone}
                                      erros={this.props.erros}
                                      forceLabel={true}
                                      phone={true}/>
                    <SpacerComponent width={40}/>
                    <TextFieldDefault label={'CEP *'}
                                      name={'zip_code'}
                                      type={'number'}
                                      value={this.state.zip_code}
                                      onChange={(event) => this.handleChange(event)}
                                      erros={this.props.erros}/>
                </Box>
                <TextFieldDefault label={'Endereço *'}
                                  name={'address'}
                                  value={this.state.address}
                                  onChange={(event) => this.handleChange(event)}
                                  erros={this.props.erros}/>
                <Box display={'flex'}
                     flexDirection={'row'}>
                    <TextFieldDefault label={'Número *'}
                                      name={'number'}
                                      value={this.state.number}
                                      onChange={(event) => this.handleChange(event)}
                                      erros={this.props.erros}/>
                    <SpacerComponent width={40}/>
                    <TextFieldDefault label={'Complemento'}
                                      name={'complement'}
                                      value={this.state.complement}
                                      onChange={(event) => this.handleChange(event)}
                                      erros={this.props.erros}/>
                </Box>
                <TextFieldDefault label={'Bairro *'}
                                  name={'neighborhood'}
                                  value={this.state.neighborhood}
                                  onChange={(event) => this.handleChange(event)}
                                  erros={this.props.erros}/>
                <Box display={'flex'}
                     flexDirection={'row'}
                     alignItems={'center'}>
                    <TextFieldDefault label={'Cidade *'}
                                      name={'city'}
                                      value={this.state.city}
                                      onChange={(event) => this.handleChange(event)}
                                      erros={this.props.erros}/>
                    <SpacerComponent width={40}/>
                    <TextFieldDefault label={'Estado *'}
                                      select={true}
                                      name={'state'}
                                      value={this.state.state}
                                      onChange={(event) => this.handleChange(event)}
                                      erros={this.props.erros}>
                        {this.makeItensSelect()}
                    </TextFieldDefault>
                </Box>
                <TextFieldDefault label={'Nível do consultor *'}
                                  select={true}
                                  name={'role'}
                                  value={this.state.role}
                                  onChange={(event) => this.handleChange(event)}
                                  erros={this.props.erros}>
                    <MenuItem value={RoleUtil.COORDENADOR}>COORDENADOR</MenuItem>
                    <MenuItem value={RoleUtil.CONSULTOR_REGIONAL}>CONSULTOR REGIONAL</MenuItem>
                    <MenuItem value={RoleUtil.CONSULTOR}>CONSULTOR</MenuItem>
                </TextFieldDefault>
                <TextFieldDefault label={'Desconto'}
                                  name={'discount'}
                                  type={'number'}
                                  value={this.state.discount}
                                  onChange={(event) => this.handleChange(event)}
                                  erros={this.props.erros}/>
                <SpacerComponent height={20}/>
                <Box display="flex" justifyContent="center">
                    <Button variant={'contained'}
                            color={'primary'}
                            size={"large"}
                            fullWidth={true}
                            onClick={() => this.handlerSubmitEdit()}
                            disabled={this.state.btnAlterDisabled}>
                        Cadastrar
                    </Button>
                </Box>
            </>
        );
    }
}

export default FormCreateConsultantScreen;