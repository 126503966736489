import React, {Component} from 'react';
import HeaderComponent from "../../../Components/HeaderComponent/HeaderComponent";
import CompanyService from "../../../Services/CompanyService";
import {Typography} from "@material-ui/core";
import EditFormEstablishment from "./EditFormEstablishment";
import SegmentNameService from "../../../Services/SegmentNameService";
import SegmentService from "../../../Services/SegmentService";
import MessageUtil from "../../../Utils/MessageUtil";
import EditEstablishmentValidation from "../../../FormValidations/EditEstablishmentValidation";
import MeUtil from "../../../Utils/MeUtil";
import EstablishmentValidation from "../../../FormValidations/EstablishmentValidation";
import ManageEstablishmentScreen from "../ManageEstablishmentScreen/ManageEstablishmentScreen";
import {SuperContext} from "../../../Contexts/SuperContext";
import GeneralTranslation from "../../../Translations/FormValidations/GeneralTranslation";

class EditEstablishment extends Component {
    static ROUTE = '/edit-establishment';
    static contextType = SuperContext;

    segmentNameSerice;
    segmentService;
    companyService;

    constructor(props) {
        super(props);

        this.state = {
            inLoad: false,
            erros: [],
            segmentName: [],
            segment: [],
            formEdit: {},
            isEdit: false,
        };

        this.segmentNameSerice = new SegmentNameService();
        this.segmentService = new SegmentService();
        this.companyService = new CompanyService();

    }

    componentDidMount() {
        this.load();
    }

    async load() {
        try {

            this.setState({
                inLoad: true,
            });

            await this.isEdit();

            const response = await this.segmentNameSerice.get();

            this.setState({
                inLoad: false,
                segmentName: response.data.data,
            });

        } catch (e) {
            this.setState({
                inLoad: false,
                messagens: MessageUtil.make({
                    title: 'Error',
                    body: e,
                }),
            });
        }
    }

    async isEdit() {
        try {

            this.setState({
                inLoad: true,
            });

            if (this.props.match.params.uuid === undefined) {
                return;
            }

            const uuid = this.props.match.params.uuid;

            const response = await this.companyService.searchByUuid(uuid);

            this.setState({
                inLoad: false,
                formEdit: response.data.data,
                isEdit: true,
                isEditUuid: this.props.match.params.uuid,
            });

        } catch (e) {
            this.setState({
                inLoad: false,
                messagens: MessageUtil.make({
                    title: 'Error',
                    body: e,
                }),
            });
        }
    }

    getSegmentBySegmentName = async (id) => {
        try {

            this.setState({
                inLoad: true,
            });

            const response = await this.segmentService.get(id);

            this.setState({
                inLoad: false,
                segment: response.data.data,
            });

        } catch (e) {
            this.setState({
                inLoad: false,
                messagens: MessageUtil.make({
                    title: 'Error',
                    body: e,
                }),
            });
        }
    }

    doCreate = async (object) => {

        if (this.context.me.company.child_companies < this.context.myPlan.payment_plan.companies_limit) {
            this.doCreateFinish(object);
            return;
        }

        this.setState({
            messagens: MessageUtil.make({
                title: 'Atenção',
                body: 'Ao cadastrar um novo CNPJ, será cobrada uma tarifa extra em seu plano atual, no valor de R$50,00.',
                cancelButtonText: 'Cancelar',
                okButtonFunction: () => {
                    this.doCreateFinish(object);
                },
                cancelButtonFunction: () => {
                    window.location.href = ManageEstablishmentScreen.ROUTE;
                }
            }),
        });

    }

    doCreateFinish = async (object) => {
        try {
            const me = await MeUtil.get();
            object.matrix = me.data.data.company.uuid;

            const validade = await EstablishmentValidation.validate(object);

            if (validade !== true) {
                this.setState({
                    erros: validade,
                    messagens: MessageUtil.makeToValidationField(
                        new GeneralTranslation(validade).translate()
                    ),
                });
                return;
            }

            this.setState({
                inLoad: true,
            });

            await this.companyService.create(object);

            this.setState({
                inLoad: false,
                messagens: MessageUtil.make({
                    title: 'Sucesso',
                    body: 'Sua Empresa foi cadastrada com sucesso.',
                    okBtnToRoute: ManageEstablishmentScreen.ROUTE,
                }),
            });

        } catch (e) {
            this.setState({
                inLoad: false,
                messagens: MessageUtil.make({
                    title: 'Atenção',
                    body: e,
                }),
            });
        }
    }

    doEditConfirm = async (object) => {

        const context = this;

        this.setState({
            messagens: MessageUtil.make({
                title: 'Atenção',
                body: 'Deseja Salvar as alterações?',
                okButtonFunction: function (value) {
                    context.doEditCompany(object);
                },
                cancelButtonText: 'Cancelar',
            }),
        });

    }

    doEditCompany = async (object) => {
        try {
            const validade = await EditEstablishmentValidation.validate(object);

            if (validade !== true) {
                this.setState({
                    erros: validade,
                    messagens: MessageUtil.makeToValidationField(
                        new GeneralTranslation(validade).translate()
                    ),
                });
                return;
            }

            this.setState({
                inLoad: true,
            });

            const newForm = this.makeFormEditCompany(object);

            await this.companyService.update(
                this.state.isEditUuid,
                newForm
            );

            this.setState({
                inLoad: false,
                messagens: MessageUtil.make({
                    title: 'Sucesso',
                    body: 'Dados da empresa foram alterados.',
                    okBtnToRoute: ManageEstablishmentScreen.ROUTE,
                }),
            });

        } catch (e) {
            this.setState({
                inLoad: false,
                messagens: MessageUtil.make({
                    title: 'Atenção',
                    body: e,
                }),
            });
        }
    }

    makeFormEditCompany(object) {

        return {

            fantasy_name: object.fantasy_name,
            social_reason: object.social_reason,
            city: object.city,
            address: object.address,
            complement: object.complement,
            neighborhood: object.neighborhood,
            number: object.number,
            state: object.state,
            telephone: object.telephone,
            zip_code: object.zip_code,
            segment_type_uuid: object.segment_type_uuid,
            logo_base64: object.logo_base64,
            fidelity_program: object.fidelity_program,
            certificate_password: object.certificate_password,
            certificate_file: object.certificate_file,

        }
    }

    render() {
        return (
            <div>
                <HeaderComponent title={'Gerenciar Estabelecimento'}
                                 messagens={this.state?.messagens}
                                 inLoad={this.state.inLoad}
                                 menu={true}/>
                {this.state.isEdit &&
                <Typography align={'center'}
                            variant={'h6'}
                            color={'primary'}>
                    Gerenciar Estabelecimento - {this.state?.formEdit?.social_reason}
                </Typography>
                }
                {!this.state.isEdit &&
                <Typography align={'center'}
                            variant={'h6'}
                            color={'primary'}>
                    Gerenciar Estabelecimento
                </Typography>
                }
                <br/>
                <EditFormEstablishment doCreate={this.doCreate}
                                       doEdit={this.doEditConfirm}
                                       erros={this.state.erros}
                                       segmentName={this.state.segmentName}
                                       segment={this.state.segment}
                                       getSegmentBySegmentName={this.getSegmentBySegmentName}
                                       formEdit={this.state.formEdit}
                                       isEdit={this.state.isEdit}/>
            </div>
        );
    }
}

export default EditEstablishment;