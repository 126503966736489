import React, {Component} from 'react';
import HeaderComponent from "../../../Components/HeaderComponent/HeaderComponent";
import {Box, Typography} from "@material-ui/core";
import SpacerComponent from "../../../Components/SpacerComponent/SpacerComponent";
import CurrentPlanBillingDatailScreen from "./CurrentPlanBillingDatailScreen";
import TableBillingDetailScreen from "./TableBillingDetailScreen";
import MessageUtil from "../../../Utils/MessageUtil";
import PlanService from "../../../Services/PlanService";

class BillingDetailScreen extends Component {
    static ROUTE = '/billing-detail'

    constructor(props) {
        super(props);

        this.state = {
            inLoad: false,
            payment: null,
        };

        this.planService = new PlanService();
    }

    componentDidMount() {
        this.load();
    }

    async load() {
        try {
            this.setState({
                inLoad: true,
            });

            const response = await this.planService.payments();

            this.setState({
                inLoad: false,
                payment: response.data,
            });

        } catch (e) {
            this.setState({
                inLoad: false,
                messagens: MessageUtil.make({
                    title: 'Atenção',
                    body: e,
                }),
            });
        }
    }

    render() {
        return (
            <>
                <HeaderComponent title={'Detalhes de cobrança'}
                                 menu={true}
                                 messagens={this.state?.messagens}
                                 inLoad={this.state.inLoad}/>
                <Box display={'flex'}
                     justifyContent={'center'}
                     alignItems={'center'}>
                    <Box width={'70%'}>
                        <SpacerComponent height={30}/>
                        <Typography variant={'h5'}
                                    color={'primary'}
                                    align={'center'}>
                            Detalhes de cobrança
                        </Typography>
                        <SpacerComponent height={40}/>
                        <CurrentPlanBillingDatailScreen payment={this.state.payment}/>
                        <SpacerComponent height={40}/>
                        <TableBillingDetailScreen bills={this.state.payment?.bills}/>
                    </Box>
                </Box>
            </>
        );
    }
}

export default BillingDetailScreen;