import React, {Component} from 'react';
import {FooterStyled} from "./styled";
import {Typography} from "@material-ui/core";

class FooterChoicePlanScreen extends Component {
    render() {
        return (
            <FooterStyled>
                <Typography variant={'body2'}
                            align={'center'}>
                    Os pagamentos serão aceitos apenas via cartão de crédito
                </Typography>
            </FooterStyled>
        );
    }
}

export default FooterChoicePlanScreen;