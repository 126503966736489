class ReplaceUtil {
    static do({
                  value,
                  regx,
                  replaceTo = '',
                  insertFront,
              }) {
        try {

            let newValue = value.replace(regx, replaceTo);

            if (insertFront !== undefined) {
                newValue = insertFront + newValue;
            }

            return newValue;

        } catch (e) {
            return null;
        }
    }
}

export default ReplaceUtil;