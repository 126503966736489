import styled from "styled-components";
import {Typography} from "@material-ui/core";
import CheckIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/Close";

export const ContentMainCertificate = styled.div`
  width: 100%;
`;

export const TextInfoCertificateStyled = styled(Typography)`
  color: ${props => props.set ? '#5bd72c' : '#e51212'};
  font-weight: bold;
  font-size: 18px;
`;

export const CheckIconStyled = styled(CheckIcon)`
  color: #5bd72c;
  font-size: 30px;
`;

export const CloseIconStyled = styled(CloseIcon)`
  color: #e51212;
  font-size: 30px;
`;
