import React, {Component} from 'react';
import PropTypes from 'prop-types';
import HeaderComponent from "../../../Components/HeaderComponent/HeaderComponent";
import {Typography} from "@material-ui/core";
import FormReleaseAward from "./FormReleaseAward";
import MessageUtil from "../../../Utils/MessageUtil";
import AwardService from "../../../Services/AwardService";
import PdfExtractPage from "../ExtractScreen/PdfExtractPage";

class ReleaseAwardScreen extends Component {
    static ROUTE = '/release-award';

    constructor(props) {
        super(props);

        this.state = {
            inLoad: false,
            erros: [],
            award: null,
            releaseResponse: null,
            keyUuid: null,
        };

        this.awardService = new AwardService();
    }

    doFind = async (uuid) => {
        try {

            this.setState({
                inLoad: true,
            });

            const response = await this.awardService.findAwardBooking(uuid);

            this.setState({
                inLoad: false,
                award: response.data.data,
                keyUuid: uuid,
            });

        } catch (e) {
            this.setState({
                inLoad: false,
                messagens: MessageUtil.make({
                    title: 'Atenção',
                    body: e,
                }),
            });
        }
    }

    confirmRelease = () => {
        const contextAux = this;
        this.setState({
            messagens: MessageUtil.make({
                title: 'Tem certeza que deseja liberar a premiação?',
                okButtonText: 'Sim',
                body: '',
                okButtonFunction: contextAux.doFinishRelease,
                cancelButtonText: 'Não',
            }),
        });
    }

    doFinishRelease = async () => {
        try {

            this.setState({
                inLoad: true,
            });

            const response = await this.awardService.releaseAwardBooking(
                this.state.award.uuid
            );

            this.setState({
                inLoad: false,
                releaseResponse: response,
                messagens: MessageUtil.make({
                    title: 'Prêmio liberado',
                    body: 'Gerar comprovante?',
                    okButtonText: 'Sim',
                    okButtonFunction: () => {
                        window.open(PdfExtractPage.ROUTE + `/${this.state.keyUuid}`, '_blank');
                        window.location.reload();
                    },
                    cancelButtonText: 'Cancel',
                    cancelButtonFunction: () => {
                        window.location.reload();
                    },
                }),
            });

        } catch (e) {
            this.setState({
                inLoad: false,
                messagens: MessageUtil.make({
                    title: 'Atenção',
                    body: e,
                }),
            });
        }
    }

    render() {
        return (
            <div>
                <HeaderComponent messagens={this.state?.messagens}
                                 title={'Liberar Prêmio'}
                                 inLoad={this.state.inLoad}
                                 menu={true}/>
                <Typography align={'center'}
                            variant={'h6'}
                            color={'primary'}>
                    Liberar Prêmio
                </Typography>
                <br/>
                <FormReleaseAward erros={this.state.erros}
                                  doFind={this.doFind}
                                  award={this.state.award}
                                  confirmRelease={this.confirmRelease}/>
            </div>
        );
    }
}

ReleaseAwardScreen.propTypes = {};

export default ReleaseAwardScreen;
