import BaseTranslate from "../BaseTranslate";

class GeneralTranslation extends BaseTranslate {
    constructor(erros) {
        super(erros);

        this.erros = erros;
        this.translation = {
            logo_base64: 'Logo',
            address: "Endereço",
            award_limit_time: 'Limite de Tempo de Reserva do Prêmio',
            award_name: "Nome do prêmio",
            card_cvv: 'CVV',
            card_expiration: 'Data de Vencimento',
            card_number: 'Cartão de crédito',
            city: "Cidade",
            cnpj: "CNPJ",
            company: 'Estabelecimento',
            cpf: "CPF",
            description: 'Descrição',
            end_validity: 'Data Final',
            fantasy_name: "Nome Fantasia",
            fidelity_programs_uuid: 'Programa de fidelidade',
            image: 'Logo',
            message: 'Mensagem',
            name: 'Nome',
            neighborhood: "Bairro",
            number: "Número",
            password_confirm: "Confirmar Senha",
            points_validity: 'Tempo de Validade dos Pontos',
            sector: 'Setor',
            segment_name: "Área de atuação",
            segment_type_uuid: "Segmento",
            social_reason: "Razao Social",
            start_validity: 'Data Inicial',
            state: "Estado",
            subject: 'Assunto',
            telephone: "Telefone",
            title: 'Titulo',
            value_points: 'Valor em pontos',
            zip_code: "CEP",
        };
    }
}

export default GeneralTranslation;