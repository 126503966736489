import React, {Component} from 'react';
import {ContentCheckStyled, ContentCNPJInput, ContentDateInput, ContentFilterStyled, ContentInputText} from "./styled";
import TextFieldDefault from "../../../Components/TextFieldDefaultComponent/TextFieldDefault";
import {Button, MenuItem, Typography} from "@material-ui/core";
import SpacerComponent from "../../../Components/SpacerComponent/SpacerComponent";
import CheckBoxDefaultComponent from "../../../Components/CheckBoxDefaultComponent/CheckBoxDefaultComponent";
import FormMask from "../../../FromMask/FormMask";
import SearchIcon from '@material-ui/icons/Search';
import {SpaceBar} from "@material-ui/icons";

class FiltersPaymentStatusScreen extends Component {
    constructor(props) {
        super(props);

        this.state = {
            status: null,
            more_than_5_children: null,
        };
    }

    componentDidMount() {
        this.props.filtersPaymentStatusScreen(
            this
        );
    }

    makeItensPlan() {
        const itens = [];

        itens.push(
            <MenuItem key={''} value={null}>Nenhum</MenuItem>
        );

        this.props.plans.map((value) => {
            itens.push(
                <MenuItem key={value.uuid} value={value.uuid}>{value.name}</MenuItem>
            );
        });

        return itens;
    }


    handleChange(event) {
        const {name, value} = event.target;
        this.setState({
            [name]: value,
        });
    }

    handleCheck(name, value) {
        this.setState({
            [name]: value,
        });
    }

    handleCheckBool(name) {
        this.setState({
            [name]: (this.state[name] === true ? null : true),
        });
    }

    render() {
        return (
            <ContentFilterStyled>
                <ContentInputText>
                    <div>
                        <Typography variant={'h6'}>
                            CNPJ:
                        </Typography>
                        <TextFieldDefault label={'Buscar CNPJ'}
                                          name={'cnpj'}
                                          fullWidth={false}
                                          type={'text'}
                                          mask={FormMask.cnpj}
                                          onChange={(event) => this.handleChange(event)}/>
                    </div>
                    <SpacerComponent width={20}/>
                    <div>
                        <Typography variant={'h6'}>
                            Código Consultor:
                        </Typography>
                        <TextFieldDefault label={'Código Consultor'}
                                          name={'consultant'}
                                          fullWidth={false}
                                          type={'text'}
                                          onChange={(event) => this.handleChange(event)}/>
                    </div>
                    <SpacerComponent width={20}/>
                    <div>
                        <Typography variant={'h6'}>
                            Data:
                        </Typography>
                        <ContentDateInput>
                            <TextFieldDefault label={'Data Inicial'}
                                              name={'start_date'}
                                              fullWidth={false}
                                              type={'date'}
                                              forceLabel={true}
                                              onChange={(event) => this.handleChange(event)}/>
                            <SpacerComponent width={5}/>
                            <Typography variant={'body1'}>
                                até
                            </Typography>
                            <SpacerComponent width={5}/>
                            <TextFieldDefault label={'Data Final'}
                                              name={'end_date'}
                                              fullWidth={false}
                                              type={'date'}
                                              forceLabel={true}
                                              onChange={(event) => this.handleChange(event)}/>
                        </ContentDateInput>
                    </div>
                    <SpacerComponent width={20}/>
                    <div>
                        <Typography variant={'h6'}>
                            Plano
                        </Typography>
                        <TextFieldDefault select={true}
                                          fullWidth={false}
                                          name={'payment_plan'}
                                          margin={'none'}
                                          onChange={(event) => this.handleChange(event)}>
                            {this.makeItensPlan()}
                        </TextFieldDefault>
                    </div>
                </ContentInputText>
                <Typography variant={'h6'}>
                    Status:
                </Typography>
                <ContentCheckStyled>
                    <CheckBoxDefaultComponent onChange={() => this.handleCheckBool('is_model')}
                                              label={'Empresa Modelo'}/>
                    <CheckBoxDefaultComponent onChange={() => this.handleCheck('status[0]', 1)}
                                              label={'Ativo'}/>
                    <CheckBoxDefaultComponent onChange={() => this.handleCheck('status[1]', 2)}
                                              label={'Inativo'}/>
                </ContentCheckStyled>
                <ContentCheckStyled>
                    <CheckBoxDefaultComponent onChange={() => this.handleCheck('status[2]', 3)}
                                              label={'Inadimplente'}/>
                    <SpacerComponent width={27} />
                    <CheckBoxDefaultComponent onChange={() => this.handleCheckBool('more_than_5_children')}
                                              label={'+ de 5 Filiais'}/>
                </ContentCheckStyled>
                <SpacerComponent height={20}/>
                 <Button variant={'contained'}
                        color={'primary'}
                        onClick={() => this.props.load(true)}
                        startIcon={<SearchIcon/>}>
                    Buscar
                </Button>
            </ContentFilterStyled>
        );
    }
}

export default FiltersPaymentStatusScreen;