import styled from "styled-components";
import {Tooltip} from "@material-ui/core";

export const TooltipStyled = styled(Tooltip)`
  position: ${props => !props.changePosition ? 'relative' : 'absolute'};
  top: ${props => props.top + 'px' ?? 'none'};
  bottom: ${props => props.bottom + 'px' ?? 'none'};
  right: ${props => props.right + 'px' ?? 'none'};
  left: ${props => props.left + 'px' ?? 'none'};
  margin-left: ${props => props.marginLeft + 'px' ?? 'none'};
  color: ${props => props.color ?? 'none'};
`;