import React, {Component} from 'react';
import {Box, Hidden} from "@material-ui/core";
import AvatarCircleComponent from "../AvatarCircleComponent/AvatarCircleComponent";
import SpacerComponent from "../SpacerComponent/SpacerComponent";
import {TitleSubHeaderStyled} from "./styled";


class SubHeaderComponent extends Component {
    render() {
        return (
            <div>
                <Box display={'flex'}
                     justifyContent={'center'}
                     alignItems={'center'}>
                    <Hidden xsDown={true}>
                        <Box display={'flex'}
                             flexDirection={'row'}
                             justifyContent={'flex-start'}
                             alignItems={'center'}
                             flex={1}>
                            <AvatarCircleComponent/>
                            <SpacerComponent width={20}/>
                            {/*<SearchTextFieldComponent/>*/}
                        </Box>
                    </Hidden>
                    <Hidden lgUp={true}
                            xlUp={true}
                            mdUp={true}
                            smUp={true}>
                        <Box display={'flex'}
                             flexDirection={'column'}
                             justifyContent={'center'}
                             alignItems={'center'}
                             flex={1}>
                            <AvatarCircleComponent/>
                            <SpacerComponent height={100}/>
                            {/*<SearchTextFieldComponent/>*/}
                        </Box>
                    </Hidden>
                    <SpacerComponent height={40}/>
                    <TitleSubHeaderStyled variant={'h6'}
                                          color={'primary'}>
                        {this.props.title}
                    </TitleSubHeaderStyled>
                    <SpacerComponent height={40}/>
                </Box>
            </div>
        );
    }
}

export default SubHeaderComponent;