import React, {Component} from 'react';
import {ContentMainStyled, ContentSizeStyled} from "./styled";
import TextFieldDefault from "../../../Components/TextFieldDefaultComponent/TextFieldDefault";
import {Button} from "@material-ui/core";
import SpacerComponent from "../../../Components/SpacerComponent/SpacerComponent";

class FormRegulationFidelity extends Component {
    constructor(props) {
        super(props);

        this.state = {
            regulation: undefined,
        };
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.regulation === prevProps.regulation) {
            return;
        }
        this.setForm();
    }

    async setForm() {
        this.setState({
            regulation: this.props.regulation,
        });
    }

    handleChange(event) {
        const {name, value} = event.target;
        this.setState({
            [name]: value,
            btnAlterDisabled: false,
        });
    }

    updateRegulation = () => {
        this.props.updateRegulation(this.state);
    }

    render() {
        return (
            <ContentMainStyled>
                <ContentSizeStyled>
                    <TextFieldDefault
                        name={'regulation'}
                        multiline={true}
                        value={this.state.regulation}
                        onChange={(event) => this.handleChange(event)}
                        erros={this.props.erros}/>
                    <SpacerComponent height={40}/>
                    <Button variant={'contained'}
                            color={'primary'}
                            size={"large"}
                            fullWidth={true}
                            onClick={this.updateRegulation}>
                        Finalizar
                    </Button>
                </ContentSizeStyled>
            </ContentMainStyled>
        );
    }
}

export default FormRegulationFidelity;