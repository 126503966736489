import React, {Component} from 'react';
import {IconButton} from "@material-ui/core";
import {SearchTextFieldStyled} from "./styled";
import {SearchOutlined} from "@material-ui/icons";
import {AwardContext} from "../../../../../Contexts/AwardContext";

class SearchTextFieldComponent extends Component {
    static contextType = AwardContext;

    constructor(props) {
        super(props);

        this.state = {
            filter: null,
        };
    }

    handleChange(event) {
        const {value} = event.target;

        this.setState({
            filter: value,
        });
    }

    render() {
        return (
            <div>
                <SearchTextFieldStyled id="outlined-basic"
                                       label="Buscar"
                                       variant="outlined"
                                       onChange={(event) => this.handleChange(event)}
                                       InputProps={{
                                           endAdornment: <IconButton
                                               onClick={() => this.context.doSearch(
                                                   this.state.filter
                                               )}>
                                               <SearchOutlined/>
                                           </IconButton>
                                       }}
                />
            </div>
        );
    }
}

export default SearchTextFieldComponent;