class ErrorUtil {
    static make(message) {
        return new Error(
            JSON.stringify(
                {
                    error: {
                        error: {
                            message: message
                        }
                    }
                }
            )
        )
    }
}

export default ErrorUtil