import ApiClient from "./Api/ApiClient";
import MeUtil from "../Utils/MeUtil";
import * as url from "url";

class UserService {

    constructor() {
        this.uri = 'user';
        this.apiClient = new ApiClient();
    }


    async forgotPassword(object) {
        try {

            return await this.apiClient.doRequest({
                method: ApiClient.POST,
                uri: 'forgot-pass',
                expectedStatus: 201,
                auth: false,
                body: object,
            });

        } catch (e) {
            throw e;
        }
    }

    async resetPassword(object) {
        try {

            return await this.apiClient.doRequest({
                method: ApiClient.POST,
                uri: 'reset-pass',
                expectedStatus: 201,
                auth: false,
                body: object,
            });

        } catch (e) {
            throw e;
        }
    }

    async update(uuid, object) {
        try {

            return await this.apiClient.doRequest({
                method: ApiClient.PATCH,
                uri: 'user/' + uuid,
                expectedStatus: 201,
                body: object,
            });

        } catch (e) {
            throw e;
        }
    }

    async getMyPlan() {
        try {

            return await this.apiClient.doRequest({
                method: ApiClient.GET,
                uri: 'payment-plan/my-plan',
                expectedStatus: 200,
            });

        } catch (e) {
            throw e;
        }
    }

    async getPermissions() {
        try {

            return await this.apiClient.doRequest({
                method: ApiClient.GET,
                uri: 'permissions/app_pj',
                expectedStatus: 200,
            });

        } catch (e) {
            throw e;
        }
    }

    async startDeleteAccount() {
        try {
            const user = await MeUtil.get();
            const companyId = user.data.data.company.uuid;

            return await this.apiClient.doRequest({
                method: ApiClient.GET,
                uri: 'company/exclude-account/' + companyId,
                expectedStatus: 200,
            });
        } catch (e) {
            throw e;
        }
    }

    async doDeleteAccount(endpoint) {
        try {

            const urlBase = this.apiClient.getBaseUrl();

            endpoint = endpoint
                .replace(`${urlBase}/api/v1/`, '');

            return await this.apiClient.doRequest({
                method: ApiClient.GET,
                uri: endpoint,
                expectedStatus: 200,
            });
        } catch (e) {
            throw e;
        }
    }

}

export default UserService;