import styled from "styled-components";
import {Paper} from "@material-ui/core";

export const ContainerMainStyled = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-left: 20%;
  padding-right: 20%;
  width: 100%;

  @media only screen and (max-width: 600px) {
    padding-left: 20px;
    padding-right: 20px;
  }
`;

export const ContainerCurrentPlanStyled = styled(Paper)`
  padding: 20px;
  background: transparent;
  border-width: 2px;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
`;

export const ContentFormStyled = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;

  button {
    width: 300px;
    height: 60px;
    font-size: 20px;
    background: #46a95f;
    color: white;
  }

`;

export const TitleFormStyled = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  div:first-of-type {
    width: 40px;
    height: 40px;
    border-radius: 100px;
    background: black;
    color: white;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export const ContentCVVDate = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;