import React, {Component} from 'react';
import {Box, IconButton, Typography} from '@material-ui/core';
import {AddCircleOutline} from '@material-ui/icons'
import SpacerComponent from "../../../Components/SpacerComponent/SpacerComponent";

class ButtonMoreEstablishment extends Component {
    render() {
        return (
            <Box display={'flex'}
                 alignItems="center">
                <SpacerComponent height={20}/>
                <IconButton onClick={() => this.props.addEstablishment()}>
                    <AddCircleOutline/>
                </IconButton>
                <Typography>
                    Adicionar Novo Estabelecimento
                </Typography>
            </Box>
        );
    }
}

export default ButtonMoreEstablishment;