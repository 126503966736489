import React, {Component} from 'react';
import HeaderComponent from "../../../Components/HeaderComponent/HeaderComponent";
import SpacerComponent from "../../../Components/SpacerComponent/SpacerComponent";
import {Box, Typography} from "@material-ui/core";
import {ContainerMainStyled} from "./styled";
import CardPaymentMethodScreen from "./CardPaymentMethodScreen";
import FormManagePaymentScreen from "./FormManagePaymentScreen";
import {ManagePaymentContext} from "../../../Contexts/ManagePaymentContext";
import MessageUtil from "../../../Utils/MessageUtil";
import MeUtil from "../../../Utils/MeUtil";
import PlanService from "../../../Services/PlanService";
import MyAccountScreen from "../MyAccountScreen/MyAccountScreen";
import FlagsAcceptedCardComponent from "../../../Components/FlagsAcceptedCardComponent/FlagsAcceptedCardComponent";

class ManagePaymentScreen extends Component {
    static ROUTE = '/manage-payment';

    constructor(props) {
        super(props);

        this.state = {

            inLoad: false,
            me: null,
            payments: null,
            erros: [],

        };

        this.planService = new PlanService();
    }

    componentDidMount() {
        this.load();
    }

    load = async () => {
        try {

            this.setState({
                inLoad: true,
            });

            const meUtil = await MeUtil.get();
            const payments = await this.planService.payments();

            if (meUtil.data.data.company.blocked_access === true || meUtil.data.data.company.has_pending_payment === true) {
                this.setState({
                    inLoad: false,
                    me: meUtil.data.data,
                    payments: payments.data,
                    messagens: MessageUtil.make({
                        title: 'Atenção',
                        body: 'Consta em nossos registros que seu plano tem faturas em aberto. Cadastre um método de pagamento válido para poder continuar oferecendo vantagens aos seus clientes.',
                    }),
                });
                return;
            }

            this.setState({
                inLoad: false,
                me: meUtil.data.data,
                payments: payments.data,
            });

        } catch (e) {
            this.setState({
                inLoad: false,
                messagens: MessageUtil.make({
                    title: 'Atenção',
                    body: e,
                }),
            });
        }
    }

    doCreate = async (form) => {
        try {
            this.setState({
                inLoad: true,
            });

            await this.planService.updateSubscribePlan({
                uuid: this.state.me.company.uuid,
                form,
            });

            this.setState({
                inLoad: false,
                messagens: MessageUtil.make({
                    title: 'Atenção',
                    body: 'Dados alterado com sucesso.',
                    okBtnToRoute: MyAccountScreen.ROUTE,
                }),
            });
        } catch (e) {
            this.setState({
                inLoad: false,
                messagens: MessageUtil.make({
                    title: 'Atenção',
                    body: e,
                }),
            });
        }
    }

    render() {
        return (
            <ManagePaymentContext.Provider value={{
                parent: this,
            }}>
                <HeaderComponent title={'Método de pagamento'}
                                 menu={true}
                                 messagens={this.state?.messagens}
                                 inLoad={this.state.inLoad}/>
                <ContainerMainStyled>
                    <SpacerComponent height={30}/>
                    <Typography variant={'h5'}
                                color={'primary'}
                                align={'center'}>
                        Método de pagamento
                    </Typography>
                    <SpacerComponent height={20}/>
                    <CardPaymentMethodScreen/>
                    <FormManagePaymentScreen erros={this.state.erros}/>
                    <SpacerComponent height={50}/>
                </ContainerMainStyled>
            </ManagePaymentContext.Provider>
        );
    }
}

export default ManagePaymentScreen;