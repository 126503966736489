import * as yup from 'yup';
import ValidationUtil from "../Utils/ValidationUtil";

class FidelityValidation {

    static async validate(form) {
        try {

            let validateForm = {
                name: yup
                    .string()
                    .required(ValidationUtil.MSG_FIELD_REQUIRED),
                image: yup
                    .string()
                    .required(ValidationUtil.MSG_FIELD_REQUIRED),
                points_validity: yup
                    .number(ValidationUtil.MSG_FIELD_NUMBER)
                    .typeError(ValidationUtil.MSG_FIELD_NUMBER)
                    .required(ValidationUtil.MSG_FIELD_REQUIRED)
                    .positive(ValidationUtil.MSG_FIELD_POSITIVE)
                    .integer(ValidationUtil.MSG_INTERGER),
                award_limit_time: yup
                    .number(ValidationUtil.MSG_FIELD_NUMBER)
                    .typeError(ValidationUtil.MSG_FIELD_NUMBER)
                    .required(ValidationUtil.MSG_FIELD_REQUIRED)
                    .positive(ValidationUtil.MSG_FIELD_POSITIVE)
                    .integer(ValidationUtil.MSG_INTERGER),
            };

            if (form.noLimitPoint === true) {
                delete validateForm.points_validity;
            }

            let validate = yup.object().shape(validateForm);

            validate.validateSync(form, {abortEarly: false})

            return true;

        } catch (e) {
            let erros = [];
            e.inner.forEach(function (value, index) {
                erros[value.path] = value.message;
            });

            return erros;
        }
    }
}

export default FidelityValidation;