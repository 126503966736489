import React from "react";
import Analytics from "../Utils/analytics";

export const SuperContext = React.createContext({
    myPlan: null,
    me: null,
    load: null,
    updateMyRoles: null,
    myRoles: [],
    isAdmin: null,
    showFooter: null,
    footerAbsolute: null,
    fidelity: [],
    analytics: null,
});