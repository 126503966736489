import styled from "styled-components";
import {Paper} from "@material-ui/core";

export const ContentMainStyled = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ClickAreaStyled = styled.div`
  cursor: pointer;
`;

export const ContentCalcStyled = styled(Paper)`
  position: fixed;
  width: 400px;
  height: 520px;
  z-index: 9;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  padding: 20px;
`;