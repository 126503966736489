import AuthService from "./AuthService";
import jwtDecoded from 'jwt-decode';
import moment from "moment";

const STORAGE_KEY_TOKEN = '@meuapp/token'

class ApiToken {

    authService;

    constructor() {
        this.token = localStorage.getItem(STORAGE_KEY_TOKEN);
    }

    static get() {
        return new ApiToken();
    }

    static saveToken(token) {
        localStorage.setItem(STORAGE_KEY_TOKEN, token);
    }

    static logout() {
        localStorage.removeItem(STORAGE_KEY_TOKEN);
    }

    formatToken(token) {
        return `Bearer ${token}`
    }

    async verifyToken() {

        const exp = jwtDecoded(this.token).exp;
        const currentTime = moment().unix();

        if ((currentTime + 20) >= exp) {
            return this.refreshToken();
        }

        return this.formatToken(this.token);

    }

    async refreshToken() {

        try {

            this.authService = new AuthService();
            const token = this.formatToken(this.token);
            const response = await this.authService.doRefreshToken({token: token});

            ApiToken.saveToken(response.data.token);
            return this.formatToken(response.data.token);

        } catch (e) {
            throw  e;
        }

    }

    static tokenDecode() {
        try {

            const token = localStorage.getItem(STORAGE_KEY_TOKEN);
            if (token === null) {
                return {};
            }
            return jwtDecoded(token);

        } catch (e) {
            throw e;
        }
    }

    static shouldRemoveTokenByException(exception) {

        const object = JSON.parse(exception.message);

        if (object.status !== 401) {
            return;
        }

        ApiToken.logout();

    }

}

export default ApiToken;