import React, {Component} from 'react';
import HeaderComponent from "../../../Components/HeaderComponent/HeaderComponent";
import {Box, Grid, InputLabel, MenuItem, Select, Typography} from "@material-ui/core";
import SpacerComponent from "../../../Components/SpacerComponent/SpacerComponent";
import MessageUtil from "../../../Utils/MessageUtil";
import GiftCardService from "../../../Services/GiftCardService";
import ItemGiftCard from "./ItemGiftCard";
import {GiftCardPurchaseContext} from "../../../Contexts/GiftCardPurchaseContext";
import ModalGiftCard from "./ModalGiftCard";
import TextFieldDefault from "../../../Components/TextFieldDefaultComponent/TextFieldDefault";

class GiftCardPurchaseScreen extends Component {
    static ROUTE = '/gift-card-purchase';

    constructor(props) {
        super(props);

        this.state = {
            inLoad: false,
            giftCards: [],
            selectProduct: null,
            selectProductLines: null,
            typesData: [],
            types: [],
        };

        this.giftCardService = new GiftCardService();
    }

    handleChange(event) {
        const {name, value} = event.target;
        this.setState({
            [name]: value,
        });

        if (name === 'types') {

            let query = '';
            value.forEach(function (val) {
                query += `categories[]=${val}&`;
            });

            query = '?' + query;

            this.load(query);
        }
    }

    async load(search = '') {
        try {

            this.setState({
                inLoad: true,
            })

            const giftCards = await this.giftCardService.retrieveToBuy(search);
            const categories = await this.giftCardService.getCategories();

            this.setState({
                inLoad: false,
                giftCards: giftCards.data.product_lines,
                typesData: categories.data,
            })

        } catch (e) {
            this.setState({
                inLoad: false,
                messagens: MessageUtil.make({
                    title: 'Atenção',
                    body: e,
                }),
            });
        }
    }

    makeItemGiftCard() {
        let itens = [];
        const context = this;

        Object.keys(this.state.giftCards).map(function (key) {
            const value = context.state.giftCards[key];

            itens.push(
                <ItemGiftCard productLines={value}/>
            );
        });

        return itens;
    }

    makeTypes() {
        const itens = [];

        const context = this;

        itens.push(
            <MenuItem value={''} disabled={true}>Filtre por categorias</MenuItem>
        );

        Object.keys(this.state.typesData).map(function (key) {
            const value = context.state.typesData[key];

            itens.push(
                <MenuItem key={value} value={value}>{value}</MenuItem>
            );
        });

        return itens;
    }

    componentDidMount() {
        this.load();
    }

    render() {
        return (
            <GiftCardPurchaseContext.Provider value={{
                parent: this,
            }}>
                <HeaderComponent title={'Registrar Cashback'}
                                 menu={true}
                                 inLoad={this.state.inLoad}
                                 messagens={this.state.messagens}/>
                <Box display={'flex'}
                     justifyContent={'center'}
                     alignItems={'center'}>
                    <Box width={'80%'}>
                        <Typography align={'center'}
                                    variant={'h6'}
                                    color={'primary'}>
                            Voucher Disponíveis
                        </Typography>
                        <SpacerComponent height={50}/>
                        <Typography variant={'h6'}>Categorias:</Typography>
                        <Typography variant={'subtitle1'}>Escolha a baixo uma ou mais categorias para realizar um
                            filtro:</Typography>
                        <Select fullWidth={false}
                                variant={'outlined'}
                                multiple={true}
                                value={this.state.types}
                                name={'types'}
                                margin={'none'}
                                displayEmpty={true}
                                placeholder={'Categorias'}
                                onChange={(event) => this.handleChange(event)}>
                            {this.makeTypes()}
                        </Select>
                        <SpacerComponent height={50}/>
                        {this.makeItemGiftCard()}
                    </Box>
                </Box>
                <ModalGiftCard product={this.state.selectProduct}
                               productLines={this.state.selectProductLines}/>
            </GiftCardPurchaseContext.Provider>
        );
    }
}

export default GiftCardPurchaseScreen;