import ApiClient from "./Api/ApiClient";

class DashboardService {

    constructor() {
        this.uri = 'dashboard';
        this.apiClient = new ApiClient();
    }

    async get({inital, final}) {
        try {

            const filter = `?start_date=${inital}&end_date=${final}`;

            return await this.apiClient.doRequest({
                method: ApiClient.GET,
                uri: this.uri + filter,
                expectedStatus: 200,
            });

        } catch (e) {
            throw e;
        }
    }

    async getPrizesReserved({inital, final}) {
        try {

            const filter = `?start_date=${inital}&end_date=${final}`;

            return await this.apiClient.doRequest({
                method: ApiClient.GET,
                uri: `award-booking/get-by-company${filter}`,
                expectedStatus: 200,
            });

        } catch (e) {
            throw e;
        }
    }

    async getAdmin({initial, final}) {
        try {

            const filter = `?start_date=${initial}&end_date=${final}`;

            return await this.apiClient.doRequest({
                method: ApiClient.GET,
                uri: 'dashboard-consultant' + filter,
                expectedStatus: 200,
            });

        } catch (e) {
            throw e;
        }
    }

    async dashboardCompany(filters) {
        try {

            return await this.apiClient.doRequest({
                method: ApiClient.GET,
                uri: `dashboard-company?${filters}`,
                expectedStatus: 200,
            });

        } catch (e) {
            throw e;
        }
    }

}

export default DashboardService;