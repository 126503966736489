import React, {Component} from 'react';
import HeaderComponent from "../../../Components/HeaderComponent/HeaderComponent";
import SpacerComponent from "../../../Components/SpacerComponent/SpacerComponent";
import ContentSac from "./ContentSac";
import {Box, Button} from "@material-ui/core";
import SubHeaderComponent from "../../../Components/SubHeaderDefault/SubHeaderComponent";
import RouteUtil from "../../../Utils/RouteUtil";
import ContactUsScreen from "../ContactUsScreen/ContactUsScreen";
import {ContentSecondaryStyled} from "./styled";

class SacScreen extends Component {

    static ROUTE = '/faq';

    render() {
        return (
            <div>
                <HeaderComponent title={'FAQ'}
                                 menu={true}/>
                <Box display={'flex'}
                     justifyContent={'center'}
                     alignItems={'center'}
                     width={'100%'}>
                    <ContentSecondaryStyled>
                        <SubHeaderComponent title={'FAQ'}/>
                        <SpacerComponent height={40}/>
                        <ContentSac/>
                        <SpacerComponent height={80}/>
                        <Box display={'flex'}
                             justifyContent={'center'}
                             alignItems={'center'}>
                            <Button variant={'contained'}
                                    color={'primary'}
                                    onClick={() => RouteUtil.toRoute(
                                        ContactUsScreen.ROUTE
                                    )}>
                                fale conosco
                            </Button>
                        </Box>
                    </ContentSecondaryStyled>
                </Box>
            </div>
        );
    }
}

export default SacScreen;