import VindiErroUtil from "./VindiErroUtil";

class MessageUtil {

    static make({
                    title,
                    body,
                    requirements = null,
                    okBtnToRoute = null,
                    okButtonText = 'ok',
                    okButtonFunction = null,
                    cancelButtonText = null,
                    cancelButtonFunction = null,
                }) {

        let check = false;
        const bodyAux = body;
        const bodyAux2 = body;

        try {
            if (
                typeof body === 'object' &&
                JSON.parse(bodyAux.message).status !== undefined &&
                JSON.parse(bodyAux.message).status === 400 &&
                JSON.parse(bodyAux.message)?.error?.error?.requirements === undefined &&
                check === false
            ) {
                const result = VindiErroUtil.make(JSON.parse(bodyAux.message).error);

                body = result.message;
                requirements = result.requirements;

                if (body !== undefined) {
                    check = true;
                }
                if (body === undefined) {
                    check = false;
                }
            }
        } catch (e) {
        }

        if (typeof bodyAux2 === 'object' && check === false) {
            try {
                const response = JSON.parse(bodyAux2.message);

                body = response.error.error.message;
                requirements = response?.error?.error?.requirements;
            } catch (e) {
                body = 'Erro inesperado, tente novamente mais tarde';
            }
        }

        return {
            open: true,
            title: title,
            body: body,
            requirements: requirements,
            okBtnToRoute: okBtnToRoute,
            okButtonText: okButtonText,
            okButtonFunction: okButtonFunction,
            cancelButtonText: cancelButtonText,
            cancelButtonFunction: cancelButtonFunction,
        };
    }

    static makeToValidationField(erros) {

        return MessageUtil.make({
            title: 'Atenção',
            body: 'Os seguintes campos não foram preenchidos corretamente, verifique-os e tente novamente.',
            requirements: erros,
        });

    }
}

export default MessageUtil;
