import styled from 'styled-components';

export const ContentMainStyled = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ContentSecondaryStyled = styled.div`
  width: ${props => props.width ? props.width + 'px' : 0};
  padding: ${props => props.padding ? props.padding + 'px' : 0};
`;