import React, {Component} from 'react';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import {CanHelpFloatStyled} from "./styled";

class CanHelpFloat extends Component {
    render() {
        return (
            <CanHelpFloatStyled elevation={3}
                                onClick={() => {
                                    window.open(
                                        `https://api.whatsapp.com/send?phone=${process.env.REACT_APP_WHATSAPP_PHONE}`,
                                        '_blank',
                                    );
                                }}>
                <WhatsAppIcon/>
            </CanHelpFloatStyled>
        );
    }
}

export default CanHelpFloat;