import React, {Component} from 'react';
import {
    ContentButtonStyled,
    ContentFormStyled,
    FormCardStyled,
    FormPlanStyled,
    TermStyled
} from "./GiftCardstyled";
import {Box, Checkbox, MenuItem, Typography} from "@material-ui/core";
import TextFieldDefault from "../../../Components/TextFieldDefaultComponent/TextFieldDefault";
import SpacerComponent from "../../../Components/SpacerComponent/SpacerComponent";
import PaymentValidation from "../../../FormValidations/PaymentValidation";
import FormMask from "../../../FromMask/FormMask";
import MessageUtil from "../../../Utils/MessageUtil";
import ReplaceUtil from "../../../Utils/ReplaceUtil";
import PaymentUtil from "../../../Utils/PaymentUtil";
import StringUtil from "../../../Utils/StringUtil";
import FlagsAcceptedCardComponent from "../../../Components/FlagsAcceptedCardComponent/FlagsAcceptedCardComponent";
import {GiftCardPurchaseModalContext} from "../../../Contexts/GiftCardPurchaseModalContext";
import MeUtil from "../../../Utils/MeUtil";
import FixSelectComponent from "../../../Components/FixSelect/FixSelect";

const valid = require("card-validator");

class FormGiftCardPaymentScreen extends Component {
    static contextType = GiftCardPurchaseModalContext;

    constructor(props) {
        super(props);

        this.state = {
            country: 'BR',
            term_card: false,
            vindi_accept: false,
            payment_company_code: null,
        };
    }

    handleChange = (event) => {
        const {name, value} = event.target;
        this.liveValidCardNumber(name, value);

        if (name === 'card_number') {
            this.setState({
                payment_company_code: PaymentUtil.getCode(value),
            })
        }

        this.setState({
            [name]: value,
        });
    }

    liveValidCardNumber(name, value) {
        if (name !== 'card_number') {
            return;
        }

        if (StringUtil.onlyNumber(value).length < 16) {
            return;
        }

        const numberValidation = valid.number(value);
        if (!numberValidation.isPotentiallyValid) {
            console.log('liveValidCardNumber');
            this.context.parent.setState({
                erros: {
                    card_number: 'Cartão inválido',
                }
            });
            return;
        }

        const vindiCodeFlag = PaymentUtil.getCode(value);

        if (vindiCodeFlag === undefined) {
            this.context.parent.setState({
                erros: {
                    card_number: 'A bandeira do cartão não foi informada',
                }
            });
            return;
        }

        this.context.parent.setState({
            erros: [],
        });
    }

    handleChangeTerm = () => {
        this.setState({
            term_card: !this.state.term_card,
        });
    }

    handleChangeVindiAccept = () => {
        this.setState({
            vindi_accept: !this.state.vindi_accept,
        });
    }

    doCreate = async () => {
        if (this.state.term_card !== true) {
            this.context.parent.context.parent.setState({
                messagens: MessageUtil.make({
                    title: 'Atenção',
                    body: 'Aceite o compartilhamento de dados para continuar.',
                }),
            });
            return;
        }

        if (this.state.vindi_accept !== true) {
            this.context.parent.context.parent.setState({
                messagens: MessageUtil.make({
                    title: 'Atenção',
                    body: 'Aceite o compartilhamento das informações de pagamento.',
                }),
            });
            return;
        }

        const validate = await PaymentValidation.validate(this.state);
        if (validate !== true) {
            this.context.parent.setState({
                erros: validate,
            });
            return;
        }

        let newForm = {};
        Object.assign(newForm, this.state);

        newForm.card_number = ReplaceUtil.do({
            value: newForm.card_number,
            regx: /\s/g,
        });

        this.context.parent.doBuy(newForm);
    }

    load = async () => {
        try {
            this.context.parent.context.parent.setState({
                inLoad: true,
            });

            const paymentProfile = (await MeUtil.get()).data.data.company.payment_profile;

            this.setState({
                ...this.state,
                ...paymentProfile,
            })

            this.context.parent.context.parent.setState({
                inLoad: false,
            });
        } catch (e) {
            this.context.parent.context.parent.setState({
                inLoad: false,
                messagens: MessageUtil.make({
                    title: 'Atenção',
                    body: e,
                }),
            });
        }
    }

    componentDidMount() {
        /*this.load();*/
    }

    render() {
        return (
            <FormPlanStyled variant={'outlined'}>
                <ContentFormStyled>
                    <FormCardStyled>
                        <Typography variant={'subtitle1'}
                                    align={'center'}>
                            Preencha seus dados conforme constam no seu cartão de crédito.
                        </Typography>
                        <FlagsAcceptedCardComponent/>
                        <TextFieldDefault label={'Nome *'}
                                          name={'name'}
                                          onChange={(event) => this.handleChange(event)}
                                          value={this.state.name}
                                          erros={this.props.erros}/>
                        <TextFieldDefault label={'Cartão de Credito *'}
                                          name={'card_number'}
                                          mask={FormMask.cardNumber}
                                          onChange={(event) => this.handleChange(event)}
                                          value={this.state.card_number}
                                          erros={this.props.erros}/>
                        {this.state.payment_company_code !== null &&
                            <FixSelectComponent>
                                <TextFieldDefault label={'Bandeira do cartao *'}
                                                  name={'payment_company_code'}
                                                  value={this.state.payment_company_code}
                                                  select={true}
                                                  onChange={(event) => this.handleChange(event)}
                                                  erros={this.props.erros}>
                                    <MenuItem value={'mastercard'}>MasterCard</MenuItem>
                                    <MenuItem value={'visa'}>Visa</MenuItem>
                                    <MenuItem value={'american_express'}>American Express</MenuItem>
                                    {/*<MenuItem value={'diners_club'}>Diners Club</MenuItem>
                                <MenuItem value={'elo'}>Elo</MenuItem>
                                <MenuItem value={'hipercard'}>Hipercard</MenuItem>
                                <MenuItem value={'jcb'}>JCB</MenuItem>*/}
                                </TextFieldDefault>
                            </FixSelectComponent>
                        }
                        <Box display={'flex'}
                             flexDirection={'row'}>
                            <TextFieldDefault label={'Data de Vencimento *'}
                                              name={'card_expiration'}
                                              forceLabel={true}
                                              mask={FormMask.cardDate}
                                              onChange={(event) => this.handleChange(event)}
                                              value={this.state.card_expiration}
                                              erros={this.props.erros}/>
                            <SpacerComponent width={20}/>
                            <TextFieldDefault label={'CVV *'}
                                              name={'card_cvv'}
                                              type={'text'}
                                              mask={FormMask.cardCvv}
                                              onChange={(event) => this.handleChange(event)}
                                              value={this.state.card_cvv}
                                              erros={this.props.erros}/>
                        </Box>
                        <TermStyled
                            control={
                                <Checkbox checked={this.state.term_card}
                                          onChange={this.handleChangeTerm}
                                          name={'term_card'}/>
                            }
                            label={'Aceito compartilhar meus dados com o meio de pagamento escolhido pela Meu App.'}/>
                        <SpacerComponent height={10}/>
                        <TermStyled
                            control={
                                <Checkbox checked={this.state.vindi_accept}
                                          onChange={this.handleChangeVindiAccept}
                                          name={'vindi_accept'}/>
                            }
                            label={'Aceito compartilhar minhas informações com a Plataforma de pagamento online e cobrança recorrente.'}/>
                    </FormCardStyled>
                </ContentFormStyled>
                <ContentButtonStyled onClick={this.doCreate}>
                    <h5>CONFIRMAR</h5>
                </ContentButtonStyled>
            </FormPlanStyled>
        );
    }
}

export default FormGiftCardPaymentScreen;