import * as yup from 'yup';
import ValidationUtil from "../Utils/ValidationUtil";

class CompanyEditValidation {

    static async validate(form) {
        try {
            if (form.cpf === "   .   .   -  ") {
                form.cpf = "";
            }

            let validate = yup.object().shape({
                name: yup
                    .string()
                    .required(ValidationUtil.MSG_FIELD_REQUIRED),
                cpf: yup
                    .string()
                    .required(ValidationUtil.MSG_FIELD_REQUIRED),
                cnpj: yup
                    .string()
                    .required(ValidationUtil.MSG_FIELD_REQUIRED),
                social_reason: yup
                    .string()
                    .required(ValidationUtil.MSG_FIELD_REQUIRED),
                fantasy_name: yup
                    .string()
                    .required(ValidationUtil.MSG_FIELD_REQUIRED),
                telephone: yup
                    .string()
                    .required(ValidationUtil.MSG_FIELD_REQUIRED),
                zip_code: yup
                    .number(ValidationUtil.MSG_FIELD_NUMBER)
                    .typeError(ValidationUtil.MSG_FIELD_NUMBER)
                    .required(ValidationUtil.MSG_FIELD_REQUIRED),
                address: yup
                    .string()
                    .required(ValidationUtil.MSG_FIELD_REQUIRED),
                number: yup
                    .string()
                    .required(ValidationUtil.MSG_FIELD_REQUIRED),
                city: yup
                    .string()
                    .required(ValidationUtil.MSG_FIELD_REQUIRED),
                neighborhood: yup
                    .string()
                    .required(ValidationUtil.MSG_FIELD_REQUIRED),
                state: yup
                    .string()
                    .required(ValidationUtil.MSG_FIELD_REQUIRED),
            });

            validate.validateSync(form, {abortEarly: false})

            return true;

        } catch (e) {
            let erros = [];
            e.inner.forEach(function (value, index) {
                erros[value.path] = value.message;
            });

            return erros;
        }
    }

}

export default CompanyEditValidation;