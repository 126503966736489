import React, {Component} from 'react';
import HeaderComponent from "../../../Components/HeaderComponent/HeaderComponent";
import {Box, Grid} from "@material-ui/core";
import ChartDefault from "./ChartDefault";
import TableTotal from "./TableTotal";
import DateUtil from "../../../Utils/DateUtil";
import MessageUtil from "../../../Utils/MessageUtil";
import ErrorUtil from "../../../Utils/ErrorUtil";
import DashboardService from "../../../Services/DashboardService";
import SearchTextFieldComponent from "./SearchTextFieldComponent/SearchTextFieldComponent";
import SpacerComponent from "../../../Components/SpacerComponent/SpacerComponent";


class DashboardAdminScreen extends Component {
    static ROUTE = '/dashboard-admin';

    constructor(props) {
        super(props);

        this.state = {
            messagens: null,
            companiesByPaymentPlan: [],
            createdCompanies: [],
        };

        this.dashboardService = new DashboardService();
    }

    componentDidMount() {
        this.load();
    }

    load = async (
        initial = DateUtil.getDateRangeFormat(7).initial,
        final = DateUtil.getDateRangeFormat(7).final
    ) => {
        try {

            this.setState({
                inLoad: true,
            });

            if (initial >= final) {
                throw ErrorUtil.make(
                    'A data inicial não pode ser maior que a final'
                );
            }

            const response = await this.dashboardService.getAdmin({
                initial,
                final,
            });

            this.setState({
                inLoad: false,
                companiesByPaymentPlan: response.data.companiesByPaymentPlan,
                createdCompanies: response.data.createdCompanies,
            })

        } catch (e) {
            this.setState({
                inLoad: false,
                messagens: MessageUtil.make({
                    title: 'Atenção',
                    body: e,
                }),
            });
        }
    }

    render() {
        return (
            <>
                <HeaderComponent menu={true}
                                 title={'Dashboard Admin'}
                                 messagens={this.state.messagens}
                                 inLoad={this.state.inLoad}/>
                <Box display={'flex'}
                     justifyContent={'center'}
                     alignItems={'center'}>
                    <Box width={'80%'}>
                        <SpacerComponent height={20}/>
                        <SearchTextFieldComponent load={this.load}/>
                        <SpacerComponent height={50}/>
                        <Grid container={true}
                              spacing={3}>
                            <ChartDefault title={'METAS - CONSULTORES'}
                                          data={this.state.createdCompanies}/>
                            <TableTotal data={this.state.companiesByPaymentPlan}/>
                        </Grid>
                    </Box>
                </Box>
            </>
        );
    }
}

export default DashboardAdminScreen;