import styled from 'styled-components';

export const ContentMainStyled = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: fixed;
  z-index: 999999999;
  background: white;
  top: 0;
  left: 0;

  img {
    opacity: 0.7;
  }
`;