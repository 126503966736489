import React, {Component} from 'react';
import HeaderComponent from "../../../Components/HeaderComponent/HeaderComponent";
import {Box, Grid, Typography} from "@material-ui/core";
import SpacerComponent from "../../../Components/SpacerComponent/SpacerComponent";
import CardGiftcardIcon from '@material-ui/icons/CardGiftcard';
import SaveIcon from '@material-ui/icons/Save';
import ItemSelectCampaign from "./ItemSelectAward";
import ListCampaingScreen from "../NewAward/ListCampaingScreen";
import CampaignScreen from "../CampaignScreen/CampaignScreen";
import CampaignNameScreen from "../CampaignNameScreen/CampaignNameScreen";
import TextFieldsIcon from '@material-ui/icons/TextFields';
import {CakeSharp} from "@material-ui/icons";
import CampaignBirthdayScreen from "../CampaignBirthdayScreen/CampaignScreen";
import TooltipDefault from "../../../Components/TooltipComponent/TololtipDefault";

class SelectCampaign extends Component {
    static ROUTE = '/select-campaigns';

    render() {
        return (
            <>
                <HeaderComponent title={'Campanhas'}
                                 menu={true}/>
                <SpacerComponent height={50}/>
                <Box display={'flex'}
                     flexDirection={'row'}
                     alignItems={'center'}
                     justifyContent={'center'}>
                    <Typography variant={'h6'}
                                color={'primary'}
                                align={'center'}>
                        Escolha a categoria da campanha que gostaria de criar
                    </Typography>
                    <SpacerComponent width={10}/>
                    <TooltipDefault
                        text={'Aqui você poderá escolher a modalidade de campanha que oferecerá ao seu cliente, para que ele possa acelerar seu acúmulo de pontos.'}/>
                </Box>
                <SpacerComponent height={100}/>
                <Box display={'flex'}
                     justifyContent={'center'}
                     alignItems={'center'}>
                    <Box width={'90%'}>
                        <Grid container={true}
                              alignItems={'center'}
                              alignContent={'center'}
                              justifyContent={'center'}
                              spacing={3}>
                            <ItemSelectCampaign icon={SaveIcon}
                                                title={'Lista de Campanhas Salvas'}
                                                route={ListCampaingScreen.ROUTE}
                                                list={true}
                                                tooltipText={'Tenha acesso aqui, a um histórico de todas as campanhas já realizadas, vinculadas ao seu programa de fidelidade.'}/>
                            <ItemSelectCampaign icon={CardGiftcardIcon}
                                                action={'Criar'}
                                                title={'Campanha por Período'}
                                                route={CampaignScreen.ROUTE}
                                                tooltipText={'Nessa modalidade de campanha você poderá, pelo período de tempo que você desejar, multiplicar cada ponto acumulado por seus clientes quantas vezes você determinar.'}
                                                tooltipColor={'white'}/>
                            <ItemSelectCampaign icon={CakeSharp}
                                                action={'Criar'}
                                                title={'Campanha de Aniversário do Cliente'}
                                                route={CampaignBirthdayScreen.ROUTE}
                                                tooltipText={'Multiplique os pontos dos seus clientes o quanto desejar, cada vez que ele pontuar na data ou mês de seu aniversário.'}
                                                tooltipColor={'white'}/>
                        </Grid>
                    </Box>
                </Box>
                <SpacerComponent height={100}/>
            </>
        );
    }
}

export default SelectCampaign;