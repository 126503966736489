import React, {Component} from 'react';
import {Box, CircularProgress, Grid, Typography} from "@material-ui/core";
import {ContentChartStyled} from "./styled";
import SpacerComponent from "../../../Components/SpacerComponent/SpacerComponent";

class LoadDashboard extends Component {
    render() {
        return (
            <Grid item={true}
                  xs={12}
                  sm={12}
                  md={12}
                  lg={6}
                  xl={6}>
                <ContentChartStyled elevation={3}>
                    <Typography variant={'h6'}
                                color={'primary'}>
                        {this.props.title}
                    </Typography>
                    <SpacerComponent height={20}/>
                    <Box display={'flex'}
                         justifyContent={'center'}
                         alignItems={'center'}>
                        <CircularProgress/>
                    </Box>
                </ContentChartStyled>
            </Grid>
        );
    }
}

export default LoadDashboard;