import ApiClient from "./Api/ApiClient";

class GoalService {
    constructor() {
        this.uri = 'goal';
        this.apiClient = new ApiClient();
    }

    async create(object) {
        try {

            return await this.apiClient.doRequest({
                method: ApiClient.POST,
                uri: this.uri,
                expectedStatus: 201,
                body: object,
            });

        } catch (e) {
            throw e;
        }
    }

    async createByClient(object) {
        try {

            return await this.apiClient.doRequest({
                method: ApiClient.POST,
                uri: 'goal-client',
                expectedStatus: 201,
                body: object,
            });

        } catch (e) {
            throw e;
        }
    }
}

export default GoalService;