import React, {Component} from 'react';
import HeaderComponent from "../../../Components/HeaderComponent/HeaderComponent";
import {Grid, Typography} from "@material-ui/core";
import SpacerComponent from "../../../Components/SpacerComponent/SpacerComponent";
import ItemChoicePlanScreen from "./ItemChoicePlanScreen";
import {ContentGridStyled, ContentRangeStyled} from "./styled";
import PlanService from "../../../Services/PlanService";
import MeUtil from "../../../Utils/MeUtil";
import MessageUtil from "../../../Utils/MessageUtil";
import FooterChoicePlanScreen from "./FooterChoicePlanScreen";
import {ChoicePlanContext} from "../../../Contexts/ChoicePlanContext";
import ModalChangePlanScreen from "./ModalChangePlanScreen";
import UserService from "../../../Services/UserService";

class ChoicePlanScreen extends Component {
    static ROUTE = '/choice-plan';

    constructor(props) {
        super(props);

        this.state = {
            inLoad: false,
            erros: [],
            plans: [],
            showModalEdit: false,
            choicePlan: null,
            myCurrentPlan: null,
        };

        this.planService = new PlanService();
        this.userService = new UserService();
    }

    componentDidMount() {
        this.load();
    }

    async load() {
        try {

            this.setState({
                inLoad: true,
            });

            const meUtil = (await MeUtil.get()).data.data;
            const response = await this.planService.getByCompany(meUtil.company.uuid);
            const myCurrentPlan = await this.userService.getMyPlan();

            this.setState({
                plans: response.data.data,
                inLoad: false,
                meUtil: meUtil,
                myCurrentPlan: myCurrentPlan.data,
            });

        } catch (e) {
            this.setState({
                inLoad: false,
                messagens: MessageUtil.make({
                    title: 'Atenção',
                    body: e,
                }),
            });
        }
    }

    makePlans() {
        const toRender = [];

        if (this.state.meUtil === undefined) {
            return toRender;
        }

        this.state.plans.map(value => {
            if (parseFloat(this.state.meUtil.company.payment_plan.price) > parseFloat(value.price)) {
                return;
            }

            toRender.push(
                <ItemChoicePlanScreen key={value.uuid}
                                      plan={value}/>
            );
        });

        return toRender;
    }

    render() {
        return (
            <ChoicePlanContext.Provider value={{
                parentState: this,
                isEdit: this.props.match.params.edit,
            }}>
                <HeaderComponent title={'Escolha um plano'}
                                 menu={true}
                                 messagens={this.state?.messagens}
                                 inLoad={this.state.inLoad}/>
                <ModalChangePlanScreen show={this.state.showModalEdit}/>
                <SpacerComponent height={20}/>
                <Typography color={'primary'}
                            variant={'h4'}
                            align={'center'}>
                    {
                        this.props.match.params.edit !== 'true' ?
                            'Escolha o melhor plano para o seu negócio' :
                            'Troque o plano do seu Programa de Fidelidade'
                    }
                </Typography>
                <ContentRangeStyled>
                    <div>
                        Certifique-se de aderir ao plano adequado ao volume de clientes de sua empresa
                    </div>
                </ContentRangeStyled>
                <SpacerComponent height={30}/>
                <ContentGridStyled>
                    <Grid container={true}
                          spacing={3}
                          justify={'center'}
                          alignItems={'center'}>
                        {this.makePlans()}
                    </Grid>
                </ContentGridStyled>
                <SpacerComponent height={20}/>
                <FooterChoicePlanScreen/>
                <SpacerComponent height={20}/>
            </ChoicePlanContext.Provider>
        );
    }
}

export default ChoicePlanScreen;