class StringUtil {
    static onlyNumber(string) {
        return string.replace(/\D/g, "");
    }

    static money(value) {
        return parseFloat(value)
            .toFixed(2)
            .toString()
            .replaceAll('.', ',');
    }
}

export default StringUtil;