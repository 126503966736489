import React, {Component} from 'react';
import {ContentMainStyled} from "./styled";
import moment from "moment";
import {Typography} from "@material-ui/core";
import CompanyRegistrationScreen from "../../Screens/PJAccess/CompanyRegistrationScreen/CompanyRegistrationScreen";
import DashboardScreen from "../../Screens/PJAccess/DashboardScreen/DashboardScreen";
import DateUtil from "../../Utils/DateUtil";

class AlertHeaderComponent extends Component {
    isAlert = () => {
        try {

            if (this.props.me.company.payment_profile.card_expiration === undefined) {
                return;
            }

            const dateNow = moment();

            let dateExpireCard = moment(
                this.props.me.company.payment_profile.card_expiration
            );

            if (dateNow.isSameOrAfter(dateExpireCard)) {
                return 'expiration';
            }

            dateExpireCard = moment(
                this.props.me.company.payment_profile.card_expiration
            )
                .subtract(60, 'days');

            if (window.location.pathname !== '/my-account') {
                return;
            }

            if (dateNow.isSameOrAfter(dateExpireCard)) {
                return 'upcoming_expiration';
            }
        } catch (e) {
        }
    }

    isCertificateExpired = () => {
        try {

            if (!window.location.href.includes(DashboardScreen.ROUTE)) {
                return false;
            }

            const momentNow = moment();
            const momentDate = moment(this.props.me.company.certificate_expiration_data);

            return momentDate.isBefore(momentNow);

        } catch (e) {

        }
    }

    render() {
        if (this.props.me === null) {
            return <></>;
        }
        if (this.isAlert() === 'upcoming_expiration') {
            return (
                <ContentMainStyled elevation={3}
                                   color={'#c9ad87'}>
                    <Typography variant={'h6'}
                                align={'center'}>
                        O método de pagamento utilizado está prestes a se tornar inválido. Por favor, altere seu método
                        de pagamento em breve para evitar o cancelamento de sua conta.
                    </Typography>
                </ContentMainStyled>
            );
        }
        if (this.isAlert() === 'expiration') {
            return (
                <ContentMainStyled elevation={3}
                                   color={'#ca715b'}>
                    <Typography variant={'h6'}
                                align={'center'}>
                        O método de pagamento utilizado está fora do período de validade. Troque o seu método de
                        pagamento até o dia da próxima cobrança {this.props.myPlan.next_billing_date}, para evitar o
                        cancelamento do seu plano.
                    </Typography>
                </ContentMainStyled>
            );
        }
        if (this.isCertificateExpired()) {
            return (
                <ContentMainStyled elevation={3}
                                   color={'#cb4735'}>
                    <Typography variant={'h6'}
                                align={'center'}>
                        Seu CERTIFICADO DIGITAL está vencido. <a
                        href={CompanyRegistrationScreen.ROUTE + '/' + this.props.me.company.uuid}>Clique aqui</a> para
                        fazer um
                        upload de um novo arquivo em formato pfx.
                    </Typography>
                </ContentMainStyled>
            );
        }
        return <></>;
    }
}

export default AlertHeaderComponent;