import React, {Component} from 'react';
import {Grid} from "@material-ui/core";
import ItemGalleryStory from "./ItemGalleryStory";
import {StoryContext} from "../../../Contexts/StoryContext";

class GalleryStory extends Component {
    static contextType = StoryContext;

    render() {
        const image0 = this.props.imagesSaves[0]?.picture[0]?.url;
        const image1 = this.props.imagesSaves[1]?.picture[0]?.url;
        const image2 = this.props.imagesSaves[2]?.picture[0]?.url;
        const image3 = this.props.imagesSaves[3]?.picture[0]?.url;

        return (
            <Grid container={true}
                  spacing={3}>
                <ItemGalleryStory onChange={(image) => this.context.setImage(0, image)}
                                  imageSave={image0}
                                  uuid={this.props.imagesSaves[0]?.uuid}
                                  delete={(uuid) => this.context.doDeleteConfirm(uuid)}/>
                <ItemGalleryStory onChange={(image) => this.context.setImage(1, image)}
                                  imageSave={image1}
                                  uuid={this.props.imagesSaves[1]?.uuid}
                                  delete={(uuid) => this.context.doDeleteConfirm(uuid)}/>
                <ItemGalleryStory onChange={(image) => this.context.setImage(2, image)}
                                  imageSave={image2}
                                  uuid={this.props.imagesSaves[2]?.uuid}
                                  delete={(uuid) => this.context.doDeleteConfirm(uuid)}/>
                <ItemGalleryStory onChange={(image) => this.context.setImage(3, image)}
                                  imageSave={image3}
                                  uuid={this.props.imagesSaves[3]?.uuid}
                                  delete={(uuid) => this.context.doDeleteConfirm(uuid)}/>
            </Grid>
        );
    }
}

export default GalleryStory;