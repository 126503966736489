import React, {Component} from 'react';
import PropTypes from 'prop-types';
import SpacerComponent from "../../../Components/SpacerComponent/SpacerComponent";
import ApiImageUtil from "../../../Utils/ApiImageUtil";
import CashbackImg from "../../../Assets/cashback.png";
import {ImgAwardReleaseStyled} from "./styled";
import {Typography} from "@material-ui/core";

class InfoReleaseAward extends Component {
    constructor(props) {
        super(props);
    }

    getRealValueType = (type) => {
        if (type == null || type === 1) {
            return 1;
        }

        if (type === 3) {
            return 3;
        }

        if (type === 4) {
            return 4;
        }

        return 2;
    }

    getImg() {
        if (this.props.award.award_company.award.type === 1 || this.props.award.award_company.award.type === null) {
            return ApiImageUtil.tryGet(this.props.award.award_company.award.images);
        }

        if (this.props.award.award_company.award.type === 2) {
            return CashbackImg;
        }

        if (this.props.award.award_company.award.type === 3) {
            return ApiImageUtil.tryGet(this.props.award.award_company.award.images);
        }

        if (this.props.award.award_company.award.type === 5) {
            return this.props.award.award_company.award.gift_card_data.logo_url;
        }

        return ApiImageUtil.tryGet(this.props.award.award_company.award.image);
    }

    render() {
        if (this.props.award === null) {
            return <></>;
        }
        const data = this.props.award;
        const client = data.client.user;
        const award = data.award_company.award;
        return (
            <div>
                <SpacerComponent height={40}/>
                <ImgAwardReleaseStyled src={this.getImg()}/>
                <Typography variant={'h6'}
                            align={'center'}>{award.title ?? award.description}</Typography>
                <SpacerComponent height={30}/>
                <Typography variant={'subtitle1'}
                            align={'center'}>Resgate solicitado por:</Typography>
                <Typography variant={'h6'}
                            align={'center'}>{client.name}</Typography>
                <SpacerComponent height={40}/>
            </div>
        );
    }
}

InfoReleaseAward.propTypes = {};

export default InfoReleaseAward;
