import React, {Component} from 'react';
import {GoogleMap, LoadScript, Marker} from '@react-google-maps/api';
import {Typography} from "@material-ui/core";


const containerStyle = {
    width: '100%',
    height: '300px',

};

const center = {
    lat: -22.845178,
    lng: -43.329117
};

class Maps extends Component {
    constructor(props) {
        super(props);

        this.state = {
            latLng: {},
        };

        this.map = null;
    }

    getCenter = () => {
        const {lat, long} = this.props.saveLatLog;

        if (lat && long) {
            return {
                lat: parseFloat(lat),
                lng: parseFloat(long),
            }
        }

        return center;
    }

    componentDidMount() {
        if ("geolocation" in navigator) {
            navigator.geolocation.getCurrentPosition((position) => {
                this.props.onChange({
                    lat: position.coords.latitude,
                    long: position.coords.longitude,
                })
            });
        }
    }

    render() {
        return (
            <>
                <Typography align={'center'}
                            variant={'h6'}
                            color={'primary'}>
                    Defina o seu local no mapa, assim podemos direcionar sua empresa para usuários próximos.
                </Typography>
                <LoadScript googleMapsApiKey={process.env.REACT_APP_MAPS_KEY}>
                    <GoogleMap mapContainerStyle={containerStyle}
                               center={this.getCenter()}
                               zoom={14}
                               onLoad={(instance) => (this.map = instance)}
                               onDragEnd={() => this.props.onChange({
                                   lat: this.map.getCenter().lat(),
                                   long: this.map.getCenter().lng()
                               })}>
                        <Marker position={this.getCenter()}
                                draggable={true}
                                onDragEnd={(value) => this.props.onChange({
                                    lat: value.latLng.lat(),
                                    long: value.latLng.lng(),
                                })}/>
                    </GoogleMap>
                </LoadScript>
            </>
        );
    }
}

export default Maps;