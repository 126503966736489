import React, {Component} from 'react';
import HeaderComponent from "../../../Components/HeaderComponent/HeaderComponent";
import {Box, Button, Typography} from "@material-ui/core";
import {ContentSecondaryStyled} from "./styled";
import SubHeaderComponent from "../../../Components/SubHeaderDefault/SubHeaderComponent";
import SpacerComponent from "../../../Components/SpacerComponent/SpacerComponent";
import RouteUtil from "../../../Utils/RouteUtil";
import ContactUsScreen from "../ContactUsScreen/ContactUsScreen";
import CheckBoxDefaultComponent from "../../../Components/CheckBoxDefaultComponent/CheckBoxDefaultComponent";
import DashboardScreen from "../DashboardScreen/DashboardScreen";
import MessageUtil from "../../../Utils/MessageUtil";
import UserService from "../../../Services/UserService";

class AccountDeleteScreen extends Component {
    static ROUTE = '/account-delete';

    constructor(props) {
        super(props);

        this.state = {
            checked: false,
        };

        this.userService = new UserService();
    }

    startDelete = async () => {
        try {
            this.setState({
                inLoad: true,
            });

            const result = await this.userService.startDeleteAccount();
            const message = result.data.message;
            const endpoint = result.data['confirmation-url'];

            this.setState({
                inLoad: false,
                messagens: MessageUtil.make({
                    title: 'Atenção',
                    body: message,
                    cancelButtonFunction: () => {
                    },
                    cancelButtonText: 'Não',
                    okButtonFunction: () => this.doDeleteAccount(endpoint),
                    okButtonText: 'Sim',
                }),
            });
        } catch (e) {
            this.setState({
                inLoad: false,
                messagens: MessageUtil.make({
                    title: 'Atenção',
                    body: e,
                }),
            });
        }
    }

    doDeleteAccount = async (endpoint) => {
        try {
            this.setState({
                inLoad: true,
            });

            const result = await this.userService.doDeleteAccount(endpoint);

            this.setState({
                inLoad: false,
                messagens: MessageUtil.make({
                    title: 'Atenção',
                    body: result.data.message,
                    okBtnToRoute: DashboardScreen.ROUTE,
                }),
            });
        } catch (e) {
            console.log(e);
            this.setState({
                inLoad: false,
                messagens: MessageUtil.make({
                    title: 'Atenção',
                    body: e,
                }),
            });
        }
    }

    render() {
        return (
            <div>
                <HeaderComponent title={'Excluir Conta'}
                                 menu={true}
                                 messagens={this.state?.messagens}
                                 inLoad={this.state.inLoad}/>
                <Box display={'flex'}
                     justifyContent={'center'}
                     alignItems={'center'}
                     width={'100%'}>
                    <ContentSecondaryStyled>
                        <SubHeaderComponent title={'Tem certeza que quer excluir sua conta?'}/>
                        <SpacerComponent height={40}/>
                        <Typography variant={'subtitle2'}
                                    align={'center'}>
                            Ao excluir sua conta seu perfil passará pelo seguinte processo:
                        </Typography>
                        <SpacerComponent height={40}/>
                        <Typography variant={'subtitle1'}
                                    align={'left'}>
                            * Seus clientes não poderão mais pontuar em seu programa de fidelidade.<br/>
                            * Não serão possíveis alterações em datas de vencimento de campanhas e
                            premiações já existentes.<br/>
                            * Não será possível a criação de novas campanhas ou cadastro de
                            premiações.<br/>
                        </Typography>
                        <SpacerComponent height={20}/>
                        <Typography variant={"subtitle1"}
                                    color={'error'}>
                            * A exclusão de sua conta será efetiva a partir da utilização do último ponto
                            acumulado ou resgate de prêmio reservado, por parte de seus clientes ativos,
                            ou a partir do vencimento estipulado em cada caso.
                        </Typography>
                        <SpacerComponent height={30}/>
                        <Typography variant={'subtitle1'}>
                            Caso queira alterar seu plano para um plano inferior, basta entrar em contato com
                            a nossa equipe pelo <a href={ContactUsScreen.ROUTE}>Fale Conosco</a>.
                        </Typography>
                        <SpacerComponent height={30}/>
                        <CheckBoxDefaultComponent onChange={() => {
                            this.setState({
                                checked: !this.state.checked,
                            });
                        }}
                                                  label={'Estou ciente do processo pelo qual minha conta passará.'}/>
                        <SpacerComponent height={50}/>
                        <Box display={'flex'}
                             flexDirection={'row'}
                             justifyContent={'center'}
                             alignItems={'center'}
                             width={'100%'}>
                            <Button variant={'contained'}
                                    color={'primary'}
                                    onClick={() => {
                                        window.location.href = DashboardScreen.ROUTE;
                                    }}>
                                VOLTAR
                            </Button>
                            <SpacerComponent width={100}/>
                            <Button variant={'contained'}
                                    color={'default'}
                                    disabled={!this.state.checked}
                                    onClick={this.startDelete}>
                                QUERO EXCLUIR
                            </Button>
                        </Box>
                    </ContentSecondaryStyled>
                </Box>
            </div>
        );
    }
}

export default AccountDeleteScreen;