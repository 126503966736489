import React, {Component} from 'react';
import {ContentCheckBoxDefaultStyled} from "./styled";
import {Checkbox, Typography} from "@material-ui/core";
import PropTypes from 'prop-types';

class CheckBoxDefaultComponent extends Component {
    render() {
        return (
            <ContentCheckBoxDefaultStyled>
                <Checkbox checked={this.props.checked}
                          onChange={this.props.onChange}
                          name={this.props.name}
                          disabled={this.props.disabled}/>
                <Typography variant={'subtitle1'}
                            color={this.props.color}>{this.props.label}</Typography>
            </ContentCheckBoxDefaultStyled>
        );
    }
}

CheckBoxDefaultComponent.propTypes = {
    checked: PropTypes.bool,
    onChange: PropTypes.func.isRequired,
    name: PropTypes.string,
    label: PropTypes.string,
    disabled: PropTypes.bool,
    color: PropTypes.string,
};

export default CheckBoxDefaultComponent;