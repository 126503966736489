import ApiClient from "./Api/ApiClient";

class AwardService {
    constructor() {
        this.uri = 'award';
        this.apiClient = new ApiClient();
    }

    async get(searchGeneric = '') {
        try {

            let filter = '?order[active]=desc&order[value_points]=asc&paginate=false&search_generic=' + searchGeneric;

            return await this.apiClient.doRequest({
                method: ApiClient.GET,
                uri: this.uri + filter,
                expectedStatus: 200,
            });

        } catch (e) {
            throw e;
        }
    }

    async show(uuid) {
        try {

            return await this.apiClient.doRequest({
                method: ApiClient.GET,
                uri: this.uri + '/' + uuid,
                expectedStatus: 200,
            });

        } catch (e) {
            throw e;
        }
    }

    async create(object) {
        try {

            return await this.apiClient.doRequest({
                method: ApiClient.POST,
                uri: this.uri,
                expectedStatus: 201,
                body: object,
            });

        } catch (e) {
            throw e;
        }
    }

    async update(uuid, object) {
        try {

            return await this.apiClient.doRequest({
                method: ApiClient.PATCH,
                uri: this.uri + '/' + uuid,
                expectedStatus: 201,
                body: object,
            });

        } catch (e) {
            throw e;
        }
    }

    async deleteConfirm(uuid) {
        try {

            return await this.apiClient.doRequest({
                method: ApiClient.GET,
                uri: `award/can-delete/${uuid}`,
                expectedStatus: 200,
            });

        } catch (e) {
            throw e;
        }
    }

    async delete(uuid) {
        try {

            return await this.apiClient.doRequest({
                method: ApiClient.DELETE,
                uri: `award/${uuid}`,
                expectedStatus: 201,
            });

        } catch (e) {
            throw e;
        }
    }

    async amountByCompanies(body, uuid) {
        try {

            return await this.apiClient.doRequest({
                method: ApiClient.POST,
                uri: this.uri + '/amount-by-companies/' + uuid,
                expectedStatus: 200,
                body: body,
            });

        } catch (e) {
            throw e;
        }
    }

    async getAmountByAward(uuid) {
        try {

            return await this.apiClient.doRequest({
                method: ApiClient.GET,
                uri: this.uri + '/amount-by-companies/' + uuid,
                expectedStatus: 200,
            });

        } catch (e) {
            throw e;
        }
    }

    async findAwardBooking(uuid) {
        try {
            return await this.apiClient.doRequest({
                method: ApiClient.GET,
                uri: '/award-booking/' + uuid,
                expectedStatus: 200,
            });
        } catch (e) {
            throw e;
        }
    }

    async releaseAwardBooking(uuid) {
        try {
            return await this.apiClient.doRequest({
                method: ApiClient.POST,
                uri: 'award-redemption',
                expectedStatus: 201,
                body: {
                    'award_booking': uuid,
                }
            });
        } catch (e) {
            throw e;
        }
    }
}

export default AwardService;