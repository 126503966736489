import React, {Component} from 'react';
import {Box, Grid, Typography} from "@material-ui/core";
import {BtnEditItemAwardStyled, ImgItemStyled, PaperItemImgStyled, PaperItemStyled, StatusIconStyled} from "./styled";
import SpacerComponent from "../../../Components/SpacerComponent/SpacerComponent";
import DateUtil from "../../../Utils/DateUtil";
import NewAward from "./NewAward";
import ApiImageUtil from "../../../Utils/ApiImageUtil";
import CashbackScreen from "../CashbackScreen/CashbackScreen";
import CashbackImg from "../../../Assets/cashback.png";
import InstantAwardImg from "../../../Assets/instant-award.jpeg";
import InstantNewAward from "../InstantAward/InstantNewAward";
import AwardUtil from "../../../Utils/AwardUtil";
import CampaignScreen from "../CampaignScreen/CampaignScreen";
import CampaignNameScreen from "../CampaignNameScreen/CampaignNameScreen";
import CampaignBirthdayScreen from "../CampaignBirthdayScreen/CampaignScreen";
import GiftCardScreen from "../GiftCardScreen/GiftCardScreen";

class ItemAward extends Component {

    constructor(props) {
        super(props);

        this.state = {
            isSelect: false,
        };
    }

    makeDateString() {

        if (
            !this.props.award.start_validity
            ||
            !this.props.award.end_validity
        ) {
            return this.makeDateStringCampaing();
        }

        return 'Data '
            + DateUtil.raw(this.props.award.start_validity).toDate()
            + ' a '
            + DateUtil.raw(this.props.award.end_validity).toDate()
    }

    makeDateStringCampaing() {
        if (
            !this.props.award.start_at
            ||
            !this.props.award.end_at
        ) {
            return 'Sem período de validade';
        }
        return 'Data '
            + DateUtil.raw(this.props.award.start_at).toDate()
            + ' a '
            + DateUtil.raw(this.props.award.end_at).toDate()
    }

    toEdit() {
        if (this.props.award.type === 1 || this.props.award.type === null) {
            window.location.href = NewAward.ROUTE + '/' + this.props.award.uuid;
            return;
        }

        if (this.props.award.type === 2) {
            window.location.href = CashbackScreen.ROUTE + '/' + this.props.award.uuid;
            return;
        }

        if (this.props.award.type === 3) {
            window.location.href = InstantNewAward.ROUTE + '/' + this.props.award.uuid;
            return;
        }

        if (this.props.award.type === 5) {
            window.location.href = GiftCardScreen.ROUTE + '/' + this.props.award.uuid;
            return;
        }

        if (!this.props.award.birthday && !this.props.award.products && this.props.award.object_type === 'campaign') {
            window.location.href = CampaignScreen.ROUTE + '/' + this.props.award.uuid;
            return;
        }

        if (this.props.award.birthday === true && this.props.award.object_type === 'campaign') {
            window.location.href = CampaignBirthdayScreen.ROUTE + '/' + this.props.award.uuid;
            return;
        }

        if (this.props.award.products !== undefined && this.props.award.object_type === 'campaign') {
            window.location.href = CampaignNameScreen.ROUTE + '/' + this.props.award.uuid;
            return;
        }

        console.log(this.props.award.object_type);
        alert('Nao foi possivel identificar o tipo de prêmio/campanha');
    }

    toEnabledDisabled() {

        if (this.props.selectAward !== true) {
            return;
        }

        this.setState({
            isSelect: !this.state.isSelect,
        });

        this.props.setAwardsEnabledDisabled(
            this.props.award.uuid,
            !this.state.isSelect
        );

    }

    makeRealBool() {

        if (this.props.selectAward === false) {
            return false;
        }

        return this.state.isSelect;

    }

    getBoolToDisableEnabled() {
        if (!this.props.award.type) {
            return this.props.award.active;
        }

        if (this.props.award.type === 1 || this.props.award.type === null) {
            return (
                this.props.award.active &&
                !this.props.award.isExpired &&
                !this.props.award.amount <= 0
            );
        }

        if (this.props.award.type === 2 || this.props.award.type === 3) {
            return (
                this.props.award.active &&
                !this.props.award.isExpired
            );
        }

        if (this.props.award.type === 5) {
            return (
                this.props.award.active &&
                !this.props.award.isExpired
            );
        }
    }

    getImg() {
        if (this.props.award.type === 1 || this.props.award.type === null) {
            return ApiImageUtil.tryGet(this.props.award.images);
        }

        if (this.props.award.type === 2) {
            return CashbackImg;
        }

        if (this.props.award.type === 3) {
            return ApiImageUtil.tryGet(this.props.award.images);
        }

        if (this.props.award.type === 5) {
            return this.props.award.gift_card_data.logo_url;
        }

        return ApiImageUtil.tryGet(this.props.award.image);
    }

    getCashbackText() {
        const value = this.props.award.value;

        if (this.props.award.value_type === 1) {
            return `Desconto de R\$ ${parseFloat(value).toFixed(2).replace('.', ',')}`;
        }

        return `Desconto de ${value}%`;
    }

    makeAmount() {
        if (this.props.award.type !== 2 &&
            typeof this.props.award.amount !== 'object' &&
            this.props.award.amount !== undefined) {
            return this.props.award.amount + 'itens';
        }

        return '';
    }

    render() {
        return (
            <Grid item={true}
                  xs={12}
                  sm={12}
                  md={6}
                  lg={3}
                  xl={2}
                  onClick={() => this.toEnabledDisabled()}>
                <Box height={310}>
                    <PaperItemStyled elevation={3}
                                     select={this.makeRealBool()}>
                        <PaperItemImgStyled elevation={3}>
                            <BtnEditItemAwardStyled color="primary"
                                                    size={'small'}
                                                    onClick={() => this.toEdit()}>
                                {this.props.removeStatus ? 'Abrir' : 'Editar'}
                            </BtnEditItemAwardStyled>
                            <ImgItemStyled
                                src={this.getImg()}/>
                            {this.props.award.type === 2 &&
                                <h6>{this.getCashbackText()}</h6>
                            }
                        </PaperItemImgStyled>
                        <SpacerComponent height={10}/>
                        <Typography variant={'subtitle2'}
                                    color={'primary'}
                                    align={'center'}>
                            {AwardUtil.type(this.props.award.type)}
                        </Typography>
                        <SpacerComponent height={20}/>
                        <Typography variant={'subtitle2'}
                                    color={'primary'}
                                    align={'center'}>
                            {this.props.award.title}
                        </Typography>
                        {this.props.award.value_points &&
                            <Typography variant={'body2'}
                                        color={'primary'}
                                        align={'center'}>
                                <b>{this.props.award.value_points} pontos</b>
                            </Typography>
                        }
                        <Typography variant={'body2'}
                                    color={'primary'}
                                    align={'center'}>
                            {this.makeAmount()}
                        </Typography>
                        <Typography variant={'body2'}
                                    color={'primary'}
                                    align={'center'}>
                            {this.makeDateString()}
                        </Typography>
                        {!this.props.removeStatus &&
                            <Box display={'flex'}
                                 flexDirection={'row'}
                                 justifyContent={'center'}
                                 alignItems={'center'}>
                                <Typography variant={'body2'}
                                            color={'primary'}
                                            align={'center'}>
                                    Status: {((this.getBoolToDisableEnabled()) ? 'Ativo' : 'Desativado')}
                                </Typography>
                                <StatusIconStyled fontSize={'small'}
                                                  colorCustom={(this.getBoolToDisableEnabled())}/>
                            </Box>
                        }
                        <SpacerComponent height={5}/>
                    </PaperItemStyled>
                </Box>
            </Grid>
        );
    }
}

export default ItemAward;