import styled from "styled-components";
import {Paper} from "@material-ui/core";

export const ContentMainStyled = styled.div`
  padding-left: 30px;
  padding-right: 30px;
`;

export const ContentTableStyled = styled.div`
  max-height: 400px;
`;

export const ContentFilterStyled = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 20px;

  div {
    display: flex;
    flex-direction: row;
  }
`;

export const ModalChart = styled(Paper)`
  width: 80%;
  height: 650px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9999999;
  border-radius: 10px;

  button {
    position: absolute;
    right: 10px;
    z-index: 9;
  }
`;

export const ContentChartStyled = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-content: center;
`;
