import React, {Component} from 'react';
import {ContentLinkStyled, ContentMainStyled, ContentSizeStyled} from "../../CommonAccess/LoginScreen/styled";
import TextFieldDefault from "../../../Components/TextFieldDefaultComponent/TextFieldDefault";
import SpacerComponent from "../../../Components/SpacerComponent/SpacerComponent";
import {Box, Button, MenuItem, Typography} from "@material-ui/core";
import CheckValidDateNewAwardScreen from "../NewAward/CheckValidDateNewAwardScreen";
import FidelityService from "../../../Services/FidelityService";
import ApiImageUtil from "../../../Utils/ApiImageUtil";
import {CashbackContext} from "../../../Contexts/CashbackContext";
import PointCalculatorComponent from "../../../Components/PointCalculatorComponent/PointCalculatorComponent";
import {ButtonDelete} from "./styled";
import FixSelectComponent from "../../../Components/FixSelect/FixSelect";
import GiftCardService from "../../../Services/GiftCardService";
import StringUtil from "../../../Utils/StringUtil";

class FormGiftCardScreen extends Component {
    static contextType = CashbackContext;

    constructor(props) {
        super(props);

        this.state = {
            type: 5,
            value_type: null,
            fidelity: [],
            giftCards: [],
        };

        this.fidelityService = new FidelityService();
        this.giftCardService = new GiftCardService();
    }

    componentDidMount() {
        this.load();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.formEdit === prevProps.formEdit) {
            return;
        }
        this.setFormEdit();
    }

    setFormEdit() {
        const newState = Object.assign(this.state, this.props.formEdit);
        newState.imagePrevios = ApiImageUtil.tryGet(this.props.formEdit.images);
        newState.start_validity = (
            this.props.formEdit.start_validity === null ? undefined : this.props.formEdit.start_validity
        );
        newState.end_validity = (
            this.props.formEdit.end_validity === null ? undefined : this.props.formEdit.end_validity
        );
        if (this.props.formEdit.start_validity === null) {
            newState.withoutValidDate = true;
        }
        newState.gift_card_order_id = this.props.formEdit.gift_card_data.order_id;
        this.setState(newState);
    }

    async load() {
        try {

            const response = await this.fidelityService.getByUser();
            const respoinseGift = await this.giftCardService.getPurchased();

            this.setState({
                fidelity: response.data.data,
                giftCards: respoinseGift.data,
            });

        } catch (e) {
            throw e;
        }
    }

    handleChange(event) {
        const {name, value} = event.target;
        this.setState({[name]: value});
        if (name === 'value') {
            this.rulesToValue(value);
        }
    }

    handleValidDateChange(value) {
        this.setState({
            withoutValidDate: value,
        });
    }

    handlerSubmit = () => {
        const form = Object.assign({}, this.state);
        delete form.fidelity;
        delete form.giftCards;

        this.props.doCreate(form);
    }

    handlerDelete = () => {
        this.props.confirmDelete();
    };

    renderSelect() {

        if (this.props.isEdit === null) {
            return <div></div>;
        }

        if (this.props.isEdit === false) {
            return this.componentSelect();
        }

        if (this.props.isEdit === true && this.state.fidelity_programs_uuid === undefined) {
            return <div></div>;
        }

        return this.componentSelect();

    }

    componentSelect() {
        return (
            <TextFieldDefault select={true}
                              name={'fidelity_programs_uuid'}
                              value={this.state.fidelity_programs_uuid}
                              onChange={(event) => this.handleChange(event)}
                              erros={this.props.erros}>
                {this.makeFidelity()}
            </TextFieldDefault>
        );
    }

    makeFidelity() {
        let itens = [];
        this.state.fidelity.forEach(function (value, index) {
            itens.push(
                <MenuItem key={index} value={value.uuid}>{value.name}</MenuItem>
            );
        })

        return itens;
    }

    makeGiftCard() {
        let itens = [];
        this.state.giftCards.forEach(function (value, index) {
            if (value.status === 'pending') {
                return;
            }

            if (value.gift_cards.length <= 0) {
                return;
            }

            const giftCardArray = value.gift_cards[0];

            itens.push(
                <MenuItem disabled={value.available_amount <= 0}
                          key={index}
                          value={giftCardArray.order_id}>
                    Disp.: {value.available_amount} Comp.: {value.amount} | {giftCardArray.product_line_name} |
                    R$ {StringUtil.money(giftCardArray.value)}
                </MenuItem>
            );

            /*desagrupado*/
            /*giftCardArray.forEach(function (value, index) {
                itens.push(
                    <MenuItem disabled={false}
                              key={index}
                              value={value.order_id}>
                        {value.product_line_name} | Valor. {value.value}
                    </MenuItem>
                );
            });*/
        })

        return itens;
    }

    render() {
        return (
            <ContentMainStyled>
                <ContentSizeStyled>
                    <FixSelectComponent>
                        <TextFieldDefault select={true}
                                          name={'gift_card_order_id'}
                                          value={this.state.gift_card_order_id}
                                          onChange={(event) => this.handleChange(event)}
                                          erros={this.props.erros}>
                            {this.makeGiftCard()}
                        </TextFieldDefault>
                    </FixSelectComponent>
                    <ContentLinkStyled>
                        <a href={'/gift-card-purchase'}
                           color={'secondary'}>COMPRAR Voucher</a>
                    </ContentLinkStyled>
                    <TextFieldDefault label={'Quantidade *'}
                                      name={'amount'}
                                      type={'number'}
                                      fullWidth={true}
                                      value={this.state?.amount}
                                      onChange={(event) => this.handleChange(event)}
                                      erros={this.state?.erros}/>
                    <TextFieldDefault label={'Quantidade de pontos para resgate *'}
                                      name={'value_points'}
                                      type={'number'}
                                      value={this.state.value_points}
                                      onChange={(event) => this.handleChange(event)}
                                      erros={this.props.erros}/>
                    {this.state.value_type === 1 &&
                        <PointCalculatorComponent
                            doConfirm={(result) => this.setState({value_points: result})}/>
                    }
                    <TextFieldDefault label={'Regras'}
                                      name={'rules'}
                                      value={this.state.rules}
                                      type={'number'}
                                      multiline={true}
                                      rows={5}
                                      onChange={(event) => this.handleChange(event)}
                                      erros={this.props.erros}
                    />
                    <TextFieldDefault label={'Descrição *'}
                                      name={'description'}
                                      value={this.state.description}
                                      onChange={(event) => this.handleChange(event)}
                                      erros={this.props.erros}
                                      multiline={true}
                                      rows={5}/>
                    <SpacerComponent height={20}/>
                    <Typography variant={'body1'}
                                color={'primary'}>
                        Período de Validade
                    </Typography>
                    <Box display={'flex'}
                         flexDirection={'row'}>
                        <TextFieldDefault label={'Data Inicial *'}
                                          name={'start_validity'}
                                          value={this.state.start_validity}
                                          onChange={(event) => this.handleChange(event)}
                                          type={'date'}
                                          erros={this.props.erros}
                                          forceLabel={true}
                                          disabled={this.state.withoutValidDate}/>
                        <SpacerComponent width={40}/>
                        <TextFieldDefault label={'Data Final *'}
                                          name={'end_validity'}
                                          value={this.state.end_validity}
                                          type={'date'}
                                          onChange={(event) => this.handleChange(event)}
                                          erros={this.props.erros}
                                          forceLabel={true}
                                          disabled={this.state.withoutValidDate}/>
                    </Box>
                    <CheckValidDateNewAwardScreen onChange={(value) => this.handleValidDateChange(value)}
                                                  withoutValidDate={this.state.withoutValidDate}/>
                    <SpacerComponent height={20}/>
                    <Typography variant={'body1'}
                                color={'primary'}>
                        Programa de Fidelidade
                    </Typography>
                    {this.renderSelect()}
                    <SpacerComponent height={20}/>
                    <Box display="flex" justifyContent="center">
                        <Button variant={'contained'}
                                color={'primary'}
                                size={"large"}
                                fullWidth={true}
                                onClick={this.handlerSubmit}>
                            {this.context.parent.state.action === 'create' ? 'Registrar Voucher' : 'Alterar Voucher'}
                        </Button>
                    </Box>
                    {this.context.parent.state.action !== 'create' &&
                        <>
                            <SpacerComponent height={20}/>
                            <Box display="flex" justifyContent="center">
                                <ButtonDelete variant={'contained'}
                                              size={"large"}
                                              fullWidth={true}
                                              onClick={this.handlerDelete}>
                                    deletar
                                </ButtonDelete>
                            </Box>
                        </>
                    }
                </ContentSizeStyled>
            </ContentMainStyled>
        );
    }
}

export default FormGiftCardScreen;