import React, {Component} from 'react';
import {Accordion, AccordionSummary, Box, MenuItem, Typography} from "@material-ui/core";
import TextFieldDefault from "../../../Components/TextFieldDefaultComponent/TextFieldDefault";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import SpacerComponent from "../../../Components/SpacerComponent/SpacerComponent";
import {AccordionDetailsStyled} from "./styled";
import AddressUtil from "../../../Utils/AddressUtil";
import FormMask from "../../../FromMask/FormMask";
import AvatarComponent from "../../../Components/AvatarComponent/AvatarComponent";
import InputFileWithCountDefault from "../../../Components/InputFileDefault/InputFileWithCountDefault";
import CertificateFormComponent from "../../../Components/CertificateFormComponent/CertificateFormComponent";
import {ContentSizeStyled} from "../CompanyRegistrationScreen/styled";

class ContentEstablishment extends Component {
    constructor(props) {
        super(props);

        this.state = {
            state: '',
            setCertificate: false,
        };
    }

    componentDidMount() {
        this.props.getState(
            this.getState
        );
    }


    isFirst() {
        if (this.props.count === 1) {
            return true;
        }

        return false;
    }

    handleChange = (event) => {
        const {name, value} = event.target;
        this.setAddressByCEP(name, value);
        this.setState({[name]: value});
        if (name === 'segment_name') {
            this.props.getSegmentBySegmentName(value);
        }

    }

    async setAddressByCEP(inputName, value) {
        if (inputName !== 'zip_code') {
            return;
        }

        if (value.length !== 8) {
            return;
        }

        const addressUtil = await AddressUtil.translateZipCode(value);

        this.setState({
            'state': addressUtil.state,
            'city': addressUtil.city,
            'neighborhood': addressUtil.neighborhood,
            'address': addressUtil.streetName,
            'complement': addressUtil.complement
        });
    }

    makeItensSelect() {
        let itens = [];
        for (let [key, value] of Object.entries(AddressUtil.states)) {
            itens.push(
                <MenuItem key={key} value={value}>{value}</MenuItem>
            );
        }
        return itens;
    }

    makeSegmentName() {
        let itens = [];
        this.props.segmentName.forEach(function (value, index) {
            itens.push(
                <MenuItem key={index} value={value.id}>{value.name}</MenuItem>
            );
        })

        return itens;
    }

    makeSegment() {
        let itens = [];
        this.props.segment.forEach(function (value, index) {
            itens.push(
                <MenuItem key={index} value={value.uuid}>{value.name}</MenuItem>
            );
        })

        return itens;
    }

    getState = () => {
        return this.state;
    }

    setPreviosImage = (image) => {
        this.setState({
            imagePrevios: image,
        });
    }

    toBase64Image = (base64) => {
        this.setState({
            logo_base64: base64.split(',')[1],
        });
    }

    setPreviosImageCertificate = (image) => {
        this.setState({
            setCertificate: true,
        });
    }

    toBase64Certificate = (base64) => {
        this.setState({
            'certificate_file': base64.split(',')[1],
            btnAlterDisabled: false,
        });
    }

    render() {
        return (
            <Accordion variant={'outlined'}
                       color={'primary'}
                       defaultExpanded={this.isFirst()}>
                <AccordionSummary expandIcon={<ExpandMoreIcon/>}
                                  aria-controls={'panel-controller-' + this.props.count}
                                  id={'panel-' + this.props.count}>
                    <Typography>
                        {'Estabelecimento ' + this.props.count}
                    </Typography>
                </AccordionSummary>
                <AccordionDetailsStyled>
                    <Box display={'flex'}
                         flexDirection={'column'}
                         justifyContent={'center'}
                         alignItems="center"
                         width={'100%'}>
                        <TextFieldDefault label={'CNPJ *'}
                                          name={'cnpj'}
                                          onChange={(event) => this.handleChange(event)}
                                          mask={FormMask.cnpj}
                                          forceLabel={true}
                                          erros={this.props.erros}/>
                        <TextFieldDefault label={'Nome Fantasia *'}
                                          name={'fantasy_name'}
                                          onChange={(event) => this.handleChange(event)}
                                          erros={this.props.erros}/>
                        <TextFieldDefault label={'Razão social *'}
                                          name={'social_reason'}
                                          onChange={(event) => this.handleChange(event)}
                                          erros={this.props.erros}/>
                        <TextFieldDefault label={'Área de atuação'}
                                          name={'segment_name'}
                                          onChange={(event) => this.handleChange(event)}
                                          select={true}
                                          erros={this.props.erros}>
                            {this.makeSegmentName()}
                        </TextFieldDefault>
                        {this.props.segment.length > 0 &&
                        <TextFieldDefault label={'Segmento'}
                                          name={'segment_type_uuid'}
                                          select={true}
                                          onChange={(event) => this.handleChange(event)}
                                          erros={this.props.erros}>
                            {this.makeSegment()}
                        </TextFieldDefault>
                        }
                        <SpacerComponent height={10}/>
                        <Box display={'flex'}
                             width={'100%'}
                             flexDirection={'column'}
                             alignItems={'flex-start'}
                             justifyContent={'flex-start'}>
                            <Typography variant={'body1'}
                                        color={'primary'}>
                                Logo da Empresa Matriz
                            </Typography>
                            <SpacerComponent height={10}/>
                            <Box display={'flex'}
                                 flexDirection={'row'}
                                 alignItems={'center'}
                                 justifyContent={'flex-start'}>
                                <AvatarComponent
                                    src={this.state.imagePrevios}/>
                                <SpacerComponent width={20}/>
                                <InputFileWithCountDefault setPreviosImage={this.setPreviosImage}
                                                           toBase64Image={this.toBase64Image}
                                                           count={this.props.count}/>
                            </Box>
                        </Box>
                        <SpacerComponent height={10}/>
                        <Box display={'flex'}
                             width={'100%'}>
                            <TextFieldDefault label={'Telefone *'}
                                              name={'telephone'}
                                              onChange={(event) => this.handleChange(event)}
                                              phone={true}
                                              erros={this.props.erros}
                                              forceLabel={true}/>
                            <SpacerComponent width={40}/>
                            <TextFieldDefault label={'CEP *'}
                                              name={'zip_code'}
                                              onChange={(event) => this.handleChange(event)}
                                              erros={this.props.erros}/>
                        </Box>
                        <TextFieldDefault label={'Endereço *'}
                                          name={'address'}
                                          value={this.state.address}
                                          onChange={(event) => this.handleChange(event)}
                                          erros={this.props.erros}/>
                        <Box display={'flex'}
                             width={'100%'}>
                            <TextFieldDefault label={'Número *'}
                                              name={'number'}
                                              onChange={(event) => this.handleChange(event)}
                                              erros={this.props.erros}/>
                            <SpacerComponent width={40}/>
                            <TextFieldDefault label={'Complemento'}
                                              name={'complement'}
                                              onChange={(event) => this.handleChange(event)}
                                              erros={this.props.erros}/>
                        </Box>
                        <TextFieldDefault label={'Bairro *'}
                                          name={'neighborhood'}
                                          value={this.state.neighborhood}
                                          onChange={(event) => this.handleChange(event)}
                                          erros={this.props.erros}/>
                        <Box display={'flex'}
                             width={'100%'}>
                            <TextFieldDefault label={'Cidade *'}
                                              name={'city'}
                                              value={this.state.city}
                                              onChange={(event) => this.handleChange(event)}
                                              erros={this.props.erros}/>
                            <SpacerComponent width={40}/>
                            <TextFieldDefault label={'Estado *'}
                                              select={true}
                                              name={'state'}
                                              value={this.state.state}
                                              onChange={(event) => this.handleChange(event)}
                                              erros={this.props.erros}>
                                {this.makeItensSelect()}
                            </TextFieldDefault>
                        </Box>
                        <SpacerComponent height={20}/>
                        <CertificateFormComponent certificate_password={this.state.certificate_password}
                                                  handleChange={this.handleChange}
                                                  erros={this.props.erros}
                                                  setPreviosImageCertificate={this.setPreviosImageCertificate}
                                                  toBase64Certificate={this.toBase64Certificate}
                                                  setCertificate={this.state.setCertificate}/>
                        <SpacerComponent height={20}/>
                    </Box>
                </AccordionDetailsStyled>
            </Accordion>
        );
    }
}

export default ContentEstablishment;