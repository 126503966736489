import React, {Component} from 'react';
import HeaderComponent from "../../../Components/HeaderComponent/HeaderComponent";
import SubHeaderComponent from "../../../Components/SubHeaderDefault/SubHeaderComponent";
import SpacerComponent from "../../../Components/SpacerComponent/SpacerComponent";
import {Box} from "@material-ui/core";
import ContentListFidelity from "./ContentListFidelity";
import FidelityService from "../../../Services/FidelityService";
import MessageUtil from "../../../Utils/MessageUtil";
import {ManageFidelityContext} from "../../../Contexts/ManageFidelityContext";
import CompanyRegistrationFidelityScreen from "../CompanyRegistrationFidelityScreen/CompanyRegistrationFidelityScreen";

class ManageFidelityScreen extends Component {
    static ROUTE = '/manage-fidelity';

    fidelityService;

    constructor(props) {
        super(props);

        this.state = {
            inLoad: false,
            erros: [],
            fidelities: [],
        };

        this.fidelityService = new FidelityService();

    }

    componentDidMount() {
        this.load();
    }

    async load() {
        try {

            this.setState({
                inLoad: true,
            });

            const response = await this.fidelityService.getByUser();

            this.setState({
                inLoad: false,
                fidelities: response.data.data,
            });

        } catch (e) {
            this.setState({
                inLoad: false,
                messagens: MessageUtil.make({
                    title: 'Error',
                    body: e,
                }),
            });
        }
    }

    doEdit = async (uuid) => {

        window.location.href = CompanyRegistrationFidelityScreen.ROUTE
            + '/edit/'
            + uuid;

    }

    render() {
        return (
            <ManageFidelityContext.Provider value={{
                doEdit: this.doEdit,
                parentState: this,
            }}>
                <div>
                    <HeaderComponent title={'Gerenciamento do Programa de Fidelidade'}
                                     menu={true}
                                     messagens={this.state?.messagens}
                                     inLoad={this.state.inLoad}
                                     footerAbsolute={true}/>
                    <Box display={'flex'}
                         justifyContent={'center'}
                         alignItems={'center'}>
                        <Box width={'80%'}>
                            <SubHeaderComponent title={'Gerenciamento do Programa de Fidelidade'}/>
                            <SpacerComponent height={30}/>
                            <ContentListFidelity fidelities={this.state.fidelities}/>
                        </Box>
                    </Box>
                </div>
            </ManageFidelityContext.Provider>
        );
    }
}

export default ManageFidelityScreen;