import React, {Component} from 'react';
import {Box, Typography} from "@material-ui/core";

import SpacerComponent from "../../../Components/SpacerComponent/SpacerComponent";
import {Checkbox} from "@material-ui/core";

class WTRCompanyRegistrationFidelityScreen extends Component {
    constructor(props) {
        super(props);

        this.state = {
            acceptTerm: false,
        };
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.noLimitPoint === prevProps.noLimitPoint) {
            return;
        }

        this.setState({
            acceptTerm: this.props.noLimitPoint,
        });
    }

    handleCheckTerm = () => {
        this.setState({
            acceptTerm: !this.state.acceptTerm,
        });
        this.props.onChange(this.state.acceptTerm);
    }

    render() {
        return (
            <Box display={'flex'}
                 flexDirection={'row'}
                 alignItems={'center'}>
                <Checkbox checked={this.state.acceptTerm}
                          onChange={() => this.handleCheckTerm()}
                          color={'primary'}/>
                <SpacerComponent/>
                <Typography variant={'body1'}
                            color={'primary'}>
                    Sem restrição de tempo.
                </Typography>
            </Box>
        );
    }
}

export default WTRCompanyRegistrationFidelityScreen;