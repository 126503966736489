import * as yup from 'yup';
import ValidationUtil from "../Utils/ValidationUtil";

class CampaignBirthdayValidation {

    static async validate(form) {
        try {

            let objectValidade = {
                title: yup
                    .string()
                    .required(ValidationUtil.MSG_FIELD_REQUIRED),
                action_to_active: yup
                    .string()
                    .required(ValidationUtil.MSG_FIELD_REQUIRED),
                reward_factor: yup
                    .number(ValidationUtil.MSG_FIELD_NUMBER)
                    .max(999999999, ValidationUtil.MSG_FIELD_MAX_NUMBER)
                    .typeError(ValidationUtil.MSG_FIELD_NUMBER)
                    .positive(ValidationUtil.MSG_FIELD_POSITIVE)
                    .integer(ValidationUtil.MSG_INTERGER)
                    .required(ValidationUtil.MSG_FIELD_REQUIRED),
                rules: yup
                    .string()
                    .max(255, ValidationUtil.MSG_FIELD_MAX)
                    .required(ValidationUtil.MSG_FIELD_REQUIRED),
                start_at: yup
                    .string()
                    .required(ValidationUtil.MSG_FIELD_REQUIRED),
                end_at: yup
                    .date()
                    .when(
                        'start_at',
                        (startDate, schema) => (startDate && schema.min(
                            startDate,
                            ValidationUtil.MSG_DATE_RANGE_END
                        )),
                    ).required(ValidationUtil.MSG_FIELD_REQUIRED),
                fidelity_program_uuid: yup
                    .string()
                    .required(ValidationUtil.MSG_FIELD_REQUIRED),
            };

            if (Array.isArray(form.images) && form.image === undefined) {
                delete objectValidade.image;
            }

            if (form.withoutValidDate === true) {
                delete objectValidade.start_at;
                delete objectValidade.end_at;
            }

            let validate = yup.object().shape(objectValidade);

            validate.validateSync(form, {abortEarly: false})

            return true;

        } catch (e) {
            let erros = [];
            e.inner.forEach(function (value, index) {
                erros[value.path] = value.message;
            });

            return erros;
        }
    }

}

export default CampaignBirthdayValidation;