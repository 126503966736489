import React, {Component} from 'react';
import UserService from "../../Services/UserService";
import CheckBoxDefaultComponent from "../CheckBoxDefaultComponent/CheckBoxDefaultComponent";
import PropTypes from 'prop-types';

class UserPermissionComponent extends Component {
    constructor(props) {
        super(props);

        this.state = {
            data: [],
        };

        this.userService = new UserService();
    }

    componentDidMount() {
        this.load();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.values !== this.state.permissions) {
            this.setState({
                permissions: this.props.values,
            });
        }
    }

    async load() {
        try {

            const response = await this.userService.getPermissions();

            this.setState({
                data: response.data,
            });

        } catch (e) {
            throw e;
        }
    }

    handlePermission(item) {
        const permissions = this.state.permissions;

        if (!permissions.includes(item.uuid)) {
            permissions.push(item.uuid);

            this.setState({
                permissions
            });
            this.props.onChange(this.state.permissions);
            return
        }

        if (permissions.includes(item.uuid)) {
            const index = permissions.indexOf(item.uuid);
            if (index > -1) {
                permissions.splice(index, 1);

                this.setState({
                    permissions
                });
            }
            this.props.onChange(this.state.permissions);
            return;
        }
    }

    createListPermission() {
        const data = [];
        this.state.data.map((item) => {
            data.push(
                <CheckBoxDefaultComponent key={item.uuid}
                                          onChange={() => this.handlePermission(item)}
                                          label={item.description}
                                          name={item.description}
                                          checked={this.isChecked(item)}/>
            );
        });

        return data;
    }

    isChecked = (item) => {
        if (this.state.permissions === undefined) {
            return false;
        }

        return this.state.permissions.includes(item.uuid);
    }

    render() {
        return (
            <div>
                {this.createListPermission()}
            </div>
        );
    }
}

UserPermissionComponent.propTypes = {
    onChange: PropTypes.func.isRequired,
    values: PropTypes.array,
};

export default UserPermissionComponent;