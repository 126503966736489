import styled from 'styled-components';
import {Fab} from "@material-ui/core";

export const ItemGalleryStyled = styled.div`
  width: auto;
  max-width: 300px;
  height: 500px;
  background: #313131;
  position: relative;
  border-radius: 10px;
  border-width: 1px;
  border-style: solid;

  img {
    position: relative;
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 10px;
  }
`;

export const ActionButtonStyled = styled(Fab)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 999;
  background: transparent;
  border-style: solid;
  border-width: 1px;
  border-color: #696969;
  color: #696969;
`;