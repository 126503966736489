import React, {Component} from 'react';
import {Table, TableBody, TableCell, TableHead, TableRow} from "@material-ui/core";
import CardSimulationBillingDetailScreen from "./CardSimulationBillingDetailScreen/CardSimulationBillingDetailScreen";
import DateUtil from "../../../Utils/DateUtil";
import {parse} from "dotenv";

class TableBillingDetailScreen extends Component {

    renderTable() {

        const itens = [];

        this.props.bills.forEach((value) => {
                itens.push(
                    <TableRow>
                        <TableCell align={'center'}>{DateUtil.raw(value.date).toDate()}</TableCell>
                        <TableCell align={'center'}>
                            <CardSimulationBillingDetailScreen paymentCompanyCode={value.card.payment_company_code}
                                                               cardNumber={value.card.number}/>
                        </TableCell>
                        <TableCell align={'center'}>R$ {parseFloat(value.amount).toFixed(2)}</TableCell>
                        <TableCell align={'center'}>{value.plan_description}</TableCell>
                        <TableCell align={'center'}>{this.getCnpjAditional(value.items)?.quantity}</TableCell>
                        <TableCell align={'center'}>{this.getCnpjAditional(value.items)?.total_price}</TableCell>
                    </TableRow>
                );
            }
        );

        return itens;

    }

    getCnpjAditional(object) {

        let newObject = null;

        object.forEach((value) => {

            if (value.name !== 'CNPJ Adicional') {
                return;
            }

            newObject = value;

        });

        return newObject;

    }

    render() {
        if (this.props.bills === undefined) {
            return <></>;
        }
        return (
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell align={'center'}>DATA</TableCell>
                        <TableCell align={'center'}>FORMA DE PAGAMENTO</TableCell>
                        <TableCell align={'center'}>VALOR</TableCell>
                        <TableCell align={'center'}>PLANO</TableCell>
                        <TableCell align={'center'}>CNPJ EXCEDENTE</TableCell>
                        <TableCell align={'center'}>CNPJ EXCEDENTE VALOR (R$)</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {this.renderTable()}
                </TableBody>
            </Table>
        );
    }
}

export default TableBillingDetailScreen;