import React, {Component} from 'react';
import SpacerComponent from "../../SpacerComponent/SpacerComponent";
import {Link, Typography} from "@material-ui/core";
import DashboardScreen from "../../../Screens/PJAccess/DashboardScreen/DashboardScreen";
import NewAward from "../../../Screens/PJAccess/NewAward/NewAward";
import ListNewAwardScreen from "../../../Screens/PJAccess/NewAward/ListNewAwardScreen";
import ExtractScreen from "../../../Screens/PJAccess/ExtractScreen/ExtractScreen";
import OperatorScreen from "../../../Screens/PJAccess/OperatorScreen/OperatorScreen";
import CompanyRegistrationScreen from "../../../Screens/PJAccess/CompanyRegistrationScreen/CompanyRegistrationScreen";
import ManageEstablishmentScreen from "../../../Screens/PJAccess/ManageEstablishmentScreen/ManageEstablishmentScreen";
import ManageFidelityScreen from "../../../Screens/PJAccess/ManageFidelityScreen/ManageFidelityScreen";
import SacScreen from "../../../Screens/PJAccess/SACScreen/SACScreen";
import {HeaderContext} from "../../../Contexts/HeaderContext";
import SelectAwardScreen from "../../../Screens/PJAccess/SelectAwards/SelectAwardScreen";
import SelectCampaign from "../../../Screens/PJAccess/SelectCampaign/SelectAwardScreen";
import CompanyRegistrationFidelityScreen
    from "../../../Screens/PJAccess/CompanyRegistrationFidelityScreen/CompanyRegistrationFidelityScreen";

class CommonMobileMenuHeader extends Component {
    static contextType = HeaderContext;

    tryGetFidelity = () => {
        try {
            return this.context.fidelity[0].uuid;
        } catch (e) {
            return null;
        }
    }

    render() {
        return (
            <>
                <SpacerComponent height={50}/>
                <Typography variant={'h6'}>
                    <Link href={DashboardScreen.ROUTE}>Dashboard</Link>
                </Typography>
                <Typography variant={'h6'}>
                    <Link href={SelectAwardScreen.ROUTE}>Prêmios</Link>
                </Typography>
                <Typography variant={'h6'}>
                    <Link href={SelectCampaign.ROUTE}>Campanhas</Link>
                </Typography>
                <Typography variant={'h6'}>
                    <Link href={ExtractScreen.ROUTE}>Extrato</Link>
                </Typography>
                <Typography variant={'subtitle1'}>
                    <Link href={OperatorScreen.ROUTE}>Operadores de Loja</Link>
                </Typography>
                <Typography variant={'subtitle1'}>
                    <Link href={CompanyRegistrationScreen.ROUTE
                        + '/'
                        + this.context.me.company.uuid}>Dados da Empresa</Link>
                </Typography>
                <Typography variant={'subtitle1'}>
                    <Link href={ManageEstablishmentScreen.ROUTE}>Estabelecimentos</Link>
                </Typography>
                <Typography variant={'subtitle1'}>
                    <Link href={CompanyRegistrationFidelityScreen.ROUTE + '/edit/' + this.tryGetFidelity()}>Programa de
                        Fidelidade</Link>
                </Typography>
                <Typography variant={'subtitle1'}>
                    <Link href={'#'}
                          onClick={this.context.goToMyPlan}>Meu plano</Link>
                </Typography>
                <Typography variant={'subtitle1'}>
                    <Link href={SacScreen.ROUTE}>SAC</Link>
                </Typography>
                <Typography variant={'subtitle1'}>
                    <Link href={'#'}
                          onClick={this.context.logout}>Sair</Link>
                </Typography>
            </>
        );
    }
}

export default CommonMobileMenuHeader;