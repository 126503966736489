import React, {Component} from 'react';
import {Button, Typography} from "@material-ui/core";
import TextFieldDefault from "../../../Components/TextFieldDefaultComponent/TextFieldDefault";
import {ContentFooterTotalStyled, ContentMainFooterTotalStyled} from "./styled";
import SpacerComponent from "../../../Components/SpacerComponent/SpacerComponent";
import {parse} from "dotenv";
import NumberFormat from "react-number-format";
import {CSVLink, CSVDownload} from "react-csv";
import DateUtil from "../../../Utils/DateUtil";

class FooterPaymentStatusScreen extends Component {

    getTotalValor() {
        let total = 0;

        this.props.data.map((value) => {
            total += parseFloat(value.value);
        });

        return total;

    }

    getDataExportCsv() {
        const data = [
            [
                'CNPJ',
                'Fantasia',
                'Plano Atual',
                'Valor',
                'Data Cadastro',
                'Data Inadimplencia',
                'Motivo',
                'Status',
                'Consultor',
            ],
        ];

        this.props.data.map((value) => {
            data.push([
                value.cnpj,
                value.name,
                value.payment_plan,
                value.value,
                DateUtil.raw(value.created_at).toDate(),
                DateUtil.raw(value.charge_rejected_at).toDate(),
                value.charge_rejected_reason,
                value.status,
                value.consultant,
            ]);
        });

        return data;
    }

    render() {
        return (
            <ContentMainFooterTotalStyled>
                <SpacerComponent height={30}/>
                <Typography variant={'h5'}>
                    Totais de consulta:
                </Typography>
                <SpacerComponent height={20}/>
                <ContentFooterTotalStyled>
                    <Typography variant={'h6'}>
                        <b>CNPJ: </b> {this.props.data.length}
                    </Typography>
                    <SpacerComponent width={100}/>
                    <Typography variant={'h6'}>
                        <b>Valor Total: </b> <NumberFormat value={this.getTotalValor()}
                                                           thousandSeparator={'.'}
                                                           decimalSeparator={','}
                                                           decimalScale={2}
                                                           fixedDecimalScale={true}
                                                           prefix={'R$ '}
                                                           displayType={'text'}/>
                    </Typography>
                    <SpacerComponent width={100}/>
                    <Button variant={'contained'}>
                        <CSVLink data={this.getDataExportCsv()}>Exportar CSV</CSVLink>
                    </Button>
                </ContentFooterTotalStyled>
            </ContentMainFooterTotalStyled>
        );
    }
}

export default FooterPaymentStatusScreen;