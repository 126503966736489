import React from "react";

class BaseTranslate {
    translate() {
        const erroTranslate = [];

        Object.keys(this.erros).map((key) => {
            erroTranslate.push(
                `${this.translation[key] ?? key}: ${this.erros[key]}`
            );
        });

        return erroTranslate;
    }
}

export default BaseTranslate;