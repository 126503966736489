import React, {Component} from 'react';
import HeaderComponent from "../../../Components/HeaderComponent/HeaderComponent";
import {Typography} from "@material-ui/core";
import FidelityService from "../../../Services/FidelityService";
import MessageUtil from "../../../Utils/MessageUtil";
import FormRegulationFidelity from "./FormRegulationFidelity";
import RegulationFidelityValidation from "../../../FormValidations/RegulationFidelityValidation";
import DashboardScreen from "../DashboardScreen/DashboardScreen";

class RegulationFidelityScreen extends Component {
    static ROUTE = '/regulation-fidelity';

    constructor(props) {
        super(props);

        this.state = {
            inLoad: false,
            erros: [],
            regulation: null,
        };

        this.fidelityService = new FidelityService();

    }

    componentDidMount() {
        this.load();
    }

    async load() {
        try {

            this.setState({
                inLoad: true,
            });

            if (this.props.match.params.already === 'true') {

                const response = await this.fidelityService.show(
                    this.props.match.params.fidelity
                );

                this.setState({
                    inLoad: false,
                    regulation: response.data.data.regulation,
                });
                return;
            }

            const response = await this.fidelityService.getRegulation(
                this.props.match.params.company,
                this.props.match.params.fidelity,
            );

            this.setState({
                inLoad: false,
                regulation: response.data.text,
            });

        } catch (e) {
            this.setState({
                inLoad: false,
                messagens: MessageUtil.make({
                    title: 'Atenção',
                    body: e,
                }),
            });
        }
    }

    updateRegulation = async (form) => {
        try {

            const validate = await RegulationFidelityValidation.validate(form);
            if (validate !== true) {
                this.setState({
                    erros: validate,
                });
                return;
            }

            this.setState({
                inLoad: true,
            });

            await this.fidelityService.update(
                form,
                this.props.match.params.fidelity
            );

            let toRoute = DashboardScreen.ROUTE;

            if (this.props.match.params.to === 'back') {

                this.setState({
                    inLoad: false,
                    messagens: MessageUtil.make({
                        title: 'Sucesso',
                        body: 'Seu regulamento foi registrado, clique em "OK" para continuar',
                        okButtonFunction: () => {
                            window.history.back();
                        }
                    }),
                });
                return;
            }

            this.setState({
                inLoad: false,
                messagens: MessageUtil.make({
                    title: 'Sucesso',
                    body: 'Seu regulamento foi registrado, clique em "OK" para continuar',
                    okBtnToRoute: toRoute,
                }),
            });

        } catch (e) {
            this.setState({
                inLoad: false,
                messagens: MessageUtil.make({
                    title: 'Atenção',
                    body: e,
                }),
            });
        }
    }

    render() {
        return (
            <div>
                <HeaderComponent messagens={this.state?.messagens}
                                 title={'Regulamento do programa de fidelidade'}
                                 inLoad={this.state.inLoad}
                                 menu={(this.props.match.params.to === 'back')}/>
                <Typography align={'center'}
                            variant={'h6'}
                            color={'primary'}>
                    Regulamento do programa de fidelidade
                </Typography>
                <br/>
                <FormRegulationFidelity erros={this.state.erros}
                                        regulation={this.state.regulation}
                                        updateRegulation={this.updateRegulation}/>
            </div>
        );
    }
}

export default RegulationFidelityScreen;