import styled from "styled-components"
import {Button} from "@material-ui/core";

export const ContentMainStyled = styled.div`
  padding: 60px;

  @media only screen and (max-width: 600px) {
    padding: 20px !important;
  }
`;

export const LinkMyAccountStyled = styled.a`
  font-size: 15px;
`;

function ruleColor(capacity_percentage) {

    if (capacity_percentage >= 80 && capacity_percentage <= 90) {
        return '#cca67d';
    }

    if (capacity_percentage >= 100) {
        return '#f24726';
    }

    return '#2370a8';

}

export const ButtonChangePlanStyled = styled(Button)`
  background: ${props => ruleColor(props.capacity_percentage)};
  color: white;
`;

export const ContentMainUpgradePlanStyled = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 20px;
`;

export const ContentButtonUpgradePlanStyled = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;