import React, {Component} from 'react';
import ThemaCustomized from "./Customized/ThemaCustomized";
import {MuiPickersUtilsProvider} from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import {CssBaseline} from "@material-ui/core";
import AlertHeaderComponent from "./Components/AlertHeaderComponent/AlertHeaderComponent";
import {ThemeProvider} from "@material-ui/core/styles";
import {SuperContext} from "./Contexts/SuperContext";
import UserService from "./Services/UserService";
import CompanyService from "./Services/CompanyService";
import MeUtil from "./Utils/MeUtil";
import ApiToken from "./Services/Api/ApiToken";
import RoleUtil from "./Utils/RoleUtil";
import Routes from "./Routes/Routes";
import LoginScreen from "./Screens/CommonAccess/LoginScreen/LoginScreen";
import FooterComponent from "./Components/FooterComponent/FooterComponent";
import {initializeApp} from "firebase/app";
import {getAnalytics} from "firebase/analytics";
import FirebaseUtil from "./Utils/FirebaseUtil";
import FidelityService from "./Services/FidelityService";
import CanHelpFloat from "./Components/CanHelpFloat/CanHelpFloat";
import Analytics from "./Utils/analytics";


class Start extends Component {
    constructor(props) {
        super(props);

        this.state = {
            myPlan: null,
            me: null,
            fidelity: [],
            isAdmin: null,
            showFooter: false,
            footerAbsolute: false,
            instanceAnalytics: null,
        };

        this.userService = new UserService();
        this.companyService = new CompanyService();
        this.fidelityService = new FidelityService();
    }

    componentDidMount() {
        this.load();
        this.startFirebase();
    }

    async load() {
        try {
            if (ApiToken.get().token === null) {
                this.setState({
                    myPlan: null,
                    me: null,
                });
                return;
            }

            const me = await this.loadMe();
            const isAdmin = this.isAdmin(me);

            this.setState({
                myPlan: await this.loadMyPlan(),
                me: me,
                isAdmin: isAdmin,
                fidelity: await this.loadFidelity(),
            });

        } catch (e) {
            if (process.env.NODE_ENV === 'development') {
                throw e;
            }
            ApiToken.logout();
            window.location.href = LoginScreen.ROUTE;
        }
    }

    async loadMe() {
        try {

            const me = await MeUtil.get();

            return me.data.data;

        } catch (e) {
            return null;
        }
    }

    async loadMyPlan() {
        try {

            const myPlan = await this.userService.getMyPlan();

            return myPlan.data;

        } catch (e) {
            return null;
        }
    }

    async loadFidelity() {
        try {
            const fidelity = await this.fidelityService.getByUser();
            return fidelity.data.data;
        } catch (e) {
            return [];
        }
    }

    isAdmin(me) {
        try {
            return me.roles[RoleUtil.ADMIN_UUID] !== undefined;
        } catch (e) {
            ApiToken.logout();
            window.location.href = LoginScreen.ROUTE;
        }
    }

    showFooter = (value) => {
        this.setState({
            showFooter: value,
        });
    }

    footerAbsolute = (value) => {
        this.setState({
            footerAbsolute: value,
        });
    }

    startFirebase = () => {
        try {
            if (process.env.NODE_ENV === 'development') {
                return;
            }

            const app = initializeApp(FirebaseUtil.getConfig());
            const instance = getAnalytics(app);

            const instanceAnalytics = new Analytics(instance);

            this.setState({
                instanceAnalytics: instanceAnalytics,
            });

        } catch (e) {
        }
    };

    render() {
        return (
            <SuperContext.Provider value={{
                myPlan: this.state.myPlan,
                me: this.state.me,
                load: this.load,
                isAdmin: this.state.isAdmin,
                showFooter: this.showFooter,
                footerAbsolute: this.footerAbsolute,
                fidelity: this.state.fidelity,
                analytics: this.state.instanceAnalytics,
            }}>
                <ThemeProvider theme={ThemaCustomized.system()}>
                    <MuiPickersUtilsProvider utils={MomentUtils}
                                             locale={'pt-BR'}>
                        <CssBaseline/>
                        <AlertHeaderComponent me={this.state.me}
                                              myPlan={this.state.myPlan}/>
                        <Routes/>
                        {this.state.showFooter &&
                            <FooterComponent footerAbsolute={this.state.footerAbsolute}/>
                        }
                        <CanHelpFloat/>
                    </MuiPickersUtilsProvider>
                </ThemeProvider>
            </SuperContext.Provider>
        );
    }
}

export default Start;