import React, {Component} from 'react';
import {ContentMainStyled, ModalChangePlanStyled} from "./styled";
import {Box, Button, Typography} from "@material-ui/core";
import CheckItemChoicePlanScreen from "./CheckItemChoicePlanScreen";
import SpacerComponent from "../../../Components/SpacerComponent/SpacerComponent";
import {ChoicePlanContext} from "../../../Contexts/ChoicePlanContext";
import {CheckCircleIconStyled} from "../../../Components/HeaderComponent/styled";
import MyAccountScreen from "../MyAccountScreen/MyAccountScreen";
import PlanService from "../../../Services/PlanService";
import MessageUtil from "../../../Utils/MessageUtil";
import DateUtil from "../../../Utils/DateUtil";

class ModalChangePlanScreen extends Component {
    static contextType = ChoicePlanContext;

    constructor(props) {
        super(props);

        this.state = {
            changePlanSucess: false,
            alertLimitPlan: false,
        };

        this.planService = new PlanService();
    }

    closeModal = () => {
        const currentMyPlan = this.context.parentState.state.myCurrentPlan;

        if (currentMyPlan.total_vacancy <= 0) {
            this.setState({
                alertLimitPlan: true,
            });
            return;
        }

        this.toMyAccount();

    }

    confirmedChangePlan = async () => {
        try {

            this.context.parentState.setState({
                inLoad: true,
            });

            await this.planService.subscribePlan({
                uuid: this.context.parentState.state.choicePlan.uuid,
            });

            this.context.parentState.setState({
                inLoad: false,
            });

            this.setState({
                changePlanSucess: true,
            });

        } catch (e) {
            this.context.parentState.setState({
                inLoad: false,
                messagens: MessageUtil.make({
                    title: 'Atenção',
                    body: e,
                }),
            });
        }
    }

    toMyAccount() {
        window.location.href = MyAccountScreen.ROUTE;
    }

    getTextToCLient(valueClients) {

        if (valueClients === null) {
            return 'Clientes ilimitados';
        }

        return `Até ${valueClients} Clientes`;

    }

    render() {

        if (this.props.show !== true) {
            return <></>;
        }

        const currentPlan = this.context.parentState.state.meUtil.company.payment_plan;
        const choicePlan = this.context.parentState.state.choicePlan;
        const currentMyPlan = this.context.parentState.state.myCurrentPlan;

        return (
            <ModalChangePlanStyled>
                <ContentMainStyled>
                    {this.state.changePlanSucess !== true &&
                    this.state.alertLimitPlan !== true &&
                    <>
                        <Typography variant={'h6'}
                                    color={'primary'}
                                    align={'center'}>
                            Troque o plano do seu Programa de Fidelidade
                        </Typography>
                        <SpacerComponent height={20}/>
                        <hr/>
                        <SpacerComponent height={20}/>
                        <Typography variant={'body1'}>
                            PLANO ATUAL: Plano {currentPlan.name}
                        </Typography>
                        <SpacerComponent height={10}/>
                        <CheckItemChoicePlanScreen label={this.getTextToCLient(currentPlan.clients_limit)}
                                                   variant={'subtitle1'}/>
                        <CheckItemChoicePlanScreen label={`Ate ${currentPlan.companies_limit} CNPJ\`(s) cadastrado${currentPlan.companies_limit > 1 ? 's' : ''}`}
                                                   variant={'subtitle1'}/>
                        <hr/>
                        <SpacerComponent height={20}/>
                        <Typography variant={'body1'}>
                            NOVO PLANO: Plano {choicePlan.name}
                        </Typography>
                        <SpacerComponent height={10}/>
                        <CheckItemChoicePlanScreen label={this.getTextToCLient(choicePlan.clients_limit)}
                                                   variant={'subtitle1'}/>
                        <CheckItemChoicePlanScreen label={`Ate ${choicePlan.companies_limit} CNPJ\`(s) cadastrado${currentPlan.companies_limit > 1 ? 's' : ''}`}
                                                   variant={'subtitle1'}/>
                        <hr/>
                        <SpacerComponent height={10}/>
                        <Typography variant={'subtitle1'}>
                            Seu novo plano comeca em {currentMyPlan.next_billing_date}
                        </Typography>
                        <SpacerComponent height={30}/>
                        <Box display={'flex'}
                             justifyContent={'flex-end'}
                             alignItems={'flex-end'}>
                            <Button variant={'contained'}
                                    onClick={this.closeModal}>
                                Cancelar
                            </Button>
                            <SpacerComponent width={10}/>
                            <Button variant={'contained'}
                                    color={'primary'}
                                    onClick={this.confirmedChangePlan}>
                                Confirmar Alterações
                            </Button>
                        </Box>
                    </>
                    }
                    {this.state.changePlanSucess === true &&
                    <>
                        <Box display={'flex'}
                             flexDirection={'column'}
                             justifyContent={'center'}
                             alignItems={'center'}>
                            <CheckCircleIconStyled/>
                            <SpacerComponent height={20}/>
                            <Typography variant={'h6'}
                                        color={'primary'}
                                        align={'center'}>
                                Seu plano foi alterado com sucesso!
                            </Typography>
                            <SpacerComponent height={20}/>
                            <Button variant={'contained'}
                                    color={'primary'}
                                    onClick={this.toMyAccount}>
                                ok
                            </Button>
                        </Box>
                    </>
                    }
                    {this.state.alertLimitPlan === true &&
                    <>
                        <Box display={'flex'}
                             flexDirection={'column'}
                             justifyContent={'center'}
                             alignItems={'center'}>
                            <Typography variant={'h4'}
                                        color={'primary'}
                                        align={'center'}>
                                Atenção!
                            </Typography>
                            <SpacerComponent height={20}/>
                            <Typography variant={'h6'}
                                        align={'center'}>
                                Enquanto seu plano não for atualizado sua empresa não poderá cadastrar novos clientes em
                                seu programa de fidelidade.
                            </Typography>
                            <SpacerComponent height={20}/>
                            <Button variant={'contained'}
                                    color={'primary'}
                                    fullWidth={true}
                                    onClick={() => {
                                        this.context.parentState.setState({
                                            alertLimitPlan: false,
                                            showModalEdit: false,
                                        });
                                        this.setState({
                                            alertLimitPlan: false,
                                        });
                                    }}>
                                Voltar para tela de upgrade
                            </Button>
                            <SpacerComponent height={20}/>
                            <Button variant={'contained'}
                                    fullWidth={true}
                                    onClick={this.toMyAccount}>
                                Continuar com o meu plano
                            </Button>
                        </Box>
                    </>
                    }
                </ContentMainStyled>
            </ModalChangePlanStyled>
        );
    }
}

export default ModalChangePlanScreen;