class TooltipMessageUtil {

    static get POINTS_MSG1() {
        return 'A cada um real gasto na sua empresa, o seu cliente ganha um ponto para trocar no seu programa de fidelidade.';
    }

    static get GIFT_MSG2() {
        return 'Aqui você pode inserir o valor do Gift\n' +
            '    Card que deseja adquirir, de acordo com o intervalo de valor permitido para cada opção.';
    }

    static get GIFT_MSG3() {
        return 'Selecione a quantidade de Gilt Cards escolhido, você deseja adquirir';
    }
}

export default TooltipMessageUtil;