import React, {Component} from 'react';
import {Box, Grid, Typography} from "@material-ui/core";
import {
    BrandLineDescriptionStyled,
    BrandLineImgStyled,
    BrandLineLinerStyled,
    BrandLineStyled,
} from "./styled";
import SpacerComponent from "../../../Components/SpacerComponent/SpacerComponent";
import GroupItemGiftCard from "./GroupItemGiftCard";

class ItemGiftCard extends Component {
    constructor(props) {
        super(props);
    }

    toEdit() {
        alert('Comprar');
    }

    makeGroupItemGiftCard() {
        let itens = [];
        const context = this;

        const productsOrder = this.props.productLines.products.sort((a, b) => a.min_value - b.max_value);

        let insertItemAfter;
        productsOrder.forEach(function (value, index) {
            if (value.min_value != value.max_value) {
                insertItemAfter = value;
                return;
            }

            itens.push(
                <GroupItemGiftCard product={value}
                                   productLines={context.props.productLines}/>
            );
        });

        if (insertItemAfter) {
            itens.push(
                <GroupItemGiftCard product={insertItemAfter}
                                   productLines={context.props.productLines}/>
            );
        }

        return itens;
    }

    redemptionTextMake = () => {
        var text = '';

        if (this.props.productLines.store_redemption) {
            text += 'Lojas Físicas'
        }

        if (this.props.productLines.online_redemption) {
            if (text !== '') {
                text += ' e ';
            }
            text += 'Lojas Digitais'
        }

        return text;

    }

    render() {
        return (
            <>
                <BrandLineStyled>
                    <BrandLineLinerStyled/>
                    <SpacerComponent width={20}/>
                    <BrandLineImgStyled>
                        <img src={this.props.productLines.logo_url}/>
                    </BrandLineImgStyled>
                    <SpacerComponent width={20}/>
                    <BrandLineLinerStyled/>
                </BrandLineStyled>
                <SpacerComponent height={30}/>
                <Typography variant={'h6'}
                            align={'center'}>{this.props.productLines.product_line_name}</Typography>
                <BrandLineDescriptionStyled variant={'body2'}
                                            align={'center'}>{this.props.productLines.product_line_description}</BrandLineDescriptionStyled>
                <SpacerComponent height={40}/>
                <Typography variant={'h6'}
                            align={'center'}>
                    <Typography variant={'h6'}
                                color={'error'}>Utilizável em:
                    </Typography>
                    <Typography variant={'h6'}>
                        {this.redemptionTextMake()}
                    </Typography>
                </Typography>
                <SpacerComponent height={40}/>
                <Grid container={true}
                      spacing={3}>
                    {this.makeGroupItemGiftCard()}
                </Grid>
            </>
        );
    }
}

export default ItemGiftCard;