import React, {Component} from 'react';
import CheckIcon from "@material-ui/icons/Check";
import SpacerComponent from "../../../Components/SpacerComponent/SpacerComponent";
import {Box, Typography} from "@material-ui/core";

class CheckShowPlanPaymentScreen extends Component {
    render() {
        return (
            <Box display={'flex'}
                 justifyContent={'center'}
                 alignItems={'center'}>
                <CheckIcon fontSize={'large'}/>
                <SpacerComponent width={10}/>
                <Typography variant={'body2'}>
                    {this.props.title}
                </Typography>
            </Box>
        );
    }
}

export default CheckShowPlanPaymentScreen;