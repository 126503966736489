import React, {Component} from 'react';
import HeaderComponent from "../../../Components/HeaderComponent/HeaderComponent";
import {Box, Grid, Typography} from "@material-ui/core";
import SpacerComponent from "../../../Components/SpacerComponent/SpacerComponent";
import ItemExportClientData from "./ItemExportClientData";
import ListNewAwardScreen from "../NewAward/ListNewAwardScreen";
import ListAltIcon from '@material-ui/icons/ListAlt';
import bling from "../../../Assets/bling.png";
import bitrix24 from "../../../Assets/bitrix24.png";
import excel from "../../../Assets/excel.png";
import ErrorUtil from "../../../Utils/ErrorUtil";
import MessageUtil from "../../../Utils/MessageUtil";
import ExportService from "../../../Services/ExportService";
import exportService from "../../../Services/ExportService";

class ExportClientDataScreen extends Component {
    constructor(props) {
        super(props);

        this.state = {
            inLoad: false,
        };

        this.exportService = new ExportService();
    }

    static ROUTE = '/export-data-client';

    doDownload = async (type) => {
        try {

            this.setState({
                inLoad: true,
            });

            const result = await this.exportService.client(type);

            this.setState({
                inLoad: false,
                messagens: MessageUtil.make({
                    title: 'Sucesso',
                    body: result.data.message,
                }),
            });

        } catch (e) {
            this.setState({
                inLoad: false,
                messagens: MessageUtil.make({
                    title: 'Atenção',
                    body: e,
                }),
            });
        }
    }

    render() {
        return (
            <>
                <HeaderComponent title={'Prêmios'}
                                 menu={true}
                                 inLoad={this.state.inLoad}
                                 messagens={this.state.messagens}/>
                <SpacerComponent height={50}/>
                <Box display={'flex'}
                     flexDirection={'column'}
                     alignItems={'center'}
                     justifyContent={'center'}>
                    <Typography variant={'h6'}
                                color={'primary'}
                                align={'center'}>
                        Exportar Dados de Clientes
                    </Typography>
                    <Typography variant={'body1'}
                                color={'primary'}
                                align={'center'}>
                        Escolha o modelo clicando nas opções abaixo
                    </Typography>
                </Box>
                <SpacerComponent height={100}/>
                <Box display={'flex'}
                     justifyContent={'center'}
                     alignItems={'center'}>
                    <Box width={'90%'}>
                        <Grid container={true}
                              alignItems={'center'}
                              alignContent={'center'}
                              justifyContent={'center'}
                              spacing={3}>
                            <ItemExportClientData img={excel}
                                                  title={'Padrão'}
                                                  click={() => this.doDownload('xls')}/>
                            <ItemExportClientData img={bitrix24}
                                                  title={'Bitrix24'}
                                                  click={() => this.doDownload('bling')}/>
                            <ItemExportClientData img={bling}
                                                  title={'Bling'}
                                                  click={() => this.doDownload('bitrix24')}/>
                        </Grid>
                    </Box>
                </Box>
                <SpacerComponent height={100}/>
            </>
        );
    }
}

export default ExportClientDataScreen;