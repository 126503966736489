import React, {Component} from 'react';
import {DownloadTextStyled} from "./styled";
import JsPDF from 'jspdf';
import ExtractService from "../../../Services/ExtractService";
import PdfExtract from "./Pdf";
import {ExtractContext} from "../../../Contexts/ExtractContext";
import PdfExtractPage from "./PdfExtractPage";


class Recibo extends Component {
    static contextType = ExtractContext;
    constructor(props) {
        super(props);

    }

    getData = async (uuid) => {
        window.open(PdfExtractPage.ROUTE + `/${uuid}`, '_blank');
    }

    makePdf = (data) => {
        const doc = new JsPDF();
        doc.text('teste',30, 20, 'center');
        doc.save();
    }

    render() {
        if (this.props.value.type !== 'Resgate de prêmio') {
            return <></>;
        }

        return (
            <div>
                <DownloadTextStyled variant={'button'}
                                    onClick={() => this.getData(this.props.value.key)}>
                    Recibo
                </DownloadTextStyled>
            </div>
        );
    }
}

export default Recibo;