import React, {Component} from 'react';
import {ContentMainStyled} from "./styled";
import logo from "../../../Assets/logo_login.png";
import {Logo} from "../LoginScreen/styled";

class LoadPageScreen extends Component {
    render() {
        return (
            <ContentMainStyled>
                <Logo src={logo}/>
            </ContentMainStyled>
        );
    }
}

export default LoadPageScreen;