import React, {Component} from 'react';
import {Box, Chip, MenuItem, Select, Typography} from "@material-ui/core";
import SpacerComponent from "../SpacerComponent/SpacerComponent";
import {FidelityContext} from "../../Contexts/FidelityContext";

class SelectMultiple extends Component {
    static contextType = FidelityContext;

    static ROUTE = '/test';

    constructor(props) {
        super(props);

        this.state = {
            values: [],
        };

    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.valueSelected === prevProps.valueSelected) {
            return;
        }

        this.setState({
            values: this.props.valueSelected,
        });

    }

    handleChange = (event) => {
        this.setState({
            values: event.target.value
        });

        this.props.handleChangeEstablishment(event.target.value);
    };

    render() {
        return (
            <div>
                <Typography variant={'body1'}
                            color={'primary'}
                            style={{marginRight: 5}}>
                    Estabelecimentos Participantes:
                </Typography>
                {this.state.values.length <= 0 &&
                <Typography variant={'body2'}
                            color={'primary'}
                            style={{marginRight: 5}}>
                    Utilize a lista abaixo para escolher os estabelecimentos
                </Typography>
                }
                {this.state.values.length > 0 &&
                <Box display={'flex'}
                     flexDirection={'column'}
                     justifyContent={'flex-start'}>
                    {this.state.values.map((value) => (
                        <div>
                            <SpacerComponent height={5}/>
                            <Chip variant={'outlined'}
                                  color={'primary'}
                                  label={this.props.toShow[value]}
                                  onDelete={() => this.context.doDetachCompany(value)}/>
                            <SpacerComponent height={10}/>
                        </div>
                    ))}
                </Box>
                }
                <Select fullWidth={true}
                        variant={'outlined'}
                        multiple={true}
                        value={this.state.values}
                        onChange={this.handleChange}>
                    {this.props?.value?.map((value) => (
                        <MenuItem key={value.uuid}
                                  value={value.uuid}>
                            {value.name +
                            (value.fildelity !== undefined ? ' | ' + value.fildelity : ' | Não associado')
                            }
                        </MenuItem>
                    ))}
                </Select>
            </div>
        );
    }
}

export default SelectMultiple;