import React, {Component} from 'react';
import {Box, Button, Typography} from "@material-ui/core";
import {Logo, Title} from "./styled";
import logo from "../../../Assets/logo_login.png";
import SpacerComponent from "../../../Components/SpacerComponent/SpacerComponent";
import LoginScreen from "../../CommonAccess/LoginScreen/LoginScreen";

class StaticElementReseteOperatorInstructionScreen extends Component {
    toStore = () => {
        if (navigator.userAgent.toLowerCase().includes('android')) {
            window.location.href = 'http://play.google.com/store/apps/details?id=com.meuapp.fidelidadepj';
            return;
        }

        if (navigator.userAgent.toLowerCase().includes('iphone')) {
            window.location.href = 'https://apps.apple.com/us/app/meu-app-de-fidelidade-pj/id1573055215';
            return;
        }

        if (navigator.userAgent.toLowerCase().includes('ipad')) {
            window.location.href = 'https://apps.apple.com/us/app/meu-app-de-fidelidade-pj/id1573055215';
            return;
        }

        window.location.href = LoginScreen.ROUTE;
    }

    getButtonText = () => {
        if (navigator.userAgent.toLowerCase().includes('android')) {
            return 'Abrir Play Store';
        }

        if (navigator.userAgent.toLowerCase().includes('iphone')) {
            return 'Abrir App Store';
        }

        if (navigator.userAgent.toLowerCase().includes('ipad')) {
            return 'Abrir App Store';
        }

        return null;
    }

    render() {
        return (
            <div>
                <Box display={'flex'}
                     justifyContent={'center'}>
                    <Logo src={logo}/>
                </Box>
                <br/>
                <Title variant={'h6'}
                       align={'center'}
                       color={'primary'}>
                    Seu cadastro como OPERADOR de LOJA
                    foi concluído com sucesso.
                </Title>
                <SpacerComponent height={30}/>
                <Typography variant={'subtitle1'}
                            align={'center'}>
                    Agora é necessário que você se dirija à sua loja (Play Store ou Apple Store),Realize o download do
                    aplicativo MEU APP DE FIDELIDADE EMPRESAS e faça o login com o email e senha cadastrados.
                </Typography>
                <SpacerComponent height={30}/>
                {this.getButtonText() != null &&
                    <Box display={'flex'}
                         justifyContent={'center'}>
                        <Button variant={'contained'}
                                color={'primary'}
                                onClick={this.toStore}>
                            {this.getButtonText()}
                        </Button>
                    </Box>
                }
            </div>
        );
    }
}

export default StaticElementReseteOperatorInstructionScreen;