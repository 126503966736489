import React, {Component} from 'react';
import UserService from "../../../Services/UserService";
import MessageUtil from "../../../Utils/MessageUtil";
import HeaderComponent from "../../../Components/HeaderComponent/HeaderComponent";
import {ContentMainStyled, ContentSizeStyled} from "./styled";
import StaticElementResetePasswordScreen from "./StaticElementResetePasswordScreen";
import FormResetePasswordScreen from "./FormResetePasswordScreen";
import ResetValidation from "../../../FormValidations/ResetValidation";
import LoginScreen from "../../CommonAccess/LoginScreen/LoginScreen";
import ErrorUtil from "../../../Utils/ErrorUtil";
import RoleUtil from "../../../Utils/RoleUtil";
import OperatorInstructionScreen from "../OperatorInstruction/OperatorInstructionScreen";

class ResetPasswordScreen extends Component {
    static ROUTE = '/reset-password';

    constructor(props) {
        super(props);

        this.state = {
            inLoad: false,
            erros: [],
        };

        this.userService = new UserService();
    }

    doReset = async (form) => {
        try {

            const validate = await ResetValidation.validate(form);

            if (form.score !== 3) {
                throw ErrorUtil.make('Por favor defina uma senha mais forte')
            }

            if (validate !== true) {
                this.setState({
                    erros: validate,
                });
                return;
            }

            this.setState({
                inLoad: true,
            });

            form.token = this.props.match.params.uuid;
            const response = await this.userService.resetPassword(form);

            const isClientPF = response?.data?.data?.roles[RoleUtil.CLIENTE_PF];
            const isOperatorPJ = response?.data?.data?.roles[RoleUtil.OPERADOR_LOJA];

            let toRoute = LoginScreen.ROUTE;
            if (isClientPF) {
                toRoute = 'meuappdefidelidade://';
            }

            if (isOperatorPJ) {
                toRoute = OperatorInstructionScreen.ROUTE;
            }

            this.setState({
                inLoad: false,
                erros: [],
                messagens: MessageUtil.make({
                    title: 'Sucesso',
                    body: 'Sua senha foi redefinida, clique em ok para continuar',
                    okBtnToRoute: toRoute,
                }),
            });

        } catch (e) {
            this.setState({
                inLoad: false,
                messagens: MessageUtil.make({
                    title: 'Atenção',
                    body: e,
                }),
            });
        }
    }

    render() {
        return (
            <div>
                <HeaderComponent messagens={this.state?.messagens}
                                 inLoad={this.state.inLoad}
                                 title={'Meu App | Resete de senha'}/>
                <ContentMainStyled>
                    <ContentSizeStyled>
                        <StaticElementResetePasswordScreen/>
                        <FormResetePasswordScreen doReset={this.doReset}
                                                  erros={this.state.erros}/>
                    </ContentSizeStyled>
                </ContentMainStyled>
            </div>
        );
    }
}

export default ResetPasswordScreen;