import styled from "styled-components"


export const ContentMainCardSimulationStyled = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

export const CardSimulationStyled = styled.div`
  width: 70px;
  height: 40px;
  background: rgba(70, 86, 102, 0.49);
  border-radius: 10px;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    width: 90%;
  }
`;