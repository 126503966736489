import React, {Component} from 'react';
import {Box, Button, Grid, IconButton, Typography} from "@material-ui/core";
import {BtnEditItemAwardStyled, ImgItemStyled, PaperItemImgStyled, PaperItemStyled} from "./styled";
import SpacerComponent from "../../../Components/SpacerComponent/SpacerComponent";
import ApiImageUtil from "../../../Utils/ApiImageUtil";
import {ManageFidelityContext} from "../../../Contexts/ManageFidelityContext";
import EditIcon from '@material-ui/icons/Edit';

class ItemFidelity extends Component {
    static contextType = ManageFidelityContext;

    render() {
        return (
            <Grid item={true}
                  xs={12}
                  sm={12}
                  md={6}
                  lg={3}
                  xl={2}>
                <Box height={260}>
                    <PaperItemStyled elevation={3}>
                        <PaperItemImgStyled elevation={3}>
                            <ImgItemStyled
                                src={ApiImageUtil.tryGet(this.props.fidelity.files)}/>
                        </PaperItemImgStyled>
                        <SpacerComponent height={10}/>
                        <Typography variant={'subtitle2'}
                                    color={'primary'}
                                    align={'center'}>
                            {this.props.fidelity.name}
                        </Typography>
                        <SpacerComponent height={10}/>
                        <BtnEditItemAwardStyled color="primary"
                                size={'small'}
                                onClick={() => this.context.doEdit(
                                    this.props.fidelity.uuid
                                )}>
                            <Typography variant={'subtitle2'}
                                        color={'primary'}
                                        align={'center'}>
                                Editar
                            </Typography>
                            <EditIcon/>
                        </BtnEditItemAwardStyled>
                    </PaperItemStyled>
                </Box>
            </Grid>
        );
    }
}

export default ItemFidelity;