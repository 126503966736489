import React, {Component} from 'react';
import SpacerComponent from "../SpacerComponent/SpacerComponent";
import InputFileDefault from "../InputFileDefault/InputFileDefault";
import {Box, IconButton, Snackbar, Typography} from "@material-ui/core";
import TextFieldDefault from "../TextFieldDefaultComponent/TextFieldDefault";
import {
    CheckIconStyled,
    CloseIconStyled,
    ContentMainCertificate,
    ContentTextFieldStyled,
    TextInfoCertificateStyled
} from "./styled";
import {CompanyRegistrationContext} from "../../Contexts/CompanyRegistrationContext";
import FileCopyIcon from '@material-ui/icons/FileCopy';
import {CopyToClipboard} from "react-copy-to-clipboard/lib/Component";
import CloseIcon from "@material-ui/icons/Close";
import RefreshIcon from '@material-ui/icons/Refresh';
import MessageUtil from "../../Utils/MessageUtil";

class IntegrationTokenFormComponent extends Component {
    static contextType = CompanyRegistrationContext;

    constructor(props) {
        super(props);

        this.state = {
            integrationToken: undefined,
            snackBar: false,
        };
    }

    componentDidMount() {
        this.load();
    }

    load = async () => {
        try {

            const response = await this.context.companyService.getToken();

            this.setState({
                integrationToken: response.data.api_token,
            });

        } catch (e) {

        }
    }

    updateToken = async () => {
        this.context.parent.setState({
            messagens: MessageUtil.make({
                title: 'Deseja realmente atualizar o token?',
                body: 'Ao realizar essa operação será necessário alterar o token no ERP.',
                okButtonText: 'Atualizar',
                okButtonFunction: async (value) => {
                    try {
                        this.context.parent.setState({
                            inLoad: true,
                        });
                        await this.context.companyService.refreshToken();
                        await this.load();
                        this.context.parent.setState({
                            inLoad: false,
                        });
                    } catch (e) {
                        this.context.parent.setState({
                            inLoad: false,
                            messagens: MessageUtil.make({
                                title: 'Atenção',
                                body: e,
                            }),
                        });
                    }
                },
                cancelButtonText: 'Cancelar',
            }),
        });
    }

    render() {
        return (
            <ContentMainCertificate>
                <Typography variant={'body1'}
                            color={'primary'}
                            align={'left'}>
                    Token de integracao
                </Typography>
                <SpacerComponent height={20}/>
                <ContentTextFieldStyled>
                    <TextFieldDefault label={'Token'}
                                      disabled={false}
                                      value={this.state.integrationToken}/>
                    <SpacerComponent width={20}/>
                    <CopyToClipboard text={this.state.integrationToken}
                                     onCopy={() => this.setState({
                                         snackBar: true,
                                     })}>
                        <IconButton>
                            <FileCopyIcon/>
                        </IconButton>
                    </CopyToClipboard>
                    <SpacerComponent width={10}/>
                    <IconButton onClick={this.updateToken}>
                        <RefreshIcon/>
                    </IconButton>
                </ContentTextFieldStyled>
                <Snackbar anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                          open={this.state.snackBar}
                          autoHideDuration={2000}
                          message="Token copiado"
                          action={
                              <React.Fragment>
                                  <IconButton size="small"
                                              color="inherit"
                                              onClick={() => this.setState({
                                                  snackBar: false,
                                              })}>
                                      <CloseIcon fontSize="small"/>
                                  </IconButton>
                              </React.Fragment>
                          }/>
            </ContentMainCertificate>
        );
    }
}

export default IntegrationTokenFormComponent;