import React, {Component} from 'react';
import {DatePicker} from "@material-ui/pickers";
import {Box, Hidden, IconButton} from "@material-ui/core";
import SearchIcon from '@material-ui/icons/Search';
import {DashboardContext} from "../../../../../Contexts/DashboardContext";
import DateUtil from "../../../../../Utils/DateUtil";
import SpacerComponent from "../../../../../Components/SpacerComponent/SpacerComponent";


class SearchTextFieldComponent extends Component {
    static contextType = DashboardContext;

    constructor(props) {
        super(props);

        this.state = {
            filter: null,
            selectedDateInitial: DateUtil.getDateRangeToDate(7).initial,
            selectedDateInitialApi: DateUtil.getDateRangeFormat(7).initial,
            selectedDateFinal: DateUtil.getDateRangeToDate(7).final,
            selectedDateFinalApi: DateUtil.getDateRangeFormat(7).final,
        };
    }

    handleChange(name, value) {

        const apiState = name + 'Api';

        this.setState({
            [name]: value.toDate(),
            [apiState]: value.format('YYYY-MM-DD'),
        });
    }

    doSearch = () => {

        this.context.doSearch(
            this.state.selectedDateInitialApi,
            this.state.selectedDateFinalApi,
        );

    }

    getContent() {
        return (
            <>
                <DatePicker label={'Data Inicial'}
                            value={this.state.selectedDateInitial}
                            onChange={(date) => this.handleChange('selectedDateInitial', date)}
                            animateYearScrolling={true}
                            format="DD/MM/yyyy"/>
                <SpacerComponent width={20}/>
                <DatePicker label={'Data Final'}
                            value={this.state.selectedDateFinal}
                            onChange={(date) => this.handleChange('selectedDateFinal', date)}
                            animateYearScrolling={true}
                            format="DD/MM/yyyy"/>
                <IconButton onClick={this.doSearch}>
                    <SearchIcon/>
                </IconButton>
            </>
        );
    }

    render() {
        return (
            <>
                <Hidden xsDown={true}
                        smDown={true}>
                    <Box display={'flex'}
                         flexDirection={'row'}
                         justifyContent={'center'}
                         alignItems={'center'}>
                        {this.getContent()}
                    </Box>
                </Hidden>
                <Hidden lgUp={true}
                        xlUp={true}
                        mdUp={true}>
                    <Box display={'flex'}
                         flexDirection={'column'}
                         justifyContent={'center'}
                         alignItems={'center'}>
                        {this.getContent()}
                    </Box>
                </Hidden>
            </>
        );
    }
}

export default SearchTextFieldComponent;