import React, {Component} from 'react';
import {Box, Grid, Typography} from "@material-ui/core";
import CardSimulationMiniComponent from "../../../Components/CardSimulationMiniComponent/CardSimulationMiniComponent";
import SpacerComponent from "../../../Components/SpacerComponent/SpacerComponent";
import {LinkMyAccountStyled} from "./styled";
import {MyAccountContext} from "../../../Contexts/MyAccountContext";
import BillingDetailScreen from "../BillingDetailScreen/BillingDetailScreen";
import ManagePaymentScreen from "../ManagePaymentScreen/ManagePaymentScreen";
import CancellationPlanScreen from "../CancellationPlanScreen/CancellationPlanScreen";
import DateUtil from "../../../Utils/DateUtil";
import {SuperContext} from "../../../Contexts/SuperContext";
import ContactUsScreen from "../ContactUsScreen/ContactUsScreen";

class CardMyAccountScreen extends Component {
    static contextType = SuperContext;

    render() {
        const myPlan = this.context.myPlan;

        if (myPlan === null || myPlan.card.number === null) {
            return <></>;
        }

        return (
            <>
                <Grid item={true}
                      xs={12}
                      sm={12}>
                    <Typography variant={'h6'}
                                color={'primary'}>
                        Assinatura e Cobrança
                    </Typography>
                    <hr/>
                </Grid>
                <Grid item={true}
                      xs={12}
                      sm={6}
                      xl={6}>
                    <CardSimulationMiniComponent cardNumber={myPlan.card.number}
                                                 paymentCompanyCode={myPlan.card.payment_company_code}/>
                    <SpacerComponent height={20}/>
                    {!myPlan.to_cancel_plan ?
                        <Typography variant={'h6'}>
                            Sua proxima data de cobrança é {myPlan.next_billing_date}
                        </Typography>
                        :
                        <Typography variant={'subtitle1'}>
                            <b>Cancelamento solicitado</b>
                            <br/> Seu plano se encerrará no
                            dia <b>{DateUtil.raw(myPlan.plan_cancelation_at).toDate()}</b>. <br/> Caso não queira prosseguir
                            com o cancelamento, entre em contato com nossa equipe de suporte através do <a
                            href={ContactUsScreen.ROUTE}>Fale
                            Conosco</a>
                        </Typography>
                    }
                </Grid>
                <Grid item={true}
                      xs={12}
                      sm={6}
                      xl={6}>
                    <Box display={'flex'}
                         flexDirection={'column'}
                         justifyContent={'flex-end'}
                         alignItems={'flex-end'}>
                        <LinkMyAccountStyled href={ManagePaymentScreen.ROUTE}>Gerenciar infomacoes de
                            pagamento</LinkMyAccountStyled>
                        <LinkMyAccountStyled href={BillingDetailScreen.ROUTE}>Detalhes de
                            cobranças</LinkMyAccountStyled>
                        <LinkMyAccountStyled href={!myPlan.to_cancel_plan ? CancellationPlanScreen.ROUTE : '#'}>Cancelar
                            assinatura
                            {myPlan.to_cancel_plan ? '(Cancelamento em andamento)' : ''}</LinkMyAccountStyled>
                    </Box>
                </Grid>
            </>
        );
    }
}

export default CardMyAccountScreen;