import React, {Component} from 'react';
import HeaderComponent from "../../../Components/HeaderComponent/HeaderComponent";
import {ContentMainStyled} from "./styled";
import MessageUtil from "../../../Utils/MessageUtil";
import SpacerComponent from "../../../Components/SpacerComponent/SpacerComponent";
import CompanyService from "../../../Services/CompanyService";
import TableCompanyNotRegisteredScreen from "./TableCompanyNotRegisteredScreen";

class CompanyNotRegisteredScreen extends Component {
    static ROUTE = '/company-not-registered';

    constructor(props) {
        super(props);

        this.state = {
            inLoad: false,
            messagens: null,
            data: [],
        };

        this.companyService = new CompanyService();
    }

    componentDidMount() {
        this.load();
    }

    load = async () => {
        try {
            this.setState({
                inLoad: true,
            });

            const response = await this.companyService.getFutureCompany();

            this.setState({
                inLoad: false,
                data: response.data.data,
            });
        } catch (e) {
            this.setState({
                inLoad: false,
                messagens: MessageUtil.make({
                    title: 'Atenção',
                    body: e,
                }),
            });
        }
    }

    render() {
        return (
            <div>
                <HeaderComponent title={'Empresas Não Cadastadas'}
                                 menu={true}
                                 messagens={this.state.messagens}
                                 inLoad={this.state.load}
                                 showTitleInContent={true}/>
                <ContentMainStyled>
                    <SpacerComponent height={50}/>
                    <TableCompanyNotRegisteredScreen data={this.state.data}/>
                </ContentMainStyled>
            </div>
        );
    }
}

export default CompanyNotRegisteredScreen;