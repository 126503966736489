import React, {Component} from 'react';
import {Grid, Typography} from "@material-ui/core";
import ItemAward from "./ItemAward";
import SpacerComponent from "../../../Components/SpacerComponent/SpacerComponent";

class ContentListAward extends Component {

    makeItemAward() {

        let itens = [];
        const context = this;

        this.props.awards.forEach(function (value, index) {

            if (context.props.isCampaing) {
                if (/*value.products !== undefined && */value.object_type !== 'campaign') {
                    return;
                }
            }

            if (!context.props.isCampaing) {
                if (/*value.products !== undefined && */value.object_type === 'campaign') {
                    return;
                }
            }
            itens.push(
                <ItemAward award={value}
                           setAwardsEnabledDisabled={context.props.setAwardsEnabledDisabled}
                           selectAward={context.props.selectAward}
                           removeStatus={context.props.removeStatus}/>
            );
        });

        return itens;
    }

    render() {

        if (this.props.awards.length === 0) {
            return (
                <div>
                    <SpacerComponent height={100}/>
                    <Typography align={'center'}
                                variant={'subtitle2'}
                                color={'primary'}>
                        {this.props.emptyListText}
                        <SpacerComponent height={265}/>
                    </Typography>
                </div>
            );
        }

        return (
            <Grid container={true}
                  spacing={3}>
                {this.makeItemAward()}
            </Grid>
        );
    }
}

export default ContentListAward;