import React, {Component} from 'react';
import {Button, Grid, Typography} from "@material-ui/core";
import InputFileStory from "./InputFileStory/InputFileDefault";
import SpacerComponent from "../../../Components/SpacerComponent/SpacerComponent";

class ItemGalleryStory extends Component {
    constructor(props) {
        super(props);

        this.state = {
            image_base64: null,

        };
    }

    toBase64Image = (base64) => {
        const image = base64?.split(',')[1] ?? null;

        this.setState({
            'image_base64': image,
        });

        if (this.props.onChange !== undefined) {
            this.props.onChange(image);
        }
    }

    render() {
        return (
            <Grid item={true}
                  xs={12}
                  sm={12}
                  md={4}
                  lg={3}
                  xl={3}
            >
                <InputFileStory toBase64Image={this.toBase64Image}
                                imagePrevios={this.props.imageSave}/>
                {this.props.imageSave &&
                    <>
                        <SpacerComponent height={20}/>
                        <Typography align={'center'}>
                            <Button variant={'contained'}
                                    color={'primary'}
                                    onClick={() => this.props.delete(this.props.uuid)}>Excluir</Button>
                        </Typography>
                    </>
                }
            </Grid>
        );
    }
}

export default ItemGalleryStory;