import React, {Component} from 'react';
import {Box, Grid, Typography} from "@material-ui/core";
import {
    BrandLineStyled,
    BtnEditItemAwardStyled,
    ImgItemStyled,
    PaperItemImgStyled,
    PaperItemStyled,
    StatusIconStyled
} from "./styled";
import SpacerComponent from "../../../Components/SpacerComponent/SpacerComponent";
import {GiftCardPurchaseContext} from "../../../Contexts/GiftCardPurchaseContext";
import StringUtil from "../../../Utils/StringUtil";

class GroupItemGiftCard extends Component {
    static contextType = GiftCardPurchaseContext;

    constructor(props) {
        super(props);
    }

    startBuy = (product, productLines) => {
        this.context.parent.setState({
            selectProduct: product,
            selectProductLines: productLines,
        });
    }


    render() {
        const product = this.props.product;
        const productLines = this.props.productLines;
        return (
            <Grid item={true}
                  xs={12}
                  sm={12}
                  md={4}
                  lg={2}
                  xl={2}>
                <Box>
                    <PaperItemStyled elevation={3}
                                     onClick={() => this.startBuy(product, productLines)}>
                        {product.min_value !== product.max_value &&
                            <>
                                <h5><span>min.:</span> R$ {StringUtil.money(product.min_value)}</h5>
                                <h5><span>max.:</span> R$ {StringUtil.money(product.max_value)}</h5>
                            </>
                        }
                        {product.min_value === product.max_value &&
                            <>
                                <h5>R$ {StringUtil.money(product.min_value)}</h5>
                            </>
                        }
                    </PaperItemStyled>
                </Box>
                <SpacerComponent height={80}/>
            </Grid>

        );
    }
}

export default GroupItemGiftCard;