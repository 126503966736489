import React, {Component} from 'react';
import {Accordion, AccordionSummary, Typography, AccordionDetails, Box} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import {AccordionStyled} from "./styled";

class ContentSac extends Component {

    dataSac = [
        {
            title: 'O que é preciso fazer para utilizar o MEU APP de Fidelidade?',
            text: 'Basta acessar a loja de aplicativos de seu smartphone, baixar o MEU APP de Fidelidade, preencher o cadastro e aproveitar um mundo da vantagens.',
        },
        {
            title: 'Quais os benefícios de usar o MEU APP de Fidelidade?',
            text: 'Usando o MEU APP de Fidelidade em suas compras, você recebe de volta o valor gasto na forma de pontos, que serão acumulados no aplicativo para que, futuramente, você possa trocá-los por prêmios nos estabelecimentos parceiros.',
        },
        {
            title: 'Quantos pontos eu ganho por compra?',
            text: 'A única regra imutável na plataforma é a relação R$1,00 = 1 PONTO.',
        },
        {
            title: 'Quais são os estabelecimentos que aceitam?',
            text: 'São milhares de estabelecimentos parceiros da MEU APP! Basta utilizar o leitor de Qr-Code do aplicativo nas suas notas fiscais de compra. Se o estabelecimento não fizer parte da nossa plataforma, nós o avisaremos que seus clientes o estão procurando.',
        },
        {
            title: 'Como funciona para empresas?',
            text: 'Para as empresas a nossa plataforma é flexível facilitando a criação e a manutenção de todo o tipo de programa de fidelidade.',
        },
        {
            title: 'Posso sacar o saldo disponível no meu aplicativo?',
            text: 'O saldo em pontos, que você carrega pelo MEU APP, não pode ser sacado ou transferido para outros usuários e tampouco convertido em dinheiro.',
        },
        {
            title: 'De quantos programas de fidelidade posso participar?',
            text: 'Tantos quantos você quiser. Basta que o estabelecimento seja um parceiro da MEU APP de Fidelidade. Todos os programas de todas as empresas ficarão listados na tela principal do seu Aplicativo.',
        },
        {
            title: 'Como posso acessar minha conta ou atualizar os meus dados?',
            text: 'O acesso à conta do APP é realizado somente via aplicativo. Informações podem ser editadas, caso ocorra algum erro de cadastro.' +
                'Importante: mantenha seus dados sempre atualizados a fim de evitar o não recebimento de um prêmio pela ausência ou imprecisão dos dados.',
        },
        {
            title: 'Há cobranças durante o uso do aplicativo?',
            text: 'Nenhuma taxa é cobrada aos usuários (Pessoa Físicas) em nosso aplicativo. Esta é mais uma forma de você continuar ganhando e aproveitando todas as promoções de nossos parceiros.',
        },
        {
            title: 'Quais dados são compartilhados com terceiros?',
            text: 'Os dados de todos os usuários estão criptografados em nossa plataforma e somente os dados necessários à emissão de documento fiscal para retirada da premiação serão compartilhados.',
        },
        {
            title: 'Ainda tenho dúvidas, como posso ter mais informações?',
            text: 'Você pode ler os “Termos de Uso” no app MEU APP ou conversar com o nosso time de atendimento acessando o “FALE CONOSCO”  e nos mandando uma mensagem, ou ainda, clicando no ícone do WHATSAPP no menu principal do seu aplicativo MEU APP.”',
        },
    ];

    makeSac() {

        let itens = [];

        this.dataSac.forEach(function (value, index) {
            itens.push(
                <AccordionStyled key={index}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon/>}
                        aria-controls="panel1a-content"
                        id="panel1a-header">
                        <Typography color={'primary'}>
                            {value.title}
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>
                            {value.text}
                        </Typography>
                    </AccordionDetails>
                </AccordionStyled>
            );
        });

        return itens;

    }

    render() {
        return (
            <Box display={'flex'}
                 justifyContent={'center'}
                 alignItems={'center'}
                 flexDirection={'column'}>
                {this.makeSac()}
            </Box>
        );
    }
}

export default ContentSac;