import ApiClient from "./Api/ApiClient";

class SegmentService {
    constructor() {
        this.uri = 'segment-type';
        this.apiClient = new ApiClient();
    }

    async get(id) {
        try {

            let filter = '&filters[0][segment_names_id@equal]=' + id;

            return await this.apiClient.doRequest({
                method: ApiClient.GET,
                uri: this.uri + '?paginate=false&order[name]=asc' + filter,
                auth: false,
                expectedStatus: 200,
            });

        } catch (e) {
            throw e;
        }
    }

}

export default SegmentService;