import React, {Component} from 'react';
import {AppBar, Box, Hidden, Toolbar, Typography} from "@material-ui/core";
import {BoxLogoStyled, ContentHeader, ContentTitleStyled, LogoHeader} from "./styled";
import newLogo from "./../../Assets/new_logo.png"
import DialogComponent from "../DialogComponent/DialogComponent";
import LoadComponent from "../LoadComponent/LoadComponent";
import {Helmet} from "react-helmet";
import MenuHeader from "./MenuHeader";
import MobileMenuHeader from "./MobileMenuHeader/MobileMenuHeader";
import {HeaderContext} from "../../Contexts/HeaderContext";
import PropTypes from 'prop-types';
import {SuperContext} from "../../Contexts/SuperContext";
import ClickAreaComponent from "../ClickAreaComponent/ClickAreaComponent";

class HeaderComponent extends Component {
    static contextType = SuperContext;

    constructor(props) {
        super(props);

        this.state = {
            goToMyPlan: null,
            logout: null,
        };
    }

    toHome = () => {
        window.location.href = 'https://meuappdefidelidade.com.br/';
    }

    componentDidMount() {
        const context = this

        setTimeout(function () {
            context.context.showFooter(!context.props.disableFooter);
            context.context.footerAbsolute(context.props.footerAbsolute);
        }, 1000);
    }

    render() {
        return (
            <HeaderContext.Provider value={{
                parent: this,
                goToMyPlan: this.state.goToMyPlan,
                logout: this.state.logout,
                me: this.context.me,
                isAdmin: this.context.isAdmin,
                fidelity: this.context.fidelity,
            }}>
                <LoadComponent inLoad={this.props.inLoad ?? false}/>
                <Helmet>
                    <title>{this.props.title ?? '??'}</title>
                    {this.props.metaDescription &&
                        <meta name="description" content={this.props.metaDescription}/>
                    }
                </Helmet>
                <ContentHeader>
                    <AppBar>
                        <Toolbar>
                            <Box display={'flex'}
                                 flexDirection={'row'}
                                 width={'100%'}>
                                <BoxLogoStyled display={'flex'}
                                               alignItems="center"
                                               justifyContent="flex-start"
                                               flexGrow={1}>
                                    <ClickAreaComponent>
                                        <LogoHeader src={newLogo} onClick={this.toHome}/>
                                    </ClickAreaComponent>
                                </BoxLogoStyled>
                                {this.props.menu === true &&
                                    <Hidden>
                                        <Box display={'flex'}
                                             alignItems="center"
                                             justifyContent="flex-end">
                                            <MenuHeader/>
                                        </Box>
                                        <MobileMenuHeader/>
                                    </Hidden>
                                }
                            </Box>
                        </Toolbar>
                    </AppBar>
                </ContentHeader>
                {this.props.showTitleInContent === true &&
                    <ContentTitleStyled>
                        <Typography align={'center'}
                                    variant={'h6'}
                                    color={'primary'}>
                            {this.props.title}
                        </Typography>
                    </ContentTitleStyled>
                }
                {this.props.messagens !== null &&
                    <DialogComponent messagens={this.props.messagens ?? null}/>
                }
            </HeaderContext.Provider>
        );
    }
}

HeaderComponent.propTypes = {
    inLoad: PropTypes.bool,
    title: PropTypes.string,
    menu: PropTypes.bool,
    messagens: PropTypes.object,
    showTitleInContent: PropTypes.bool,
    footerAbsolute: PropTypes.bool,
    metaDescription: PropTypes.string,
};

export default HeaderComponent;