import React, {Component} from 'react';
import HeaderComponent from "../../../Components/HeaderComponent/HeaderComponent";
import {Typography} from "@material-ui/core";

import AwardService from "../../../Services/AwardService";
import MessageUtil from "../../../Utils/MessageUtil";
import AwardValidation from "../../../FormValidations/AwardValidation";
import CompanyService from "../../../Services/CompanyService";
import ErrorUtil from "../../../Utils/ErrorUtil";
import {AwardContext} from "../../../Contexts/AwardContext";
import ListNewAwardScreen from "../NewAward/ListNewAwardScreen";
import FormCampaignNameScreen from "./FormCampaignNameScreen";
import GeneralTranslation from "../../../Translations/FormValidations/GeneralTranslation";
import CampaignNameValidation from "../../../FormValidations/CampaignNameValidation";
import CampaignService from "../../../Services/CampaignService";
import ListCampaingScreen from "../NewAward/ListCampaingScreen";
import {CashbackContext} from "../../../Contexts/CashbackContext";

class CampaignNameScreen extends Component {
    static ROUTE = '/campaign-name';

    awardService;
    companyService;
    campaignSercice;

    constructor(props) {
        super(props);

        this.state = {
            inLoad: false,
            erros: [],
            formEdit: {},
            action: 'create',
            actionToHuman: 'cadastrado',
            actionCurrent: 'Registrar Novo Prêmio por Nome',
            isEdit: undefined,
        };

        this.awardService = new AwardService();
        this.companyService = new CompanyService();
        this.campaignSercice = new CampaignService();
    }

    componentDidMount() {
        this.isEdit();
    }

    async isEdit() {

        try {
            if (this.props.match.params.edit === undefined) {
                this.setState({
                    isEdit: false,
                });
                return;
            }

            this.setState({
                inLoad: true,
            });

            const response = await this.campaignSercice.getProductNameCampaign(this.props.match.params.edit);

            this.setState({
                isEdit: true,
                inLoad: false,
                formEdit: response.data.data,
                action: 'edit',
                actionToHuman: 'alterada',
                actionCurrent: 'Alterar Prêmio por Nome',
            });

        } catch (e) {
            this.setState({
                inLoad: false,
                messagens: MessageUtil.make({
                    title: 'Atenção',
                    body: e,
                }),
            });
        }
    }

    doCreate = async (object, skus) => {
        try {
            object.products = this.formatteSkus(skus);
            object.amount = Object.assign({}, object.companies);
            const validate = await CampaignNameValidation.validate(object, this.state.isEdit);

            if (validate !== true) {
                this.setState({
                    erros: validate,
                    messagens: MessageUtil.makeToValidationField(
                        new GeneralTranslation(validate).translate()
                    ),
                });
                return;
            }

            this.setState({
                inLoad: true,
            });

            let action = 'cadastrado';

            if (!this.state.isEdit) {
                await this.campaignSercice.createProductNameCampaign(object);
            }
            if (this.state.isEdit) {
                await this.campaignSercice.editProductNameCampaign(object.uuid, object);
                 action = 'editada';
            }

            this.setState({
                inLoad: false,
                messagens: MessageUtil.make({
                    title: 'Sucesso',
                    body: `Prêmio ${this.state.actionToHuman}, clique em ok para voltar.`,
                    okBtnToRoute: ListCampaingScreen.ROUTE,
                }),
            });

        } catch (e) {
            this.setState({
                inLoad: false,
                messagens: MessageUtil.make({
                    title: 'Atenção',
                    body: e,
                }),
            });
        }
    }

    formatteSkus(sku) {
        const products = [];

        sku.map((value) => {
            if (value.state.sku === undefined || value.state.sku === '') {
                throw ErrorUtil.make('Campo "Combinação de Nomes" invalido, verifique e tente novamente');
            }

            if (value.state.qtd === undefined || value.state.qtd === '') {
                throw ErrorUtil.make('Campo QTD invalido, verifique e tente novamente');
            }

            products.push({
                name: value.state.sku,
                amount: value.state.qtd,
            });
        });

        return products;
    }

    doDelete = async (uuid) => {
        try {
            const message = MessageUtil.make({
                title: 'Atencao',
                body: 'Deseja realmente deletar essa campanha, essa ação não pode ser recuperada.',
                okButtonText: 'Sim',
                okButtonFunction: async () => {
                    this.setState({
                        inLoad: true,
                    });

                    await this.campaignSercice.deleteProductNameCampaign(uuid);

                    this.setState({
                        inLoad: false,
                        messagens: MessageUtil.make({
                            title: 'Sucesso',
                            body: 'Prêmio foi deletada',
                            okBtnToRoute: ListCampaingScreen.ROUTE,
                        }),
                    });

                    return;
                },
                cancelButtonText: 'Não',
                cancelButtonFunction: () => {
                }
            });

            this.setState({
                messagens: message,
            });

        } catch (e) {
            this.setState({
                inLoad: false,
                messagens: MessageUtil.make({
                    title: 'Atenção',
                    body: e,
                }),
            });
        }
    }

    render() {
        return (
            <CashbackContext.Provider value={{
                parent: this,
            }}>
                <HeaderComponent title={'Registrar Novo Prêmio'}
                                 menu={true}
                                 messagens={this.state?.messagens}
                                 inLoad={this.state.inLoad}/>
                <Typography align={'center'}
                            variant={'h6'}
                            color={'primary'}>
                    {this.state.actionCurrent}
                </Typography>
                <br/>
                <FormCampaignNameScreen doCreate={this.doCreate}
                                        erros={this.state.erros}
                                        formEdit={this.state.formEdit}
                                        isEdit={this.state.isEdit}
                                        formData={this.state.formEdit}
                                        doDelete={this.doDelete}/>
            </CashbackContext.Provider>
        );
    }
}

export default CampaignNameScreen;