import React, {Component} from 'react';
import HeaderComponent from "../../../Components/HeaderComponent/HeaderComponent";
import SubHeaderAward from "./SubHeaderAward/SubHeaderAward";
import {Box, Typography} from "@material-ui/core";
import SpacerComponent from "../../../Components/SpacerComponent/SpacerComponent";
import ContentListAward from "./ContentListAward";
import AwardService from "../../../Services/AwardService";
import MessageUtil from "../../../Utils/MessageUtil";
import {AwardContext} from "../../../Contexts/AwardContext";
import CampaignService from "../../../Services/CampaignService";

class ListCampaingScreen extends Component {
    static ROUTE = '/campaigns'

    constructor(props) {
        super(props);

        this.state = {
            awards: [],
            awardsEnabledDisabled: [],
            selectAward: false,
        };

        this.awardService = new AwardService();
        this.campaignService = new CampaignService();
    }

    componentDidMount() {
        this.load();
    }

    async load(filter = '') {
        try {
            this.setState({
                inLoad: true,
            });

            const response = await this.campaignService.get(filter);

            this.setState({
                inLoad: false,
                awards: response.data,
            });
        } catch (e) {
            this.setState({
                inLoad: false,
                messagens: MessageUtil.make({
                    title: 'Error',
                    body: e,
                }),
            });
        }
    }

    doSearch = async (filter) => {
        await this.load(filter)
    };

    async doEnabledDisableAward(uuid, action) {
        try {
            await this.awardService.update(
                uuid,
                {
                    active: action,
                }
            );

        } catch (e) {
            throw new Error();
        }
    }

    setAwardsEnabledDisabled = (uuid, select) => {

        let array = this.state.awardsEnabledDisabled;

        array[uuid] = select;

        this.setState({
            awardsEnabledDisabled: array,
        })
    };

    setEnabledSelectAward = () => {
        this.setState({
            selectAward: !this.state.selectAward,
        })
    }

    doEnableAwardSelect = async () => {

        this.setState({
            inLoad: true,
        })

        const award = this.state.awardsEnabledDisabled;

        if (Object.keys(this.state.awardsEnabledDisabled).length <= 0) {
            this.setState({
                inLoad: false,
                messagens: MessageUtil.make({
                    title: 'Nenhuma alteração realizada',
                    body: 'Não foi selecionado nenhum prêmio para ser ativado',
                    okBtnToRoute: ListCampaingScreen.ROUTE,
                }),
            });
            return;
        }

        let error = false;

        for (let key of Object.keys(this.state.awardsEnabledDisabled)) {

            if (award[key] !== true) {
                continue;
            }

            try {

                await this.doEnabledDisableAward(key, 1);

            } catch (e) {
                error = true;
            }

        }

        if (error) {

            this.setState({
                inLoad: false,
                messagens: MessageUtil.make({
                    title: 'Atenção',
                    body: 'Alguns dos prêmios não foram ativados por estarem fora do período de validade.',
                    okBtnToRoute: ListCampaingScreen.ROUTE,
                }),
            });

            return;

        }


        this.setState({
            inLoad: false,
            messagens: MessageUtil.make({
                title: 'Sucesso',
                body: 'Os prêmios selecionados foram ativados',
                okBtnToRoute: ListCampaingScreen.ROUTE,
            }),
        });

    }

    doDisableAwardSelect = async () => {

        this.setState({
            inLoad: true,
        })

        const award = this.state.awardsEnabledDisabled;

        if (Object.keys(this.state.awardsEnabledDisabled).length <= 0) {
            this.setState({
                inLoad: false,
                messagens: MessageUtil.make({
                    title: 'Nenhuma alteração realizada',
                    body: 'Não foi selecionado nenhum prêmio para ser desativado',
                    okBtnToRoute: ListCampaingScreen.ROUTE,
                }),
            });
            return;
        }

        for (let key of Object.keys(this.state.awardsEnabledDisabled)) {

            if (award[key] !== true) {
                continue;
            }

            await this.doEnabledDisableAward(key, 0);
        }

        this.setState({
            inLoad: false,
            messagens: MessageUtil.make({
                title: 'Sucesso',
                body: 'Os prêmios selecionados foram desativados',
                okBtnToRoute: ListCampaingScreen.ROUTE,
            }),
        });

    }

    render() {
        return (
            <AwardContext.Provider value={{
                doSearch: this.doSearch,
            }}>
                <div>
                    <HeaderComponent title={'Campanhas Salvas'}
                                     menu={true}
                                     messagens={this.state?.messagens}
                                     inLoad={this.state.inLoad}/>
                    <Box display={'flex'}
                         justifyContent={'center'}
                         alignItems={'center'}>
                        <Box width={'80%'}>
                            <SubHeaderAward setEnabledSelectAward={this.setEnabledSelectAward}
                                            /*selectAward={this.state.selectAward}*/
                                            selectAward={'apenas para ocultar'}
                                            removeSelect={true}
                                            doEnableAwardSelect={this.doEnableAwardSelect}
                                            doDisableAwardSelect={this.doDisableAwardSelect}
                                            doSearch={this.doSearch}
                                            campaing={true}/>
                            <SpacerComponent height={40}/>
                            <ContentListAward awards={this.state.awards}
                                              isCampaing={true}
                                              setAwardsEnabledDisabled={this.setAwardsEnabledDisabled}
                                              selectAward={this.state.selectAward}
                                              removeStatus={false}
                                              emptyListText={'Você ainda não cadastrou nenhuma campanha.'}/>
                        </Box>
                    </Box>
                    <SpacerComponent height={40}/>
                </div>
            </AwardContext.Provider>
        );
    }
}

export default ListCampaingScreen;