import React, {Component} from 'react';
import {Box, IconButton, Link, Typography} from "@material-ui/core";
import {CloseButtonMenuMobileStyled, ContentMenuMobileStyled, IconMenuMobileStyled} from "../styled";
import CloseIcon from "@material-ui/icons/Close";
import {HeaderContext} from "../../../Contexts/HeaderContext";
import CommonMobileMenuHeader from "./CommonMobileMenuHeader";
import AdminMobileMenuHeader from "./AdminMobileMenuHeader";


class MobileMenuHeader extends Component {
    static contextType = HeaderContext;

    constructor(props) {
        super(props);

        this.state = {
            openMenu: false,
        };
    }

    openCloseMenu = () => {
        this.setState({
            openMenu: !this.state.openMenu,
        });
    }

    render() {
        if (this.context.me === null) {
            return <></>;
        }
        return (
            <Box display={{
                xs: 'block',
                md: 'none',
                lg: 'none',
                xl: 'none',
            }}>
                <ContentMenuMobileStyled openClose={this.state.openMenu}>
                    <CloseButtonMenuMobileStyled onClick={this.openCloseMenu}>
                        <CloseIcon/>
                    </CloseButtonMenuMobileStyled>
                    <Typography variant={'h5'}>
                        Menus
                    </Typography>
                    {this.context.isAdmin &&
                    <AdminMobileMenuHeader/>
                    }
                    {!this.context.isAdmin &&
                    <CommonMobileMenuHeader/>
                    }
                </ContentMenuMobileStyled>
                <IconButton onClick={this.openCloseMenu}>
                    <IconMenuMobileStyled/>
                </IconButton>
            </Box>
        );
    }
}

export default MobileMenuHeader;