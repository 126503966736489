import React, {Component} from 'react';
import HeaderComponent from "../../../Components/HeaderComponent/HeaderComponent";
import {Button, Checkbox, Typography} from "@material-ui/core";
import {ContentButtonStyled, ContentMainStyled, ContentStyled, ContentTopicsStyled} from "./styled";
import SpacerComponent from "../../../Components/SpacerComponent/SpacerComponent";
import ContactUsScreen from "../ContactUsScreen/ContactUsScreen";
import {TermStyled} from "../PaymentScreen/styled";
import MyAccountScreen from "../MyAccountScreen/MyAccountScreen";
import MessageUtil from "../../../Utils/MessageUtil";
import {SuperContext} from "../../../Contexts/SuperContext";
import PlanService from "../../../Services/PlanService";

class CancellationPlanScreen extends Component {
    static ROUTE = '/cancellation-plan';
    static contextType = SuperContext;

    constructor(props) {
        super(props);

        this.state = {

            inLoad: false,
            term_card: false,

        };

        this.planService = new PlanService();
    }

    handleChangeTerm = () => {
        this.setState({
            term_card: !this.state.term_card,
        });
    }

    doCancel = async () => {
        try {

            this.setState({
                inLoad: true,
            });

            await this.planService.cancel();

            this.setState({
                inLoad: false,
                messagens: MessageUtil.make({
                    title: 'Atenção',
                    body: 'Plano será cancelado',
                    okBtnToRoute: MyAccountScreen.ROUTE,
                }),
            });

        } catch (e) {
            this.setState({
                inLoad: false,
                messagens: MessageUtil.make({
                    title: 'Atenção',
                    body: e,
                }),
            });
        }
    }

    render() {
        return (
            <>
                <HeaderComponent title={'Cancelamento da assinatura'}
                                 menu={true}
                                 messagens={this.state?.messagens}
                                 inLoad={this.state.inLoad}/>
                <SpacerComponent height={20}/>
                <ContentStyled>
                    <ContentMainStyled>
                        <Typography variant={'h5'}
                                    color={'primary'}
                                    align={'center'}>
                            Tem certeza que quer cancelar sua assinatura?
                        </Typography>
                        <SpacerComponent height={40}/>
                        <Typography variant={'subtitle1'}>
                            Ao cancelar sua assinatura você retornará ao plano free. Seu perfil passará por estas
                            alterações:
                        </Typography>
                        <SpacerComponent height={20}/>
                        <ContentTopicsStyled>
                            <Typography variant={'subtitle1'}>
                                * Apenas os primeiros 70 clientes cadastrados no seu programa de fidelidade poderão
                                acumular novos pontos. Todos os clientes que entraram depois dos 70 primeiros ainda
                                poderão usar seus pontos, reservando e adquirindo prêmios, porém não poderão adquirir
                                novos pontos.
                            </Typography>
                            <Typography variant={'subtitle1'}
                                        color={'error'}>
                                <b>
                                    * O cancelamento passa a vigorar ao fim deste ciclo de
                                    cobrança: {this.context?.myPlan?.next_billing_date}.
                                </b>
                            </Typography>
                            <SpacerComponent height={10}/>
                        </ContentTopicsStyled>
                        <Typography variant={'subtitle1'}>
                            Caso queira alterar seu plano para um plano inferior, basta entrar em contato com a nossa
                            equipe pelo <a href={ContactUsScreen.ROUTE}>Fale Conosco.</a>.
                        </Typography>
                        <SpacerComponent height={40}/>
                        <TermStyled
                            control={
                                <Checkbox checked={this.state.term_card}
                                          onChange={this.handleChangeTerm}
                                          name={'term_card'}/>
                            }
                            label={'Estou ciente das alterações que ocorrerão com o meu perfil ao cancelar o meu plano.'}/>
                        <SpacerComponent height={40}/>
                        <ContentButtonStyled>
                            <Button color={'primary'}
                                    variant={'contained'}
                                    onClick={() => {
                                        window.location.href = MyAccountScreen.ROUTE;
                                    }}>
                                Voltar
                            </Button>
                            <SpacerComponent width={40}/>
                            <Button variant={'contained'}
                                    color={'primary'}
                                    disabled={!this.state.term_card}
                                    onClick={this.doCancel}>
                                Quero Cancelar
                            </Button>
                        </ContentButtonStyled>
                        <SpacerComponent height={20}/>
                    </ContentMainStyled>
                </ContentStyled>
            </>
        );
    }
}

export default CancellationPlanScreen;