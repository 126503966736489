import React, {Component} from 'react';
import HeaderComponent from "../../../Components/HeaderComponent/HeaderComponent";
import {Typography} from "@material-ui/core";

import AwardService from "../../../Services/AwardService";
import MessageUtil from "../../../Utils/MessageUtil";
import AwardValidation from "../../../FormValidations/AwardValidation";
import CompanyService from "../../../Services/CompanyService";
import ErrorUtil from "../../../Utils/ErrorUtil";
import {AwardContext} from "../../../Contexts/AwardContext";
import ListNewAwardScreen from "../NewAward/ListNewAwardScreen";
import FormNewAwardScreen from "./FormNewAwardScreen";
import InstantAwardValidation from "../../../FormValidations/InstantAwardValidation";
import GeneralTranslation from "../../../Translations/FormValidations/GeneralTranslation";

class InstantNewAward extends Component {
    static ROUTE = '/instant-award';

    awardService;
    companyService;

    constructor(props) {
        super(props);

        this.state = {
            inLoad: false,
            erros: [],
            formEdit: {},
            action: 'create',
            actionCurrent: 'Cadastrar novo prêmio instantâneo',
            isEdit: undefined,
        };

        this.awardService = new AwardService();
        this.companyService = new CompanyService();
    }

    componentDidMount() {
        this.isEdit();
    }

    async isEdit() {

        try {
            if (this.props.match.params.edit === undefined) {
                this.setState({
                    isEdit: false,
                });
                return;
            }

            this.setState({
                inLoad: true,
            });

            const response = await this.awardService.show(this.props.match.params.edit);

            this.setState({
                isEdit: true,
                inLoad: false,
                formEdit: response.data.data,
                action: 'edit',
                actionCurrent: 'Alterar Prêmio ' + response.data.data.title,
            });

        } catch (e) {
            this.setState({
                inLoad: false,
                messagens: MessageUtil.make({
                    title: 'Atenção',
                    body: e,
                }),
            });
        }
    }

    doCreate = async (object, skus) => {
        try {
            object.products = this.formatteSkus(skus);
            const validate = await InstantAwardValidation.validate(object, this.state.isEdit);

            if (validate !== true) {
                this.setState({
                    erros: validate,
                    messagens: MessageUtil.makeToValidationField(
                        new GeneralTranslation(validate).translate()
                    ),
                });
                return;
            }

            object.products = this.formatteSkus(skus);

            this.setState({
                inLoad: true,
            });

            await this.verifyCanCreate(object);
            object.amount = this.verrifyAmountBranch(object);
            this.verifyDataAmounBranch(object);

            let response;
            if (this.state.action === 'create') {
                object.active = 1;
                response = await this.awardService.create(object);
            }

            if (this.state.action === 'edit') {

                if (object.withoutValidDate) {
                    object.start_validity = null;
                    object.end_validity = null;
                }

                response = await this.awardService.update(this.state.formEdit.uuid, object);
            }

            object.companies = Object.assign({}, object.companies);
            await this.awardService.amountByCompanies(
                object,
                response.data.data.uuid
            );


            this.setState({
                inLoad: false,
                messagens: MessageUtil.make({
                    title: 'Sucesso',
                    body: 'Prêmio cadastrado clique em ok para voltar.',
                    okBtnToRoute: ListNewAwardScreen.ROUTE,
                }),
            });

        } catch (e) {
            this.setState({
                inLoad: false,
                messagens: MessageUtil.make({
                    title: 'Atenção',
                    body: e,
                }),
            });
        }
    }

    formatteSkus(sku) {
        const products = [];

        sku.map((value) => {
            if (value.state.sku === undefined || value.state.sku === '') {
                throw ErrorUtil.make('Campo SKU invalido, verifique e tente novamente');
            }

            if (value.state.qtd === undefined || value.state.qtd === '') {
                throw ErrorUtil.make('Campo QTD invalido, verifique e tente novamente');
            }

            products.push({
                code: value.state.sku,
                amount: value.state.qtd,
            });
        });

        return products;
    }

    async verifyCanCreate(object) {
        try {

            const response = await this.companyService.getByFidelity(object.fidelity_programs_uuid);
            if (response.data.data.length <= 0) {
                throw ErrorUtil.make('Não é possível criar um prêmio para um programa de fidelidade sem ' +
                    'estabelecimentos associado');
            }

        } catch (e) {
            throw e;
        }
    }

    verrifyAmountBranch(object) {
        const companies = object.companies;

        let companiesTotal = 0;

        for (let key of Object.keys(object.companies)) {
            companiesTotal = companiesTotal + parseInt(companies[key]);
        }

        return companiesTotal;

    }

    verifyDataAmounBranch(object) {
        if (Object.keys(object.companies).length <= 0) {
            throw ErrorUtil.make(
                'Por favor defina a quantidade do prêmio para pelo menos um estabelecimento'
            );
        }

        Object.keys(object.companies).forEach(function (value) {

            if (object.companies[value] === '') {
                throw ErrorUtil.make(
                    'Os estabelecimentos selecionados não podem ter o campo "Qtd. por filial" vazio'
                );
            }

        });
    }

    confirmDelete = () => {
        this.setState({
            messagens: MessageUtil.make({
                title: 'Atenção',
                body: 'Tem certeza que deseja deletar esse prêmio? Essa ação não poderá ser desfeita.',
                okButtonText: 'Sim',
                okButtonFunction: this.doDelete,
                cancelButtonText: 'Não',
            }),
        },);
    }

    doDelete = async () => {
        try {
            this.setState({
                inLoad: true,
            });

            const result = await this.awardService.deleteConfirm(this.state.formEdit.uuid);

            if (!result.data.result) {
                this.setState({
                    inLoad: false,
                    messagens: MessageUtil.make({
                        title: 'Atenção',
                        body: result.data.message,
                    }),
                });
                return;
            }

            await this.awardService.delete(this.state.formEdit.uuid);

            this.setState({
                inLoad: false,
                messagens: MessageUtil.make({
                    title: 'Sucesso',
                    body: 'Prêmio deletado, clique em ok para voltar.',
                    okBtnToRoute: ListNewAwardScreen.ROUTE,
                }),
            });
        } catch (e) {
            this.setState({
                inLoad: false,
                messagens: MessageUtil.make({
                    title: 'Atenção',
                    body: e,
                }),
            });
        }
    }

    render() {
        return (
            <AwardContext.Provider value={{
                isEdit: this.state.isEdit,
            }}>
                <HeaderComponent title={'Novo Prêmio'}
                                 menu={true}
                                 messagens={this.state?.messagens}
                                 inLoad={this.state.inLoad}/>
                <Typography align={'center'}
                            variant={'h6'}
                            color={'primary'}>
                    {this.state.actionCurrent}
                </Typography>
                <br/>
                <FormNewAwardScreen doCreate={this.doCreate}
                                    confirmDelete={this.confirmDelete}
                                    erros={this.state.erros}
                                    formEdit={this.state.formEdit}
                                    isEdit={this.state.isEdit}
                                    formData={this.state.formEdit}/>
            </AwardContext.Provider>
        );
    }
}

export default InstantNewAward;