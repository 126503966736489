import React, {Component} from 'react';
import {Box, IconButton, Typography} from "@material-ui/core";
import {ContentSegmentStyled} from "./styled";
import SpacerComponent from "../../../Components/SpacerComponent/SpacerComponent";
import EditIcon from '@material-ui/icons/Edit';

class SegmentShowEditCompanyRegistration extends Component {
    render() {

        if (this.props.isEdit === false) {
            return (<div></div>);
        }

        return (
            <div>
                <Box display={'flex'}
                     justifyContent={'flex-start'}
                     alignItems={'center'}>
                    <Typography variant={'body1'}
                                color={'primary'}>
                        Segmento escolhido
                    </Typography>
                    <IconButton onClick={() => this.props.doEditSegment()}>
                        <EditIcon fontSize={'small'}
                                  color={'primary'}/>
                    </IconButton>
                </Box>
                <ContentSegmentStyled variant={'outlined'}
                                      color={'primary'}>
                    <Typography variant={'body2'}>
                        Área de atuação: <b>{this.props.value?.segment_type?.segment_name?.name}</b>
                    </Typography>
                    <SpacerComponent height={10}/>
                    <Typography variant={'body2'}>
                        Segmento: <b>{this.props.value?.segment_type?.name}</b>
                    </Typography>
                </ContentSegmentStyled>
            </div>
        );
    }
}

export default SegmentShowEditCompanyRegistration;