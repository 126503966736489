import React, {Component} from 'react';
import SpacerComponent from "../SpacerComponent/SpacerComponent";
import InputFileDefault from "../InputFileDefault/InputFileDefault";
import {Box, Typography} from "@material-ui/core";
import TextFieldDefault from "../TextFieldDefaultComponent/TextFieldDefault";
import {CheckIconStyled, CloseIconStyled, ContentMainCertificate, TextInfoCertificateStyled} from "./styled";

class CertificateFormComponent extends Component {
    render() {
        return (
            <ContentMainCertificate>
                <Typography variant={'body1'}
                            color={'primary'}
                            align={'left'}>
                    Certificado Digital
                </Typography>
                <SpacerComponent height={20}/>
                <Box display={'flex'}
                     flexDirection={'row'}
                     alignItems={'flex-start'}
                     justifyContent={'flex-start'}>
                    <InputFileDefault setPreviosImage={this.props.setPreviosImageCertificate}
                                      toBase64Image={this.props.toBase64Certificate}
                                      accept={'.pfx'}
                                      compression={false}/>
                </Box>
                <TextFieldDefault label={'Senha Certificado Digital'}
                                  name={'certificate_password'}
                                  type={'password'}
                                  value={this.props.certificate_password}
                                  onChange={(event) => this.props.handleChange(event)}
                                  erros={this.props.erros}/>
                <SpacerComponent height={20}/>
                <Box display={'flex'}
                     flexDirection={'row'}
                     alignItems={'center'}
                     justifyContent={'flex-start'}>
                    {this.props.setCertificate &&
                    <>
                        <CheckIconStyled/>
                        <SpacerComponent width={10}/>
                        <TextInfoCertificateStyled variant={'body2'}
                                                   set={this.props.setCertificate}>
                            Certificado Digital foi inserido
                        </TextInfoCertificateStyled>
                    </>
                    }
                    {!this.props.setCertificate &&
                    <>
                        <CloseIconStyled/>
                        <SpacerComponent width={10}/>
                        <TextInfoCertificateStyled variant={'body2'}
                                                   set={this.props.setCertificate}>
                            Certificado Digital não foi enviado
                        </TextInfoCertificateStyled>
                    </>
                    }
                </Box>
            </ContentMainCertificate>
        );
    }
}

export default CertificateFormComponent;