import styled from "styled-components";
import {Button, Paper} from "@material-ui/core";

export const ContentMainStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;    
`;

export const ContentSizeStyled = styled.div`  
  width: 40%;    
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export const ButtonReleaseStyled = styled(Button)`  
  background-color: orangered;
`;

export const ImgAwardReleaseStyled = styled.img`  
  width: 300px;
  height: 300px;
  border-radius: 10px;
  object-fit: cover;
`;

export const ContentCNPJBlock = styled.div`
  display: flex;
  justify-content: center;
  justify-items: center;
  align-items: center;
`;

export const ContentTitleCNPJBlock = styled.div`
  display: flex;
  justify-content: left;
  justify-items: start;
  align-items: start;
`;

export const CardCheckPointStyled = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.46);
  z-index: 9;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export const ContentCardCheckPointStyled = styled(Paper)`  
  width: 70%;
  height: 500px;
  overflow-y: auto;
  padding: 20px;
`;

export const ItemCheckStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: start;
  padding-left: 50px;
  padding-top: 20px;
  padding-bottom: 20px;
  border-bottom: black 1px solid;
`;


