import React, {Component} from 'react';
import {ContentMainStyled, ContentSizeStyled} from "./styled";
import TextFieldDefault from "../../../Components/TextFieldDefaultComponent/TextFieldDefault";
import FormMask from "../../../FromMask/FormMask";
import SpacerComponent from "../../../Components/SpacerComponent/SpacerComponent";
import {Box, Button, MenuItem, Typography} from "@material-ui/core";
import AddressUtil from "../../../Utils/AddressUtil";
import SegmentShowEditCompanyRegistration from "../CompanyRegistrationScreen/SegmentShowEditCompanyRegistration";
import ApiImageUtil from "../../../Utils/ApiImageUtil";
import AvatarComponent from "../../../Components/AvatarComponent/AvatarComponent";
import InputFileDefault from "../../../Components/InputFileDefault/InputFileDefault";
import FidelityService from "../../../Services/FidelityService";
import CertificateFormComponent from "../../../Components/CertificateFormComponent/CertificateFormComponent";

class EditFormEstablishment extends Component {

    fidelityService;

    constructor(props) {
        super(props);

        this.state = {

            state: '',
            segment_name_aux: null,
            segment_type_aux: null,
            btnAlterDisabled: true,
            editSegment: false,
            fidelity: [],
            setCertificate: false,

        };

        this.fidelityService = new FidelityService();

    }

    componentDidMount() {
        this.load();
    }

    async load() {
        try {

            const response = await this.fidelityService.getByUser();

            this.setState({
                fidelity: response.data.data
            });

        } catch (e) {
            throw e;
        }
    }

    async handlerSubmit() {
        this.props.doCreate(this.state);
    }

    async handlerSubmitEdit() {
        this.props.doEdit(this.state);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.formEdit === prevProps.formEdit) {
            return;
        }
        this.setFormEdit();
    }

    async setFormEdit() {
        let newState = Object.assign(this.state, this.props.formEdit);
        newState.imagePrevios = ApiImageUtil.tryGet(this.props.formEdit.logo);
        newState.fidelity_program = this.props.formEdit.fidelity_program.uuid;
        this.setState(newState);
    }

    handleChange = (event) => {
        const {name, value} = event.target;
        this.setAddressByCEP(name, value);
        this.setState({
            [name]: value,
            btnAlterDisabled: false,
        });
        if (name === 'segment_name') {
            this.props.getSegmentBySegmentName(value);
        }
    }

    async setAddressByCEP(inputName, value) {
        if (inputName !== 'zip_code') {
            return;
        }

        if (value.length !== 8) {
            return;
        }

        const addressUtil = await AddressUtil.translateZipCode(value);

        this.setState({
            'state': addressUtil.state,
            'city': addressUtil.city,
            'neighborhood': addressUtil.neighborhood,
            'address': addressUtil.streetName,
            'complement': addressUtil.complement,
            btnAlterDisabled: false,
        });
    }

    setPreviosImage = (image) => {
        this.setState({
            imagePrevios: image,
        });
    }

    toBase64Image = (base64) => {
        this.setState({
            'logo_base64': base64.split(',')[1],
            btnAlterDisabled: false,
        });
    }


    makeItensSelect() {
        let itens = [];
        for (let [key, value] of Object.entries(AddressUtil.states)) {
            itens.push(
                <MenuItem key={key} value={value}>{value}</MenuItem>
            );
        }
        return itens;
    }

    makeSegmentName() {
        let itens = [];
        this.props.segmentName.forEach(function (value, index) {
            itens.push(
                <MenuItem key={index} value={value.id}>{value.name}</MenuItem>
            );
        })

        return itens;
    }

    makeSegment() {
        let itens = [];
        this.props.segment.forEach(function (value, index) {
            itens.push(
                <MenuItem key={index} value={value.uuid}>{value.name}</MenuItem>
            );
        })

        return itens;
    }

    showOurNotSegment() {

        if (this.props.isEdit === false) {
            return true;
        }

        if (this.state.editSegment === true) {
            return true;
        }
    }

    doEditSegment = () => {
        this.setState({
            editSegment: !this.state.editSegment,
        })
    }

    renderSelect() {
        if (this.props.isEdit === null) {
            return <div></div>;
        }

        if (this.props.isEdit === false) {
            return this.componentSelect();
        }

        if (this.props.isEdit === true && this.state.fidelity_program === undefined) {
            return <div></div>;
        }

        return this.componentSelect();

    }

    componentSelect() {
        return (
            <TextFieldDefault select={true}
                              name={'fidelity_program'}
                              value={this.state.fidelity_program}
                              onChange={(event) => this.handleChange(event)}
                              erros={this.props.erros}>
                {this.makeFidelity()}
            </TextFieldDefault>
        );
    }

    makeFidelity() {
        let itens = [];
        this.state.fidelity.forEach(function (value, index) {
            itens.push(
                <MenuItem key={index} value={value.uuid}>{value.name}</MenuItem>
            );
        })

        return itens;
    }

    setPreviosImageCertificate = (image) => {
        this.setState({
            setCertificate: true,
        });
    }

    toBase64Certificate = (base64) => {
        this.setState({
            'certificate_file': base64.split(',')[1],
            btnAlterDisabled: false,
        });
    }

    render() {
        return (
            <div>
                <ContentMainStyled>
                    <ContentSizeStyled>
                        <TextFieldDefault label={'CNPJ *'}
                                          name={'cnpj'}
                                          onChange={(event) => this.handleChange(event)}
                                          mask={FormMask.cnpj}
                                          forceLabel={true}
                                          erros={this.props.erros}
                                          value={this.state.cnpj}
                                          disabled={this.props.isEdit}/>
                        <TextFieldDefault label={'Nome Fantasia *'}
                                          name={'fantasy_name'}
                                          onChange={(event) => this.handleChange(event)}
                                          erros={this.props.erros}
                                          value={this.state.fantasy_name}/>
                        <TextFieldDefault label={'Razão social *'}
                                          name={'social_reason'}
                                          onChange={(event) => this.handleChange(event)}
                                          erros={this.props.erros}
                                          value={this.state.social_reason}/>
                        <SegmentShowEditCompanyRegistration value={this.state}
                                                            isEdit={this.props.isEdit}
                                                            doEditSegment={this.doEditSegment}/>
                        {this.showOurNotSegment() &&
                        <div>
                            <TextFieldDefault label={'Área de atuação'}
                                              name={'segment_name'}
                                              onChange={(event) => this.handleChange(event)}
                                              select={true}
                                              erros={this.props.erros}>
                                {this.makeSegmentName()}
                            </TextFieldDefault>
                            {this.props.segment.length > 0 &&
                            <TextFieldDefault label={'Segmento'}
                                              name={'segment_type_uuid'}
                                              select={true}
                                              onChange={(event) => this.handleChange(event)}
                                              erros={this.props.erros}>
                                {this.makeSegment()}
                            </TextFieldDefault>
                            }
                        </div>
                        }
                        <SpacerComponent height={10}/>
                        <Typography variant={'body1'}
                                    color={'primary'}>
                            Programa de Fidelidade
                        </Typography>
                        {this.renderSelect()}
                        <SpacerComponent height={20}/>
                        <Typography variant={'body1'}
                                    color={'primary'}>
                            Logo do estabelecimento
                        </Typography>
                        <SpacerComponent height={10}/>
                        <Box display={'flex'}
                             flexDirection={'row'}
                             alignItems={'center'}>
                            <AvatarComponent
                                src={this.state.imagePrevios}/>
                            <SpacerComponent width={20}/>
                            <InputFileDefault setPreviosImage={this.setPreviosImage}
                                              toBase64Image={this.toBase64Image}/>
                        </Box>
                        <SpacerComponent height={10}/>
                        <Box display={'flex'}
                             width={'100%'}>
                            <TextFieldDefault label={'Telefone *'}
                                              name={'telephone'}
                                              onChange={(event) => this.handleChange(event)}
                                              phone={true}
                                              erros={this.props.erros}
                                              forceLabel={true}
                                              value={this.state.telephone}/>
                            <SpacerComponent width={40}/>
                            <TextFieldDefault label={'CEP *'}
                                              name={'zip_code'}
                                              onChange={(event) => this.handleChange(event)}
                                              erros={this.props.erros}
                                              value={this.state.zip_code}/>
                        </Box>
                        <TextFieldDefault label={'Endereço *'}
                                          name={'address'}
                                          onChange={(event) => this.handleChange(event)}
                                          erros={this.props.erros}
                                          value={this.state.address}/>
                        <Box display={'flex'}
                             width={'100%'}>
                            <TextFieldDefault label={'Número *'}
                                              name={'number'}
                                              onChange={(event) => this.handleChange(event)}
                                              erros={this.props.erros}
                                              value={this.state.number}/>
                            <SpacerComponent width={40}/>
                            <TextFieldDefault label={'Complemento'}
                                              name={'complement'}
                                              onChange={(event) => this.handleChange(event)}
                                              erros={this.props.erros}
                                              value={this.state.complement}/>
                        </Box>
                        <TextFieldDefault label={'Bairro *'}
                                          name={'neighborhood'}
                                          value={this.state.neighborhood}
                                          onChange={(event) => this.handleChange(event)}
                                          erros={this.props.erros}/>
                        <Box display={'flex'}
                             width={'100%'}>
                            <TextFieldDefault label={'Cidade *'}
                                              name={'city'}
                                              value={this.state.city}
                                              onChange={(event) => this.handleChange(event)}
                                              erros={this.props.erros}/>
                            <SpacerComponent width={40}/>
                            <TextFieldDefault label={'Estado *'}
                                              select={true}
                                              name={'state'}
                                              value={this.state.state}
                                              onChange={(event) => this.handleChange(event)}
                                              erros={this.props.erros}>
                                {this.makeItensSelect()}
                            </TextFieldDefault>
                        </Box>
                        <SpacerComponent height={20}/>
                        <CertificateFormComponent certificate_password={this.state.certificate_password}
                                                  handleChange={this.handleChange}
                                                  erros={this.props.erros}
                                                  setPreviosImageCertificate={this.setPreviosImageCertificate}
                                                  toBase64Certificate={this.toBase64Certificate}
                                                  setCertificate={this.state.setCertificate}/>
                        <SpacerComponent height={40}/>
                        <Box display="flex" justifyContent="center">
                            {this.props.isEdit === false &&
                            <Button variant={'contained'}
                                    color={'primary'}
                                    size={"large"}
                                    fullWidth={true}
                                    onClick={() => this.handlerSubmit()}>
                                Salvar
                            </Button>
                            }
                            {this.props.isEdit === true &&
                            <Button variant={'contained'}
                                    color={'primary'}
                                    size={"large"}
                                    fullWidth={true}
                                    onClick={() => this.handlerSubmitEdit()}
                                    disabled={this.state.btnAlterDisabled}>
                                Salvar
                            </Button>
                            }
                        </Box>
                    </ContentSizeStyled>
                </ContentMainStyled>
            </div>
        );
    }
}

export default EditFormEstablishment;