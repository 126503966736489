import React, {Component} from 'react';
import DataTable from 'react-data-table-component';

class TableCompanyNotRegisteredScreen extends Component {
    constructor(props) {
        super(props);
    }

    makeColumns() {
        return [
            {
                name: 'CNPJ',
                selector: row => row.cnpj,
                sortable: true,
            },
            {
                name: 'Total escaneado',
                selector: row => row.scanned_invoices_count,
                sortable: true,
            },
        ];
    }

    makeItens() {
        const itens = [];
        this.props.data.map((value) => {

            itens.push({
                cnpj: value.cnpj,
                scanned_invoices_count: value.scanned_invoices_count,
            });
        });

        return itens;
    }

    render() {
        return (
            <DataTable columns={this.makeColumns()}
                       data={this.makeItens()}
                       fixedHeader={true}
                       fixedHeaderScrollHeight="500px"/>
        );
    }
}

export default TableCompanyNotRegisteredScreen;