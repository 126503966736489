import styled from "styled-components";
import {Typography} from "@material-ui/core";
import CheckIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/Close";

export const ContentMainCertificate = styled.div`
  width: 100%;
`;

export const ContentTextFieldStyled = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;
