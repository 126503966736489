import React, {Component} from 'react';
import {ContentMainStyled, ContentSizeStyled} from "./styled";
import TextFieldDefault from "../../../Components/TextFieldDefaultComponent/TextFieldDefault";
import {Box, Button, MenuItem} from "@material-ui/core";
import SpacerComponent from "../../../Components/SpacerComponent/SpacerComponent";
import AddressUtil from "../../../Utils/AddressUtil";

const SECTORS = [
    'FINANCEIRO',
    'SUPORTE',
    'JURÍDICO',
    'COMPLIANCE',
];

class FormContactUs extends Component {

    constructor(props) {
        super(props);

        this.state = {};
    }

    async handlerSubmit() {
        this.props.doCreate(this.state);
    }

    handleChange(event) {
        const {name, value} = event.target;
        this.setState({[name]: value});
    }

    makeItensSelect() {
        let itens = [];
        for (let [key, value] of Object.entries(SECTORS)) {
            itens.push(
                <MenuItem key={key} value={value}>{value}</MenuItem>
            );
        }
        return itens;
    }

    render() {
        return (
            <ContentMainStyled>
                <ContentSizeStyled>
                    <TextFieldDefault label={'Setor *'}
                                      select={true}
                                      name={'sector'}
                                      value={this.state.sector}
                                      onChange={(event) => this.handleChange(event)}
                                      erros={this.props.erros}>
                        {this.makeItensSelect()}
                    </TextFieldDefault>
                    <TextFieldDefault label={'Assunto *'}
                                      name={'subject'}
                                      onChange={(event) => this.handleChange(event)}
                                      erros={this.props.erros}/>
                    <TextFieldDefault label={'Mensagem *'}
                                      name={'message'}
                                      onChange={(event) => this.handleChange(event)}
                                      multiline={true}
                                      rows={10}
                                      erros={this.props.erros}/>
                    <SpacerComponent height={40}/>
                    <Box display={'flex'}
                         justifyContent={'center'}
                         alignItems={'center'}>
                        <Button variant={'contained'}
                                color={'primary'}
                                onClick={() => this.handlerSubmit()}>
                            Enviar
                        </Button>
                    </Box>
                </ContentSizeStyled>
            </ContentMainStyled>
        );
    }
}

export default FormContactUs;