import ApiClient from "./Api/ApiClient";
import MeUtil from "../Utils/MeUtil";

class CompanyService {
    constructor() {
        this.uri = 'company';
        this.apiClient = new ApiClient();
    }

    async get(withProgram = false) {
        try {

            const user = await MeUtil.get();

            let filter = '?filters[][matrix@equal]=' + user.data.data.company.id;
            if (withProgram === true) {
                filter += '&filters[][fidelity_program.id@equal]=';
            }

            filter += '&paginate=false&order[fantasy_name]=asc';

            return await this.apiClient.doRequest({
                method: ApiClient.GET,
                uri: this.uri + filter,
                expectedStatus: 200,
            });

        } catch (e) {
            throw e;
        }
    }

    async getFutureCompany() {
        try {
            return await this.apiClient.doRequest({
                method: ApiClient.GET,
                uri: 'future-companies?paginate=false',
                expectedStatus: 200,
            });
        } catch (e) {
            throw e;
        }
    }

    async getByMatrix() {
        try {

            const user = await MeUtil.get();
            const companyUuid = user.data.data.company.uuid;

            return await this.apiClient.doRequest({
                method: ApiClient.GET,
                uri: "company/get-by-matrix/" + companyUuid,
                expectedStatus: 200,
            });

        } catch (e) {
            throw e;
        }
    }

    async getByFidelity(uuid) {
        try {

            let filter = '?filters[][fidelity_program.uuid@equal]=' + uuid;
            filter += '&paginate=false';

            return await this.apiClient.doRequest({
                method: ApiClient.GET,
                uri: this.uri + filter,
                expectedStatus: 200,
            });

        } catch (e) {
            throw e;
        }
    }

    async create(object) {
        try {

            return await this.apiClient.doRequest({
                method: ApiClient.POST,
                uri: this.uri,
                expectedStatus: 201,
                auth: false,
                body: object,
            });

        } catch (e) {
            throw e;
        }
    }

    async update(uuid, object) {
        try {

            return await this.apiClient.doRequest({
                method: ApiClient.PATCH,
                uri: this.uri + '/' + uuid,
                expectedStatus: 201,
                body: object,
            });

        } catch (e) {
            throw e;
        }
    }

    async delete(uuid) {
        try {

            return await this.apiClient.doRequest({
                method: ApiClient.DELETE,
                uri: this.uri + '/' + uuid,
                expectedStatus: 201,
            });

        } catch (e) {
            throw e;
        }
    }

    async getBranch(fidelityUuid = null) {
        try {

            let filter = '';

            if (fidelityUuid !== null) {
                filter = '?filters[0][fidelity_program.uuid@equal]=' + fidelityUuid;
            }

            return await this.apiClient.doRequest({
                method: ApiClient.GET,
                uri: this.uri + filter,
                expectedStatus: 200,
            });

        } catch (e) {
            throw e;
        }
    }

    async searchByUuid(uuid) {
        try {

            return await this.apiClient.doRequest({
                method: ApiClient.GET,
                uri: this.uri + '/' + uuid,
                expectedStatus: 200,
            });

        } catch (e) {
            throw e;
        }
    }

    async attachCompanies(uuid, companies) {
        try {

            return await this.apiClient.doRequest({
                method: ApiClient.POST,
                uri: 'fidelity-program/attach-companies/' + uuid,
                expectedStatus: 200,
                body: {
                    companies: companies,
                },
            });

        } catch (e) {
            throw e;
        }
    }

    async getToken() {
        try {
            return await this.apiClient.doRequest({
                method: ApiClient.GET,
                uri: 'company/api-token/get',
                expectedStatus: 200,
            });

        } catch (e) {
            throw e;
        }
    }

    async refreshToken() {
        try {
            return await this.apiClient.doRequest({
                method: ApiClient.GET,
                uri: 'company/api-token/refresh',
                expectedStatus: 200,
            });

        } catch (e) {
            throw e;
        }
    }

    async changeModelFlag(uuid) {
        try {
            return await this.apiClient.doRequest({
                method: ApiClient.POST,
                uri: `company/change-model-flag/${uuid}`,
                expectedStatus: 200,
            });

        } catch (e) {
            throw e;
        }
    }
}

export default CompanyService;