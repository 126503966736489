import React, {Component} from 'react';
import HeaderComponent from "../../../Components/HeaderComponent/HeaderComponent";
import {
    ContentMainStyled,
    ContentPaymentMobileStyled,
    ContentPaymentStyled,
} from "./styled";
import SpacerComponent from "../../../Components/SpacerComponent/SpacerComponent";
import ShowPlanPaymentScreen from "./ShowPlanPaymentScreen";
import FormPaymentScreen from "./FormPaymentScreen";
import MessageUtil from "../../../Utils/MessageUtil";
import {Hidden} from "@material-ui/core";
import {PaymentContext} from "../../../Contexts/PaymentContext";
import PlanService from "../../../Services/PlanService";
import MyAccountScreen from "../MyAccountScreen/MyAccountScreen";
import FlagsAcceptedCardComponent from "../../../Components/FlagsAcceptedCardComponent/FlagsAcceptedCardComponent";

class PaymentScreen extends Component {
    static ROUTE = '/payment';

    constructor(props) {
        super(props);

        this.state = {
            inLoad: false,
            erros: [],
        };

        this.planService = new PlanService();
    }

    doCreate = async (form) => {
        try {
            this.setState({
                inLoad: true,
            });

            await this.planService.subscribePlan({
                uuid: this.props.match.params.uuid,
                form
            });

            this.setState({
                inLoad: false,
                messagens: MessageUtil.make({
                    title: 'Atenção',
                    body: 'Plano foi contratado com sucesso.',
                    okBtnToRoute: MyAccountScreen.ROUTE,
                }),
            });

        } catch (e) {
            this.setState({
                inLoad: false,
                messagens: MessageUtil.make({
                    title: 'Atenção',
                    body: e,
                }),
            });
        }
    }

    render() {
        return (
            <PaymentContext.Provider value={{
                parent: this,
            }}>
                <HeaderComponent title={'Pagamento'}
                                 menu={true}
                                 messagens={this.state?.messagens}
                                 inLoad={this.state.inLoad}/>
                <SpacerComponent height={10}/>
                <ContentMainStyled>
                    <Hidden xsDown={true}
                            smDown={true}>
                        <ContentPaymentStyled variant={'outlined'}>
                            <ShowPlanPaymentScreen plan={JSON.parse(this.props.match.params.plan)}/>
                            <FormPaymentScreen erros={this.state.erros}/>
                        </ContentPaymentStyled>
                    </Hidden>
                    <Hidden lgUp={true}
                            xlUp={true}
                            mdUp={true}>
                        <ContentPaymentMobileStyled variant={'outlined'}>
                            <ShowPlanPaymentScreen plan={JSON.parse(this.props.match.params.plan)}/>
                            <FormPaymentScreen erros={this.state.erros}/>
                        </ContentPaymentMobileStyled>
                    </Hidden>
                </ContentMainStyled>
            </PaymentContext.Provider>
        );
    }
}

export default PaymentScreen;