import React, {Component} from 'react';
import {ContentChartStyled, ContentHeaderCardStyled} from "./styled";
import {Grid, Typography} from "@material-ui/core";
import SpacerComponent from "../../../Components/SpacerComponent/SpacerComponent";
import {Chart} from "react-google-charts";
import LoadDashboard from "./LoadDashboard";
import TextFieldDefault from "../../../Components/TextFieldDefaultComponent/TextFieldDefault";
import ContentHCenterVStartComponent
    from "../../../Components/ContentHCenterVStartComponent/ContentHCenterVStartComponent";

class ChartDefault extends Component {

    formatData() {

        if (this.props.data === undefined) {
            return [];
        }

        const formatData = [
            ['Data', 'Total'],
        ];

        this.props.data.forEach(function (value) {

            const arrayData = [];

            arrayData.push(value.description);
            arrayData.push(parseInt(value.count));

            formatData.push(arrayData);

        });

        return formatData;

    }

    render() {
        if (this.formatData().length <= 0) {
            return (
                <LoadDashboard title={this.props.title}/>
            );
        }
        return (
            <Grid item={true}
                  xs={12}
                  sm={12}
                  md={12}
                  lg={6}
                  xl={6}>
                <ContentChartStyled elevation={3}>
                    <ContentHeaderCardStyled>
                        <Typography variant={'subtitle1'}
                                    color={'primary'}>
                            {this.props.title}
                        </Typography>
                        <TextFieldDefault label={'Dias'}
                                          fullWidth={false}/>
                    </ContentHeaderCardStyled>
                    <SpacerComponent height={20}/>
                    <Chart
                        width={'100%'}
                        height={300}
                        chartType="ColumnChart"
                        loader={<div>Loading Chart</div>}
                        data={this.formatData()}
                        options={{}}
                    />
                </ContentChartStyled>
            </Grid>
        );
    }
}

export default ChartDefault;