import React, {Component} from 'react';
import {Box, Button, MenuItem, Typography} from "@material-ui/core";
import TextFieldDefault from "../../../Components/TextFieldDefaultComponent/TextFieldDefault";
import SpacerComponent from "../../../Components/SpacerComponent/SpacerComponent";
import {ContentMainStyled, ContentSizeStyled} from "../CompanyRegistrationScreen/styled";
import MobileStepper from '@material-ui/core/MobileStepper';
import AvatarComponent from "../../../Components/AvatarComponent/AvatarComponent";
import InputFileDefault from "../../../Components/InputFileDefault/InputFileDefault";
import TooltipDefault from "../../../Components/TooltipComponent/TololtipDefault"
import WTRCompanyRegistrationFidelityScreen from "./WTRCompanyRegistrationFidelityScreen";
import LongTextFidelity from "./LongTextFidelity";
import ApiImageUtil from "../../../Utils/ApiImageUtil";
import SelectMultiple from "../../../Components/SelectMultiple/SelectMultiple";
import CompanyService from "../../../Services/CompanyService";
import MessageUtil from "../../../Utils/MessageUtil";
import CheckBoxDefaultComponent from "../../../Components/CheckBoxDefaultComponent/CheckBoxDefaultComponent";
import FixSelectComponent from "../../../Components/FixSelect/FixSelect";
import BlockUserComponent from "./BlockUser/BlockUserComponent";

class FormCompanyRegistrationFidelityScreen extends Component {

    companyService;

    constructor(props) {
        super(props);

        this.state = {
            imagePrevios: '',
            regulation: LongTextFidelity.defaultTerm,
            regulationDisabled: true,
            noLimitPoint: false,
            createEstablishment: false,
            btnAlterDisabled: true,
            establishmentSelect: [],
            toShow: [],
            establishmentSelectSelect: [],
            acceptUpdateTerm: false,
        };

        this.companyService = new CompanyService();
    }

    componentDidMount() {
        this.load();
    }

    async load() {
        try {

            const response = await this.companyService.getByMatrix();
            const responseToShow = await this.companyService.getByMatrix();

            let newObject = [];
            response.data.data.map((value) => {
                newObject.push({
                    uuid: value.uuid,
                    name: value.fantasy_name,
                    fildelity: value.fidelity_program.name,
                });
            });

            let toShow = [];
            responseToShow.data.data.map((value) => {
                toShow[value.uuid] = value.fantasy_name
            });

            this.setState({
                establishmentSelect: newObject,
                toShow: toShow,
            });

        } catch (e) {
            this.setState({
                inLoad: false,
                messagens: MessageUtil.make({
                    title: 'Atenção',
                    body: e,
                }),
            });
        }
    }

    async getEstablishment() {
        try {

            const response = await this.companyService.getByFidelity(
                this.props.formEdit.uuid
            );

            let newObject = [];
            response.data.data.map((value) => {
                newObject.push(value.uuid);
            });

            this.setState({
                establishmentSelectSelect: newObject,
            });

        } catch (e) {
            throw e;
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.formEdit === prevProps.formEdit) {
            return;
        }
        this.setFormEdit();
    }

    async setFormEdit() {
        let newState = Object.assign(this.state, this.props.formEdit);
        newState.imagePrevios = ApiImageUtil.tryGet(this.props.formEdit.files);
        newState.noLimitPoint = (this.props.formEdit.points_validity === null);
        this.getEstablishment();
        this.setState(newState);
    }

    async handlerSubmit() {
        this.props.doCreate(this.state);
    }

    async handlerSubmitEdit() {
        this.props.doEdit(this.state);
    }

    async handlerSubmitCreateAlone() {
        this.props.doCreateAlone(this.state);
    }

    handleChange = (event) => {
        const {name, value} = event.target;
        this.setState({
            [name]: value,
            btnAlterDisabled: false,
        });
    }

    setPreviosImage = (image) => {
        this.setState({
            imagePrevios: image,
        });
    }

    toBase64Image = (base64) => {
        this.setState({
            'image': base64.split(',')[1],
            btnAlterDisabled: false,
        });
    }

    changeTerm = (value) => {
        this.setState({
            regulationDisabled: value,
            btnAlterDisabled: false,
        })
    }

    pontiValidQuestion = (value) => {
        this.setState({
            noLimitPoint: !value,
            btnAlterDisabled: false,
            points_validity: null,
        });
    }

    questionChangeCreateOtherEstablishment = (value) => {
        this.setState({
            createEstablishment: !value,
        });
    }

    handleChangeEstablishment = (value) => {
        this.setState({
            participating_establishments: value,
            btnAlterDisabled: false,
        });
    }

    handleChangeConcurrentCampaign = () => {
        this.setState({
            concurrent_campaign: !this.state.concurrent_campaign,
            btnAlterDisabled: false,
        });
    }

    getSelectComapany() {

        if (this.props.isCreateAlone === true) {
            return this.getComponentSelectCompany();
        }

        if (this.props.isEdit === true) {
            return this.getComponentSelectCompany();
        }

    }

    getComponentSelectCompany() {

        return (
            <SelectMultiple title={'Estabelecimentos'}
                            handleChangeEstablishment={this.handleChangeEstablishment}
                            value={this.state.establishmentSelect}
                            valueSelected={this.state.establishmentSelectSelect}
                            toShow={this.state.toShow}/>
        );

    }

    render() {
        return (
            <ContentMainStyled>
                <ContentSizeStyled>
                    <TextFieldDefault label={'Nome do Programa de Fidelidade'}
                                      name={'name'}
                                      onChange={(event) => this.handleChange(event)}
                                      erros={this.props.erros}
                                      value={this.state.name}/>
                    <SpacerComponent height={10}/>
                    <Typography variant={'body1'}
                                color={'primary'}>
                        Logo do programa de fidelidade
                    </Typography>
                    <SpacerComponent height={10}/>
                    <Box display={'flex'}
                         flexDirection={'row'}
                         alignItems={'center'}>
                        <AvatarComponent
                            src={this.state.imagePrevios}/>
                        <SpacerComponent width={20}/>
                        <InputFileDefault setPreviosImage={this.setPreviosImage}
                                          toBase64Image={this.toBase64Image}
                                          name={'image'}
                                          erros={this.props.erros}/>
                    </Box>
                    {this.props.isEdit === true &&
                        <>
                            <SpacerComponent height={20}/>
                            <Button variant={'outlined'}
                                    color={'primary'}
                                    size={"large"}
                                    fullWidth={true}
                                    onClick={this.props.toEditRegulation}>
                                Editar regulamento
                            </Button>
                        </>
                    }
                    <SpacerComponent height={20}/>
                    <Box display={'flex'}
                         flexDirection={'row'}
                         alignItems={'center'}>
                        <Typography variant={'body1'}
                                    color={'primary'}
                                    style={{marginRight: 5}}>
                            Tempo de Validade dos Pontos
                        </Typography>
                        <TooltipDefault
                            text={'Estipule quanto tempo os pontos acumulados por seus clientes estarão disponíveis para serem trocados por prêmios.'}/>
                    </Box>
                    <Box display={'flex'}
                         flexDirection={'row'}>
                        <TextFieldDefault name={'points_validity'}
                                          onChange={(event) => this.handleChange(event)}
                                          type={'number'}
                                          erros={this.props.erros}
                                          disabled={this.state.noLimitPoint}
                                          value={this.state.points_validity}/>
                        <SpacerComponent width={40}/>
                        <FixSelectComponent>
                            <TextFieldDefault select={true}
                                              defaultValue={'days'}
                                              name={'expiration_period'}
                                              value={this.state.expiration_period}
                                              onChange={(event) => this.handleChange(event)}
                                              disabled={this.state.noLimitPoint}>
                                <MenuItem key={'point-days'} value={'days'}>Dias</MenuItem>
                                <MenuItem key={'point-weeks'} value={'weeks'}>Semanas</MenuItem>
                                <MenuItem key={'point-months'} value={'months'}>Mêses</MenuItem>
                            </TextFieldDefault>
                        </FixSelectComponent>
                    </Box>
                    <WTRCompanyRegistrationFidelityScreen onChange={this.pontiValidQuestion}
                                                          noLimitPoint={this.state.noLimitPoint}/>
                    <SpacerComponent height={20}/>
                    <Box display={'flex'}
                         flexDirection={'row'}
                         alignItems={'center'}>
                        <Typography variant={'body1'}
                                    color={'primary'}
                                    style={{marginRight: 5}}>
                            Limite de Tempo de Reserva do Prêmio
                        </Typography>
                        <TooltipDefault
                            text={'Informe quanto tempo as premiações cadastradas estarão disponíveis para resgate.'}/>
                    </Box>
                    <Box display={'flex'}
                         flexDirection={'row'}>
                        <TextFieldDefault name={'award_limit_time'}
                                          onChange={(event) => this.handleChange(event)}
                                          type={'number'}
                                          erros={this.props.erros}
                                          value={this.state.award_limit_time}/>
                        <SpacerComponent width={40}/>
                        <FixSelectComponent>
                            <TextFieldDefault select={true}
                                              defaultValue={'days'}
                                              name={'booking_limit_period'}
                                              value={this.state.booking_limit_period}
                                              onChange={(event) => this.handleChange(event)}>
                                <MenuItem key={'days'} value={'days'}>Dias</MenuItem>
                                <MenuItem key={'weeks'} value={'weeks'}>Semanas</MenuItem>
                                <MenuItem key={'months'} value={'months'}>Mêses</MenuItem>
                            </TextFieldDefault>
                        </FixSelectComponent>
                    </Box>
                    <FixSelectComponent>
                        <CheckBoxDefaultComponent onChange={this.handleChangeConcurrentCampaign}
                                                  checked={this.state.concurrent_campaign}
                                                  label={'Contemplar Campanhas simultâneas'}/>
                    </FixSelectComponent>


                    <SpacerComponent height={20}/>
                    <Typography variant={'body1'}
                                color={'primary'}
                                style={{marginRight: 5}}>
                        Notificar cliente inativo
                    </Typography>
                    <Box display={'flex'}
                         flexDirection={'row'}>
                        <TextFieldDefault name={'inactivity_notice_amount'}
                                          onChange={(event) => this.handleChange(event)}
                                          type={'number'}
                                          erros={this.props.erros}
                                          value={this.state.inactivity_notice_amount}/>
                        <SpacerComponent width={40}/>
                        <FixSelectComponent>
                            <TextFieldDefault select={true}
                                              defaultValue={'days'}
                                              name={'inactivity_notice_period'}
                                              value={this.state.inactivity_notice_period}
                                              onChange={(event) => this.handleChange(event)}>
                                <MenuItem key={'days'} value={'days'}>Dias</MenuItem>
                                <MenuItem key={'weeks'} value={'weeks'}>Semanas</MenuItem>
                                <MenuItem key={'months'} value={'months'}>Mêses</MenuItem>
                            </TextFieldDefault>
                        </FixSelectComponent>
                    </Box>
                    <BlockUserComponent values={this.state}
                                        onChange={(value) => {
                                            this.setState({...this.state, ...value});
                                            this.setState({
                                                btnAlterDisabled: false
                                            });
                                        }}/>
                    <div>
                        <SpacerComponent height={10}/>
                        {this.getSelectComapany()}
                        <SpacerComponent height={10}/>
                    </div>
                    {this.props.isEdit === false &&
                        this.props.isCreateAlone === false &&
                        <div>
                            <SpacerComponent height={20}/>
                            <Box display={'flex'} flexDirection={'column'} alignItems={'center'}>
                                <MobileStepper
                                    variant="dots"
                                    steps={3}
                                    position="static"
                                    activeStep={1}

                                />
                            </Box>
                        </div>
                    }
                    <SpacerComponent height={20}/>
                    {this.props.isEdit === false &&
                        this.props.isCreateAlone === false &&
                        <Button variant={'contained'}
                                color={'primary'}
                                size={'large'}
                                fullWidth={true}
                                onClick={() => this.handlerSubmit()}>
                            Continuar
                        </Button>
                    }
                    {this.props.isEdit === true &&
                        <>
                            <CheckBoxDefaultComponent onChange={() => this.setState({
                                acceptUpdateTerm: !this.state.acceptUpdateTerm,
                            })}
                                                      label={'Estou ciente que qualquer alteração realizada, deverá ser ajustada por mim, no regulamento do meu PROGRAMA DE FIDELIDADE.'}
                                                      color={'error'}/>
                            <SpacerComponent height={30}/>
                            <Button variant={'contained'}
                                    color={'primary'}
                                    size={"large"}
                                    fullWidth={true}
                                    onClick={() => this.handlerSubmitEdit()}
                                    disabled={(this.state.btnAlterDisabled && this.props.btnAlterDisabled) || !this.state.acceptUpdateTerm}>
                                Alterar
                            </Button>
                        </>
                    }
                    {this.props.isCreateAlone === true &&
                        <Button variant={'contained'}
                                color={'primary'}
                                size={"large"}
                                fullWidth={true}
                                onClick={() => this.handlerSubmitCreateAlone()}>
                            Salvar
                        </Button>
                    }
                </ContentSizeStyled>
            </ContentMainStyled>
        );
    }
}

export default FormCompanyRegistrationFidelityScreen;