import React, {Component} from 'react';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import DeleteIcon from '@material-ui/icons/Delete';
import {cpf, cnpj} from "cpf-cnpj-validator";
import SpacerComponent from "../../../Components/SpacerComponent/SpacerComponent";
import {ContentCNPJBlock, ContentTitleCNPJBlock} from "./styled";
import TextFieldDefault from "../../../Components/TextFieldDefaultComponent/TextFieldDefault";
import FormMask from "../../../FromMask/FormMask";
import {Typography} from "@material-ui/core";

class PointsComponent extends Component {
    constructor(props) {
        super(props);

        this.state = {
            formatValues: {
                values: [],
            }
        };
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.values === prevProps.values) {
            return;
        }
        this.setState({
            formatValues: {
                values: this.props.values.values ?? [],
            },
        })
    }

    handleChange = (event) => {
        const {name, value} = event.target;
        this.setState({
            [name]: value,
        });
    }

    addCNPJCPFBlock = () => {
        const cpfValue = this.state.values_cpf;
        const pointValue = this.state.values_points;

        if (!cpf.isValid(cpfValue)) {
            alert('CPF inválido');
            return;
        }

        if (!pointValue || parseInt(pointValue) <= 0) {
            alert('Pontos invalido');
            return;
        }

        this.state.formatValues.values.push({
            cpf: cpfValue,
            points: pointValue,
        });

        this.setState({
            values_cpf: '',
            values_points: '',
        });
        this.onChange();
    }

    deleteCNPJCPFBlock = (index) => {
        this.state.formatValues.values = this.state.formatValues.values.filter((_, i) => i !== index,);
        this.setState({});
        this.onChange();
    }

    onChange = () => {
        this.props.onChange(this.state.formatValues);
    }

    render() {
        return (
            <div>
                <SpacerComponent height={40}/>

               {/* <ContentTitleCNPJBlock>
                    <Typography variant={'subtitle1'}
                                color={'error'}>
                        Insira o CPF do usuário
                    </Typography>
                    <SpacerComponent width={350}/>
                    <Typography variant={'subtitle1'}
                                color={'error'}>
                        Quantidade de Pontos
                    </Typography>
                </ContentTitleCNPJBlock>*/}

                {this.state?.formatValues?.values?.map((value, index) => {
                    return <ContentCNPJBlock>
                        <TextFieldDefault name={'cpf_dynamic'}
                                          onChange={(event) => this.handleChange(event)}
                                          mask={FormMask.cpf}
                                          erros={this.props.erros}
                                          disabled={true}
                                          value={this.state.formatValues.values[index].cpf}/>
                        <SpacerComponent width={20}/>
                        <TextFieldDefault name={'points_dynamic'}
                                          onChange={(event) => this.handleChange(event)}
                                          erros={this.props.erros}
                                          disabled={true}
                                          value={this.state.formatValues.values[index].points}/>
                        <SpacerComponent width={20}/>
                        <DeleteIcon onClick={() => this.deleteCNPJCPFBlock(index)}/>
                    </ContentCNPJBlock>;
                })}

                <ContentCNPJBlock>
                    <TextFieldDefault name={'values_cpf'}
                                      onChange={(event) => this.handleChange(event)}
                                      mask={FormMask.cpf}
                                      erros={this.props.erros}
                                      value={this.state.values_cpf}
                                      label={'CPF *'}/>
                    <SpacerComponent width={20}/>
                    <TextFieldDefault name={'values_points'}
                                      onChange={(event) => this.handleChange(event)}
                                      type={'number'}
                                      erros={this.props.erros}
                                      value={this.state.values_points}
                                      label={'Pontos *'}/>
                    <SpacerComponent width={20}/>
                    <AddCircleOutlineIcon onClick={this.addCNPJCPFBlock}/>
                </ContentCNPJBlock>


                <SpacerComponent height={40}/>
            </div>
        );
    }
}

PointsComponent.propTypes = {};

export default PointsComponent;
