import styled from "styled-components";
import {Button, Typography} from "@material-ui/core";

export const ButtonDelete = styled(Button)`
  background: rgb(246, 38, 38);
  color: white;
`;

export const ShowSelectData = styled(Typography)`
    color: grey;
`;

