import styled from 'styled-components';
import {Grid, Paper} from "@material-ui/core";

export const ItemSelectStyled = styled(Paper)`
  width: 250px;
  height: 250px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: ${props => props.list ? '#ffffff' : '#' + process.env.REACT_APP_SECONDARY_COLOR};
  color: ${props => props.list ? '#' + process.env.REACT_APP_SECONDARY_COLOR : '#ffffff'};
  cursor: pointer;
  position: relative;
  padding: 15px;
`;

export const TitleItemSelectStyled = styled.span`
  font-size: 18px;
  font-weight: 700;
  text-align: center;
`;

export const ItemSelectGridAwardStyled = styled(Grid)`
  display: flex;
  justify-content: center;
  align-items: center;
`;