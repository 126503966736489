import * as yup from 'yup';
import ValidationUtil from "../Utils/ValidationUtil";

class PaymentValidation {

    static async validate(form) {
        try {

            let validateForm = {
                name: yup
                    .string()
                    .required(ValidationUtil.MSG_FIELD_REQUIRED),
                card_expiration: yup
                    .string()
                    .required(ValidationUtil.MSG_FIELD_REQUIRED),
                card_number: yup
                    .string()
                    .required(ValidationUtil.MSG_FIELD_REQUIRED),
                card_cvv: yup
                    .string()
                    .required(ValidationUtil.MSG_FIELD_REQUIRED),
                payment_company_code: yup
                    .string()
                    .required(ValidationUtil.MSG_FIELD_REQUIRED),
            };

            if(!form.card_number){
                delete validateForm.payment_company_code;
            }

            let validate = yup.object().shape(validateForm);

            validate.validateSync(form, {abortEarly: false})

            return true;

        } catch (e) {
            let erros = [];
            e.inner.forEach(function (value, index) {
                erros[value.path] = value.message;
            });

            return erros;
        }
    }
}

export default PaymentValidation;