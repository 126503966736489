import styled from "styled-components";
import {Avatar, Box, IconButton, Menu, Typography} from "@material-ui/core";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import MenuIcon from "@material-ui/icons/Menu";

export const ContentHeader = styled.div`
  padding-top: 80px;
`;

export const LogoHeader = styled.img`
  width: 180px;
  height: 100%;
`;

export const TitleLogo = styled(Typography)`
  line-height: 0.75;
`;

export const AvatarHeaderStyled = styled(Avatar)`
  background-color: transparent !important;
  cursor: pointer !important;
`;

export const TextConfigStyled = styled(Typography)`
  margin-left: 5px;
`;

export const ContentConfigIcon = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

export const CheckCircleIconStyled = styled(CheckCircleIcon)`
  color: green;
  font-size: 80px;
`;

export const BoxLogoStyled = styled(Box)`
`;

export const IconMenuMobileStyled = styled(MenuIcon)`
  color: white;
`;

export const ContentMenuMobileStyled = styled.div`
  position: fixed;
  z-index: 999999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: white;
  display: ${props => props.openClose ? 'flex' : 'none'};
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding-top: 50px;
  color: black;
  overflow: auto;

  h6 {
    padding-bottom: 20px;
  }

  hr {
    width: 100%;
  }

  h5 {
    margin-bottom: 20px;

    a {
      font-size: 18px;
      color: black;
      font-weight: bold;
    }
  }
`;

export const CloseButtonMenuMobileStyled = styled(IconButton)`
  position: fixed;
  top: 0;
  right: 0;
`;

export const ContentTitleStyled = styled.div`
  margin-top: 20px;
  margin-bottom: 20px;
`;

export const MenuStyled = styled(Menu)`
  z-index: 0 !important;
`;