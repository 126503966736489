const TYPES = [
    'Prêmio normal',
    'Cashback',
    'Prêmio instantâneo'
];

class AwardUtil {
    static type(type) {
        type = type - 1;

        return TYPES[type];
    };
}

export  default AwardUtil;