import styled from "styled-components"
import {Button, Paper, Typography} from "@material-ui/core";
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';

export const ImgItemStyled = styled.img`
  width: 100%;
  height: 150px;
  object-fit: contain;
`;

export const PaperItemStyled = styled(Paper)`
  border-radius: 20px;
  height: 80px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  align-items: center;
  cursor: pointer;
  
  span {
    color: #444343;
    font-weight: 400;
    font-size: 10px;
    margin: 0;
  }

  h5 {
    color: #444343;
    font-weight: 500;
    font-size: 22px;
    margin: 0;
  }

  img {
    height: 80px;
  }
`;

export const PaperItemImgStyled = styled(Paper)`
height: 150px;
  border-radius: 20px;
  overflow: hidden;    
  position: relative;  
  border-style: ${props => props.select === true ? 'solid' : undefined};
  border-color: ${props => props.select === true ? '#' + process.env.REACT_APP_PRIMARY_COLOR : undefined};
  border-width: ${props => props.select === true ? '5px' : undefined};
  
  h6 {
    text-align: center;
    color: white;
    font-weight: bold;
    font-size: 15px;
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;

export const BtnEditItemAwardStyled = styled(Button)`
  position: absolute;
  top: 5px;
  right: 5px;
  background: white;
  border-radius: 10px;
  padding: 0;
  transition: background-color 250s cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
`;

export const StatusIconStyled = styled(FiberManualRecordIcon)`
  color: ${props => props.colorCustom === true ? '#07d00f' : '#d41111'};
  font-size: 0.95rem !important;
`;

export const BrandLineStyled = styled.div`
  height: 80px;
  display: flex;
  flex-direction: row;
  margin-top: 50px;
  margin-bottom: 20px;
  align-items: center;
  justify-content: center;
`;

export const BrandLineDescriptionStyled = styled(Typography)`
  font-size: 12px;
`;

export const BrandLineLinerStyled = styled.div`
  height: 1px;
  width: 100%;
  background: gray;
  align-self: center;
`;

export const BrandLineImgStyled = styled(Paper)`
  height: 140px;
  border-radius: 20px;
  position: relative;
  padding: 10px;
  
  img {
    height: 100%;
    width: 242px;
    border-radius: 20px;
    object-fit: contain;
  }
`;

export const ParentModalGiftCardStyled = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100vw;
  z-index: 1;
  overflow-y: auto;
  height: 100vh;
  background: rgba(0, 0, 0, 0.58);
`;

export const ModalGiftCardStyled = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 650px;
  z-index: 1;
  overflow-y: auto;
  height: 80%;
`;

export const ContentModalGiftCardStyled = styled(Paper)`
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  align-items: center;

  h1 {
    margin: 0;
    cursor: pointer;
    font-size: 15px;
    position: fixed;
    right: 20px;
    top: 20px;
  }
`;

export const ValorTotalContentStyle = styled.div`
  display: flex;
  flex-direction: row;

  span {
    color: black;
  }
`;

export const PreviewGiftCard = styled.div`
    width: 270px;
`;