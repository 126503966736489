import styled from "styled-components"
import {Button, IconButton, Paper} from "@material-ui/core";
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';

export const ImgItemStyled = styled.img`
  width: 100%;
  height: 150px;
  object-fit: cover;
`;

export const PaperItemStyled = styled(Paper)`
  border-radius: 20px;
  overflow: hidden;
  position: relative;
  border-style: ${props => props.select === true ? 'solid' : undefined};
  border-color: ${props => props.select === true ? '#' + process.env.REACT_APP_PRIMARY_COLOR : undefined};
  border-width: ${props => props.select === true ? '5px' : undefined};
`;

export const PaperItemImgStyled = styled(Paper)`
  height: 150px;
  border-radius: 20px;
  overflow: hidden;
  position: relative;
  border-style: ${props => props.select === true ? 'solid' : undefined};
  border-color: ${props => props.select === true ? '#' + process.env.REACT_APP_PRIMARY_COLOR : undefined};
  border-width: ${props => props.select === true ? '5px' : undefined};
`;

export const BtnEditItemAwardStyled = styled(IconButton)`
  float: right;
  margin-right: 10px;
`;

export const StatusIconStyled = styled(FiberManualRecordIcon)`
  color: ${props => props.colorCustom === true ? '#07d00f' : '#d41111'};
  font-size: 0.95rem !important;
`;