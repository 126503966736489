import React, {Component} from 'react';

import FormCompanyRegistrationScreen from "./FormCompanyRegistrationScreen";
import HeaderComponent from "../../../Components/HeaderComponent/HeaderComponent";
import CompanyService from "../../../Services/CompanyService";
import MessageUtil from "../../../Utils/MessageUtil";
import CompanyRegistrationFidelityScreen from "../CompanyRegistrationFidelityScreen/CompanyRegistrationFidelityScreen";
import ErrorUtil from "../../../Utils/ErrorUtil";
import CompanyValidation from "../../../FormValidations/CompanyValidation";
import SegmentNameService from "../../../Services/SegmentNameService";
import SegmentService from "../../../Services/SegmentService";
import DashboardScreen from "../DashboardScreen/DashboardScreen";
import CompanyEditValidation from "../../../FormValidations/CompanyEditValidation";
import UserService from "../../../Services/UserService";
import MeUtil from "../../../Utils/MeUtil";
import AuthService from "../../../Services/Api/AuthService";
import RoleUtil from "../../../Utils/RoleUtil";
import {CompanyRegistrationContext} from "../../../Contexts/CompanyRegistrationContext";
import GeneralTranslation from "../../../Translations/FormValidations/GeneralTranslation";
import {SuperContext} from "../../../Contexts/SuperContext";

class CompanyRegistrationScreen extends Component {
    static contextType = SuperContext;

    static ROUTE = '/company-registration';

    segmentNameSerice;
    segmentService;
    companyService;
    userService;
    authService;

    constructor(props) {
        super(props);

        this.state = {
            inLoad: false,
            erros: [],
            segmentName: [],
            segment: [],
            dataEdit: null,
            formEdit: {},
            isEdit: false,
        };

        this.segmentNameSerice = new SegmentNameService();
        this.segmentService = new SegmentService();
        this.companyService = new CompanyService();
        this.userService = new UserService();
        this.authService = new AuthService();
    }

    componentDidMount() {
        this.load();
    }

    async load() {
        try {

            this.setState({
                inLoad: true,
            });

            const response = await this.segmentNameSerice.get();

            await this.isEdit()

            this.setState({
                inLoad: false,
                segmentName: response.data.data,
            });

        } catch (e) {
            this.setState({
                inLoad: false,
                messagens: MessageUtil.make({
                    title: 'Error',
                    body: e,
                }),
            });
        }
    }

    async isEdit() {

        try {
            if (this.props.match.params.uuid === undefined) {
                return;
            }

            this.setState({
                inLoad: true,
            });

            const response = await this.companyService.searchByUuid(this.props.match.params.uuid);

            this.setState({
                inLoad: false,
                formEdit: response.data.data,
                action: 'edit',
                actionCurrent: 'Alterar Prêmio ' + response.data.data.title,
                isEdit: true,
                isEditUuid: this.props.match.params.uuid,
            });

        } catch (e) {
            this.setState({
                inLoad: false,
                messagens: MessageUtil.make({
                    title: 'Atenção',
                    body: e,
                }),
            });
        }
    }

    getSegmentBySegmentName = async (id) => {
        try {
            this.setState({
                inLoad: true,
            });

            const response = await this.segmentService.get(id);

            this.setState({
                inLoad: false,
                segment: response.data.data,
            });

        } catch (e) {
            this.setState({
                inLoad: false,
                messagens: MessageUtil.make({
                    title: 'Error',
                    body: e,
                }),
            });
        }
    }

    doCreateCompany = async (object) => {
        try {
            const validade = await CompanyValidation.validate(object);
            if (validade !== true) {
                this.setState({
                    erros: validade,
                    messagens: MessageUtil.makeToValidationField(
                        new GeneralTranslation(validade).translate()
                    ),
                });
                return;
            }

            if (object.password !== object.password_confirm) {
                throw ErrorUtil.make('As senhas não conferem')
            }

            if (object.score !== 3) {
                throw ErrorUtil.make('Por favor defina uma senha mais forte')
            }

            if (object.term !== true) {
                throw ErrorUtil.make('É necessário aceitar o termo');
            }

            this.setState({
                inLoad: true,
            });

            const response = await this.companyService.create(object);

            this.context.analytics.setSectorSelect({
                cnpj: response.data.data.cnpj,
                fantasyName: response.data.data.fantasy_name,
                socialReason: response.data.data.social_reason,
                areaName: response.data.data.segment_type.name,
                segmentName: response.data.data.segment_type.segment_name.name,
            });

            await this.doAuth(object);

            this.setState({
                inLoad: false,
                messagens: MessageUtil.make({
                    title: 'Sucesso',
                    body: 'Sua empresa foi cadastrada com sucesso, clique em ok para ir para próxima etapa.',
                    okBtnToRoute: CompanyRegistrationFidelityScreen.ROUTE
                        + '/'
                        + response.data.data.uuid,
                }),
            });

        } catch (e) {
            console.log(e);
            this.setState({
                inLoad: false,
                messagens: MessageUtil.make({
                    title: 'Atenção',
                    body: e,
                }),
            });
        }
    }

    async doAuth(object) {
        try {

            const formAuth = {
                email: object.email,
                password: object.password,
                environment: RoleUtil.APP_WEB,
            };

            await this.authService.doLogin(formAuth);

        } catch (e) {
            this.setState({
                inLoad: false,
                messagens: MessageUtil.make({
                    title: 'Atenção',
                    body: e,
                }),
            });
            throw e;
        }
    }

    makeFormEditCompany(object) {

        return {

            social_reason: object.social_reason,
            fantasy_name: object.fantasy_name,
            city: object.city,
            address: object.address,
            complement: object.complement,
            neighborhood: object.neighborhood,
            number: object.number,
            state: object.state,
            telephone: object.telephone,
            zip_code: object.zip_code,
            segment_type_uuid: object.segment_type_uuid,
            logo_base64: object.logo_base64,
            certificate_password: object.certificate_password,
            certificate_file: object.certificate_file,
            lat: object.lat,
            long: object.long

        }
    }

    doEditCompany = async (object) => {
        try {
            const validade = await CompanyEditValidation.validate(object);

            if (validade !== true) {
                this.setState({
                    erros: validade,
                    messagens: MessageUtil.makeToValidationField(
                        new GeneralTranslation(validade).translate()
                    ),
                });
                return;
            }

            this.setState({
                inLoad: true,
            });

            const newForm = this.makeFormEditCompany(object);

            await this.companyService.update(
                this.state.isEditUuid,
                newForm
            );

            await this.doEditUser(object);

            this.setState({
                inLoad: false,
                messagens: MessageUtil.make({
                    title: 'Sucesso',
                    body: 'As alterações foram salvas',
                    okBtnToRoute: DashboardScreen.ROUTE,
                }),
            });

        } catch (e) {
            this.setState({
                inLoad: false,
                messagens: MessageUtil.make({
                    title: 'Atenção',
                    body: e,
                }),
            });
        }
    }

    doEditUser = async (object) => {
        try {

            let newForm = {
                name: object.name,
                cpf: object.cpf,
            };

            if (this.state.formEdit?.admin?.email !== object.email) {
                newForm.email = object.email;
            }

            if (object.password !== undefined) {
                if (object.score !== 3) {
                    throw ErrorUtil.make('Por favor defina uma senha mais forte')
                }

                newForm.password = object.password;

            }

            const userData = await MeUtil.get();

            await this.userService.update(
                userData.data.data.uuid,
                newForm
            );

        } catch (e) {
            this.setState({
                inLoad: false,
                messagens: MessageUtil.make({
                    title: 'Atenção',
                    body: e,
                }),
            });
        }
    }

    doEditConfirm = async (object) => {
        const context = this;

        this.setState({
            messagens: MessageUtil.make({
                title: 'Atenção',
                body: 'Deseja Salvar as alterações?',
                okButtonFunction: function (value) {
                    context.doEditCompany(object);
                },
                cancelButtonText: 'Cancelar',
            }),
        });

    }

    render() {
        return (
            <CompanyRegistrationContext.Provider value={{
                parent: this,
                companyService: this.companyService,
            }}>
                <HeaderComponent messagens={this.state?.messagens}
                                 inLoad={this.state.inLoad}
                                 title={'Cadastro Matriz'}
                                 menu={this.state.isEdit}
                                 metaDescription={'Registre-se para criar uma conta e aproveitar as vantagens exclusivas do Meu APP de Fidelidade. É rápido, fácil e seguro. Junte-se à nossa comunidade hoje mesmo.'}/>
                <FormCompanyRegistrationScreen doCreate={this.doCreateCompany}
                                               doEdit={this.doEditConfirm}
                                               erros={this.state.erros}
                                               segmentName={this.state.segmentName}
                                               segment={this.state.segment}
                                               getSegmentBySegmentName={this.getSegmentBySegmentName}
                                               formEdit={this.state.formEdit}
                                               isEdit={this.state.isEdit}/>
            </CompanyRegistrationContext.Provider>
        );
    }
}

export default CompanyRegistrationScreen;