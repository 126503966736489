import styled from "styled-components";
import {Paper} from "@material-ui/core";

export const CanHelpFloatStyled = styled(Paper)`
  width: 60px;
  height: 60px;
  border-radius: 100px;
  position: fixed;
  bottom: 40px;
  right: 40px;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  background: #25d366;
  color: dodgerblue;
  svg {
    font-size: 36px;
  }
  cursor: pointer;

  @media print {
    .noPrint{
      display:none;
    }
  }
`;