import React, {Component} from 'react';
import HeaderComponent from "../../../Components/HeaderComponent/HeaderComponent";
import ContentHCenterVStartComponent
    from "../../../Components/ContentHCenterVStartComponent/ContentHCenterVStartComponent";
import FormCreateGoalsScreen from "./FormCreateGoalsScreen";
import MessageUtil from "../../../Utils/MessageUtil";
import ConsultantService from "../../../Services/ConsultantService";
import GoalService from "../../../Services/GoalService";
import DashboardAdminScreen from "../DashboardAdminScreen/DashboardAdminScreen";
import CreateConsultantValidation from "../../../FormValidations/CreateConsultantValidation";
import GoalValidation from "../../../FormValidations/GoalValidation";
import GeneralTranslation from "../../../Translations/FormValidations/GeneralTranslation";

class CreateGoalsScreen extends Component {
    static ROUTE = '/create-goals';

    constructor(props) {
        super(props);

        this.state = {
            inLoad: false,
            messagens: null,
            consultants: [],
            erros: [],
        };

        this.consultantService = new ConsultantService();
        this.goalService = new GoalService();

    }

    componentDidMount() {
        this.load();
    }

    async load() {
        try {

            this.setState({
                inLoad: true,
            });

            const response = await this.consultantService.get();

            this.setState({
                inLoad: false,
                consultants: response.data.data,
            });

        } catch (e) {
            this.setState({
                inLoad: false,
                messagens: MessageUtil.make({
                    title: 'Atenção',
                    body: e,
                }),
            });
        }
    }

    create = async (form) => {
        try {
            form.meta = form?.meta?.replaceAll(',', '.')
            form.sobremeta = form?.sobremeta?.replaceAll(',', '.')
            form.supermeta = form?.supermeta?.replaceAll(',', '.')

            const validade = await GoalValidation.validate(form);
            if (validade !== true) {
                this.setState({
                    erros: validade,
                    messagens: MessageUtil.makeToValidationField(
                        new GeneralTranslation(validade).translate()
                    ),
                });
                return;
            }

            this.setState({
                inLoad: true,
            });

            await this.goalService.create(form);

            this.setState({
                inLoad: false,
                messagens: MessageUtil.make({
                    title: 'Sucesso',
                    body: 'Meta foi cadastrado.',
                    okBtnToRoute: DashboardAdminScreen.ROUTE,
                }),
            });

        } catch (e) {
            this.setState({
                inLoad: false,
                messagens: MessageUtil.make({
                    title: 'Atenção',
                    body: e,
                }),
            });
        }
    }

    render() {
        return (
            <>
                <HeaderComponent showTitleInContent={true}
                                 title={'Cadastro de Metas'}
                                 inLoad={this.state.inLoad}
                                 menu={true}
                                 messagens={this.state.messagens}/>
                <ContentHCenterVStartComponent>
                    <FormCreateGoalsScreen consultants={this.state.consultants}
                                           create={this.create}
                                           erros={this.state.erros}/>
                </ContentHCenterVStartComponent>

            </>
        );
    }
}

export default CreateGoalsScreen;