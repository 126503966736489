import React, {Component} from 'react';
import {Box, Typography} from "@material-ui/core";
import {ElementIndicatorStyled} from "./styled";

class PasswordStrengthComponent extends Component {

    static INDICATOR_TO_TEXT = [
        'Fraco',
        'Bom',
        'Forte',
    ];

    hasUpCaser() {
        if (this.props.password === undefined) {
            return;
        }

        const str = this.props.password;
        const result = str.match(/[A-Z]/g);

        if (result === null) {
            return false;
        }

        return true;
    }

    hasNumber() {
        if (this.props.password === undefined) {
            return;
        }

        const str = this.props.password;
        return /\d/.test(str);
    }

    hasLenght8orMore() {
        if (this.props.password === undefined) {
            return;
        }

        const str = this.props.password;

        if (str.length >= 8) {
            return true;
        }

        return false;

    }

    getScore() {
        const upCaseResult = this.hasUpCaser();
        const numberResult = this.hasNumber();
        const lengthResult = this.hasLenght8orMore();

        let countTrue = 0;

        if (upCaseResult) {
            countTrue++
        }

        if (numberResult) {
            countTrue++
        }

        if (lengthResult) {
            countTrue++
        }

        if (typeof this.props.onChange === "function") {
            this.props.onChange(countTrue);
        }

        return countTrue
    }

    makeIndicator(indicator) {
        return indicator <= this.getScore();
    }

    render() {
        return (
            <div>
                <Box display={'flex'}
                     flexDirection={'row'}>
                    <ElementIndicatorStyled color={'#fa3a3a'}
                                            indicator={this.makeIndicator(1)}
                                            first={true}/>
                    <ElementIndicatorStyled color={'#4a6ffa'}
                                            indicator={this.makeIndicator(2)}
                                            first={true}/>
                    <ElementIndicatorStyled color={'#39f34d'}
                                            indicator={this.makeIndicator(3)}
                                            first={false}/>
                </Box>
                <Box display={'flex'}
                     flexDirection={'row'}
                     justifyContent="flex-end">
                    <Typography variant={'caption'}>
                        {PasswordStrengthComponent.INDICATOR_TO_TEXT[this.getScore() - 1]}
                    </Typography>
                </Box>
                <Typography variant={'body2'}>
                    Senha deve conter pelo menos 8 caracteres sendo uma letra maiúscula, um número e um caractere
                    especial, ex.:(@#$%¨&*).
                </Typography>
            </div>
        );
    }
}

export default PasswordStrengthComponent;