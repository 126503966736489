class PdfExtract {
    static makeHtml({
                        awardRedemptionUuid = '',
                        awardName = '',
                        rescuedName = '',
                        cpf = '',
                        date = '',
                        fideltyName = '',
                        socialName = '',
                        cnpj = '',
                        awardRule = '',
                    }) {

        return `<!DOCTYPE html>
<html lang="en">
<head>
    <meta charset="UTF-8">
    <title>Title</title>
    <style>
        body {
            font-family: 'Roboto';
            font-size: 22px;
        }
    </style>
</head>
<link href='https://fonts.googleapis.com/css?family=Roboto' rel='stylesheet'>
<body style="padding: 50px">
<p style="text-align: center;">
    <img src="https://homolog.meuappdefidelidade.com.br/static/media/logo_login.8bc432d2.png"
         style="border-radius: 25px; width: 200px;">
</p>
<h3 style="text-align: center; color: #2392ea; font-size: 32px;">Meu App de Fidelidade</h3>
<br/><br/>
<p style="text-align: center; font-size: 32px;">Comprovante</p>
<br/>
<p>Eu, João Luiz dos Santos Duarte, CPF: 169.491.777-04, recebi de Motobras de Pina Auto
    Peças Ltds, CNPJ: 08.106.413/0001-67, a seguinte premiação:</p>
<p>Prêmio: Cashback de R$ 60,00</p>
<p>Regra:</p>
<p>Ganha pela participação no programa de fidelidade: Soumaismotobras</p>
<p>Data: 22/01/2024.</p>
<p>Assinatura</p>
<p>Chave: 3123123123</p>
</body>
</html>`;

    }
}

export default PdfExtract;