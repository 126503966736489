import ApiClient from "./Api/ApiClient";

class StoryService {
    constructor() {
        this.uri = 'story';
        this.apiClient = new ApiClient();
    }

    async get(companyUuid) {
        try {

            return await this.apiClient.doRequest({
                method: ApiClient.GET,
                uri: `story/get-by-company/${companyUuid}`,
                expectedStatus: 200,
            });

        } catch (e) {
            throw e;
        }
    }

    async show(uuid) {
        try {

            return await this.apiClient.doRequest({
                method: ApiClient.GET,
                uri: this.uri + '/' + uuid,
                expectedStatus: 200,
            });

        } catch (e) {
            throw e;
        }
    }

    async create(companyUuid, image) {
        try {

            return await this.apiClient.doRequest({
                method: ApiClient.POST,
                uri: this.uri,
                expectedStatus: 201,
                body: {
                    company_uuid: companyUuid,
                    picture: image,
                },
            });

        } catch (e) {
            throw e;
        }
    }

    async update(uuid, object) {
        try {

            return await this.apiClient.doRequest({
                method: ApiClient.PATCH,
                uri: this.uri + '/' + uuid,
                expectedStatus: 201,
                body: object,
            });

        } catch (e) {
            throw e;
        }
    }

    async delete(uuid) {
        try {

            return await this.apiClient.doRequest({
                method: ApiClient.DELETE,
                uri: this.uri + `/${uuid}`,
                expectedStatus: 201,
            });

        } catch (e) {
            throw e;
        }
    }
}

export default StoryService;