import React, {Component} from 'react';
import {BrowserRouter as Router, Redirect, Route, Switch} from "react-router-dom";
import RouteNotFound from "../Screens/PJAccess/RouteNotFoundScreen/RouteNotFound";
import PublicRoute from "./PublicRoute";

import AdminRoute from "./AdminRoute";
import PJRoute from "./PJRoute";

class Routes extends Component {
    render() {
        return (
            <Router>
                <Switch>
                    {PublicRoute}
                    {PJRoute}
                    {AdminRoute}
                    <Redirect to={RouteNotFound.ROUTE}/>
                </Switch>
            </Router>
        );
    }
}

export default Routes;