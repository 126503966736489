import React, {Component} from 'react';
import {Box, Typography} from "@material-ui/core";
import CheckBoxOutlinedIcon from '@material-ui/icons/CheckBoxOutlined';
import SpacerComponent from "../../../Components/SpacerComponent/SpacerComponent";

class CheckItemChoicePlanScreen extends Component {
    render() {
        return (
            <Box display={'flex'}
                 flexDirection={'center'}
                 alignItems={'center'}>
                <CheckBoxOutlinedIcon/>
                <SpacerComponent width={10}/>
                <Typography variant={this.props.variant ?? 'h6'}>
                    {this.props.label}
                </Typography>
            </Box>
        );
    }
}

export default CheckItemChoicePlanScreen;