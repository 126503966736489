import React, {Component} from 'react';
import {ClickAreaStyled} from "../PointCalculatorComponent/styled";

class ClickAreaComponent extends Component {
    render() {
        return (
            <ClickAreaStyled>
                {this.props.children}
            </ClickAreaStyled>
        );
    }
}

export default ClickAreaComponent;