import React, {Component} from 'react';
import TextFieldDefault from "../../../Components/TextFieldDefaultComponent/TextFieldDefault";
import SpacerComponent from "../../../Components/SpacerComponent/SpacerComponent";
import {Button,} from "@material-ui/core";
import {ContentButton} from "./styled";
import PasswordStrengthComponent from "../../../Components/PasswordStrengthComponent/PasswordStrengthComponent";

class FormResetePasswordScreen extends Component {
    constructor(props) {
        super(props);

        this.state = {};
    }

    doReset() {
        this.props.doReset(this.state);
    }

    handleChange(event) {
        const {name, value} = event.target;
        this.setState({[name]: value});
    }

    setScore(score) {
        this.state.score = score;
    }

    render() {
        return (
            <div>
                <SpacerComponent height={40}/>
                <TextFieldDefault label={'Nova senha *'}
                                  name={'password'}
                                  type={'password'}
                                  erros={this.props.erros}
                                  onChange={(event) => this.handleChange(event)}/>
                <PasswordStrengthComponent password={this.state.password}
                                           onChange={(score) => this.setScore(score)}/>
                <TextFieldDefault label={'Confirmar nova senha *'}
                                  name={'password_confirmed'}
                                  type={'password'}
                                  erros={this.props.erros}
                                  onChange={(event) => this.handleChange(event)}/>
                <SpacerComponent height={30}/>
                <ContentButton>
                    <Button variant={'contained'}
                            color={'primary'}
                            fullWidth={true}
                            size={"large"}
                            onClick={() => this.doReset()}>
                        Enviar
                    </Button>
                </ContentButton>
            </div>
        );
    }
}

export default FormResetePasswordScreen;