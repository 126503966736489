import {Paper} from "@material-ui/core";
import styled from "styled-components";

export const ContentChartStyled = styled(Paper)`
  padding: 20px;
  border-radius: 10px;
  cursor: pointer;
`;

export const ContentTotalStyled = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const TableStyleContent = styled.div`
  height: 300px;
  overflow-y: auto;
`;