import React, {Component} from 'react';
import HeaderComponent from "../../../Components/HeaderComponent/HeaderComponent";
import {Box, Button, Typography} from "@material-ui/core";
import {ContentMainStyled, ContentSizeStyled} from "./styled";
import ContentEstablishment from "./ContentEstablishment";
import ButtonMoreEstablishment from "./ButtonMoreEstablishment";
import SpacerComponent from "../../../Components/SpacerComponent/SpacerComponent";
import MessageUtil from "../../../Utils/MessageUtil";
import SegmentNameService from "../../../Services/SegmentNameService";
import SegmentService from "../../../Services/SegmentService";
import EstablishmentValidation from "../../../FormValidations/EstablishmentValidation";
import CompanyService from "../../../Services/CompanyService";
import ButtonRemoveEstablishment from "./ButtonRemoveEstablishment";
import MobileStepper from "@material-ui/core/MobileStepper";
import RegulationFidelityScreen from "../RegulationFidelity/RegulationFidelityScreen";

class CompanyRegistrationEstablishment extends Component {
    static ROUTE = '/company-registration-establishment';

    segmentNameSerice;
    segmentService;
    companyService;

    constructor(props) {
        super(props);

        this.state = {
            establishmentCount: 1,
            messagens: null,
            inLoad: false,
            erros: [],
            segmentName: [],
            segment: [],
            stateForms: [],
        };

        this.segmentNameSerice = new SegmentNameService();
        this.segmentService = new SegmentService();
        this.companyService = new CompanyService();
    }

    componentDidMount() {
        this.load();
    }

    async load() {
        try {

            this.setState({
                inLoad: true,
            });

            const response = await this.segmentNameSerice.get();

            this.setState({
                inLoad: false,
                segmentName: response.data.data,
            });

        } catch (e) {
            this.setState({
                inLoad: false,
                messagens: MessageUtil.make({
                    title: 'Error',
                    body: e,
                }),
            });
        }
    }

    getSegmentBySegmentName = async (id) => {
        try {

            this.setState({
                inLoad: true,
            });

            const response = await this.segmentService.get(id);

            this.setState({
                inLoad: false,
                segment: response.data.data,
            });

        } catch (e) {
            this.setState({
                inLoad: false,
                messagens: MessageUtil.make({
                    title: 'Error',
                    body: e,
                }),
            });
        }
    }


    async doCreate() {
        try {
            this.setState({
                inLoad: true,
            });

            const companyService = this.companyService;
            const companyUuid = this.props.match.params.company;
            const fidelity = this.props.match.params.fidelity;

            let errosList = [];

            this.state.stateForms.forEach(function (value, index) {
                let valid = EstablishmentValidation.validate(value());
                if (valid !== true) {
                    errosList[index] = valid;
                }
            });

            if (errosList.length !== 0) {
                this.setState({
                    erros: errosList,
                    inLoad: false,
                });
                return;
            }

            await Promise.all(this.state.stateForms.map(async value => {

                let form = value();
                form.matrix = companyUuid;
                form.fidelity_program = fidelity;

                await companyService.create(form);

            }));

            this.setState({
                inLoad: false,
                messagens: MessageUtil.make({
                    title: 'Sucesso',
                    body: 'Estabelecimentos foram cadastrado, clique em "ok" para continuar',
                    okBtnToRoute: RegulationFidelityScreen.ROUTE
                        + '/'
                        + this.props.match.params.company
                        + '/'
                        + this.props.match.params.fidelity
                }),
            });

        } catch (e) {
            this.setState({
                inLoad: false,
                messagens: MessageUtil.make({
                    title: 'Atenção',
                    body: e,
                }),
            });
        }
    }

    renderEstablishment = () => {
        let render = [];
        for (let i = 1; i <= this.state.establishmentCount; i++) {
            render.push(
                <ContentEstablishment count={i}
                                      segmentName={this.state.segmentName}
                                      segment={this.state.segment}
                                      getSegmentBySegmentName={this.getSegmentBySegmentName}
                                      getState={this.getState}
                                      erros={this.state.erros[i - 1]}/>
            );
        }

        return render;
    }

    handlerCount = () => {
        this.setState({
            establishmentCount: this.state.establishmentCount + 1,
        });
    }

    validForm() {

        const form = this.state.stateForms[this.state.establishmentCount - 1]();
        const valid = EstablishmentValidation.validate(form);

        let erros = this.state.erros;

        if (valid === true) {
            erros[this.state.establishmentCount - 1] = [];
            this.setState({
                erros: erros,
            });
            return true;
        }
        erros[this.state.establishmentCount - 1] = valid;

        this.setState({
            erros: erros,
        });
    }

    addEstablishment = () => {
        const valid = this.validForm();
        if (valid !== true) {
            return;
        }

        if (this.state.establishmentCount >= 5) {
            this.setState({
                messagens: MessageUtil.make({
                    title: 'Atenção',
                    body: 'Não é permitido o cadastro de mais de 5 estabelecimentos.',
                    okButtonText: 'ok',
                }),
            });
            return;
        }

        this.setState({
            establishmentCount: this.state.establishmentCount + 1,
        });
    }

    removeEstablishment = () => {
        if (this.state.establishmentCount <= 1) {
            return;
        }

        let stateForms = this.state.stateForms;

        stateForms.splice(this.state.establishmentCount - 1, 1);

        this.setState({
            establishmentCount: this.state.establishmentCount - 1,
            stateForms: stateForms,
        });

    }

    getState = (value) => {
        let stateForms = this.state.stateForms;
        stateForms[this.state.establishmentCount - 1] = value;
        this.setState({
            stateForms: stateForms,
        });
    }

    render() {
        return (
            <div>
                <HeaderComponent messagens={this.state.messagens}
                                 inLoad={this.state.inLoad}
                                 title={'Cadastro Estabelecimento'}
                                 metaDescription={'Registre-se para criar uma conta e aproveitar as vantagens exclusivas do Meu APP de Fidelidade. É rápido, fácil e seguro. Junte-se à nossa comunidade hoje mesmo.'}/>
                <Typography align={'center'}
                            variant={'h6'}
                            color={'primary'}>
                    Cadastro dos Estabelecimentos
                </Typography>
                <br/>
                <ContentMainStyled>
                    <ContentSizeStyled>
                        {this.renderEstablishment()}
                        <ButtonMoreEstablishment addEstablishment={this.addEstablishment}/>
                        <ButtonRemoveEstablishment removeEstablishment={this.removeEstablishment}/>
                        <SpacerComponent height={40}/>
                        <Box display={'flex'}
                             flexDirection={'column'}
                             alignItems={'center'}>
                            <MobileStepper
                                variant="dots"
                                steps={3}
                                position="static"
                                activeStep={2}

                            />
                        </Box>
                        <SpacerComponent height={20}/>
                        <Button variant={'contained'}
                                color={'primary'}
                                fullWidth={true}
                                size={"large"}
                                onClick={() => this.doCreate()}>
                            Cadastrar
                        </Button>
                    </ContentSizeStyled>
                </ContentMainStyled>
            </div>
        );
    }
}

export default CompanyRegistrationEstablishment;