import React, {Component} from 'react';
import HeaderComponent from "../../../Components/HeaderComponent/HeaderComponent";
import {ContentMainStyled, ContentSizeStyled} from "./styled";
import StaticElementLoginScreen from "./StaticElementLoginScreen";
import FormLoginScreen from "./FormLoginScreen";
import RegisterElementLoginScreen from "./RegisterElementLoginScreen";
import AuthService from "../../../Services/Api/AuthService";
import MessageUtil from "../../../Utils/MessageUtil";
import LoginValidation from "../../../FormValidations/LoginValidation";
import DashboardScreen from "../../PJAccess/DashboardScreen/DashboardScreen";
import RoleUtil from "../../../Utils/RoleUtil";
import MeUtil from "../../../Utils/MeUtil";
import {SuperContext} from "../../../Contexts/SuperContext";
import ApiToken from "../../../Services/Api/ApiToken";
import ManagePaymentScreen from "../../PJAccess/ManagePaymentScreen/ManagePaymentScreen";
import DashboardAdminScreen from "../../AdminAccess/DashboardAdminScreen/DashboardAdminScreen";
import GeneralTranslation from "../../../Translations/FormValidations/GeneralTranslation";

class LoginScreen extends Component {
    static ROUTE = '/';
    static contextType = SuperContext;

    authService;

    constructor(props) {
        super(props);
        this.authService = new AuthService();

        this.state = {
            isAuth: undefined,
            messagens: null,
            inLoad: true,
            erros: [],
            redirect: null,
        };
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.state.isAuth === false) {
            return
        }
        setTimeout(() => {
            this.load();
        }, 1000);
    }

    componentDidMount() {
        if (ApiToken.get().token === null) {
            this.setState({
                inLoad: false,
            });
        }
    }

    async load() {
        try {

            const query = new URLSearchParams(this.props.location.search);
            const redirect = query.get('redirect')

            if (redirect !== null) {
                this.setState({
                    redirect: redirect,
                    inLoad: false,
                    messagens: MessageUtil.make({
                        title: 'Atenção',
                        body: 'Para continuar efetue o login.',
                    }),
                });
            }

            if (
                this.context.me.uuid === undefined
                ||
                this.context.me.uuid === null
            ) {
                throw Error('no auth');
            }

            if (this.context.isAdmin) {
                window.location.href = DashboardAdminScreen.ROUTE;
                return
            }

            window.location.href = DashboardScreen.ROUTE;

        } catch (e) {
            this.setState({
                isAuth: false,
                inLoad: false,
            });
        }
    }

    doLogin = async (form) => {
        try {
            const validate = await LoginValidation.validate(form)

            form.environment = RoleUtil.APP_WEB;

            if (validate !== true) {
                this.setState({
                    erros: validate,
                    messagens: MessageUtil.makeToValidationField(
                        new GeneralTranslation(validate).translate()
                    ),
                });
                return;
            }

            this.setState({
                inLoad: true,
            });

            await this.authService.doLogin(form);

            const me = await MeUtil.get();

            this.setState({
                inLoad: false,
            });

            if (me.data.data.roles[RoleUtil.ADMIN_UUID] !== undefined) {
                window.location.href = DashboardAdminScreen.ROUTE;
                return;
            }

            if (me.data.data.company.has_pending_payment === true) {
                window.location.href = ManagePaymentScreen.ROUTE;
                return
            }

            if (this.state.redirect !== null) {
                window.location.href = this.state.redirect;
                return;
            }

            window.location.href = DashboardScreen.ROUTE;

        } catch (e) {
            this.setState({
                inLoad: false,
                messagens: MessageUtil.make({
                    title: 'Atenção',
                    body: e,
                }),
            });
        }
    }

    render() {
        return (
            <div>
                <HeaderComponent messagens={this.state.messagens}
                                 inLoad={this.state.inLoad}
                                 title={'Meu App | Login'}
                                 metaDescription={'Faça login na sua conta Meu APP de Fidelidade para acessar seus serviços. Aproveite a experiência personalizada e segura. Não tem uma conta? Registre-se agora.'}/>
                <ContentMainStyled>
                    <ContentSizeStyled>
                        <StaticElementLoginScreen/>
                        <FormLoginScreen doLogin={this.doLogin}
                                         erros={this.state.erros}/>
                        <RegisterElementLoginScreen/>
                    </ContentSizeStyled>
                </ContentMainStyled>
            </div>
        );
    }
}

export default LoginScreen;