import React, {Component} from 'react';
import {ItemSelectGridAwardStyled, ItemSelectStyled, TitleItemSelectStyled} from "./styled";
import SpacerComponent from "../../../Components/SpacerComponent/SpacerComponent";
import {Grid} from "@material-ui/core";
import TooltipDefault from "../../../Components/TooltipComponent/TololtipDefault";

class ItemSelectCampaign extends Component {

    toRoute() {
        window.location.href = this.props.route;
    }

    render() {
        const Icon = this.props.icon;

        return (
            <ItemSelectGridAwardStyled item={true}
                  xs={12}
                  sm={12}
                  md={6}
                  lg={3}
                  xl={2}>
                <ItemSelectStyled elevation={4}
                                  list={this.props.list}
                                  onClick={() => this.toRoute()}>
                    <Icon style={{fontSize: 85}}/>
                    <SpacerComponent height={30}/>
                    {this.props.action &&
                        <TitleItemSelectStyled>{this.props.action}</TitleItemSelectStyled>
                    }
                    <TitleItemSelectStyled>{this.props.title}</TitleItemSelectStyled>
                    {this.props.tooltipText &&
                        <TooltipDefault text={this.props.tooltipText}
                                        changePosition={true}
                                        bottom={10}
                                        right={10}
                                        color={this.props.tooltipColor}/>
                    }
                </ItemSelectStyled>
            </ItemSelectGridAwardStyled>
        );
    }
}

export default ItemSelectCampaign;