import ApiClient from "./Api/ApiClient";

class FidelityService {
    constructor() {
        this.uri = 'fidelity-program';
        this.apiClient = new ApiClient();
    }

    async get() {
        try {

            return await this.apiClient.doRequest({
                method: ApiClient.GET,
                uri: this.uri,
                expectedStatus: 200,
                auth: false,
            });

        } catch (e) {
            throw e;
        }
    }

    async show(uuid) {
        try {

            return await this.apiClient.doRequest({
                method: ApiClient.GET,
                uri: this.uri + '/' + uuid,
                expectedStatus: 200,
            });

        } catch (e) {
            throw e;
        }
    }

    async getByUser() {
        try {

            return await this.apiClient.doRequest({
                method: ApiClient.GET,
                uri: this.uri + '/get-by-user?order[name]=asc',
                expectedStatus: 200,
            });

        } catch (e) {
            throw e;
        }
    }

    async create(object) {
        try {

            return await this.apiClient.doRequest({
                method: ApiClient.POST,
                uri: this.uri,
                expectedStatus: 201,
                body: object,
            });

        } catch (e) {
            throw e;
        }
    }

    async update(object, uuid) {
        try {

            return await this.apiClient.doRequest({
                method: ApiClient.PATCH,
                uri: this.uri + '/' + uuid,
                expectedStatus: 201,
                auth: false,
                body: object,
            });

        } catch (e) {
            throw e;
        }
    }

    async detachCompany(uuid) {
        try {
            return await this.apiClient.doRequest({
                method: ApiClient.POST,
                uri: 'fidelity-program/detach-company/' + uuid,
                expectedStatus: 200,
            });

        } catch (e) {
            throw e;
        }
    }

    async getRegulation(company, fidelity) {
        try {

            return await this.apiClient.doRequest({
                method: ApiClient.GET,
                uri: this.uri + '/get-regulation/' + fidelity + '?company=' + company,
                expectedStatus: 200,
            });

        } catch (e) {
            throw e;
        }
    }

}

export default FidelityService;