import React, {Component} from 'react';
import {Typography} from "@material-ui/core";
import SpacerComponent from "../../../Components/SpacerComponent/SpacerComponent";
import {ContentDatailsStyled, ContentMainDatailsStyled, ContentMainSuperStyled} from "./styled";
import ApiImageUtil from "../../../Utils/ApiImageUtil";

class DetailsConsultantModal extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const value = this.props.value;
        return (
            <ContentMainSuperStyled>
                <SpacerComponent height={100}/>
                {ApiImageUtil.tryGet(value.photo) !== '' &&
                    <>
                        <Typography variant={'h6'}
                                    align={'center'}>
                            <img src={ApiImageUtil.tryGet(value.photo)}/>
                        </Typography>
                        <Typography variant={'h6'}
                                    align={'center'}>{value.name}</Typography>
                    </>
                }
                {ApiImageUtil.tryGet(value.photo) === '' &&
                    <>
                        <Typography variant={'h6'}
                                    align={'center'}>Detalhes</Typography>
                    </>
                }
                <SpacerComponent height={30}/>
                <ContentMainDatailsStyled>
                    <ContentDatailsStyled>

                        <Typography variant={'subtitle2'}
                                    align={'left'}>Nome:</Typography>
                        <Typography variant={'body2'}
                                    align={'left'}>{value.name}</Typography>
                        <SpacerComponent height={20}/>

                        <Typography variant={'subtitle2'}
                                    align={'left'}>E-mail:</Typography>
                        <Typography variant={'body2'}
                                    align={'left'}>{value.email}</Typography>
                        <SpacerComponent height={20}/>

                        <Typography variant={'subtitle2'}
                                    align={'left'}>CEP:</Typography>
                        <Typography variant={'body2'}
                                    align={'left'}>{value.cep}</Typography>
                        <SpacerComponent height={20}/>

                        <Typography variant={'subtitle2'}
                                    align={'left'}>Número:</Typography>
                        <Typography variant={'body2'}
                                    align={'left'}>{value.number}</Typography>
                        <SpacerComponent height={20}/>

                        <Typography variant={'subtitle2'}
                                    align={'left'}>Bairro:</Typography>
                        <Typography variant={'body2'}
                                    align={'left'}>{value.neighborhood}</Typography>
                        <SpacerComponent height={20}/>

                        <Typography variant={'subtitle2'}
                                    align={'left'}>Estado</Typography>
                        <Typography variant={'body2'}
                                    align={'left'}>{value.state}</Typography>
                        <SpacerComponent height={20}/>

                    </ContentDatailsStyled>
                    <ContentDatailsStyled>

                        <Typography variant={'subtitle2'}
                                    align={'left'}>CPF:</Typography>
                        <Typography variant={'body2'}
                                    align={'left'}>{value.cpf}</Typography>
                        <SpacerComponent height={20}/>

                        <Typography variant={'subtitle2'}
                                    align={'left'}>Phone:</Typography>
                        <Typography variant={'body2'}
                                    align={'left'}>{value.phone}</Typography>
                        <SpacerComponent height={20}/>

                        <Typography variant={'subtitle2'}
                                    align={'left'}>Endereço:</Typography>
                        <Typography variant={'body2'}
                                    align={'left'}>{value.address}</Typography>
                        <SpacerComponent height={20}/>

                        <Typography variant={'subtitle2'}
                                    align={'left'}>Complemento:</Typography>
                        <Typography variant={'body2'}
                                    align={'left'}>{value.complement}</Typography>
                        <SpacerComponent height={20}/>

                        <Typography variant={'subtitle2'}
                                    align={'left'}>Cidade:</Typography>
                        <Typography variant={'body2'}
                                    align={'left'}>{value.city}</Typography>
                        <SpacerComponent height={20}/>

                        <Typography variant={'subtitle2'}
                                    align={'left'}>Desconto:</Typography>
                        <Typography variant={'body2'}
                                    align={'left'}>{value.discount}</Typography>
                        <SpacerComponent height={20}/>

                    </ContentDatailsStyled>
                </ContentMainDatailsStyled>
            </ContentMainSuperStyled>
        );
    }
}

export default DetailsConsultantModal;