import React, {Component} from 'react';
import SpacerComponent from "../../SpacerComponent/SpacerComponent";
import {Link, Typography} from "@material-ui/core";
import {HeaderContext} from "../../../Contexts/HeaderContext";
import CreateConsultantScreen from "../../../Screens/AdminAccess/CreateConsultantScreen/CreateConsultantScreen";
import ConsultantScreen from "../../../Screens/AdminAccess/ConsultantScreen/ConsultantScreen";
import CreateGoalsScreen from "../../../Screens/AdminAccess/CreateGoalsScreen/CreateGoalsScreen";
import DashboardAdminScreen from "../../../Screens/AdminAccess/DashboardAdminScreen/DashboardAdminScreen";
import PaymentStatusScreen from "../../../Screens/AdminAccess/PaymentStatusScreen/PaymentStatusScreen";
import CreateGoalsClientScreen from "../../../Screens/AdminAccess/CreateGoalsClientScreen/CreateGoalsClientScreen";

class AdminMobileMenuHeader extends Component {
    static contextType = HeaderContext;

    render() {
        return (
            <>
                <SpacerComponent height={50}/>
                <Typography variant={'h6'}>
                    <Link href={DashboardAdminScreen.ROUTE}>Administrativo</Link>
                </Typography>
                <hr/>
                <Typography variant={'h6'}>
                    <Link href={PaymentStatusScreen.ROUTE}>Controle de Clientes</Link>
                </Typography>
                <hr/>
                <Typography variant={'h5'}>
                    <Link href={'#'}>Comercial</Link>
                </Typography>
                <Typography variant={'h6'}>
                    <Link href={CreateConsultantScreen.ROUTE}>Cadastrar Consultor</Link>
                </Typography>
                <Typography variant={'h6'}>
                    <Link href={ConsultantScreen.ROUTE}>Consultores</Link>
                </Typography>
                <Typography variant={'h6'}>
                    <Link href={CreateGoalsScreen.ROUTE}>Cadastro de Metas</Link>
                </Typography>
                <Typography variant={'h6'}>
                    <Link href={CreateGoalsClientScreen.ROUTE}>Cadastro de Metas por Cliente</Link>
                </Typography>
                <hr/>
                <Typography variant={'h6'}>
                    <Link href={'#'}>Usuários Pessoa física</Link>
                </Typography>
                <Typography variant={'h6'}>
                    <Link href={'#'}>Usuários Pessoa juridica</Link>
                </Typography>
                <Typography variant={'h6'}>
                    <Link href={'#'}>FAQ</Link>
                </Typography>
                <Typography variant={'subtitle1'}>
                    <Link href={'#'}
                          onClick={this.context.logout}>Sair</Link>
                </Typography>
            </>
        );
    }
}

export default AdminMobileMenuHeader;