import React, {Component} from 'react';
import {ContentMainStyled, ContentSizeStyled} from "../../CommonAccess/LoginScreen/styled";
import TextFieldDefault from "../../../Components/TextFieldDefaultComponent/TextFieldDefault";
import SpacerComponent from "../../../Components/SpacerComponent/SpacerComponent";
import {Box, Button, MenuItem, Typography} from "@material-ui/core";
import AvatarComponent from "../../../Components/AvatarComponent/AvatarComponent";
import InputFileDefault from "../../../Components/InputFileDefault/InputFileDefault";
import CheckValidDateNewAwardScreen from "./CheckValidDateNewAwardScreen";
import BranchAmount from "./BranchAmount/BranchAmount";
import FidelityService from "../../../Services/FidelityService";
import DateUtil from "../../../Utils/DateUtil";
import ApiImageUtil from "../../../Utils/ApiImageUtil";
import TooltipDefault from "../../../Components/TooltipComponent/TololtipDefault";
import TooltipMessageUtil from "../../../Utils/TooltipMessageUtil";
import PointCalculatorComponent from "../../../Components/PointCalculatorComponent/PointCalculatorComponent";
import {ButtonDelete} from "../CashbackScreen/styled";

class FormNewAwardScreen extends Component {

    fidelityService;
    loadBranchAmount = null;

    constructor(props) {
        super(props);

        this.state = {
            imagePrevios: '',
            fidelity: [],
            companies: [],
            withoutValidDate: false,
        };
        this.fidelityService = new FidelityService();
    }

    componentDidMount() {
        this.load();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.formEdit === prevProps.formEdit) {
            return;
        }
        this.setFormEdit();
    }

    setFormEdit() {
        const newState = Object.assign(this.state, this.props.formEdit);
        newState.imagePrevios = ApiImageUtil.tryGet(this.props.formEdit.images);
        newState.start_validity = (
            this.props.formEdit.start_validity === null ? undefined : this.props.formEdit.start_validity
        );
        newState.end_validity = (
            this.props.formEdit.end_validity === null ? undefined : this.props.formEdit.end_validity
        );
        if (this.props.formEdit.start_validity === null) {
            newState.withoutValidDate = true;
        }
        this.setState(newState);
    }

    async load() {
        try {

            const response = await this.fidelityService.getByUser();

            this.setState({
                fidelity: response.data.data
            });

        } catch (e) {
            throw e;
        }
    }

    handlerSubmit() {
        this.props.doCreate(this.state);
    }

    handlerDelete = () => {
        this.props.confirmDelete();
    };

    handleChange(event) {
        const {name, value} = event.target;
        this.setState({[name]: value});
        if (name === 'fidelity_programs_uuid') {
            this.callLoadBranchAmount(value);
        }
        if (name === 'start_validity') {
            this.formatDate(name, value)
        }
    }

    formatDate(name, value) {
        this.setState({
            [name]: DateUtil.raw(value).toApiFormat()
        });
    }

    setBranchAmount = (object) => {
        this.setState({
            companies: object,
        });
    }

    setPreviosImage = (image) => {
        this.setState({
            imagePrevios: image,
        });
    }

    toBase64Image = (base64) => {
        this.setState({
            'image': base64.split(',')[1],
        });
    }

    makeFidelity() {
        let itens = [];
        this.state.fidelity.forEach(function (value, index) {
            itens.push(
                <MenuItem key={index} value={value.uuid}>{value.name}</MenuItem>
            );
        })

        return itens;
    }

    handleValidDateChange(value) {
        this.setState({
            withoutValidDate: value,
        });
    }

    getLoadBranchAmount = (handleFunction) => {
        this.loadBranchAmount = handleFunction;
    }

    callLoadBranchAmount(value) {
        this.loadBranchAmount(value);
    }

    renderSelect() {

        if (this.props.isEdit === null) {
            return <div></div>;
        }

        if (this.props.isEdit === false) {
            return this.componentSelect();
        }

        if (this.props.isEdit === true && this.state.fidelity_programs_uuid === undefined) {
            return <div></div>;
        }

        return this.componentSelect();

    }

    componentSelect() {
        return (
            <TextFieldDefault select={true}
                              name={'fidelity_programs_uuid'}
                              value={this.state.fidelity_programs_uuid}
                              onChange={(event) => this.handleChange(event)}
                              erros={this.props.erros}>
                {this.makeFidelity()}
            </TextFieldDefault>
        );
    }

    render() {
        return (
            <ContentMainStyled>
                <ContentSizeStyled>
                    <TextFieldDefault label={'Nome do prêmio *'}
                                      name={'title'}
                                      value={this.state.title}
                                      onChange={(event) => this.handleChange(event)}
                                      erros={this.props.erros}/>
                    <TextFieldDefault label={'Valor em pontos *'}
                                      name={'value_points'}
                                      value={this.state.value_points}
                                      type={'number'}
                                      onChange={(event) => this.handleChange(event)}
                                      erros={this.props.erros}/>
                    <PointCalculatorComponent doConfirm={(result) => this.setState({value_points: result})}/>
                    <TextFieldDefault label={'Regras'}
                                      name={'rules'}
                                      value={this.state.rules}
                                      type={'number'}
                                      multiline={true}
                                      onChange={(event) => this.handleChange(event)}
                                      erros={this.props.erros}
                                      rows={5}/>
                    <TooltipDefault text={TooltipMessageUtil.POINTS_MSG1}
                                    changePosition={true}
                                    top={260}
                                    marginLeft={5}/>
                    <SpacerComponent height={10}/>
                    <Typography variant={'body1'}
                                color={'primary'}>
                        Imagem
                    </Typography>
                    <Box display={'flex'}
                         flexDirection={'row'}
                         alignItems={'center'}>
                        <AvatarComponent
                            src={this.state.imagePrevios}/>
                        <SpacerComponent width={20}/>
                        <InputFileDefault setPreviosImage={this.setPreviosImage}
                                          toBase64Image={this.toBase64Image}
                                          name={'image'}
                                          erros={this.props.erros}/>
                    </Box>
                    <SpacerComponent height={20}/>
                    <TextFieldDefault label={'Descrição *'}
                                      name={'description'}
                                      value={this.state.description}
                                      onChange={(event) => this.handleChange(event)}
                                      erros={this.props.erros}
                                      multiline={true}
                                      rows={5}/>
                    <TextFieldDefault label={'SKU (Código interno do produto)'}
                                      name={'sku'}
                                      value={this.state.sku}
                                      onChange={(event) => this.handleChange(event)}
                                      erros={this.props.erros}/>
                    <SpacerComponent height={20}/>
                    <Typography variant={'body1'}
                                color={'primary'}>
                        Período de Validade
                    </Typography>
                    <Box display={'flex'}
                         flexDirection={'row'}>
                        <TextFieldDefault label={'Data Inicial *'}
                                          name={'start_validity'}
                                          value={this.state.start_validity}
                                          onChange={(event) => this.handleChange(event)}
                                          type={'date'}
                                          erros={this.props.erros}
                                          forceLabel={true}
                                          disabled={this.state.withoutValidDate}/>
                        <SpacerComponent width={40}/>
                        <TextFieldDefault label={'Data Final *'}
                                          name={'end_validity'}
                                          value={this.state.end_validity}
                                          type={'date'}
                                          onChange={(event) => this.handleChange(event)}
                                          erros={this.props.erros}
                                          forceLabel={true}
                                          disabled={this.state.withoutValidDate}/>
                    </Box>
                    <CheckValidDateNewAwardScreen onChange={(value) => this.handleValidDateChange(value)}
                                                  withoutValidDate={this.state.withoutValidDate}/>
                    <SpacerComponent height={10}/>
                    <Typography variant={'body1'}
                                color={'primary'}>
                        Programa de Fidelidade
                    </Typography>
                    {this.renderSelect()}
                    <SpacerComponent height={20}/>
                    <BranchAmount setBranchAmount={this.setBranchAmount}
                                  parentState={this.state}
                                  getLoadBranchAmount={this.getLoadBranchAmount}
                                  fidelityUuid={this.state?.fidelity_programs_uuid}/>
                    <SpacerComponent height={20}/>
                    <Box display="flex" justifyContent="center">
                        <Button variant={'contained'}
                                color={'primary'}
                                size={"large"}
                                fullWidth={true}
                                onClick={() => this.handlerSubmit()}>
                            Registrar Prêmio
                        </Button>
                    </Box>
                    {this.props.isEdit &&
                        <>
                            <SpacerComponent height={20}/>
                            <Box display="flex" justifyContent="center">
                                <ButtonDelete variant={'contained'}
                                              size={"large"}
                                              fullWidth={true}
                                              onClick={this.handlerDelete}>
                                    deletar
                                </ButtonDelete>
                            </Box>
                        </>
                    }
                </ContentSizeStyled>
            </ContentMainStyled>
        );
    }
}

export default FormNewAwardScreen;