import React, {Component} from 'react';
import {ImgStyled, ItemSelectGridAwardStyled, ItemSelectStyled, TitleItemSelectStyled} from "./styled";
import SpacerComponent from "../../../Components/SpacerComponent/SpacerComponent";

class ItemExportClientData extends Component {
    render() {
        const Icon = this.props.icon;

        return (
            <ItemSelectGridAwardStyled item={true}
                                       xs={12}
                                       sm={12}
                                       md={6}
                                       lg={3}
                                       xl={2}>
                <ItemSelectStyled elevation={4}
                                  list={true}
                                  onClick={() => this.props.click()}>
                    {this.props.icon &&
                        <Icon style={{fontSize: 85}}/>
                    }
                    {this.props.img &&
                        <ImgStyled src={this.props.img}/>
                    }
                    <SpacerComponent height={30}/>
                    <TitleItemSelectStyled>{this.props.title}</TitleItemSelectStyled>
                </ItemSelectStyled>
            </ItemSelectGridAwardStyled>
        );
    }
}

export default ItemExportClientData;