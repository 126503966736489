import React, {Component} from 'react';
import ExtractService from "../../../Services/ExtractService";
import {ExtractContext} from "../../../Contexts/ExtractContext";
import {ContentImgStyled, H3Styled, ImgStyled, MainContentStyled, P1Styled, PrintButtonStyled} from "./styled";
import {Typography} from "@material-ui/core";
import JsPDF from "jspdf";
import PrintIcon from '@material-ui/icons/Print';
import DateUtil from "../../../Utils/DateUtil";

class PdfExtractPage extends Component {
    static contextType = ExtractContext;
    static ROUTE = '/extract/pdf';

    constructor(props) {
        super(props);

        this.state = {
            data: null
        };

        this.extractService = new ExtractService();
    }

    componentDidMount() {
        this.load();
    }

    load = async () => {
        const response = await this.extractService.getReceipt(this.props.match.params.key);

        this.setState({
            data: response.data.data,
        });
    }

    makePdf = () => {
        document.title = `comprovante_meu_app_${this.state.data.award_booking.client.user.name}.pdf`;
        window.print();
    }

    render() {
        if (this.state.data == null) {
            return <></>;
        }

        const data = this.state.data;

        return (
            <MainContentStyled>
                <ContentImgStyled>
                    <ImgStyled src="https://homolog.meuappdefidelidade.com.br/static/media/logo_login.8bc432d2.png"/>
                </ContentImgStyled>
                <H3Styled>Meu App de Fidelidade</H3Styled>
                <br/><br/>
                <P1Styled>Comprovante</P1Styled>
                <br/>
                <p>Eu, {data.award_booking.client.user.name}, CPF: {data.award_booking.client.cpf}, recebi
                    de {data.award_booking.award_company.company.social_reason},
                    CNPJ: {data.award_booking.award_company.company.cnpj}, a seguinte premiação:</p>
                <p>Prêmio: {data.award_booking.award_company.award.title}</p>
                <p>Quantidade: {data.award_booking.amount}</p>
                <p>Regra:</p>
                <p>{data.award_booking.award_company.award.rules}</p>
                <p>Ganha pela participação no programa de
                    fidelidade: {data.award_booking.award_company.award.fidelityProgram.name}</p>
                <p>Data: {DateUtil.raw(data.created_at).toDate()}</p>
                <p>Assinatura</p>
                <br/><br/><br/>
                <p>Chave: {data.award_booking.uuid}</p>
                <PrintButtonStyled onClick={this.makePdf}>
                    <PrintIcon/>
                </PrintButtonStyled>
            </MainContentStyled>
        );
    }
}

export default PdfExtractPage;