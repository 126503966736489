import React, {Component} from 'react';
import {ContentMainStyled, ContentQtdStyled, ContentSizeStyled} from "../../CommonAccess/LoginScreen/styled";
import TextFieldDefault from "../../../Components/TextFieldDefaultComponent/TextFieldDefault";
import SpacerComponent from "../../../Components/SpacerComponent/SpacerComponent";
import {Box, Button, Grid, MenuItem, Typography} from "@material-ui/core";
import AvatarComponent from "../../../Components/AvatarComponent/AvatarComponent";
import InputFileDefault from "../../../Components/InputFileDefault/InputFileDefault";
import CheckValidDateCampaingNameScreen from "./CheckValidDateCampaingNameScreen";
import FidelityService from "../../../Services/FidelityService";
import DateUtil from "../../../Utils/DateUtil";
import ApiImageUtil from "../../../Utils/ApiImageUtil";
import SkUs from "./SKUs/SKUs";
import {InstantFormContext} from "../../../Contexts/InstantFormContext";
import BranchAmount from "../NewAward/BranchAmount/BranchAmount";
import {ButtonDelete} from "../CampaignScreen/styled";
import MessageUtil from "../../../Utils/MessageUtil";
import {CashbackContext} from "../../../Contexts/CashbackContext";
import CheckBoxDefaultComponent from "../../../Components/CheckBoxDefaultComponent/CheckBoxDefaultComponent";

class FormCampaignNameScreen extends Component {

    static contextType = CashbackContext;
    fidelityService;
    loadBranchAmount = null;

    constructor(props) {
        super(props);

        this.state = {
            imagePrevios: '',
            imagePreviosAward: '',
            fidelity: [],
            companies: [],
            withoutValidDate: false,
            allow_sum_points: false,
        };
        this.fidelityService = new FidelityService();
        this.skusChilds = [];
    }

    componentDidMount() {
        this.load();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.formEdit === prevProps.formEdit) {
            return;
        }
        this.setFormEdit();
    }

    setFormEdit() {
        const newState = Object.assign(this.state, this.props.formEdit);
        newState.award_name = this.props.formEdit.award.title;
        newState.imagePrevios = ApiImageUtil.tryGet(this.props.formEdit.image);
        newState.imagePreviosAward = ApiImageUtil.tryGet(this.props.formEdit.award.images);
        newState.start_validity = (
            this.props.formEdit.start_at === null ? undefined : this.props.formEdit.start_at
        );
        newState.end_validity = (
            this.props.formEdit.end_at === null ? undefined : this.props.formEdit.end_at
        );
        if (this.props.formEdit.start_at === null) {
            newState.withoutValidDate = true;
        }
        this.setState(newState);
    }

    async load() {
        try {

            const response = await this.fidelityService.getByUser();

            this.setState({
                fidelity: response.data.data
            });

        } catch (e) {
            throw e;
        }
    }

    handlerSubmit = () => {
        this.props.doCreate(this.state, this.skusChilds);
    }

    handleChange(event) {
        const {name, value} = event.target;
        if (name === 'fidelity_program_uuid') {
            this.callLoadBranchAmount(value);
        }
        if (name === 'start_validity') {
            this.formatDate(name, value)
        }
        this.setState({[name]: value});
    }

    formatDate(name, value) {
        this.setState({
            [name]: DateUtil.raw(value).toApiFormat()
        });
    }

    setBranchAmount = (object) => {
        this.setState({
            companies: object,
        });
    }

    setPreviosImage = (image) => {
        this.setState({
            imagePrevios: image,
        });
    }

    toBase64Image = (base64) => {
        this.setState({
            'image': base64.split(',')[1],
        });
    }

    setPreviosImageAward = (image) => {
        this.setState({
            imagePreviosAward: image,
        });
    }

    toBase64ImageAward = (base64) => {
        this.setState({
            'award_image': base64.split(',')[1],
        });
    }

    makeFidelity() {
        let itens = [];
        this.state.fidelity.forEach(function (value, index) {
            itens.push(
                <MenuItem key={index} value={value.uuid}>{value.name}</MenuItem>
            );
        })

        return itens;
    }

    handleValidDateChange(value) {
        this.setState({
            withoutValidDate: value,
        });
    }

    getLoadBranchAmount = (handleFunction) => {
        this.loadBranchAmount = handleFunction;
    }

    callLoadBranchAmount(value) {
        this.loadBranchAmount(value);
    }

    renderSelect() {

        if (this.props.isEdit === null) {
            return <div></div>;
        }

        if (this.props.isEdit === false) {
            return this.componentSelect();
        }

        if (this.props.isEdit === true && this.state.fidelity_program_uuid === undefined) {
            return <div></div>;
        }

        return this.componentSelect();

    }

    componentSelect() {
        return (
            <TextFieldDefault select={true}
                              name={'fidelity_program_uuid'}
                              value={this.state.fidelity_program_uuid}
                              onChange={(event) => this.handleChange(event)}
                              erros={this.props.erros}
                              disabled={this.props.isEdit}>
                {this.makeFidelity()}
            </TextFieldDefault>
        );
    }

    setSKUSChildsContext = (value) => {
        this.skusChilds.push(value);
    }

    removeSKUSChildsContext = () => {
        this.skusChilds.splice(-1);
    }

    render() {
        return (
            <InstantFormContext.Provider value={{
                setSKUSChildsContext: this.setSKUSChildsContext,
                removeSKUSChildsContext: this.removeSKUSChildsContext,
                formData: this.props.formData,
                isEdit: this.props.isEdit,
            }}>
                <ContentMainStyled>
                    <ContentSizeStyled>
                        <TextFieldDefault label={'Titulo *'}
                                          name={'title'}
                                          value={this.state.title}
                                          onChange={(event) => this.handleChange(event)}
                                          erros={this.props.erros}
                                          disabled={this.props.isEdit}/>
                        <CheckBoxDefaultComponent checked={this.state.allow_sum_points}
                                                  disabled={this.props.isEdit}
                                                  onChange={() => this.setState({
                                                      allow_sum_points: !this.state.allow_sum_points,
                                                  })}
                                                  label={'Atribuir Pontuação'}/>
                        <SpacerComponent height={20}/>
                        <SkUs/>
                        <SpacerComponent height={10}/>
                        <Typography variant={'body1'}
                                    color={'primary'}>
                            Imagem
                        </Typography>
                        <Box display={'flex'}
                             flexDirection={'row'}
                             alignItems={'center'}>
                            <AvatarComponent
                                src={this.state.imagePrevios}/>
                            <SpacerComponent width={20}/>
                            <InputFileDefault setPreviosImage={this.setPreviosImage}
                                              toBase64Image={this.toBase64Image}
                                              name={'image'}
                                              erros={this.props.erros}
                                              disabled={this.props.isEdit}
                                              onMaxSize={(value) => {
                                                  this.context.parent.setState({
                                                      messagens: MessageUtil.make({
                                                          title: 'Atenção',
                                                          body: `Arquivo não pode ter mais que ${value} mb`
                                                      }),
                                                  })
                                              }}/>
                        </Box>
                        <SpacerComponent height={20}/>
                        <TextFieldDefault label={'SKU (Código interno do produto)'}
                                          name={'sku'}
                                          value={this.state.sku}
                                          onChange={(event) => this.handleChange(event)}
                                          erros={this.props.erros}
                                          disabled={this.props.isEdit}/>
                        <TextFieldDefault label={'Prêmio nome'}
                                          name={'award_name'}
                                          value={this.state.award_name}
                                          onChange={(event) => this.handleChange(event)}
                                          erros={this.props.erros}
                                          disabled={this.props.isEdit}/>
                        <SpacerComponent height={10}/>
                        <Typography variant={'body1'}
                                    color={'primary'}>
                            Prêmio Imagem
                        </Typography>
                        <Box display={'flex'}
                             flexDirection={'row'}
                             alignItems={'center'}>
                            <AvatarComponent
                                src={this.state.imagePreviosAward}/>
                            <SpacerComponent width={20}/>
                            <InputFileDefault setPreviosImage={this.setPreviosImageAward}
                                              toBase64Image={this.toBase64ImageAward}
                                              name={'award_image'}
                                              erros={this.props.erros}
                                              onMaxSize={(value) => {
                                                  this.context.parent.setState({
                                                      messagens: MessageUtil.make({
                                                          title: 'Atenção',
                                                          body: `Arquivo não pode ter mais que ${value} mb`
                                                      }),
                                                  })
                                              }}/>
                        </Box>
                        <SpacerComponent height={20}/>
                        <SpacerComponent height={20}/>
                        <Typography variant={'body1'}
                                    color={'primary'}>
                            Período de Validade
                        </Typography>
                        <Box display={'flex'}
                             flexDirection={'row'}>
                            <TextFieldDefault label={'Data Inicial *'}
                                              name={'start_at'}
                                              value={this.state.start_at}
                                              onChange={(event) => this.handleChange(event)}
                                              type={'date'}
                                              erros={this.props.erros}
                                              forceLabel={true}
                                              disabled={this.state.withoutValidDate}/>
                            <SpacerComponent width={40}/>
                            <TextFieldDefault label={'Data Final *'}
                                              name={'end_at'}
                                              value={this.state.end_at}
                                              type={'date'}
                                              onChange={(event) => this.handleChange(event)}
                                              erros={this.props.erros}
                                              forceLabel={true}
                                              disabled={this.state.withoutValidDate}/>
                        </Box>
                        <CheckValidDateCampaingNameScreen onChange={(value) => this.handleValidDateChange(value)}
                                                          withoutValidDate={this.state.withoutValidDate}/>
                        <SpacerComponent height={30}/>
                        <Typography variant={'body1'}
                                    color={'primary'}>
                            Programa de Fidelidade
                        </Typography>
                        {this.renderSelect()}
                        <SpacerComponent height={20}/>
                        <BranchAmount setBranchAmount={this.setBranchAmount}
                                      parentState={this.state}
                                      getLoadBranchAmount={this.getLoadBranchAmount}
                                      fidelityUuid={this.state?.fidelity_program_uuid}
                                      amountForce={this.props.formEdit.amount}/>
                        {this.props.isEdit &&
                            <>
                                <SpacerComponent height={40}/>
                                <Box display="flex" justifyContent="center">
                                    <Button variant={'contained'}
                                            color={'primary'}
                                            size={"large"}
                                            fullWidth={true}
                                            onClick={this.handlerSubmit}>
                                        Salvar
                                    </Button>
                                </Box>
                                <SpacerComponent height={20}/>
                            </>
                        }
                        {this.props.isEdit === false &&
                            <Box display="flex" justifyContent="center">
                                <Button variant={'contained'}
                                        color={'primary'}
                                        size={"large"}
                                        fullWidth={true}
                                        onClick={this.handlerSubmit}>
                                    Registrar Prêmio
                                </Button>
                            </Box>
                        }
                        {this.props.isEdit &&
                            <Box display="flex" justifyContent="center">
                                <ButtonDelete variant={'contained'}
                                              size={"large"}
                                              fullWidth={true}
                                              onClick={() => this.props.doDelete(this.state.uuid)}>
                                    Deletar
                                </ButtonDelete>
                            </Box>
                        }
                    </ContentSizeStyled>
                </ContentMainStyled>
            </InstantFormContext.Provider>
        );
    }
}

export default FormCampaignNameScreen;