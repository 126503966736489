import styled from "styled-components";
import {Avatar} from "@material-ui/core";

export const AvatarCircleStyled = styled(Avatar)`
  border-radius: 50%;
  border-style: solid;
  border-width: 2px;
  border-color: ${'#' + process.env.REACT_APP_SECONDARY_COLOR};
  width: 60px;
  height: 60px;
  padding: 5px;
  img {
  border-radius: 50%;
  }
`;