import * as yup from 'yup';
import ValidationUtil from "../Utils/ValidationUtil";

class GiftCardPurchaseValidation {

    static async validate(form, min = 9999, max = 9999) {
        try {
            if (form === null) {
                form = {};
            }
            console.log(min, max);
            let objectValidade = {
                card_identificator: yup
                    .string()
                    .required(),
                value: yup
                    .number(ValidationUtil.MSG_FIELD_NUMBER)
                    .required(ValidationUtil.MSG_FIELD_REQUIRED)
                    .typeError(ValidationUtil.MSG_FIELD_NUMBER)
                    .positive(ValidationUtil.MSG_FIELD_POSITIVE)
                    .min(min, ValidationUtil.MSG_FIELD_MIN_NUMBER)
                    .max(max, ValidationUtil.MSG_FIELD_MAX_NUMBER),
                amount: yup
                    .number(ValidationUtil.MSG_FIELD_NUMBER)
                    .max(999999, ValidationUtil.MSG_FIELD_MAX_NUMBER)
                    .typeError(ValidationUtil.MSG_FIELD_NUMBER)
                    .positive(ValidationUtil.MSG_FIELD_POSITIVE)
                    .integer(ValidationUtil.MSG_INTERGER)
                    .required(ValidationUtil.MSG_FIELD_REQUIRED),
            };

            let validate = yup.object().shape(objectValidade);

            validate.validateSync(form, {abortEarly: false})

            return true;

        } catch (e) {
            let erros = [];
            e.inner.forEach(function (value, index) {
                erros[value.path] = value.message;
            });

            return erros;
        }
    }

}

export default GiftCardPurchaseValidation;