import React, {Component} from 'react';
import {Grid} from "@material-ui/core";
import TextFieldDefault from "../../../../Components/TextFieldDefaultComponent/TextFieldDefault";
import {InstantFormContext} from "../../../../Contexts/InstantFormContext";

class ItemSkUs extends Component {
    static contextType = InstantFormContext;

    constructor(props) {
        super(props);

        this.state = {};
    }

    componentDidMount() {
        this.context.setSKUSChildsContext(this);
        if (this.props.value === undefined) {
            return;
        }

        this.setState({
            sku: this.props.value.sku,
            qtd: this.props.value.qtd,
        });

    }

    handleChange(event) {
        const {name, value} = event.target;

        let newValue = value;

        if(name === 'qtd'){
            const NON_DIGIT = '/[^\d]/g';

            newValue = value.replace(/\D/g, "");
            newValue = parseInt(newValue.toString().replace(NON_DIGIT, ''))

            if (isNaN(newValue)) {
                newValue = 0;
            }
        }

        this.setState({[name]: newValue});
    }

    render() {
        return (
            <>
                <Grid item={true}
                      xs={8}>
                    <TextFieldDefault label={'SKU (Cód. interno do produto)*'}
                                      name={'sku'}
                                      value={this.state.sku}
                                      onChange={(event) => this.handleChange(event)}
                                      erros={this.props.erros}/>
                </Grid>
                <Grid item={true}
                      xs={1}/>
                <Grid item={true}
                      xs={3}>
                    <TextFieldDefault label={'Qtd'}
                                      name={'qtd'}
                                      type={'number'}
                                      value={this.state.qtd}
                                      onChange={(event) => this.handleChange(event)}
                                      erros={this.props.erros}/>
                </Grid>
            </>
        );
    }
}

export default ItemSkUs;