import React, {Component} from 'react';
import TextFieldDefault from "../../../Components/TextFieldDefaultComponent/TextFieldDefault";
import SpacerComponent from "../../../Components/SpacerComponent/SpacerComponent";
import {Button,} from "@material-ui/core";
import {ContentButton} from "./styled";

class FormForgotPasswordScreen extends Component {
    constructor(props) {
        super(props);

        this.state = {

        };
    }

    doForgot() {
        this.props.doForgot(this.state);
    }

    handleChange(event) {
        const {name, value} = event.target;
        this.setState({[name]: value});
    }

    toLogin() {
        window.location.href = '/';
    }

    render() {
        return (
            <div>
                <SpacerComponent height={40}/>
                <TextFieldDefault label={'Email'}
                                  name={'email'}
                                  type={'email'}
                                  erros={this.props.erros}
                                  onChange={(event) => this.handleChange(event)}/>
                <SpacerComponent height={30}/>
                <ContentButton>
                    <Button variant={'contained'}
                            color={'primary'}
                            fullWidth={true}
                            size={"large"}
                            onClick={() => this.doForgot()}>
                        Enviar
                    </Button>
                    <SpacerComponent height={20}/>
                    <Button variant={'outlined'}
                            color={'primary'}
                            fullWidth={true}
                            size={"large"}
                            onClick={() => this.toLogin()}>
                        Fazer login
                    </Button>
                </ContentButton>
            </div>
        );
    }
}

export default FormForgotPasswordScreen;