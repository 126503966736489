import AuthService from "../Services/Api/AuthService";

class MeUtil {

    authService;

    constructor() {
        this.authService = new AuthService();
    }

    static async get() {
        const meUtil = new MeUtil();
        return await meUtil.get();
    }

    static async getUuidMatrix() {
        const meUtil = new MeUtil();
        let response = await meUtil.get();

        if (response.data.data.company.matrix_uuid === null) {
            return response.data.data.company.uuid;
        }

        return response.data.data.company.matrix_uuid;
    }

    async get() {
        try {
            return this.authService.me();
        } catch (e) {
            throw e;
        }
    }
}

export default MeUtil;