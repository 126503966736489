import React, {Component} from 'react';
import {Box, IconButton, Typography} from "@material-ui/core";

import {
    ModalTermCloseStyled,
    ModalTermContentStyled,
    ModalTermControlPageStyled,
    ModalTermStyled, TypographyTermStyled
} from "./styled";
import {Document, Page, pdfjs} from 'react-pdf';
import CloseIcon from '@material-ui/icons/Close';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import SpacerComponent from "../SpacerComponent/SpacerComponent";

class TermModalComponent extends Component {
    constructor(props) {
        super(props);

        this.state = {
            openModal: false,
            numPages: null,
            pageNumber: 1,
            titleTerm: '',
            file: null,
        };
    }

    componentDidMount() {
        this.props.onStart(this.openCloseModal);
        pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;
    }

    openCloseModal = (title = '', file = null) => {
        this.setState({
            openModal: !this.state.openModal,
            titleTerm: title,
            file: file,
            pageNumber: 1,
        });
    }

    onDocumentLoadSucess = ({numPages}) => {
        this.setState({
            numPages: numPages,
        });
    }

    nextPage = () => {

        if (this.state.numPages < (this.state.pageNumber + 1)) {
            return;
        }

        this.setState({
            pageNumber: (this.state.pageNumber + 1),
        });
    }

    previosPage = () => {

        if ((this.state.pageNumber - 1) <= 0) {
            return;
        }

        this.setState({
            pageNumber: (this.state.pageNumber - 1),
        });

    }

    render() {
        return (
            <>
                {this.state.openModal &&
                    <ModalTermStyled elevation={3}>
                        <ModalTermCloseStyled>
                            <CloseIcon onClick={this.openCloseModal}/>
                        </ModalTermCloseStyled>
                        <Typography variant={'h6'}>
                            {this.state.titleTerm}
                        </Typography>
                        <Typography variant={'subtitle2'}
                                    color={'primary'}>
                            <a href={this.state.file}
                               download={true}>Clique aqui caso queira baixar o termo</a>
                        </Typography>
                        <SpacerComponent height={20}/>
                        <ModalTermContentStyled>
                            <Document file={this.state.file}
                                      loading={'Carregando...'}
                                      error={'Não foi possivel carregar, tente novamente mais tarde'}
                                      onLoadSuccess={this.onDocumentLoadSucess}>
                                <Page pageNumber={this.state.pageNumber}
                                      width={800}/>
                            </Document>
                        </ModalTermContentStyled>
                        <ModalTermControlPageStyled>
                            <IconButton color={'primary'}
                                        onClick={this.previosPage}>
                                <ChevronLeftIcon/>
                            </IconButton>
                            <Typography variant={'body1'}
                                        color={"primary"}>
                                Pagina {this.state.pageNumber} de {this.state.numPages}
                            </Typography>
                            <IconButton color={'primary'}
                                        onClick={this.nextPage}>
                                <ChevronRightIcon/>
                            </IconButton>
                        </ModalTermControlPageStyled>
                    </ModalTermStyled>
                }
            </>
        );
    }

}

export default TermModalComponent;