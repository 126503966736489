import React, {Component} from 'react';
import TextFieldDefault from "../../../Components/TextFieldDefaultComponent/TextFieldDefault";
import {Box, Button, MenuItem, Typography} from "@material-ui/core";
import SpacerComponent from "../../../Components/SpacerComponent/SpacerComponent";

class FormCreateGoalsScreen extends Component {
    constructor(props) {
        super(props);

        this.state = {};
    }

    handleChange = (event) => {
        const {name, value} = event.target;
        this.setState({
            [name]: value,
        });
    }

    makeItemSelect() {
        const itens = [];

        this.props.consultants.map((value) => {
            itens.push(
                <MenuItem value={value.uuid}>{value.name}</MenuItem>
            );
        });

        return itens;
    }

    render() {
        return (
            <>
                <TextFieldDefault label={'Consultores *'}
                                  name={'consultant_uuid'}
                                  value={this.state.consultant_uuid}
                                  select={true}
                                  onChange={(event) => this.handleChange(event)}
                                  erros={this.props.erros}>
                    {this.makeItemSelect()}
                </TextFieldDefault>
                <Box display={'flex'}
                     flexDirection={'row'}
                     alignItems={'center'}>
                    <TextFieldDefault label={'Meta *'}
                                      name={'meta'}
                                      money={true}
                                      moneyPrefix={'R$ '}
                                      value={this.state.meta}
                                      onChange={(event) => this.handleChange(event)}
                                      erros={this.props.erros}/>
                </Box>
                <Box display={'flex'}
                     flexDirection={'row'}
                     alignItems={'center'}>
                    <TextFieldDefault label={'Sobre-meta'}
                                      name={'sobremeta'}
                                      money={true}
                                      moneyPrefix={'R$ '}
                                      value={this.state.sobremeta}
                                      onChange={(event) => this.handleChange(event)}
                                      erros={this.props.erros}/>
                </Box>
                <Box display={'flex'}
                     flexDirection={'row'}
                     alignItems={'center'}>
                    <TextFieldDefault label={'Super-meta'}
                                      name={'supermeta'}
                                      money={true}
                                      moneyPrefix={'R$ '}
                                      value={this.state.supermeta}
                                      onChange={(event) => this.handleChange(event)}
                                      erros={this.props.erros}/>
                </Box>
                <Typography variant={'subtitle1'}
                            color={'primary'}>
                    Duração Campanha
                </Typography>
                <Box display={'flex'}
                     flexDirection={'row'}>
                    <TextFieldDefault label={'Data Inicial *'}
                                      name={'start_date'}
                                      value={this.state.start_date}
                                      onChange={(event) => this.handleChange(event)}
                                      type={'date'}
                                      erros={this.props.erros}
                                      forceLabel={true}
                                      disabled={this.state.withoutValidDate}/>
                    <SpacerComponent width={40}/>
                    <TextFieldDefault label={'Data Final *'}
                                      name={'end_date'}
                                      value={this.state.end_date}
                                      type={'date'}
                                      onChange={(event) => this.handleChange(event)}
                                      erros={this.props.erros}
                                      forceLabel={true}
                                      disabled={this.state.withoutValidDate}/>
                </Box>
                <SpacerComponent height={50}/>
                <Button variant={'contained'}
                        color={'primary'}
                        fullWidth={true}
                        onClick={() => this.props.create(this.state)}>
                    Cadastrar Metas
                </Button>
            </>
        );
    }
}

export default FormCreateGoalsScreen;