import React, {Component} from 'react';
import {PrivateRoute} from "../Utils/RouterUtil";
import DashboardAdminScreen from "../Screens/AdminAccess/DashboardAdminScreen/DashboardAdminScreen";
import RoleUtil from "../Utils/RoleUtil";
import CreateConsultantScreen from "../Screens/AdminAccess/CreateConsultantScreen/CreateConsultantScreen";
import ConsultantScreen from "../Screens/AdminAccess/ConsultantScreen/ConsultantScreen";
import CreateGoalsScreen from "../Screens/AdminAccess/CreateGoalsScreen/CreateGoalsScreen";
import PaymentStatusScreen from "../Screens/AdminAccess/PaymentStatusScreen/PaymentStatusScreen";
import CreateGoalsClientScreen from "../Screens/AdminAccess/CreateGoalsClientScreen/CreateGoalsClientScreen";
import CompanyNotRegisteredScreen from "../Screens/AdminAccess/CompanyNotRegisteredScreen/CompanyNotRegisteredScreen";

export default [
    <PrivateRoute exact={true}
                  path={CreateConsultantScreen.ROUTE}
                  component={CreateConsultantScreen}
                  needRole={[RoleUtil.ADMIN_NAME,]}
                  key={'admin-1'}/>,
    <PrivateRoute exact={true}
                  path={DashboardAdminScreen.ROUTE}
                  component={DashboardAdminScreen}
                  needRole={[RoleUtil.ADMIN_NAME,]}
                  key={'admin-2'}/>,
    <PrivateRoute exact={true}
                  path={ConsultantScreen.ROUTE}
                  component={ConsultantScreen}
                  needRole={[RoleUtil.ADMIN_NAME,]}
                  key={'admin-3'}/>,
    <PrivateRoute exact={true}
                  path={CreateGoalsScreen.ROUTE}
                  component={CreateGoalsScreen}
                  needRole={[RoleUtil.ADMIN_NAME,]}
                  key={'admin-4'}/>,
    <PrivateRoute exact={true}
                  path={PaymentStatusScreen.ROUTE}
                  component={PaymentStatusScreen}
                  needRole={[RoleUtil.ADMIN_NAME,]}
                  key={'admin-4'}/>,
    <PrivateRoute exact={true}
                  path={CreateGoalsClientScreen.ROUTE}
                  component={CreateGoalsClientScreen}
                  needRole={[RoleUtil.ADMIN_NAME,]}
                  key={'admin-4'}/>,
    <PrivateRoute exact={true}
                  path={CompanyNotRegisteredScreen.ROUTE}
                  component={CompanyNotRegisteredScreen}
                  needRole={[RoleUtil.ADMIN_NAME,]}
                  key={'admin-5'}/>,
];