import ApiClient from "./Api/ApiClient";

class ContactService {
    constructor() {
        this.uri = 'contact';
        this.apiClient = new ApiClient();
    }

    async create(object) {
        try {

            return await this.apiClient.doRequest({
                method: ApiClient.POST,
                uri: this.uri,
                expectedStatus: 200,
                body: object,
            });

        } catch (e) {
            throw e;
        }
    }

}

export default ContactService;