import React, {Component} from 'react';
import {Button, Typography} from "@material-ui/core";
import {
    CardCheckPointStyled,
    ContentButtonCardCheckPointStyled,
    ContentCardCheckPointStyled,
    ContentCNPJBlock,
    ItemCheckStyled
} from "./styled";
import SpacerComponent from "../../../Components/SpacerComponent/SpacerComponent";
import CursorComponent from "../../../Components/CursorComponent/CursorComponent";

class CheckPointsComponent extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        if (!this.props.data) {
            return <></>;
        }

        return (
            <CardCheckPointStyled>
                <ContentCardCheckPointStyled>
                    <CursorComponent>
                        <Typography align={'right'}
                                    variant={'body2'}
                                    onClick={this.props.closeCheck}>Fechar</Typography>
                    </CursorComponent>
                    <Typography variant={'h6'}
                                align={'center'}>Verifique os dados abaixo antes de confirmar</Typography>
                    <SpacerComponent height={50}/>
                    {this.props.data.map((value, index) => {
                        return <ItemCheckStyled>
                            <Typography variant={'subtitle2'}>CPF:</Typography>
                            <Typography variant={'body1'}>{value.cpf}</Typography>
                            <SpacerComponent height={10}/>
                            <Typography variant={'subtitle2'}>Cliente:</Typography>
                            <Typography variant={'body1'}>{value.name}</Typography>
                            <SpacerComponent height={10}/>
                            <Typography variant={'subtitle2'}>Pontos:</Typography>
                            <Typography variant={'body1'}>{value.points}</Typography>
                        </ItemCheckStyled>;
                    })}
                    <div>
                        <SpacerComponent height={50}/>
                        <Button variant={'contained'}
                                color={'primary'}
                                size={"large"}
                                fullWidth={true}
                                onClick={this.props.isCSV ? this.props.confirmReleaseCSV : this.props.confirmRelease}>
                            Pontuar
                        </Button>
                    </div>
                </ContentCardCheckPointStyled>
            </CardCheckPointStyled>
        );
    }
}

export default CheckPointsComponent;