import ApiClient from "./Api/ApiClient";
import DateUtil from "../Utils/DateUtil";

class ConsultantService {

    constructor() {
        this.uri = 'consultant';
        this.apiClient = new ApiClient();
    }

    async get(filters = '') {
        try {

            return await this.apiClient.doRequest({
                method: ApiClient.GET,
                uri: this.uri + `?paginate=false${filters}`,
                expectedStatus: 200,
            });

        } catch (e) {
            throw e;
        }
    }

    async create(object) {
        try {

            return await this.apiClient.doRequest({
                method: ApiClient.POST,
                uri: this.uri,
                expectedStatus: 201,
                body: object,
            });

        } catch (e) {
            throw e;
        }
    }

    async getDataChart({
                           initial = DateUtil.getDateRangeFormat(7).initial,
                           final = DateUtil.getDateRangeFormat(7).final,
                           uuid,
                       }) {
        try {

            return await this.apiClient.doRequest({
                method: ApiClient.GET,
                uri: `consultant/goals-dashboard?start_date=${initial}&end_date=${final}&consultant=${uuid}`,
                expectedStatus: 200,
            });

        } catch (e) {
            throw e;
        }
    }

}

export default ConsultantService;