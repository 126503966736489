import styled from 'styled-components';
import {Accordion} from "@material-ui/core";

export const ContentSecondaryStyled = styled.div`
  width: 60%;

  @media only screen and (max-width: 600px) {
    width: 100%;
    padding: 20px;
  }
`;

export const AccordionStyled = styled(Accordion)`
  width: 100%;
`;