import React, {Component} from 'react';
import {ContentFilterStyled} from "./styled";
import TextFieldDefault from "../../../Components/TextFieldDefaultComponent/TextFieldDefault";
import {Button, MenuItem, Typography} from "@material-ui/core";
import SpacerComponent from "../../../Components/SpacerComponent/SpacerComponent";
import CheckBoxDefaultComponent from "../../../Components/CheckBoxDefaultComponent/CheckBoxDefaultComponent";
import RoleUtil from "../../../Utils/RoleUtil";

class FiltersConsultantScreen extends Component {
    constructor(props) {
        super(props);

        this.state = {
        };
    }

    componentDidMount() {
        this.props.filtersPaymentStatusScreen(
            this
        );
    }

    handleChange(event) {
        const {name, value} = event.target;
        this.setState({
            [name]: value,
        });
    }

    render() {
        return (
            <ContentFilterStyled>
                <div>
                    <TextFieldDefault label={'Data Inicial'}
                                      name={'start_date'}
                                      fullWidth={false}
                                      type={'date'}
                                      forceLabel={true}
                                      onChange={(event) => this.handleChange(event)}/>
                    <SpacerComponent width={250}/>
                    <TextFieldDefault label={'Data Final'}
                                      name={'end_date'}
                                      fullWidth={false}
                                      type={'date'}
                                      forceLabel={true}
                                      onChange={(event) => this.handleChange(event)}/>
                    <SpacerComponent width={250}/>
                    <TextFieldDefault select={true}
                                      defaultValue={1}
                                      name={'role'}
                                      onChange={(event) => this.handleChange(event)}
                                      disabled={this.state.noLimitPoint}>
                        <MenuItem key={'1'} value={undefined}>Sem filtro</MenuItem>
                        <MenuItem key={'2'} value={RoleUtil.COORDENADOR}>COORDENADOR</MenuItem>
                        <MenuItem key={'3'} value={RoleUtil.CONSULTOR_REGIONAL}>CONSULTOR REGIONAL</MenuItem>
                        <MenuItem key={'4'} value={RoleUtil.CONSULTOR}>CONSULTORES COMERCIAIS</MenuItem>
                    </TextFieldDefault>
                </div>
                <Button variant={'contained'}
                        color={'primary'}
                        onClick={() => this.props.load(true)}>
                    Buscar
                </Button>
            </ContentFilterStyled>
        );
    }
}

export default FiltersConsultantScreen;