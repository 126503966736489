import React, {Component} from 'react';
import {CardSimulationStyled, ContentMainCardSimulationStyled} from "./styled";
import {Typography} from "@material-ui/core";
import {ReactComponent as VisaLogo} from "../../../../Assets/visa-logo.svg";
import {ReactComponent as MasterCardLogo} from "../../../../Assets/mastercard-logo.svg";
import SpacerComponent from "../../../../Components/SpacerComponent/SpacerComponent";
import {ReactComponent as Amex} from "../../../../Assets/amex.svg";
import {ReactComponent as DinersClub} from "../../../../Assets/diners-club.svg";
import {ReactComponent as Elo} from "../../../../Assets/elo.svg";
import {ReactComponent as Hipercard} from "../../../../Assets/hipercard.svg";
import {ReactComponent as Jcb} from "../../../../Assets/jcb.svg";

const flags = {
    'visa': VisaLogo,
    'mastercard': MasterCardLogo,
    'american_express': Amex,
    'diners_club': DinersClub,
    'elo': Elo,
    'hipercard': Hipercard,
    'jcb': Jcb,
};

class CardSimulationBillingDetailScreen extends Component {
    render() {
        const Flag = flags[this.props.paymentCompanyCode];
        return (
            <ContentMainCardSimulationStyled>
                <CardSimulationStyled>
                    {Flag &&
                        <Flag/>
                    }
                </CardSimulationStyled>
                <SpacerComponent width={20}/>
                <Typography variant={'h6'}>
                    **** **** **** {this.props.cardNumber}
                </Typography>
            </ContentMainCardSimulationStyled>
        );
    }
}

export default CardSimulationBillingDetailScreen;