import styled from 'styled-components';
import {Accordion} from "@material-ui/core";

export const ContentMainStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;    
`;

export const ContentSizeStyled = styled.div`  
  width: 700px;    
  padding: 20px;
`;

export const AccordionDetailsStyled = styled(Accordion)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 20px;
`;