import styled from "styled-components";

export const ParentPagination = styled.div`
  width: 100%;
`;

export const SubParentPagination = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: right;
  align-items: center;
`;

export const ItemPagination = styled.div`
  cursor: pointer;
  padding-left: 10px;
  padding-right: 10px;
  font-size: 14px;
  font-weight: ${props => props.current ? 'bold' : 'normal'};
`;