import React, {Component} from 'react';
import HeaderComponent from "../../../Components/HeaderComponent/HeaderComponent";
import ContentHCenterVStartComponent
    from "../../../Components/ContentHCenterVStartComponent/ContentHCenterVStartComponent";
import {CreateAdminContext} from "../../../Contexts/CreateAdminContext";
import FormCreateConsultantScreen from "./FormCreateConsultantScreen";
import MessageUtil from "../../../Utils/MessageUtil";
import CreateConsultantValidation from "../../../FormValidations/CreateConsultantValidation";
import ConsultantService from "../../../Services/ConsultantService";
import DashboardAdminScreen from "../DashboardAdminScreen/DashboardAdminScreen";
import GeneralTranslation from "../../../Translations/FormValidations/GeneralTranslation";

class CreateConsultantScreen extends Component {
    static ROUTE = '/create-admin';

    constructor(props) {
        super(props);

        this.state = {
            inLoad: false,
            messagens: null,
        };

        this.consultantService = new ConsultantService();
    }

    doCreate = async (object) => {
        try {
            const validade = await CreateConsultantValidation.validate(object);
            if (validade !== true) {
                this.setState({
                    erros: validade,
                    messagens: MessageUtil.makeToValidationField(
                        new GeneralTranslation(validade).translate()
                    ),
                });
                return;
            }

            this.setState({
                inLoad: true,
            });

            await this.consultantService.create(object);

            this.setState({
                inLoad: false,
                messagens: MessageUtil.make({
                    title: 'Sucesso',
                    body: 'Consultor foi cadastrado.',
                    okBtnToRoute: DashboardAdminScreen.ROUTE,
                }),
            });

        } catch (e) {
            this.setState({
                inLoad: false,
                messagens: MessageUtil.make({
                    title: 'Atenção',
                    body: e,
                }),
            });
        }
    }

    render() {
        return (
            <CreateAdminContext.Provider value={{
                parent: this,
            }}>
                <HeaderComponent title={'Cadastro de Consultor'}
                                 menu={true}
                                 messagens={this.state.messagens}
                                 inLoad={this.state.inLoad}
                                 showTitleInContent={true}/>
                <ContentHCenterVStartComponent>
                    <FormCreateConsultantScreen doCreate={this.doCreate}
                                                erros={this.state.erros}/>
                </ContentHCenterVStartComponent>
            </CreateAdminContext.Provider>
        );
    }
}

export default CreateConsultantScreen;