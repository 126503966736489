import React, {Component} from 'react';
import {Box, Button, IconButton, MenuItem, Typography} from "@material-ui/core";
import SpacerComponent from "../../../Components/SpacerComponent/SpacerComponent";
import {PaperStyled} from "./styled";
import TextFieldDefault from "../../../Components/TextFieldDefaultComponent/TextFieldDefault";
import {ContentMainStyled, ContentSizeStyled} from "../CompanyRegistrationScreen/styled";
import CloseIcon from '@material-ui/icons/Close';
import UserPermissionComponent from "../../../Components/UserPermissionComponent/UserPermissionComponent";
import TooltipDefault from "../../../Components/TooltipComponent/TololtipDefault";
import FixSelect from "../../../Components/FixSelect/FixSelect";


class CreateOperator extends Component {
    action = null

    constructor(props) {
        super(props);

        this.state = {
            showSelect: false,
        };
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.valueEdit === null && this.action !== 'create') {
            this.action = 'create';
            this.setState({
                uuid: undefined,
                name: undefined,
                email: undefined,
                company: undefined,
                permissions: [],
                showSelect: true,
            });
            return;
        }

        if (this.props.valueEdit !== null && this.action !== 'update') {
            this.action = 'update';

            const newArray = [];
            this.props?.valueEdit?.permissions.map((value) => {
                newArray.push(value.uuid);
            });

            this.setState({
                uuid: this.props?.valueEdit?.uuid ?? undefined,
                name: this.props?.valueEdit?.name ?? undefined,
                email: this.props?.valueEdit?.email ?? undefined,
                company: this.props?.valueEdit?.company.uuid ?? undefined,
                permissions: newArray,
                showSelect: true,
            });
        }
    }

    handleChange(event) {
        const {name, value} = event.target;
        this.setState({[name]: value});
    }

    handlePermission = (value) => {
        this.setState({
            permissions: value,
        });
    }

    makeCompaniesSelect() {

        let itens = [];
        this.props.companies.forEach(function (value, index) {
            itens.push(
                <MenuItem key={index} value={value.uuid}>{value.fantasy_name}</MenuItem>
            );
        })

        return itens;

    }

    render() {
        if (this.props.addOperatorOpen !== true) {
            return <div></div>;
        }

        return (
            <div>
                <PaperStyled variant={'outlined'}>
                    <Box display={'flex'}
                         flexDirection={'column'}
                         justifyContent={'center'}
                         alignItems={'flex-end'}>
                        <IconButton onClick={this.props.controllerAddOperator}>
                            <CloseIcon/>
                        </IconButton>
                    </Box>
                    <ContentMainStyled>
                        <ContentSizeStyled>
                            <Box display={'flex'}
                                 flexDirection={'row'}
                                 alignItems={'center'}
                                 justifyContent={'center'}>
                                <Typography variant={'h6'}
                                            color={'primary'}
                                            align={'center'}>
                                    Adicionar Operador
                                </Typography>
                                <SpacerComponent width={10}/>
                                <TooltipDefault
                                    text={'Nessa tela você conseguirá adicionar/remover e ajustar o nível de permissionamento de seus operadores de loja.  Após inserir os dados, selecione nos checkboxes as transações que cada um será autorizado a realizar.'}/>
                            </Box>
                            <TextFieldDefault label={'Nome do Operador *'}
                                              name={'name'}
                                              onChange={(event) => this.handleChange(event)}
                                              erros={this.props.erros}
                                              value={this.state.name}/>
                            <TextFieldDefault label={'E-mail do Operador *'}
                                              name={'email'}
                                              onChange={(event) => this.handleChange(event)}
                                              erros={this.props.erros}
                                              value={this.state.email}/>
                            {this.state.showSelect &&
                                <FixSelect>
                                    <TextFieldDefault label={'Estabelecimento *'}
                                                      name={'company'}
                                                      onChange={(event) => this.handleChange(event)}
                                                      select={true}
                                                      erros={this.props.erros}
                                                      value={this.state.company}>
                                        {this.makeCompaniesSelect()}
                                    </TextFieldDefault>
                                </FixSelect>
                            }
                            <UserPermissionComponent onChange={this.handlePermission}
                                                     values={this.state.permissions}/>
                            <SpacerComponent height={20}/>
                            <Box display={'flex'}
                                 flexDirection={'column'}
                                 justifyContent={'center'}
                                 alignItems={'center'}>
                                <Button variant={'outlined'}
                                        color={'primary'}
                                        onClick={
                                            () => this.props.valueEdit === null ?
                                                this.props.doCreate(this.state) :
                                                this.props.doEditFinish(this.state)
                                        }>
                                    {this.props.valueEdit === null ? 'Adicionar' : 'Editar'}
                                </Button>
                            </Box>
                        </ContentSizeStyled>
                    </ContentMainStyled>
                </PaperStyled>
                <SpacerComponent height={40}/>
            </div>
        );
    }
}

export default CreateOperator;