import styled from "styled-components";
import {IconButton, TextField} from "@material-ui/core";

export const ContentMainFilterStyled = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 20px;
`;

export const IconButtonStyled = styled(IconButton)`
    position: relative !important;
`