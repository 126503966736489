import {getAnalytics, logEvent} from "firebase/analytics";

class Analytics {
    constructor(instance) {
        this.analytics = instance;
    }

    doSendEvent({eventName, object}) {
        try {
            logEvent(
                this.analytics,
                eventName,
                object,
            );
        } catch (e) {

        }
    }

    setSectorSelect({areaName, segmentName, cnpj, socialReason, fantasyName}) {
        this.doSendEvent({
                eventName: 'set_sector_select',
                object: {
                    cnpj: cnpj,
                    social_reason: socialReason,
                    fantasy_name: fantasyName,
                    area_name: areaName,
                    segment_name: segmentName,
                },
            }
        );
    }
}

export default Analytics;