import React, {Component} from 'react';
import {Route} from "react-router-dom";
import PrivacyPolicy from "../Screens/PJAccess/PrivacyPolicy/PrivacyPolicy";
import ShareScreen from "../Screens/PJAccess/ShareScreen/ShareScreen";
import RouteNotFound from "../Screens/PJAccess/RouteNotFoundScreen/RouteNotFound";
import LoginScreen from "../Screens/CommonAccess/LoginScreen/LoginScreen";
import ForgotPassword from "../Screens/PJAccess/ForgotPassword/ForgotPassword";
import ResetPasswordScreen from "../Screens/PJAccess/ResetPasswordScreen/ResetPasswordScreen";
import CompanyRegistrationScreen from "../Screens/PJAccess/CompanyRegistrationScreen/CompanyRegistrationScreen";
import CompanyRegistrationFidelityScreen
    from "../Screens/PJAccess/CompanyRegistrationFidelityScreen/CompanyRegistrationFidelityScreen";
import CompanyRegistrationEstablishment
    from "../Screens/PJAccess/CompanyRegistrationEstablishment/CompanyRegistrationEstablishment";
import RegulationFidelityScreen from "../Screens/PJAccess/RegulationFidelity/RegulationFidelityScreen";
import OperatorInstructionScreen from "../Screens/PJAccess/OperatorInstruction/OperatorInstructionScreen";
import ReleaseAwardScreen from "../Screens/PJAccess/release_award/ReleaseAwardScreen";

export default [
    <Route exact={true}
           path={PrivacyPolicy.ROUTE}
           component={PrivacyPolicy}
           key={'public-2'}/>,
    <Route exact={true}
           path={ShareScreen.ROUTE}
           component={ShareScreen}
           key={'public-2'}/>,
    <Route exact={true}
           path={RouteNotFound.ROUTE}
           component={RouteNotFound}
           key={'public-3'}/>,
    <Route exact={true}
           path={LoginScreen.ROUTE}
           component={LoginScreen}
           key={'public-4'}/>,
    <Route exact={true}
           path={ForgotPassword.ROUTE}
           component={ForgotPassword}
           key={'public-5'}/>,
    <Route exact={true}
           path={ResetPasswordScreen.ROUTE + '/:uuid'}
           component={ResetPasswordScreen}
           key={'public-6'}/>,
    <Route exact={true}
           path={CompanyRegistrationScreen.ROUTE + '/:uuid?'}
           component={CompanyRegistrationScreen}
           key={'public-7'}/>,
    <Route exact={true}
           path={CompanyRegistrationFidelityScreen.ROUTE + '/:uuid/:edit?/:incomplete?'}
           component={CompanyRegistrationFidelityScreen}
           key={'public-8'}/>,
    <Route exact={true}
           path={CompanyRegistrationEstablishment.ROUTE + '/:company/:fidelity'}
           component={CompanyRegistrationEstablishment}
           key={'public-9'}/>,
    <Route exact={true}
           path={RegulationFidelityScreen.ROUTE + '/:company/:fidelity/:to?/:already?'}
           component={RegulationFidelityScreen}
           key={'public-10'}/>,
    <Route exact={true}
           path={OperatorInstructionScreen.ROUTE}
           component={OperatorInstructionScreen}
           key={'public-11'}/>,
    <Route exact={true}
           path={ReleaseAwardScreen.ROUTE}
           component={ReleaseAwardScreen}
           key={'public-12'}/>,
];