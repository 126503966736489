import React, {Component} from 'react';
import {Box, Button, Typography} from "@material-ui/core";
import SpacerComponent from "../SpacerComponent/SpacerComponent";
import imageCompression from "browser-image-compression";
import PropTypes from 'prop-types';
import AvatarComponent from "../AvatarComponent/AvatarComponent";

class InputFileDefault extends Component {

    static ONE_MB = 1050000;
    static TWO_MB = 2050000;
    static THREE_MB = 3050000;
    static FOUR_MB = 4150000;
    static FIVE_MB = 5450000;

    handleImage = async (event) => {

        let file = event.target.files[0];

        if (file === undefined) {
            return;
        }

        if (this.props.accept !== undefined) {
            const extension = '.' + file.name.split('.').pop();

            if (extension !== this.props.accept) {
                alert('O arquivo não possui extensão ' + this.props.accept);
                return;
            }

        }

        file = await this.compression(file);

        this.setPreviosImage(file);
        this.toBase64(file);

    }

    async compression(file) {

        if (this.props.compression === false) {
            return file;
        }

        const sizeSelect = (this.props.maxSize ?? InputFileDefault.ONE_MB);

        if (file.size > sizeSelect) {

            const sizeCurrent = Math.trunc(
                Math.round(sizeSelect / 1000000)
                    .toFixed(1)
            );

            if (this.props.onMaxSize !== undefined) {
                this.props.onMaxSize(sizeCurrent);
                return;
            }
            alert(`Arquivo não pode ter mais que ${sizeCurrent} mb`);
            return;
        }

        const options = {
            maxSizeMB: 0.7,
            maxWidthOrHeight: 1920,
            useWebWorker: true
        };

        return await imageCompression(file, options);
    }

    setPreviosImage(file) {
        if (!file) {
            return;
        }
        const image = URL.createObjectURL(file);

        if (this.props.setPreviosImage === undefined) {
            return;
        }

        this.props.setPreviosImage(image);
    }

    toBase64(file) {
        if (!file) {
            return;
        }

        if (this.props.toBase64Image === undefined) {
            return;
        }

        let reader = new FileReader();
        reader.readAsDataURL(file);
        return reader.onloadend = async () => {
            this.props.toBase64Image(reader.result);
        };
    }

    isValidate() {
        try {

            return this.props.erros.image;

        } catch (e) {

            return true

        }
    }

    getAccept() {

        if (this.props.accept !== undefined) {
            return this.props.accept;
        }

        return 'image/*';
    }

    render() {

        const id = Math.floor(Math.random() * 100) + 1;

        return (
            <div>
                {this.props.title !== undefined &&
                    <>
                        <SpacerComponent height={10}/>
                        <Typography variant={'body1'}
                                    color={'primary'}>
                            {this.props.title}
                        </Typography>
                        <SpacerComponent height={10}/>
                    </>
                }
                <Box display={'flex'}
                     flexDirection={'row'}
                     alignItems={'center'}>
                    {this.props.withPreview === true &&
                        <>
                            <AvatarComponent
                                src={this.props.imagePrevios}/>
                            <SpacerComponent width={20}/>
                        </>
                    }
                    <Box display={'flex'}
                         flexDirection={'column'}
                         alignItems={'center'}>
                        <input
                            accept={this.getAccept()}
                            style={{display: 'none'}}
                            id={id}
                            multiple
                            type="file"
                            onChange={(event) => this.handleImage(event)}
                            disabled={this.props.disabled}/>
                        <label htmlFor={id}>
                            <Button component="span"
                                    variant={"contained"}
                                    size={"small"}
                                    disabled={this.props.disabled}>
                                {this.props.text ?? 'Selecionar novo arquivo'}
                            </Button>
                        </label>
                        {this.isValidate() !== true &&
                            <div>
                                <SpacerComponent height={10}/>
                                <Typography variant={'caption'}
                                            color={'error'}>{this.isValidate()}</Typography>
                            </div>
                        }
                    </Box>
                </Box>
            </div>
        );
    }
}

InputFileDefault.propTypes = {
    accept: PropTypes.string,
    compression: PropTypes.bool,
    maxSize: PropTypes.number,
    onMaxSize: PropTypes.func,
    setPreviosImage: PropTypes.func,
    toBase64Image: PropTypes.func,
    erros: PropTypes.array,
    imagePrevios: PropTypes.string,
    withPreview: PropTypes.bool,
    title: PropTypes.string,
    disabled: PropTypes.bool,
    text: PropTypes.string,
};

export default InputFileDefault;