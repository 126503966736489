import React, {Component} from 'react';
import {ButtonReleaseStyled, ContentMainStyled, ContentSizeStyled} from "./styled";
import TextFieldDefault from "../../../Components/TextFieldDefaultComponent/TextFieldDefault";
import {Button} from "@material-ui/core";
import SpacerComponent from "../../../Components/SpacerComponent/SpacerComponent";
import AwardService from "../../../Services/AwardService";
import InfoReleaseAward from "./InfoReleaseAward";

class FormReleaseAward extends Component {
    constructor(props) {
        super(props);

        this.state = {
            reservation_uuid: null,
            enabledButton: false,
        };
    }

    handleChange(event) {
        const {name, value} = event.target;
        let enabledButton = false;
        if ((value.match(/-/g) || []).length >= 4) {
            enabledButton = true;
        }

        this.setState({
            [name]: value,
            enabledButton: enabledButton,
        });
    }

    doFind = () => {
        this.props.doFind(this.state.reservation_uuid);
    }

    render() {
        return (
            <ContentMainStyled>
                <ContentSizeStyled>
                    <TextFieldDefault
                        name={'reservation_uuid'}
                        value={this.state.regulation}
                        onChange={(event) => this.handleChange(event)}
                        erros={this.props.erros}/>
                    <SpacerComponent height={40}/>
                    <InfoReleaseAward award={this.props.award}/>
                    <Button variant={'contained'}
                            color={'primary'}
                            size={"large"}
                            fullWidth={true}
                            disabled={!this.state.enabledButton}
                            onClick={this.doFind}>
                        Procurar
                    </Button>
                    {this.props.award != null &&
                        <>
                            <SpacerComponent height={30}/>
                            <ButtonReleaseStyled variant={'contained'}
                                                 color={'secondary'}
                                                 size={"large"}
                                                 fullWidth={true}
                                                 disabled={!this.state.enabledButton}
                                                 onClick={this.props.confirmRelease}>
                                Liberar
                            </ButtonReleaseStyled>
                        </>
                    }
                </ContentSizeStyled>
            </ContentMainStyled>
        );
    }
}

export default FormReleaseAward;