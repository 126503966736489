import React, {Component} from 'react';
import {AvatarStyled} from "./styled";

class AvatarComponent extends Component {
    render() {
        return (
            <AvatarStyled src={this.props.src}/>
        );
    }
}

export default AvatarComponent;