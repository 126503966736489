import ApiClient from "./Api/ApiClient";

class CampaignService {
    constructor() {
        this.uri = 'campaign';
        this.apiClient = new ApiClient();
    }

    async get(searchGeneric = '') {
        try {
            let filter = '';

            if (searchGeneric) {
                filter = `?filters[][title@like]=${searchGeneric}`;
            }

            return await this.apiClient.doRequest({
                method: ApiClient.GET,
                uri: this.uri + filter,
                expectedStatus: 200,
            });

        } catch (e) {
            throw e;
        }
    }

    async show(uuid) {
        try {

            return await this.apiClient.doRequest({
                method: ApiClient.GET,
                uri: this.uri + '/' + uuid,
                expectedStatus: 200,
            });

        } catch (e) {
            throw e;
        }
    }

    async create(object) {
        try {

            return await this.apiClient.doRequest({
                method: ApiClient.POST,
                uri: this.uri,
                expectedStatus: 201,
                body: object,
            });

        } catch (e) {
            throw e;
        }
    }

    async edit(uuid, object) {
        try {
            return await this.apiClient.doRequest({
                method: ApiClient.PATCH,
                uri: this.uri + '/' + uuid,
                expectedStatus: 201,
                body: object,
            });

        } catch (e) {
            throw e;
        }
    }

    async createProductNameCampaign(object) {
        try {
            return await this.apiClient.doRequest({
                method: ApiClient.POST,
                uri: 'product-name-campaign',
                expectedStatus: 201,
                body: object,
            });

        } catch (e) {
            throw e;
        }
    }

    async editProductNameCampaign(uuid, object) {
        try {
            return await this.apiClient.doRequest({
                method: ApiClient.PATCH,
                uri: 'product-name-campaign/' + uuid,
                expectedStatus: 201,
                body: object,
            });

        } catch (e) {
            throw e;
        }
    }

    async getProductNameCampaign(uuid) {
        try {

            return await this.apiClient.doRequest({
                method: ApiClient.GET,
                uri: 'product-name-campaign/' + uuid,
                expectedStatus: 200,
            });

        } catch (e) {
            throw e;
        }
    }

    async delete(uuid) {
        try {

            return await this.apiClient.doRequest({
                method: ApiClient.DELETE,
                uri: this.uri + '/' + uuid,
                expectedStatus: 201,
            });

        } catch (e) {
            throw e;
        }
    }

    async deleteProductNameCampaign(uuid) {
        try {

            return await this.apiClient.doRequest({
                method: ApiClient.DELETE,
                uri: 'product-name-campaign/' + uuid,
                expectedStatus: 201,
            });

        } catch (e) {
            throw e;
        }
    }
}

export default CampaignService;