import React, {Component} from 'react';
import {Paper, Table, TableContainer, TableHead, TableRow, TableCell, TableBody} from "@material-ui/core";
import DateUtil from "../../../Utils/DateUtil";
import {ArrowDownward, ArrowUpward} from "@material-ui/icons";
import Recibo from "./Recibo";
import PaginationComponent from "../../../Components/PaginationComponent/PaginationComponent";
import SpacerComponent from "../../../Components/SpacerComponent/SpacerComponent";
import {ExtractContext} from "../../../Contexts/ExtractContext";

class TableExtract extends Component {
    static contextType = ExtractContext;

    constructor(props) {
        super(props);

        this.enterValue = [
            'Nota fiscal',
            'Recibo',
        ];
    }

    makeRowTable() {

        const rows = [];
        const context = this;

        Object.keys(context.props.extractList).forEach(function (key, index) {
            const value = context.props.extractList[key];

            rows.push(
                <TableRow key={value.uuid}>
                    <TableCell>{value.type}</TableCell>
                    <TableCell>{DateUtil.raw(value.datetime).toDateTime24()}</TableCell>
                    <TableCell>{value.client}</TableCell>
                    <TableCell>{value.key}</TableCell>
                    <TableCell>{value.user_name}</TableCell>
                    <TableCell>{value.company}</TableCell>
                    <TableCell>{value.points}</TableCell>
                    <TableCell>{value.prize ?? '-'}</TableCell>
                    <TableCell>
                        <Recibo value={value}/>
                    </TableCell>
                </TableRow>
            );
        });

        return rows;

    }

    getIcon(type) {
        if (this.enterValue.includes(type)) {
            return <ArrowDownward htmlColor={'#35a863'}/>;
        }

        return <ArrowUpward htmlColor={'#bd4343'}/>;
    }

    render() {
        return (
            <div>
                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Tipo</TableCell>
                                <TableCell>Emitido em</TableCell>
                                <TableCell>Cliente</TableCell>
                                <TableCell>Documento de Liberação</TableCell>
                                <TableCell>Operador</TableCell>
                                <TableCell>Estabelecimento</TableCell>
                                <TableCell>Pontuação</TableCell>
                                <TableCell>Prêmio</TableCell>
                                <TableCell>Recibo</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {this.makeRowTable()}
                        </TableBody>
                    </Table>
                </TableContainer>
                <SpacerComponent height={20}/>
                <PaginationComponent total={parseInt(this.context.parent.state?.pagination?.total)}
                                     perPage={parseInt(this.context.parent.state?.pagination?.per_page)}
                                     currentPage={parseInt(this.context.parent.state?.pagination?.current_page)}
                                     lastPage={parseInt(this.context.parent.state?.pagination?.last_page)}
                                     itensVisible={2}
                                     onChangePage={(page) => {
                                         this.context.parent.doChangePage({page});
                                     }}/>
            </div>
        );
    }
}

export default TableExtract;