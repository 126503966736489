import React, {Component} from 'react';
import {ContentMainStyled, ContentSecondaryStyled} from "./styled";
import PropTypes from 'prop-types';

class ContentHCenterVStartComponent extends Component {
    render() {
        return (
            <ContentMainStyled>
                <ContentSecondaryStyled width={this.props.width}
                                        padding={this.props.padding}>
                    {this.props.children}
                </ContentSecondaryStyled>
            </ContentMainStyled>
        );
    }
}

ContentHCenterVStartComponent.defaultProps = {
    width: 400,
    padding: 20,
};

ContentHCenterVStartComponent.propTypes = {
    width: PropTypes.number,
    padding: PropTypes.number,
};

export default ContentHCenterVStartComponent;