import React, {Component} from 'react';
import {Box, Button, Grid, Typography} from "@material-ui/core";
import {CurrentPlanStyled, PaperItemChoiceStyled} from "./styled";
import SpacerComponent from "../../../Components/SpacerComponent/SpacerComponent";
import CheckItemChoicePlanScreen from "./CheckItemChoicePlanScreen";
import {ChoicePlanContext} from "../../../Contexts/ChoicePlanContext";
import PaymentScreen from "../PaymentScreen/PaymentScreen";

class ItemChoicePlanScreen extends Component {
    static contextType = ChoicePlanContext;

    renderButton() {
        const paymentPlan = this.context.parentState.state.meUtil.company.payment_plan;

        if (paymentPlan.uuid === this.props.plan.uuid) {
            return (
                <CurrentPlanStyled variant={'h6'}>
                    PLANO ATUAL
                </CurrentPlanStyled>
            );
        }

        if (this.props.plan.available === true) {
            return (
                <Button color={'primary'}
                        variant={'contained'}
                        fullWidth={true}
                        onClick={() => this.toPaymentScreen(this.props.plan)}>
                    Assinar
                </Button>
            );
        }

        return (
            <Button color={'primary'}
                    disabled={true}
                    fullWidth={true}>
                Não disponível
            </Button>
        );

    }

    toPaymentScreen(plan) {
        if (this.context.isEdit === 'true') {
            this.context.parentState.setState({
                showModalEdit: true,
                choicePlan: plan,
            });
            return;
        }

        window.location.href = PaymentScreen.ROUTE + '/' + plan.uuid + '/' + JSON.stringify(plan);

    }

    getTextToCLient() {

        if (this.props.plan.clients_limit === null) {
            return 'Clientes ilimitados';
        }

        return `Até ${this.props.plan.clients_limit} Clientes`;

    }

    render() {
        return (
            <Grid item={true}
                  xs={12}
                  sm={12}
                  md={6}
                  lg={4}
                  xl={4}>
                <PaperItemChoiceStyled variant={'outlined'}>
                    <Typography variant={'h6'}
                                color={'primary'}
                                align={'center'}>
                        {this.props.plan.name}
                    </Typography>
                    <SpacerComponent height={10}/>
                    <Typography variant={'h4'}
                                align={'center'}>
                        R$ {this.props.plan.price}
                    </Typography>
                    <SpacerComponent height={30}/>
                    <Box display={'flex'}
                         flexDirection={'column'}>
                        <CheckItemChoicePlanScreen label={this.getTextToCLient()}/>
                        <CheckItemChoicePlanScreen
                            label={`Até ${this.props.plan.companies_limit} CNPJ\`(s) cadastrado${this.props.plan.companies_limit > 1 ? 's' : ''}`}/>
                    </Box>
                    <SpacerComponent height={50}/>
                    <Box display={'flex'}
                         justifyContent={'center'}>
                        {this.renderButton()}
                    </Box>
                </PaperItemChoiceStyled>
            </Grid>
        );
    }
}

export default ItemChoicePlanScreen;