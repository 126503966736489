import React, {Component} from 'react';
import {CursorPointStyled} from "./styled";

class CursorComponent extends Component {
    render() {
        return (
            <CursorPointStyled>{this.props.children}</CursorPointStyled>
        );
    }
}

export default CursorComponent;