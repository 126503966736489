import React, {Component} from 'react';
import {Box, Grid, Typography} from "@material-ui/core";
import ChoicePlanScreen from "../ChoicePlanScreen/ChoicePlanScreen";
import {MyAccountContext} from "../../../Contexts/MyAccountContext";
import {ButtonChangePlanStyled} from "./styled";
import {SuperContext} from "../../../Contexts/SuperContext";

class MyPlanAccountScreen extends Component {
    static contextType = SuperContext;

    toChangePLan() {
        window.location.href = ChoicePlanScreen.ROUTE + '/true';
    }

    calcAddCnpj(child, limit) {

        const result = child - limit;

        if (result < 0) {
            return 0;
        }

        return result;
    }

    render() {
        const myPlan = this.context.myPlan;

        if (myPlan === null) {
            return <></>;
        }

        return (
            <SuperContext.Consumer>
                {superContext => (
                    <>
                        <Grid item={true}
                              xs={12}
                              sm={12}>
                            <Typography variant={'h6'}
                                        color={'primary'}>
                                Meus Planos
                            </Typography>
                            <hr/>
                        </Grid>
                        <Grid item={true}
                              xs={12}
                              sm={6}>
                            <Typography variant={'subtitle1'}>
                                <b>Programa de Fidelidade:</b> {myPlan.fidelity_program.name}
                            </Typography>
                            {myPlan.payment_plan.clients_limit &&
                                <Typography variant={'subtitle1'}>
                                    <b>Plano:</b> {myPlan.payment_plan.name}. Limite de
                                    até {myPlan.payment_plan.clients_limit} clientes
                                </Typography>
                            }
                            {!myPlan.payment_plan.clients_limit &&
                                <Typography variant={'subtitle1'}>
                                    <b>Plano:</b> {myPlan.payment_plan.name}. Clientes ilimitados
                                </Typography>
                            }
                            <Typography variant={'subtitle1'}>
                                <b>Clientes ativos:</b> {myPlan.total_clients}
                            </Typography>
                            <Typography variant={'subtitle1'}>
                                <b>Vagas restantes:</b> {myPlan.total_vacancy}
                            </Typography>
                            <Typography variant={'subtitle1'}>
                                <b>CNPJ's do
                                    plano:</b> {superContext.me?.company?.total_companies} de {myPlan.payment_plan.companies_limit}
                            </Typography>
                            <Typography variant={'subtitle1'}>
                                <b>CNPJ's
                                    adicionados: </b> {superContext.me?.company?.child_companies}
                            </Typography>
                            <Typography variant={'subtitle1'}>
                                <b>Valor:</b> R$ {myPlan.payment_plan.price}
                            </Typography>
                            <Typography variant={'subtitle1'}>
                                <b>Periodicidade:</b> {myPlan.payment_plan.frequency}
                            </Typography>
                        </Grid>
                        <Grid item={true}
                              xs={12}
                              sm={6}>
                            <Box display={'flex'}
                                 flexDirection={'column'}
                                 justifyContent={'flex-end'}
                                 alignItems={'flex-end'}>
                                <ButtonChangePlanStyled variant={'contained'}
                                                        capacity_percentage={myPlan.capacity_percentage}
                                                        onClick={this.toChangePLan}>
                                    Alterar plano
                                </ButtonChangePlanStyled>
                            </Box>
                        </Grid>
                    </>
                )}
            </SuperContext.Consumer>
        );
    }

}

export default MyPlanAccountScreen;