import React, {Component} from 'react';
import {Button, Grid, Paper, Typography} from "@material-ui/core";
import TextFieldDefault from "../../../../Components/TextFieldDefaultComponent/TextFieldDefault";
import ItemSkUs from "./ItemSKUs";
import SpacerComponent from "../../../../Components/SpacerComponent/SpacerComponent";
import {InstantFormContext} from "../../../../Contexts/InstantFormContext";
import {ContentSKUStyled, InfoSKUStyled} from "../styled";

class SkUs extends Component {
    static contextType = InstantFormContext;

    constructor(props) {
        super(props);

        this.state = {
            itens: [],
            formData: null,
        };
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.context.isEdit === false && this.state.itens.length <= 0) {
            this.setState({
                itens: [
                    <ItemSkUs/>,
                ]
            });
            return;
        }

        if (this.context.formData.immediate_award_products === undefined) {
            return;
        }

        if (this.state.formData === this.context.formData) {
            return;
        }

        const itens = [];

        this.context.formData.immediate_award_products.map((value) => {
            itens.push(
                <ItemSkUs value={{
                    sku: value.product_code,
                    qtd: value.amount,
                }}/>
            );
        });

        this.setState({
            itens,
            formData: this.context.formData,
        });
    }

    add = () => {

        const itens = this.state.itens;
        itens.push(
            <ItemSkUs/>
        );

        this.setState({
            itens,
        });
    }

    remove = () => {
        const itens = this.state.itens.splice(1);

        this.setState({
            itens,
        });

        this.context.removeSKUSChildsContext();
    }

    render() {
        return (
            <ContentSKUStyled variant={'outlined'}>
                <Typography variant={'subtitle2'}
                            align={'center'}
                            color={'primary'}>
                    Combinação de SKUs
                </Typography>
                <InfoSKUStyled variant={'body2'}
                               align={'center'}
                               color={'error'}>
                    * Cadastre seu cód. interno conforme aparece na nota fiscal. (Letras maiúsculas e minúsculas serão
                    consideradas, assim como acentos, pontos e qualquer outro caractere especial).
                </InfoSKUStyled>
                <SpacerComponent height={20}/>
                <Grid container={true}
                      direction={'row'}
                      justify="center"
                      alignItems="center">
                    {this.state.itens}
                </Grid>
                <Button variant={'outlined'}
                        color={'primary'}
                        fullWidth={true}
                        onClick={this.add}>Adicionar sku</Button>
                <SpacerComponent height={20}/>
                <Button variant={'outlined'}
                        color={'primary'}
                        fullWidth={true}
                        onClick={this.remove}>Remover último sku</Button>
            </ContentSKUStyled>
        );
    }
}

export default SkUs;