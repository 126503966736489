import React, {Component} from 'react';
import HeaderComponent from "../../../Components/HeaderComponent/HeaderComponent";
import {Typography} from "@material-ui/core";
import {ContentParentStyled} from "./styled";

class PrivacyPolicy extends Component {
    static ROUTE = '/privacy-policy';

    render() {
        return (
            <>
                <HeaderComponent title={'Meu App | Política de privacidade'}/>
                <ContentParentStyled>
                    <Typography variant={'h6'}
                                color={'primary'}
                                align={'center'}>
                        Política de Privacidade
                    </Typography>
                    <p>
                        A Meu APP assegura o compromisso com a privacidade e segurança de seus clientes durante todo o
                        processo de navegação e utilização de seu site e Aplicativo. Os dados cadastrais dos usuários
                        não
                        são vendidos, trocados ou divulgados para terceiros, exceto quando essas informações são
                        necessárias
                        para o processo de entrega ou para cobrança. Seus dados pessoais são peça fundamental para que
                        seu
                        prêmio seja entregue a seu contento. Por exemplo: para emissão de notas fiscais enviamos seus
                        dados
                        para a Empresa selecionada para retirada de seu Prêmio e, por subsequente, emissão da Nota
                        fiscal de
                        seu Prêmio, bem como enviamos os dados de cobrança, quando necessário, para empresa de cobrança
                        recorrente, nossa parceira.
                    </p>
                    <p>
                        A Meu APP utiliza cookies durante sua navegação de forma que toda vez que você fizer seu login,
                        suas informações e navegações anteriores fiquem gravadas e tornem sua usabilidade cada vez
                        melhor. Os cookies são pequenos arquivos, não maliciosos, tão pouco danosos, que são guardados
                        no seu computador e nos ajudam a tornar sua navegação diferenciada. A intenção é utilizar as
                        informações para que possamos prestar um serviço mais eficiente, contínuo e personalizado.
                    </p>
                    <p>
                        Os usuários ao se cadastrarem através do Aplicativo próprio da plataforma, disponível nas lojas
                        oficiais (IOs e Android) e as empresas pelo site <a
                        href={'https://meuappdefidelidade.com.br'}>https://meuappdefidelidade.com.br</a> , terão a
                        opção de receber ou não em sua
                        caixa de e-mail ofertas, avisos de promoções e informações da Meu APP e suas parceiras, sabendo
                        que, a qualquer tempo, essa opção poderá ser alterada pelo usuário. Não utilizamos seu cadastro
                        pessoal para envio de material publicitário sem autorização prévia.
                    </p>
                    <p>
                        Seus dados são registrados pela Meu APP de forma automatizada, dispensando manipulação humana,
                        garantindo sigilo absoluto. Os dados na sua conta em <a
                        href={'https://meuappdefidelidade.com.br'}>https://meuappdefidelidade.com.br</a> são protegidas
                        por senha, para que
                        somente você tenha acesso às suas informações. Para que estes dados permaneçam intactos, nós
                        recomendamos que não divulgue sua senha a terceiros, mesmo a amigos e parentes.
                    </p>
                    <p>
                        A Meu APP não irá solicitar, em nenhuma hipótese, seja por e-mail ou telefone ou aplicativo sua
                        senha pessoal ou dados referentes ao seu cartão de crédito, (será de inteira responsabilidade do
                        usuário a divulgação de sua senha, ressalvada a hipótese de extravio ou roubo de documento),
                        caso isso aconteça, altere imediatamente sua senha e dados no Aplicativo ou no site (empresas).
                        Eventualmente, apenas nos casos de apuração de divergências, podem ser solicitadas informações
                        cadastrais do cliente por telefone ou Whatsapp.
                    </p>
                    <p>
                        Lembre-se de desconectar sua conta em <a
                        href={'https://meuappdefidelidade.com.br'}>https://meuappdefidelidade.com.br</a> como também
                        fechar a janela do seu navegador quando
                        concluir sua navegação na Internet. Isso garante que outras pessoas não tenham acesso às suas
                        informações e correspondências, caso você compartilhe um computador com alguém ou esteja usando
                        um computador em local público como uma biblioteca ou local de acesso público à Internet.
                    </p>
                    <p>
                        A Meu APP reserva-se ao direito de alterar todos e quaisquer detalhes e termos do site, do
                        Aplicativo bem como suas políticas de cobrança e outros, sem aviso prévio.
                    </p>
                    <p>
                        MEU APLICATIVO LTDA. CNPJ: 42.273.601/0001-18 - RUA JOSUÉ DE BARROS, 99 – BL 8 - 102, CAMPO
                        GRANDE – 23092-190 – RIO DE JANEIRO/RJ
                    </p>
                </ContentParentStyled>
            </>
        );
    }
}

export default PrivacyPolicy;