import React, {Component} from 'react';
import CheckBoxDefaultComponent from "../../../../Components/CheckBoxDefaultComponent/CheckBoxDefaultComponent";
import SpacerComponent from "../../../../Components/SpacerComponent/SpacerComponent";
import {Button, TableCell, TableRow, Typography} from "@material-ui/core";
import TextFieldDefault from "../../../../Components/TextFieldDefaultComponent/TextFieldDefault";
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import {ContentCNPJBlock} from "../styled";
import DeleteIcon from '@material-ui/icons/Delete';
import FormMask from "../../../../FromMask/FormMask";
import {cpf, cnpj} from "cpf-cnpj-validator";

class BlockUserComponent extends Component {
    constructor(props) {
        super(props);

        this.state = {
            formatValues: {
                allow_manual_scoring_only: false,
                block_invoices_no_owner: false,
                person_invoices_only_owner: false,
                blocked_company_invoices: [],
                blocked_user_allowed_company_invoices: [],
            }
        };
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.values === prevProps.values) {
            return;
        }
        this.setState({
            formatValues: {
                allow_manual_scoring_only: this.props.values.allow_manual_scoring_only,
                block_invoices_no_owner: this.props.values.block_invoices_no_owner,
                person_invoices_only_owner: this.props.values.person_invoices_only_owner,
                blocked_company_invoices: this.props.values.blocked_company_invoices ?? [],
                blocked_user_allowed_company_invoices: this.props.values.blocked_user_allowed_company_invoices ?? [],
            },
        })
    }

    handleChange = (event) => {
        const {name, value} = event.target;
        this.setState({
            [name]: value,
        });
    }

    addCNPJBlock = () => {
        const cnpjValue = this.state.blocked_company_invoices;

        if (!cnpj.isValid(cnpjValue)) {
            alert('CNPJ inválido');
            return;
        }


        this.state.formatValues.blocked_company_invoices.push({
            cnpj: cnpjValue
        });

        this.setState({
            blocked_company_invoices: '',
        });
        this.onChange();
    }

    deleteCNPJBlock = (index) => {
        this.state.formatValues.blocked_company_invoices = this.state.formatValues.blocked_company_invoices.filter((_, i) => i !== index,);
        this.setState({});
        this.onChange();
    }

    addCNPJCPFBlock = () => {
        const cnpjValue = this.state.blocked_user_allowed_company_invoices;
        const cpfValue = this.state.blocked_user_allowed_company_invoices_cpf;

        if (!cnpj.isValid(cnpjValue)) {
            alert('CNPJ inválido');
            return;
        }

        if (!cpf.isValid(cpfValue)) {
            alert('CPF inválido');
            return;
        }

        this.state.formatValues.blocked_user_allowed_company_invoices.push({
            cnpj: cnpjValue,
            cpf: cpfValue,
        });

        this.setState({
            blocked_user_allowed_company_invoices: '',
            blocked_user_allowed_company_invoices_cpf: '',
        });
        this.onChange();
    }

    deleteCNPJCPFBlock = (index) => {
        this.state.formatValues.blocked_user_allowed_company_invoices = this.state.formatValues.blocked_user_allowed_company_invoices.filter((_, i) => i !== index,);
        this.setState({});
        this.onChange();
    }

    onChange = () => {
        this.props.onChange(this.state.formatValues);
    }

    render() {
        console.log(this.state.formatValues.allow_manual_scoring_only);
        return (
            <div>
                <SpacerComponent height={40}/>
                <CheckBoxDefaultComponent onChange={() => {
                    this.state.formatValues.allow_manual_scoring_only = !this.state.formatValues.allow_manual_scoring_only;
                    this.setState({});
                    this.onChange();
                }}
                                          checked={this.state.formatValues.allow_manual_scoring_only}
                                          label={'Somente Pontuação Manual'}/>
                <SpacerComponent height={20}/>
                <CheckBoxDefaultComponent onChange={() => {
                    this.state.formatValues.block_invoices_no_owner = !this.state.formatValues.block_invoices_no_owner;
                    this.setState({});
                    this.onChange();
                }}
                                          checked={this.state.formatValues.block_invoices_no_owner}
                                          label={'Notas sem identificação do consumidor não pontuam'}/>
                <SpacerComponent height={20}/>
                <CheckBoxDefaultComponent onChange={() => {
                    this.state.formatValues.person_invoices_only_owner = !this.state.formatValues.person_invoices_only_owner;
                    this.setState({});
                    this.onChange();
                }}
                                          checked={this.state.formatValues.person_invoices_only_owner}
                                          label={'Notas com identificação do consumidor (CPF), số podem ser pontuadas pelo proprietário da nota.'}/>
                <SpacerComponent height={20}/>
                <CheckBoxDefaultComponent onChange={() => {
                }}
                                          checked={this.state.formatValues?.blocked_company_invoices?.length > 0}
                                          label={'Nenhuma nota com identificação do consumidor (CNPJ) abaixo, poderá ser pontuada:'}/>
                <SpacerComponent height={20}/>
                <Typography variant={'subtitle1'}
                            color={'error'}>CNPJ'S BLOQUEADOS PARA ESCANEAMENTO:</Typography>

                <SpacerComponent height={20}/>

                {this.state?.formatValues?.blocked_company_invoices?.map((value, index) => {
                    return <ContentCNPJBlock>
                        <TextFieldDefault name={'blocked_company_invoices_dynamic'}
                                          onChange={(event) => this.handleChange(event)}
                                          mask={FormMask.cnpj}
                                          erros={this.props.erros}
                                          disabled={true}
                                          value={this.state.formatValues.blocked_company_invoices[index].cnpj}/>
                        <SpacerComponent width={20}/>
                        <DeleteIcon onClick={() => this.deleteCNPJBlock(index)}/>
                    </ContentCNPJBlock>;
                })}

                <ContentCNPJBlock>
                    <TextFieldDefault name={'blocked_company_invoices'}
                                      onChange={(event) => this.handleChange(event)}
                                      mask={FormMask.cnpj}
                                      erros={this.props.erros}
                                      label={'CNPJ'}
                                      value={this.state.blocked_company_invoices}/>
                    <SpacerComponent width={20}/>
                    <AddCircleOutlineIcon onClick={this.addCNPJBlock}/>
                </ContentCNPJBlock>
                <SpacerComponent height={50}/>
                <CheckBoxDefaultComponent onChange={() => console.log()}
                                          checked={this.state?.formatValues?.blocked_user_allowed_company_invoices?.length > 0}
                                          label={'Notas com identificação do consumidor (CNPJ), só poderão ser pontuadas pelo CPF associado,abaixo:'}/>

                {this.state?.formatValues?.blocked_user_allowed_company_invoices?.map((value, index) => {
                    return <ContentCNPJBlock>
                        <TextFieldDefault name={'blocked_user_allowed_company_invoices_dynamic'}
                                          onChange={(event) => this.handleChange(event)}
                                          mask={FormMask.cnpj}
                                          erros={this.props.erros}
                                          disabled={true}
                                          value={this.state.formatValues.blocked_user_allowed_company_invoices[index].cnpj}/>
                        <SpacerComponent width={20}/>
                        <TextFieldDefault name={'blocked_user_allowed_company_invoices_dynamic'}
                                          onChange={(event) => this.handleChange(event)}
                                          mask={FormMask.cpf}
                                          erros={this.props.erros}
                                          disabled={true}
                                          value={this.state.formatValues.blocked_user_allowed_company_invoices[index].cpf}/>
                        <SpacerComponent width={20}/>
                        <DeleteIcon onClick={() => this.deleteCNPJCPFBlock(index)}/>
                    </ContentCNPJBlock>;
                })}

                <ContentCNPJBlock>
                    <TextFieldDefault name={'blocked_user_allowed_company_invoices'}
                                      onChange={(event) => this.handleChange(event)}
                                      mask={FormMask.cnpj}
                                      erros={this.props.erros}
                                      label={'CNPJ'}
                                      value={this.state.blocked_user_allowed_company_invoices}/>
                    <SpacerComponent width={20}/>
                    <TextFieldDefault name={'blocked_user_allowed_company_invoices_cpf'}
                                      onChange={(event) => this.handleChange(event)}
                                      mask={FormMask.cpf}
                                      erros={this.props.erros}
                                      label={'CPF'}
                                      value={this.state.blocked_user_allowed_company_invoices_cpf}/>
                    <SpacerComponent width={20}/>
                    <AddCircleOutlineIcon onClick={this.addCNPJCPFBlock}/>
                </ContentCNPJBlock>


                <SpacerComponent height={40}/>
            </div>
        );
    }
}

BlockUserComponent.propTypes = {};

export default BlockUserComponent;
