import React, {Component} from 'react';
import HeaderComponent from "../../../Components/HeaderComponent/HeaderComponent";
import {ContentMainStyled, ContentSizeStyled} from "./styled";
import StaticElementReseteOperatorInstructionScreen from "./StaticElementReseteOperatorInstructionScreen";


class OperatorInstructionScreen extends Component {
    static ROUTE = '/operator-instruction';

    render() {
        return (
            <div>
                <HeaderComponent messagens={this.state?.messagens}
                                 title={'Meu App | Resete de senha'}/>
                <ContentMainStyled>
                    <ContentSizeStyled>
                        <StaticElementReseteOperatorInstructionScreen/>
                    </ContentSizeStyled>
                </ContentMainStyled>
            </div>
        );
    }
}

export default OperatorInstructionScreen;