import ApiClient from "./Api/ApiClient";
import MeUtil from "../Utils/MeUtil";
import * as url from "url";

class ExportService {
    constructor() {
        this.apiClient = new ApiClient();
    }

    async client(type) {
        try {
            return await this.apiClient.doRequest({
                method: ApiClient.GET,
                uri: `client/export/${type}`,
                expectedStatus: 200,
            });
        } catch (e) {
            throw e;
        }
    }
}

export default ExportService;