import React, {Component} from 'react';
import {ContentTableStyled} from "../ConsultantScreen/styled";
import {Grid, Table, TableBody, TableCell, TableHead, TableRow, Typography} from "@material-ui/core";
import SpacerComponent from "../../../Components/SpacerComponent/SpacerComponent";

class TableTotal extends Component {
    constructor(props) {
        super(props);

        this.total = 0;
    }

    makeItens() {
        const itens = [];

        this.total = 0;
        this.props.data.map((value) => {
            this.total += value.total;
            itens.push(
                <TableRow>
                    <TableCell align={'center'}>{value.payment_plan_description}</TableCell>
                    <TableCell align={'center'}>{value.clients}</TableCell>
                    <TableCell align={'center'}>{value.total}</TableCell>
                </TableRow>
            );
        });

        return itens;
    }

    render() {
        return (
            <Grid item={true}
                  xs={12}
                  sm={12}
                  md={6}
                  lg={6}
                  xl={6}>
                <ContentTableStyled>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell align={'center'}>PLANO</TableCell>
                                <TableCell align={'center'}>CLIENTES</TableCell>
                                <TableCell align={'center'}>TOTAL</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {this.makeItens()}
                        </TableBody>
                    </Table>
                    <SpacerComponent height={20}/>
                    <Typography variant={'h6'}
                                align={'right'}>
                        TOTAL GERAL: R${this.total}
                    </Typography>
                </ContentTableStyled>
            </Grid>
        );
    }
}

export default TableTotal;