import styled from 'styled-components';

export const FooterContentStyled = styled.div`
  width: 100%;
  background: ${'#' + process.env.REACT_APP_PRIMARY_COLOR};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 25px;
  padding-bottom: 25px;
  margin-top: 60px;
  position: ${props => props.footerAbsolute ? 'absolute' : 'relative'};
  bottom: ${props => props.footerAbsolute ? '0' : 'auto'};

  a {
    color: white;
    font-weight: 600;
    text-decoration: none;
    margin-left: 20px;
  }

  @media only screen and (max-width: 600px) {
    flex-direction: column;
    position: relative !important;

    a {
      margin-bottom: 20px;
      margin-left: 0;
    }
  }

  @media only screen and (min-height: 900px) {
    margin-top: 124px !important;
  }
`;

export const ContentLinksStyled = styled.div`
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: center;

  @media only screen and (max-width: 600px) {
    flex-direction: column;
    text-align: center;
  }
`

export const ContentSocialStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;

  p {
    font-weight: 600;
    color: #92c3ef;
    font-size: 17px;
    margin-bottom: 10px;
  }
  
  div {
    display: flex;
    flex-direction: row;
    justify-content: center;
    
    img {
      width: 48px;
      height: 48px;
      cursor: pointer;
    }
  }
`;

export const ContentCopyrightStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;

  p {
    font-weight: 600;
    color: #92c3ef;
    font-size: 15px;
    margin-top: 20px;
    margin-bottom: 0;
    text-align: center;
  }
`