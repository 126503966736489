import React, {Component} from 'react';
import {Box, Hidden, Typography} from "@material-ui/core";
import AvatarCircleComponent from "../../../../Components/AvatarCircleComponent/AvatarCircleComponent";
import SpacerComponent from "../../../../Components/SpacerComponent/SpacerComponent";
import SearchTextFieldComponent from "./SearchTextFieldComponent/SearchTextFieldComponent";

class SubHeaderDashboard extends Component {
    render() {
        return (
            <div>
                <Box display={'flex'}
                     justifyContent={'center'}
                     alignItems={'center'}>
                    <Hidden xsDown={true}
                            smDown={true}>
                        <Box display={'flex'}
                             flexDirection={'row'}
                             justifyContent={'flex-start'}
                             alignItems={'center'}
                             flex={1}>
                            <AvatarCircleComponent/>
                            <SpacerComponent width={20}/>
                            <SearchTextFieldComponent doSearch={this.props.doSearch}/>
                            <SpacerComponent width={20}/>
                        </Box>
                    </Hidden>
                    <Hidden lgUp={true}
                            xlUp={true}
                            mdUp={true}>
                        <Box display={'flex'}
                             flexDirection={'column'}
                             justifyContent={'center'}
                             alignItems={'center'}
                             flex={1}>
                            <AvatarCircleComponent/>
                            <SpacerComponent height={20}/>
                            <SearchTextFieldComponent doSearch={this.props.doSearch}/>
                            <SpacerComponent height={20}/>
                        </Box>
                    </Hidden>
                </Box>
                <SpacerComponent height={40}/>
            </div>
        );
    }
}

export default SubHeaderDashboard;