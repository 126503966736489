import React, {Component} from 'react';
import {ContentCVVDate, ContentFormStyled, TitleFormStyled} from "./styled";
import {Button, Checkbox, FormControlLabel, Grid, MenuItem, Typography} from "@material-ui/core";
import SpacerComponent from "../../../Components/SpacerComponent/SpacerComponent";
import TextFieldDefault from "../../../Components/TextFieldDefaultComponent/TextFieldDefault";
import FormMask from "../../../FromMask/FormMask";
import AddressUtil from "../../../Utils/AddressUtil";
import MessageUtil from "../../../Utils/MessageUtil";
import PaymentValidation from "../../../FormValidations/PaymentValidation";
import {ManagePaymentContext} from "../../../Contexts/ManagePaymentContext";
import GeneralTranslation from "../../../Translations/FormValidations/GeneralTranslation";
import PaymentUtil from "../../../Utils/PaymentUtil";
import StringUtil from "../../../Utils/StringUtil";
import FlagsAcceptedCardComponent from "../../../Components/FlagsAcceptedCardComponent/FlagsAcceptedCardComponent";


const valid = require("card-validator");

class FormManagePaymentScreen extends Component {
    static contextType = ManagePaymentContext;

    constructor(props) {
        super(props);

        this.state = {
            country: 'BR',
            vindi_accept: false,
            payment_company_code: null,
        };
    }

    handleChange = (event) => {
        const {name, value} = event.target;
        this.liveValidCardNumber(name, value);
        this.liveFlagUpdate(name, value);

        if (name === 'card_number') {
            this.setState({
                payment_company_code: PaymentUtil.getCode(value),
            })
        }

        this.setState({
            [name]: value,
        });
    }

    liveValidCardNumber(name, value) {
        if (name !== 'card_number') {
            return;
        }

        if(StringUtil.onlyNumber(value).length < 16) {
            return;
        }

        const numberValidation = valid.number(value);
        if (!numberValidation.isPotentiallyValid) {
            this.context.parent.setState({
                erros: {
                    card_number: 'Cartão inválido',
                }
            });
            return;
        }

        const vindiCodeFlag = PaymentUtil.getCode(value);

        if (vindiCodeFlag === undefined) {
            this.context.parent.setState({
                erros: {
                    card_number: 'A bandeira do cartão não foi informada',
                }
            });
            return;
        }

        this.context.parent.setState({
            erros: [],
        });
    }

    liveFlagUpdate(name, value) {
        const vindiCodeFlag = PaymentUtil.getCode(value);

        if (vindiCodeFlag !== undefined) {
            this.context.parent.setState({
                erros: [],
            });
        }
    }

    makeItensSelect() {
        let itens = [];
        for (let [key, value] of Object.entries(AddressUtil.states)) {
            itens.push(
                <MenuItem key={key} value={key}>{value}</MenuItem>
            );
        }
        return itens;
    }

    doCreate = async () => {

        if (this.state.vindi_accept !== true) {
            this.context.parent.setState({
                messagens: MessageUtil.make({
                    title: 'Atenção',
                    body: 'Aceite o compartilhamento das informações de pagamento.',
                }),
            });
            return;
        }

        const validate = await PaymentValidation.validate(this.state);
        if (validate !== true) {
            this.context.parent.setState({
                erros: validate,
                messagens: MessageUtil.makeToValidationField(
                    new GeneralTranslation(validate).translate()
                ),
            });
            return;
        }

        let newForm = {};
        Object.assign(newForm, this.state);
        newForm.card_number = newForm.card_number.replace(/\s/g, '');

        this.context.parent.doCreate(newForm);
    }

    handleChangeVindiAccept = () => {
        this.setState({
            vindi_accept: !this.state.vindi_accept,
        });
    }

    render() {
        return (
            <ContentFormStyled>
                <SpacerComponent height={20}/>
                <Typography variant={'h6'}>
                    Para alterar o seu cartão atual, basta preencher o formulário abaixo e clicar em confirmar.
                </Typography>
                <SpacerComponent height={40}/>
                <TitleFormStyled>
                    <Typography variant={'subtitle2'}>
                        Preencha seus dados conforme constam no seu cartão de crédito.
                    </Typography>
                </TitleFormStyled>
                <FlagsAcceptedCardComponent/>
                <TextFieldDefault label={'Nome *'}
                                  name={'name'}
                                  onChange={(event) => this.handleChange(event)}
                                  value={this.state.name}
                                  erros={this.props.erros}/>
                <TextFieldDefault label={'Cartão de Credito *'}
                                  name={'card_number'}
                                  mask={FormMask.cardNumber}
                                  onChange={(event) => this.handleChange(event)}
                                  value={this.state.card_number}
                                  erros={this.props.erros}/>
                {this.state.payment_company_code !== null &&
                    <TextFieldDefault label={'Bandeira do cartao *'}
                                      name={'payment_company_code'}
                                      value={this.state.payment_company_code}
                                      select={true}
                                      onChange={(event) => this.handleChange(event)}
                                      erros={this.props.erros}>
                        <MenuItem value={'mastercard'}>MasterCard</MenuItem>
                        <MenuItem value={'visa'}>Visa</MenuItem>
                        <MenuItem value={'american_express'}>American Express</MenuItem>
                        {/*<MenuItem value={'diners_club'}>Diners Club</MenuItem>
                        <MenuItem value={'elo'}>Elo</MenuItem>
                        <MenuItem value={'hipercard'}>Hipercard</MenuItem>
                        <MenuItem value={'jcb'}>JCB</MenuItem>*/}
                    </TextFieldDefault>
                }
                <ContentCVVDate>
                    <TextFieldDefault label={'Data de Vencimento *'}
                                      name={'card_expiration'}
                                      forceLabel={true}
                                      mask={FormMask.cardDate}
                                      onChange={(event) => this.handleChange(event)}
                                      value={this.state.card_expiration}
                                      erros={this.props.erros}/>
                    <SpacerComponent width={50}/>
                    <TextFieldDefault label={'CVV *'}
                                      name={'card_cvv'}
                                      mask={FormMask.cardCvv}
                                      onChange={(event) => this.handleChange(event)}
                                      value={this.state.card_cvv}
                                      erros={this.props.erros}/>
                </ContentCVVDate>
                <SpacerComponent height={20}/>
                <FormControlLabel
                    control={
                        <Checkbox checked={this.state.vindi_accept}
                                  onChange={this.handleChangeVindiAccept}
                                  name={'vindi_accept'}/>
                    }
                    label={'Aceito compartilhar minhas informações com a Plataforma de pagamento online e cobrança recorrente.'}/>
                <SpacerComponent height={40}/>
                <Button variant={'contained'}
                        color={'primary'}
                        onClick={this.doCreate}>
                    CONFIRMAR
                </Button>
                <SpacerComponent height={40}/>
            </ContentFormStyled>
        );
    }
}

export default FormManagePaymentScreen;