import React, {Component} from 'react';
import {ClickAreaStyled, ContentCalcStyled, ContentMainStyled} from "./styled";
import {Box, Button, Typography} from "@material-ui/core";
import SpacerComponent from "../SpacerComponent/SpacerComponent";
import TextFieldDefault from "../TextFieldDefaultComponent/TextFieldDefault";

class PointCalculatorComponent extends Component {
    constructor(props) {
        super(props);

        this.calc_money = 0;
        this.calc_percent = 0;

        this.state = {
            showCalc: false,
            result: 0,
        };
    }

    handleChange = (event) => {
        const {name, value} = event.target;

        if (name === 'calc_money') {
            this.calc_money = parseFloat(value);
        }
        if (name === 'calc_percent') {
            this.calc_percent = parseFloat(value);
        }
        this.doCalc();
    }

    doCalc = () => {
        if (this.calc_percent <= 0 || this.calc_percent === '') {
            this.setState({
                result: '',
            });
            return;
        }

        const calc = (this.calc_money * 100) / this.calc_percent;

        if (isNaN(calc)) {
            this.setState({
                result: '',
            });
            return;
        }

        this.setState({
            result: calc.toFixed(2),
        });
    }

    handlerSubmit = () => {
        this.setState({
            showCalc: false,
        });
        this.props.doConfirm(Math.trunc(this.state.result));
    }

    showModal = () => {
        if (this.props.disabled) {
            return;
        }

        this.setState({showCalc: !this.state.showCalc});
    }

    render() {
        return (
            <ContentMainStyled>
                <ClickAreaStyled onClick={this.showModal}>
                    <Typography variant={'body1'}
                                color={this.props.disabled ? '' : 'error'}
                                align={'center'}>Calculadora de pontos</Typography>
                </ClickAreaStyled>
                {this.state.showCalc &&
                    <ContentCalcStyled elevation={3}>
                        <Typography variant={'subtitle1'}
                                    align={'center'}>Calculadora de pontos</Typography>
                        <SpacerComponent height={50}/>
                        <TextFieldDefault label={'Valor de venda'}
                                          money={true}
                                          name={'calc_money'}
                                          moneyPrefix={'R$ '}
                                          onChange={(event) => this.handleChange(event)}/>
                        <TextFieldDefault label={'% Margem'}
                                          name={'calc_percent'}
                                          onChange={(event) => this.handleChange(event)}/>
                        <Typography variant={'subtitle2'}
                                    color={'error'}>
                            Para valores de porcentagem fracionados use "." para separar as casas decimais.
                        </Typography>
                        <SpacerComponent height={20}/>
                        <Typography variant={'h6'}>
                            Pontos: {this.state.result}
                        </Typography>
                        <SpacerComponent height={50}/>
                        <Box display="flex"
                             justifyContent="center"
                             flexDirection={'column'}>
                            <Button variant={'contained'}
                                    color={'primary'}
                                    size={"large"}
                                    fullWidth={true}
                                    onClick={() => this.handlerSubmit()}>
                                Confirmar pontos
                            </Button>
                            <SpacerComponent height={20}/>
                            <Button variant={'outlined'}
                                    color={'primary'}
                                    size={"large"}
                                    fullWidth={true}
                                    onClick={() => this.setState({
                                        showCalc: false,
                                    })}>
                                Fechar
                            </Button>
                        </Box>
                    </ContentCalcStyled>
                }
            </ContentMainStyled>
        );
    }
}

export default PointCalculatorComponent;