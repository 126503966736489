import React, {Component} from 'react';
import {DatePicker} from "@material-ui/pickers";
import {Box, Hidden, IconButton} from "@material-ui/core";
import SearchIcon from '@material-ui/icons/Search';
import SpacerComponent from "../../../../Components/SpacerComponent/SpacerComponent";
import DateUtil from "../../../../Utils/DateUtil";
import {ContentMainFilterStyled, IconButtonStyled} from "./styled";


class SearchTextFieldComponent extends Component {

    constructor(props) {
        super(props);

        this.state = {
            filter: null,
            selectedDateInitial: DateUtil.getDateRangeToDate(7).initial,
            selectedDateInitialApi: DateUtil.getDateRangeFormat(7).initial,
            selectedDateFinal: DateUtil.getDateRangeToDate(7).final,
            selectedDateFinalApi: DateUtil.getDateRangeFormat(7).final,
        };
    }

    handleChange(name, value) {

        const apiState = name + 'Api';

        this.setState({
            [name]: value.toDate(),
            [apiState]: value.format('YYYY-MM-DD'),
        });
    }

    doSearch = () => {
        this.props.showModal(
            undefined,
            this.state.selectedDateFinalApi,
            this.state.selectedDateInitialApi,
        );
    }

    render() {
        return (
            <ContentMainFilterStyled>
                <DatePicker label={'Data Inicial'}
                            value={this.state.selectedDateInitial}
                            onChange={(date) => this.handleChange('selectedDateInitial', date)}
                            animateYearScrolling={true}
                            format="DD/MM/yyyy"/>
                <SpacerComponent width={20}/>
                <DatePicker label={'Data Final'}
                            value={this.state.selectedDateFinal}
                            onChange={(date) => this.handleChange('selectedDateFinal', date)}
                            animateYearScrolling={true}
                            format="DD/MM/yyyy"/>
                <SpacerComponent width={20}/>
                <IconButtonStyled onClick={this.doSearch}>
                    <SearchIcon/>
                </IconButtonStyled>
            </ContentMainFilterStyled>
        );
    }
}

export default SearchTextFieldComponent;