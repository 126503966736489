import React, {Component} from 'react';
import HeaderComponent from "../../../Components/HeaderComponent/HeaderComponent";
import {Box, Grid, Typography} from "@material-ui/core";
import SpacerComponent from "../../../Components/SpacerComponent/SpacerComponent";
import ItemSelectAward from "./ItemSelectAward";
import NewAward from "../NewAward/NewAward";
import CashbackScreen from "../CashbackScreen/CashbackScreen";
import InstantNewAward from "../InstantAward/InstantNewAward";
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import AddShoppingCartIcon from '@material-ui/icons/AddShoppingCart';
import CardGiftcardIcon from '@material-ui/icons/CardGiftcard';
import SaveIcon from '@material-ui/icons/Save';
import ListNewAwardScreen from "../NewAward/ListNewAwardScreen";
import TooltipDefault from "../../../Components/TooltipComponent/TololtipDefault";
import GiftCardScreen from "../GiftCardScreen/GiftCardScreen";
import TextFieldsIcon from "@material-ui/icons/TextFields";
import CampaignNameScreen from "../CampaignNameScreen/CampaignNameScreen";
import ItemSelectCampaign from "../SelectCampaign/ItemSelectAward";
import ReleaseAwardScreen from "../release_award/ReleaseAwardScreen";
import NewReleasesIcon from '@material-ui/icons/NewReleases';

class SelectAwardScreen extends Component {
    static ROUTE = '/select-awards';

    render() {
        return (
            <>
                <HeaderComponent title={'Prêmios'}
                                 menu={true}/>
                <SpacerComponent height={50}/>
                <Box display={'flex'}
                     flexDirection={'row'}
                     alignItems={'center'}
                     justifyContent={'center'}>
                    <Typography variant={'h6'}
                                color={'primary'}
                                align={'center'}>
                        Escolha a categoria de prêmio que gostaria de criar
                    </Typography>
                    <SpacerComponent width={10}/>
                    <TooltipDefault
                        text={'Aqui você poderá escolher o tipo de prêmio que oferecerá ao seu cliente, podendo ser em produto, cashback (Desconto em valor monetário ou porcentagem) e acompanhar as premiações já oferecidas, vinculadas ao seu programa de fidelidade.'}/>
                </Box>
                <SpacerComponent height={100}/>
                <Box display={'flex'}
                     justifyContent={'center'}
                     alignItems={'center'}>
                    <Box width={'90%'}>
                        <Grid container={true}
                              alignItems={'center'}
                              alignContent={'center'}
                              justifyContent={'flex-start'}
                              spacing={3}>
                            <ItemSelectAward icon={SaveIcon}
                                             title={'Lista de Prêmio Salvos'}
                                             route={ListNewAwardScreen.ROUTE}
                                             list={true}
                                             tooltipText={'Tenha acesso aqui, a um histórico de todas as premiações já oferecidas em seu programa de fidelidade.'}/>
                            <ItemSelectAward icon={CardGiftcardIcon}
                                             action={'Criar'}
                                             title={'Prêmio Simples'}
                                             route={NewAward.ROUTE}
                                             tooltipText={'Nessa modalidade de premiação você poderá cadastrar os produtos ou serviços que deseja oferecer a seus clientes, estabelecer pontuação para resgate e registrar as regras referentes a premiação.'}
                                             tooltipColor={'white'}/>
                            <ItemSelectAward icon={AttachMoneyIcon}
                                             action={'Criar'}
                                             title={'Cashback'}
                                             route={CashbackScreen.ROUTE}
                                             tooltipText={'Premie seus clientes com descontos de valor monetário em porcentagem, por compras realizadas em seu estabelecimento'}
                                             tooltipColor={'white'}/>
                            <ItemSelectAward icon={AttachMoneyIcon}
                                             action={'Criar'}
                                             title={'Voucher'}
                                             route={GiftCardScreen.ROUTE}
                                             tooltipText={''}
                                             tooltipColor={'white'}/>
                            <ItemSelectAward icon={NewReleasesIcon}
                                             action={'Liberar'}
                                             title={'Prêmio'}
                                             route={ReleaseAwardScreen.ROUTE}
                                             tooltipText={''}
                                             tooltipColor={'white'}/>
                        </Grid>
                    </Box>
                </Box>
                <SpacerComponent height={100}/>
            </>
        );
    }
}

export default SelectAwardScreen;