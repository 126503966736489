import MaskedInput from "react-text-mask/dist/reactTextMask";
import React from "react";

class FormMask {

    static get phone() {
        return '(99) 9 9999-9999';
    }

    static get phoneFix() {
        return '(99) 9999-99999';
    }

    static get cnpj() {
        return '99.999.999/9999-99'
    }

    static get cpf() {
        return '999.999.999-99';
    }

    static get idCard() {
        return '99.999.999-99';
    }

    static get cardDate() {
        return '99/9999';
    }

    static get cardNumber() {
        return '9999 9999 9999 9999';
    }

    static get cardCvv() {
        return '9999';
    }

}

export default FormMask;