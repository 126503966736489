import React, {Component} from 'react';
import InfoOutlined from '@material-ui/icons/InfoOutlined'
import PropTypes from 'prop-types';
import {TooltipStyled} from "./styled";

class TooltipDefault extends Component {
    render() {
        return (
            <TooltipStyled title={this.props.text}
                           changePosition={this.props.changePosition}
                           top={this.props.top}
                           bottom={this.props.bottom}
                           left={this.props.left}
                           right={this.props.right}
                           marginLeft={this.props.marginLeft}
                           placement={'top-start'}
                           color={this.props.color}>
                <InfoOutlined color={'primary'}/>
            </TooltipStyled>
        )
    }
}

TooltipDefault.defaultProps = {
    changePosition: false,
};

TooltipDefault.propTypes = {
    text: PropTypes.string.isRequired,
    changePosition: PropTypes.bool,
    top: PropTypes.number,
    bottom: PropTypes.number,
    left: PropTypes.number,
    right: PropTypes.number,
    marginLeft: PropTypes.number,
    color: PropTypes.string,
};

export default TooltipDefault;