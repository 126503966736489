import React, {Component} from 'react';
import DateUtil from "../../../Utils/DateUtil";
import {TableContentStyled} from "./styled";
import DataTable from 'react-data-table-component';

class TableConsultantScreen extends Component {
    constructor(props) {
        super(props);
    }

    makeColumns() {

        return [
            {
                name: 'NOME',
                selector: row => row.name,
                sortable: true,
            },
            {
                name: 'CÓDIGO',
                selector: row => row.code,
                sortable: true,
            },
            {
                name: 'CARGO',
                selector: row => row.role,
                sortable: true,
            },
            {
                name: 'META VALOR',
                selector: row => row.goals_value,
                sortable: true,
            },
            {
                name: 'META PLANO COBRE',
                selector: row => row.cobre,
                sortable: true,
            },
            {
                name: 'META PLANO BRONZE',
                selector: row => row.bronze,
                sortable: true,
            }, {
                name: 'META PLANO PRATA',
                selector: row => row.prata,
                sortable: true,
            },
            {
                name: 'META PLANO OURO',
                selector: row => row.ouro,
                sortable: true,
            },
            {
                name: 'META PLANO DIAMANTE',
                selector: row => row.diamante,
                sortable: true,
            },
            {
                name: 'VALOR TOTAL DE METAS',
                selector: row => row.total,
                sortable: true,
            },
        ];
    }

    makeItens() {
        const itens = [];
        this.props.data.map((value, index) => {
            if (this.props.filterRole !== undefined) {
                if (value.role[this.props.filterRole] === undefined) {
                    return;
                }
            }

            const metaValor = value.goals.value ?? 0;
            const cobre = value.goals.Cobre ?? 0;
            const bronze = value.goals.Bronze ?? 0;
            const prata = value.goals.Prata ?? 0;
            const ouro = value.goals.Ouro ?? 0;
            const diamante = value.goals.Diamante ?? 0;
            const total = value.goals_value ?? 0;

            itens.push({
                uuid: value.uuid,
                name: <a href={'#'}
                         onClick={() => this.props.showModal(value)}>
                    {value.name}
                </a>,
                code: <a href={'#'}
                         onClick={() => this.props.showModal(value)}>
                    {value.code}
                </a>,
                role: Object.values(value.role)[0],
                goals_value: metaValor,
                cobre: cobre,
                bronze: bronze,
                prata: prata,
                ouro: ouro,
                diamante: diamante,
                total: total,
            });
        });

        return itens;
    }

    render() {
        return (
            <DataTable columns={this.makeColumns()}
                       data={this.makeItens()}
                       fixedHeader={true}
                       fixedHeaderScrollHeight="500px"
                       onRowClicked={(e) => this.props.showModal(e)}/>
        );
    }
}

export default TableConsultantScreen;