import axios from 'axios';
import ApiToken from "./ApiToken";

const GET = 'get';
const POST = 'post';
const PATCH = 'patch';
const DELETE = 'delete';

class ApiClient {
    constructor(version = null) {
        this.axiosInstance = null;
        this.baseURL = this.getBaseUrl();
        this.prefix = process.env.REACT_APP_API_PREFIX;
        this.version = ((version === null) ? process.env.REACT_APP_API_VERSION : version);
        this.makeInstance();
    }

    getBaseUrl() {
        if (process.env.NODE_ENV === 'development') {
            return process.env.REACT_APP_API_BASE_URL;
        }

        if (window.location.href.includes('homolog')) {
            return process.env.REACT_APP_API_BASE_URL;
        }

        return process.env.REACT_APP_API_BASE_URL_PRODUCTION;
    }

    makeInstance() {

        const baseURL = this.baseURL + '/' + this.prefix + '/' + this.version + '/';
        this.axiosInstance = axios.create({
            baseURL: baseURL,
            validateStatus: false,
        });
    }

    async doRequest({
                        uri,
                        method = ApiClient.GET,
                        auth = true,
                        body = {},
                        token = null,
                        expectedStatus = null
                    }) {
        try {

            if (auth === true && token === null) {
                token = await ApiToken.get().verifyToken();
                this.axiosInstance.defaults.headers.common['Authorization'] = token;
            }

            if (token !== null) {
                this.axiosInstance.defaults.headers.common['Authorization'] = token;
            }

            const response = await this.axiosInstance.request({
                url: uri,
                method: method,
                data: body
            });

            if (expectedStatus !== null) {
                if (response.status !== expectedStatus) {
                    throw new Error(JSON.stringify({
                        error: response.data,
                        status: response.status,
                    }));
                }
            }

            return response;

        } catch (e) {
            ApiToken.shouldRemoveTokenByException(e);
            throw e;
        }
    }

    static get GET() {
        return GET;
    }

    static get POST() {
        return POST;
    }

    static get PATCH() {
        return PATCH;
    }

    static get DELETE() {
        return DELETE;
    }

}

export default ApiClient;