import React, {Component} from 'react';
import HeaderComponent from "../../../Components/HeaderComponent/HeaderComponent";
import SubHeaderComponent from "../../../Components/SubHeaderDefault/SubHeaderComponent";
import SpacerComponent from "../../../Components/SpacerComponent/SpacerComponent";
import {Box} from "@material-ui/core";
import MessageUtil from "../../../Utils/MessageUtil";
import CompanyService from "../../../Services/CompanyService";
import {ManageEstablishmentContext} from "../../../Contexts/ManageEstablishmentContext";
import ContentListEstablishment from "./ContentListEstablishment";
import EditEstablishment from "../CompanyRegistrationEstablishment/EditEstablishment";
import {SuperContext} from "../../../Contexts/SuperContext";

class ManageEstablishmentScreen extends Component {
    static ROUTE = '/manage-establishment';
    static contextType = SuperContext;

    companyService;

    constructor(props) {
        super(props);

        this.state = {
            inLoad: false,
            erros: [],
            establishments: [],
        };

        this.companyService = new CompanyService();
    }

    componentDidMount() {
        this.load();
    }

    async load() {
        try {

            this.setState({
                inLoad: true,
            });

            const response = await this.companyService.get();

            this.setState({
                inLoad: false,
                establishments: response.data.data,
            });

        } catch (e) {
            this.setState({
                inLoad: false,
                messagens: MessageUtil.make({
                    title: 'Error',
                    body: e,
                }),
            });
        }
    }

    doEdit = async (uuid) => {
        window.location.href = EditEstablishment.ROUTE
            + '/'
            + uuid;
    }

    toCreate = () => {
        if (this.context.myPlan.payment_plan.is_default) {
            this.setState({
                messagens: MessageUtil.make({
                    title: 'Atenção',
                    body: 'A adição de CNPJ não é habilitada para usuários do PLANO FREE. Acesse a opção MEU PLANO no seu menu principal, faça um upgrade e tenha acesso a mais vantagens!',
                    okButtonText: 'ok',
                }),
            });
            return;
        }

        window.location.href = EditEstablishment.ROUTE;
    }

    doDelete = (uuid) => {
        this.setState({
            messagens: MessageUtil.make({
                title: 'Atenção',
                body: 'Deseja realmente deletar esse estabelecimento? Essa ação não poderá ser desfeita',
                okButtonText: 'Deletar',
                cancelButtonText: 'Não',
                okButtonFunction: () => this.delete(uuid)
            }),
        });
    }

    delete = async (uuid) => {
        try {
            this.setState({
                inLoad: true,
            });

            await this.companyService.delete(uuid);

            this.setState({
                inLoad: false,
                messagens: MessageUtil.make({
                    title: 'Sucesso',
                    body: 'Estabelecimento foi deletado',
                    okBtnToRoute: ManageEstablishmentScreen.ROUTE,
                }),
            });
        } catch (e) {
            this.setState({
                inLoad: false,
                messagens: MessageUtil.make({
                    title: 'Error',
                    body: e,
                }),
            });
        }
    }

    render() {
        return (
            <ManageEstablishmentContext.Provider value={{
                doEdit: this.doEdit,
                toCreate: this.toCreate,
                doDelete: this.doDelete,
            }}>
                <div>
                    <HeaderComponent title={'Gerenciar Estabelecimentos'}
                                     menu={true}
                                     messagens={this.state?.messagens}
                                     inLoad={this.state.inLoad}/>
                    <Box display={'flex'}
                         justifyContent={'center'}
                         alignItems={'center'}>
                        <Box width={'80%'}>
                            <SubHeaderComponent title={'Gerenciar Estabelecimentos'}
                                                search={false}/>
                            <SpacerComponent height={30}/>
                            <ContentListEstablishment establishments={this.state.establishments}/>
                        </Box>
                    </Box>
                </div>
            </ManageEstablishmentContext.Provider>
        );
    }
}

export default ManageEstablishmentScreen;