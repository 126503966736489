import React, {Component} from 'react';
import {Box, Button, Hidden} from "@material-ui/core";
import AvatarCircleComponent from "../../../../Components/AvatarCircleComponent/AvatarCircleComponent";
import SpacerComponent from "../../../../Components/SpacerComponent/SpacerComponent";
import SearchTextFieldComponent from "./SearchTextFieldComponent/SearchTextFieldComponent";
import CampaignScreen from "../../CampaignScreen/CampaignScreen";

class SubHeaderAward extends Component {
    render() {
        return (
            <div>
                <Box display={'flex'}
                     justifyContent={'center'}
                     alignItems={'center'}>
                    <Hidden xsDown={true}
                            smDown={true}>
                        <Box display={'flex'}
                             flexDirection={'row'}
                             justifyContent={'flex-start'}
                             alignItems={'center'}
                             flex={1}>
                            <AvatarCircleComponent/>
                            <SpacerComponent width={20}/>
                            <SearchTextFieldComponent doSearch={this.props.doSearch}/>
                        </Box>
                    </Hidden>
                    <Hidden lgUp={true}
                            xlUp={true}
                            mdUp={true}>
                        <Box display={'flex'}
                             flexDirection={'column'}
                             justifyContent={'center'}
                             alignItems={'center'}
                             flex={1}>
                            <AvatarCircleComponent/>
                            <SpacerComponent height={20}/>
                            <SearchTextFieldComponent doSearch={this.props.doSearch}/>
                            <SpacerComponent height={20}/>
                            {this.props.removeSelect !== true &&
                                <>
                                    {this.props.selectAward === false &&
                                        <Button variant="outlined"
                                                color="primary"
                                                onClick={this.props.setEnabledSelectAward}>
                                            Selecionar prêmios
                                        </Button>
                                    }
                                    {this.props.selectAward === true &&
                                        <Box display={'flex'}
                                             flexDirection={'column'}
                                             justifyContent={'center'}
                                             alignItems={'center'}
                                             flex={1}>
                                            <Button variant="outlined"
                                                    color="primary"
                                                    onClick={this.props.doEnableAwardSelect}>
                                                ativar prêmios
                                            </Button>
                                            <SpacerComponent height={20}/>
                                            <Button variant="outlined"
                                                    color="primary"
                                                    onClick={this.props.doDisableAwardSelect}>
                                                desativar prêmios
                                            </Button>
                                            <SpacerComponent height={20}/>
                                            <Button variant="outlined"
                                                    color="primary"
                                                    onClick={this.props.setEnabledSelectAward}>
                                                cancelar
                                            </Button>
                                        </Box>
                                    }
                                </>
                            }
                        </Box>
                    </Hidden>
                    <Hidden xsDown={true}
                            smDown={true}>
                        <Box>
                            {this.props.selectAward === false &&
                                <Button variant="outlined"
                                        color="primary"
                                        onClick={this.props.setEnabledSelectAward}>
                                    Selecionar prêmios
                                </Button>
                            }
                            {this.props.selectAward === true &&
                                <Box display={'flex'}
                                     flexDirection={'row'}
                                     justifyContent={'center'}
                                     alignItems={'center'}
                                     flex={1}>
                                    <Button variant="outlined"
                                            color="primary"
                                            onClick={this.props.doEnableAwardSelect}>
                                        ativar prêmios
                                    </Button>
                                    <SpacerComponent width={10}/>
                                    <Button variant="outlined"
                                            color="primary"
                                            onClick={this.props.doDisableAwardSelect}>
                                        desativar prêmios
                                    </Button>
                                    <SpacerComponent width={10}/>
                                    <Button variant="outlined"
                                            color="primary"
                                            onClick={this.props.setEnabledSelectAward}>
                                        cancelar
                                    </Button>
                                </Box>
                            }
                        </Box>
                    </Hidden>
                </Box>
            </div>
        );
    }
}

export default SubHeaderAward;