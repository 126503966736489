import React, {Component} from 'react';
import {ContentCopyrightStyled, ContentLinksStyled, ContentSocialStyled, FooterContentStyled} from "./styled";
import ContactUsScreen from "../../Screens/PJAccess/ContactUsScreen/ContactUsScreen";
import facebook_icon from "../../Assets/facebook.png";
import linkedin_icon from "../../Assets/linkedin.png";
import instagram_icon from "../../Assets/instagram.png";
import TermModalComponent from "../TermModalComponent/TermModalComponent";
import termo from '../../Assets/TERMO_DE_USO_E_POLITICA_DE_PRIVACIDADE.pdf';
import DateUtil from "../../Utils/DateUtil";

class FooterComponent extends Component {
    openModalTerm = null;

    toSocial(link) {
        window.open(link, '_blank');
    }

    render() {
        return (
            <FooterContentStyled footerAbsolute={this.props.footerAbsolute}>
                <ContentLinksStyled>
                    <a href={ContactUsScreen.ROUTE}>Fale Conosco</a>
                    <a href={'#'}
                       onClick={() => this.openModalTerm('Termos de uso e Política de privacidade', termo)}>Termos de
                        uso e Política de
                        privacidade</a>
                </ContentLinksStyled>
                <ContentSocialStyled>
                    <p>sigam-nos nas redes sociais</p>
                    <div>
                        <img src={facebook_icon}
                             onClick={() => this.toSocial('https://www.facebook.com/appdefidelidade')}/>
                        <img src={instagram_icon}
                             onClick={() => this.toSocial('https://instagram.com/meuappdefidelidade')}/>
                        <img src={linkedin_icon}
                             onClick={() => this.toSocial('https://www.linkedin.com/company/meu-app-de-fidelidade')}/>
                    </div>
                </ContentSocialStyled>
                <ContentCopyrightStyled>
                    <p>Copyright {DateUtil.getYear()} Todos os direitos reservados a Meu App de Fidelidade</p>
                </ContentCopyrightStyled>
                <TermModalComponent onStart={(value) => this.openModalTerm = value}/>
            </FooterContentStyled>
        );
    }
}

export default FooterComponent;