import React, {Component} from 'react';
import {Box, Button, Typography} from "@material-ui/core";
import imageCompression from "browser-image-compression";
import PropTypes from 'prop-types';
import SpacerComponent from "../../../../Components/SpacerComponent/SpacerComponent";
import {ActionButtonStyled, ItemGalleryStyled} from "../styled";
import {Add, Close} from "@material-ui/icons";

class InputFileStory extends Component {
    constructor(props) {
        super(props);

        this.state = {
            imagePrevios: null,

        };
    }

    static ONE_MB = 1050000;
    static TWO_MB = 2050000;
    static THREE_MB = 3050000;
    static FOUR_MB = 4150000;
    static FIVE_MB = 5450000;

    handleImage = async (event) => {
        let file = event.target.files[0];

        if (file === undefined) {
            return;
        }

        if (this.props.accept !== undefined) {
            const extension = '.' + file.name.split('.').pop();

            if (extension !== this.props.accept) {
                alert('O arquivo não possui extensão ' + this.props.accept);
                return;
            }

        }

        file = await this.compression(file);

        this.setPreviosImage(file);
        this.toBase64(file);

    }

    async compression(file) {

        if (this.props.compression === false) {
            return file;
        }

        const sizeSelect = (this.props.maxSize ?? InputFileStory.FIVE_MB);

        if (file.size > sizeSelect) {

            const sizeCurrent = Math.trunc(
                Math.round(sizeSelect / 1000000)
                    .toFixed(1)
            );

            if (this.props.onMaxSize !== undefined) {
                this.props.onMaxSize(sizeCurrent);
                return;
            }
            alert(`Arquivo não pode ter mais que ${sizeCurrent} mb`);
            return;
        }

        const options = {
            maxSizeMB: 0.7,
            maxWidthOrHeight: 1920,
            useWebWorker: true
        };

        return await imageCompression(file, options);
    }

    setPreviosImage(file) {
        const image = URL.createObjectURL(file);

        this.setState({
            imagePrevios: image,
        });
    }

    toBase64(file) {

        if (this.props.toBase64Image === undefined) {
            return;
        }

        let reader = new FileReader();
        reader.readAsDataURL(file);
        return reader.onloadend = async () => {
            this.props.toBase64Image(reader.result);
        };
    }

    isValidate() {
        try {

            return this.props.erros.image;

        } catch (e) {

            return true

        }
    }

    getAccept() {

        if (this.props.accept !== undefined) {
            return this.props.accept;
        }

        return 'image/*';
    }

    removeImage = () => {
        this.setState({
            imagePrevios: null,
        });

        this.props.toBase64Image(null);
    }

    render() {
        const id = Math.floor(Math.random() * 100) + 1;
        return (
            <ItemGalleryStyled>
                {(this.state.imagePrevios !== null || this.props.imagePrevios !== null) &&
                    <img
                        src={ this.state.imagePrevios ?? this.props.imagePrevios }/>
                }
                <input
                    accept={this.getAccept()}
                    style={{display: 'none'}}
                    id={id}
                    multiple
                    type="file"
                    onChange={(event) => this.handleImage(event)}/>
                {this.state.imagePrevios === null &&
                    <label htmlFor={id}>
                        <ActionButtonStyled component="span">
                            <Add/>
                        </ActionButtonStyled>
                    </label>
                }
                {this.state.imagePrevios !== null &&
                    <label onClick={this.removeImage}>
                        <ActionButtonStyled component="span">
                            <Close/>
                        </ActionButtonStyled>
                    </label>
                }
            </ItemGalleryStyled>
        );
    }
}

InputFileStory.propTypes = {
    accept: PropTypes.string,
    compression: PropTypes.bool,
    maxSize: PropTypes.number,
    onMaxSize: PropTypes.func,
    toBase64Image: PropTypes.func,
    erros: PropTypes.array,
    imagePrevios: PropTypes.string,
    withPreview: PropTypes.bool,
    title: PropTypes.string,
};

export default InputFileStory;