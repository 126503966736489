import React, {Component} from 'react';
import {Paper, Table, TableContainer, TableHead, TableRow, TableCell, TableBody, IconButton} from "@material-ui/core";
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from "@material-ui/icons/Edit";

class TableOperator extends Component {

    makeTableRow() {

        let itens = [];
        const context = this;

        this.props.operators.forEach(function (value, index) {
            itens.push(
                <TableRow key={1}>
                    <TableCell>{value.name}</TableCell>
                    <TableCell>{value.email}</TableCell>
                    <TableCell>{value.company.fantasy_name}</TableCell>
                    <TableCell>
                        {!value.locked &&
                            <IconButton size={'small'}
                                        onClick={() => context.props.doEdit(value)}>
                                <EditIcon/>
                            </IconButton>
                        }
                    </TableCell>
                    <TableCell>
                        {!value.locked &&
                            <IconButton size={'small'}
                                        onClick={() => context.props.doDeleteConfirm(value.uuid)}>
                                <DeleteIcon/>
                            </IconButton>
                        }
                    </TableCell>
                </TableRow>
            );
        });

        return itens;

    }

    render() {
        return (
            <div>
                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Operador</TableCell>
                                <TableCell>E-mail</TableCell>
                                <TableCell>Estabelecimento</TableCell>
                                <TableCell></TableCell>
                                <TableCell></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {this.makeTableRow()}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
        );
    }
}

export default TableOperator;