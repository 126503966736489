import styled from 'styled-components';

export const ContentMainStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;    
`;

export const ContentSizeStyled = styled.div`  
  width: 400px;    
  padding: 20px;
`;

export const ContentCNPJBlock = styled.div`
  display: flex;
  justify-content: center;
  justify-items: center;
  align-items: center;
`;