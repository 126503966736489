import React, {Component} from 'react';
import {Box, Typography} from "@material-ui/core";

import SpacerComponent from "../../../Components/SpacerComponent/SpacerComponent";
import {Checkbox} from "@material-ui/core";

class CheckValidDateNewAwardScreen extends Component {
    constructor(props) {
        super(props);

        this.state = {
            acceptTerm: false,
        };
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.withoutValidDate !== prevProps.withoutValidDate) {
            this.setState({
                acceptTerm: this.props.withoutValidDate,
            });
        }
    }

    handleCheckTerm = () => {
        this.setState({
            acceptTerm: !this.state.acceptTerm,
        });

        if (this.props.onChange !== undefined) {
            this.props.onChange(!this.state.acceptTerm);
        }
    }

    render() {
        return (
            <Box display={'flex'}
                 flexDirection={'row'}
                 alignItems={'center'}>
                <Checkbox checked={this.state.acceptTerm}
                          onChange={() => this.handleCheckTerm()}
                          color={'primary'}
                disabled={this.props.disabled}/>
                <SpacerComponent height={20}
                                 width={10}/>
                <Typography variant={'body1'}
                            color={'primary'}>
                    Sem período de validade
                </Typography>
            </Box>
        );
    }
}

export default CheckValidDateNewAwardScreen;