import {keys} from "@material-ui/core/styles/createBreakpoints";

const LOCATE_FIELD = {
    'phones.number': 'Número do telefone',
    'phones': 'Telefone',
    'holder_name': 'Nome',
    'card_expiration': 'Data de Vencimento',
    'card_cvv': 'CVV',
    'payment_profile': 'Criação do perfil',
    'card_number': 'Cartão de Credito',
    'email': 'email',
    'registry_code': 'CPF',
    'payment_company_code': 'Cartão de Credito'
};

class VindiErroUtil {

    static make(erro) {
        const message = erro.message;

        const requirements = {};
        if (erro.fields !== undefined) {
            const fields = erro.fields;
            for (const key of Object.keys(fields)) {
                requirements[key] = [
                    `${this.locateField(key)}: ${fields[key]}`
                ];
            }
        }

        return {
            message,
            requirements,
        };
    }

    static locateField(raw) {
        return LOCATE_FIELD[raw] ?? raw;
    }

}

export default VindiErroUtil;