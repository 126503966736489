import React, {Component} from 'react';
import HeaderComponent from "../../../Components/HeaderComponent/HeaderComponent";
import FiltersPaymentStatusScreen from "./FiltersPaymentStatusScreen";
import TablePaymentStatusScreen from "./TablePaymentStatusScreen";
import MessageUtil from "../../../Utils/MessageUtil";
import PlanService from "../../../Services/PlanService";
import DashboardService from "../../../Services/DashboardService";
import FooterPaymentStatusScreen from "./FooterPaymentStatusScreen";
import CompanyService from "../../../Services/CompanyService";

class PaymentStatusScreen extends Component {
    static ROUTE = '/payment-status';
    constructor(props) {
        super(props);

        this.state = {
            inLoad: false,
            mensagens: null,
            plans: [],
            data: [],
        };

        this.planService = new PlanService();
        this.dashboardService = new DashboardService();
        this.companyService = new CompanyService();

        this.filtersPaymentStatusScreenContext = null;
    }

    componentDidMount() {
        this.load();
    }

    load = async (withFilter = false) => {
        try {
            this.setState({
                inLoad: true,
            });

            let filters = '';

            if (withFilter === true) {
                filters = this.getFilters();
            }

            const dashboardResponse = await this.dashboardService.dashboardCompany(filters);
            const response = await this.planService.retriveAll();

            this.setState({
                inLoad: false,
                plans: response.data.data,
                data: dashboardResponse.data,
            });
        } catch (e) {
            this.setState({
                inLoad: false,
                messagens: MessageUtil.make({
                    title: 'Atenção',
                    body: e,
                }),
            });
        }
    }

    changeModel = async (uuid, isModel) => {
        try {
            this.setState({
                inLoad: false,
                mensagens: MessageUtil.make({
                    title: 'Atenção',
                    body: `Tem certeza que deseja ${isModel ? 'despromover' : 'promover'} essa empresa a uma "Empresa modelo"?`,
                    cancelButtonText: 'Cancelar',
                    cancelButtonFunction: () => {
                    },
                    okButtonText: 'Sim',
                    okButtonFunction: async () => {
                        try{

                            this.setState({
                                inLoad: true,
                            });

                            await this.companyService.changeModelFlag(uuid);

                            this.setState({
                                inLoad: false,
                            });

                            this.load();

                        }catch (e) {
                            this.setState({
                                inLoad: false,
                                mensagens: MessageUtil.make({
                                    title: 'Atenção',
                                    body: e,
                                }),
                            });
                        }

                    }
                }),
            });

        } catch (e) {
            this.setState({
                inLoad: false,
                mensagens: MessageUtil.make({
                    title: 'Atenção',
                    body: e,
                }),
            });
        }
    }

    filtersPaymentStatusScreen = (context) => {
        this.filtersPaymentStatusScreenContext = context;
    }

    getFilters = () => {

        const object = this.filtersPaymentStatusScreenContext.state;

        let filter = '';

        for (var key in object) {
            if (object.hasOwnProperty(key)) {
                if (object[key] === null || object[key] === undefined) {
                    continue;
                }

                filter += `${key}=${object[key]}&`
            }
        }

        return filter;
    }

    render() {
        return (
            <>
                <HeaderComponent title={'Controle de Clientes'}
                                 inLoad={this.state.inLoad}
                                 messagens={this.state.mensagens}
                                 menu={true}
                                 showTitleInContent={true}/>
                <FiltersPaymentStatusScreen plans={this.state.plans}
                                            filtersPaymentStatusScreen={this.filtersPaymentStatusScreen}
                                            load={this.load}/>
                <TablePaymentStatusScreen data={this.state.data}
                                          changeModel={this.changeModel}/>
                <FooterPaymentStatusScreen data={this.state.data}/>
            </>
        );
    }
}

export default PaymentStatusScreen;