import React, {Component} from 'react';
import {ContainerCurrentPlanStyled} from "./styled";
import CardSimulationManagePaymentScreen from "./CardSimulationManagePaymentScreen/CardSimulationManagePaymentScreen";
import {Typography} from "@material-ui/core";
import SpacerComponent from "../../../Components/SpacerComponent/SpacerComponent";
import {ManagePaymentContext} from "../../../Contexts/ManagePaymentContext";
import DateUtil from "../../../Utils/DateUtil";

class CardPaymentMethodScreen extends Component {
    static contextType = ManagePaymentContext;

    tryGetDate() {
        try {

            return DateUtil.raw(this.context.parent.state.payments.bills.pop().date).toDate();

        } catch (e) {
            return '';
        }
    }

    render() {
        if (this.context.parent.state.me?.company === undefined) {
            return <></>;
        }
        const company = this.context.parent.state.me.company;

        return (
            <ContainerCurrentPlanStyled variant={'outlined'}>
                <CardSimulationManagePaymentScreen paymentCompanyCode={company.payment_profile.payment_company_code}
                                                   cardNumber={company.payment_profile.card_number}/>
                <SpacerComponent height={20}/>
                <Typography variant={'h6'}>
                    Vence: {DateUtil.raw(company.payment_profile.card_expiration).toDateCardExpiration()}
                </Typography>
                <SpacerComponent height={20}/>
                <Typography variant={'h6'}>
                    {company.payment_profile.street},
                    {' ' + company.payment_profile.number},
                    {' ' + company.payment_profile.neighborhood},
                    {' ' + company.payment_profile.city},
                    {' ' + company.payment_profile.state},
                    {' ' + company.payment_profile.country}
                </Typography>
                <SpacerComponent height={20}/>
                {this.context.parent.state.payments.bills !== undefined &&
                <Typography variant={'subtitle2'}>
                    USADO PELA ÚLTIMA VEZ: {this.tryGetDate()}
                </Typography>
                }
            </ContainerCurrentPlanStyled>
        );
    }
}

export default CardPaymentMethodScreen;