import React, {Component} from 'react';
import {Box, IconButton, Typography} from '@material-ui/core';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import SpacerComponent from "../../../Components/SpacerComponent/SpacerComponent";

class ButtonRemoveEstablishment extends Component {
    render() {
        return (
            <Box display={'flex'}
                 alignItems="center">
                <SpacerComponent height={20}/>
                <IconButton onClick={() => this.props.removeEstablishment()}>
                    <RemoveCircleOutlineIcon/>
                </IconButton>
                <Typography>
                    Remover Último Estabelecimento
                </Typography>
            </Box>
        );
    }
}

export default ButtonRemoveEstablishment;