import styled from 'styled-components';
import {FormControlLabel, Paper} from "@material-ui/core";

export const ContentMainStyled = styled.div`
  width: 100%;
  padding: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  @media only screen and (max-width: 600px) {
    padding: 20px;
  }
`;

export const ContentPaymentStyled = styled(Paper)`
  width: 1000px;
  background: transparent;
  display: flex;
  flex-direction: row;
  height: 700px;
`;

export const ContentPaymentMobileStyled = styled(Paper)`
  background: transparent;
  display: flex;
  flex-direction: column;
  height: 650px;

  @media only screen and (max-width: 600px) {
    height: auto;
  }
`;

export const ShowPlanStyled = styled.div`
  width: 33.333%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 0;
  background: #2370a8;
  color: white;

  @media only screen and (max-width: 600px) {
    width: 100%;
    padding: 20px;
  }

  h5 {
    font-weight: bold;
    font-size: 30px;
  }

  h4 {
    font-weight: bold;
    font-size: 50px;
  }

  p {
    font-size: 20px;
  }
`;

export const FormPlanStyled = styled.div`
  width: 66.666%;
  background: transparent;
  border-radius: 0;
  display: flex;
  flex-direction: column;

  @media only screen and (max-width: 600px) {
    flex-direction: column;
    width: 100%;
  }
`;

export const ContentFormStyled = styled.div`
  height: 85%;
  display: flex;
  flex-direction: row;

  @media only screen and (max-width: 600px) {
    flex-direction: column;
  }
`;

export const FormCardStyled = styled.div`
  width: 100%;
  padding: 20px;
  border-right: solid 1px #c7c7c7;

  @media only screen and (max-width: 600px) {
    width: 100%;
  }
`;

export const ContentButtonStyled = styled.div`
  height: 15%;
  background: #1ece67;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  h5 {
    color: white;
    font-size: 30px;
  }
`;

export const TermStyled = styled(FormControlLabel)`
  display: flex;
  justify-content: flex-start;
  align-items: center;

  span {
    font-size: 12.9px;
  }
`;