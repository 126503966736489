import styled from "styled-components";
import {Paper} from "@material-ui/core";

export const ContentMainStyled = styled(Paper)`
  width: 100%;
  height: 100px;
  position: fixed;
  top: 55px;
  left: 0;
  background: ${props => props.color ? props.color : 'cyan'};
  z-index: 9;
  border-radius: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  color: white;
  
  a {
    color: white;
  }

  @media only screen and (max-width: 600px) {
    height: 200px;
  }
`;