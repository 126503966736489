import ApiClient from "./Api/ApiClient";

class ExtractService {

    constructor() {
        this.uri = 'points-report';
        this.apiClient = new ApiClient();
    }

    async getByCompany({
                           companyUuid,
                           queryString,
                       }) {
        try {
            console.log(queryString);
            return await this.apiClient.doRequest({
                method: ApiClient.GET,
                uri: this.uri + `/get-report-by-company/${companyUuid}?paginate=10&${queryString}`,
                expectedStatus: 200,
            });

        } catch (e) {
            throw e;
        }
    }

    async getReceipt(uuid) {
        try {

            return await this.apiClient.doRequest({
                method: ApiClient.GET,
                uri: `award-redemption/get-receipt/${uuid}`,
                expectedStatus: 200,
            });

        } catch (e) {
            throw e;
        }
    }

    async scoringManual(object) {
        try {

            return await this.apiClient.doRequest({
                method: ApiClient.POST,
                uri: 'points/add',
                expectedStatus: 200,
                body: object,
            });

        } catch (e) {
            throw e;
        }
    }

    async scoringManualCheck(object) {
        try {

            return await this.apiClient.doRequest({
                method: ApiClient.POST,
                uri: 'points/check',
                expectedStatus: 200,
                body: object,
            });

        } catch (e) {
            throw e;
        }
    }

    async getModelCSV() {
        try {

            return await this.apiClient.doRequest({
                method: ApiClient.GET,
                uri: 'points/get-example-file',
                expectedStatus: 200,
            });

        } catch (e) {
            throw e;
        }
    }

    async getFiltersData(companyUuid) {
        try {

            return await this.apiClient.doRequest({
                method: ApiClient.GET,
                uri: `points-report/get-filters-data/${companyUuid}`,
                expectedStatus: 200,
            });

        } catch (e) {
            throw e;
        }
    }
}

export default ExtractService;