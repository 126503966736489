import React, {Component} from 'react';
import {
    ContentModalGiftCardStyled,
    ModalGiftCardStyled,
    PaperItemStyled,
    ParentModalGiftCardStyled,
    PreviewGiftCard, ValorTotalContentStyle
} from "./styled";
import SpacerComponent from "../../../Components/SpacerComponent/SpacerComponent";
import {GiftCardPurchaseContext} from "../../../Contexts/GiftCardPurchaseContext";
import TextFieldDefault from "../../../Components/TextFieldDefaultComponent/TextFieldDefault";
import {Button, Typography} from "@material-ui/core";
import GiftCardValidation from "../../../FormValidations/GiftCardValidation";
import GiftCardPurchaseValidation from "../../../FormValidations/GiftCardPurchaseValidation";
import MessageUtil from "../../../Utils/MessageUtil";
import GeneralTranslation from "../../../Translations/FormValidations/GeneralTranslation";
import FormGiftCardPaymentScreen from "../PaymentScreen/FormGiftCardPaymentScreen";
import {GiftCardPurchaseModalContext} from "../../../Contexts/GiftCardPurchaseModalContext";
import GiftCardService from "../../../Services/GiftCardService";
import TooltipDefault from "../../../Components/TooltipComponent/TololtipDefault";
import TooltipMessageUtil from "../../../Utils/TooltipMessageUtil";
import StringUtil from "../../../Utils/StringUtil";

class ModalGiftCard extends Component {
    static  contextType = GiftCardPurchaseContext;

    constructor(props) {
        super(props);

        this.giftCardService = new GiftCardService();

        this.state = {
            value: null,
            amount: 1,
        };
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!this.props.product) {
            return;
        }

        if (prevProps.product?.product_identifier === this.props.product?.product_identifier) {
            return;
        }

        if (this.props.product.min_value !== this.props.product.max_value) {
            this.setState({
                value: null,
                amount: 1,
            });
            return;
        }

        this.setState({
            value: this.props.product.min_value,
            amount: 1,
        });
    }

    close = () => {
        this.context.parent.setState({
            selectProduct: null,
            selectProductLines: null,
        });
    }

    handleChange(event) {
        const {name, value} = event.target;
        if (name === 'value' && value !== undefined) {
            this.setState({
                [name]: parseFloat(value.replace(',', '.')),
            });
            return;
        }
        this.setState({[name]: value});
    }

    doBuy = async (form) => {
        try {
            this.context.parent.setState({
                inLoad: true,
            });
            form = {...form, ...this.state};
            const product = this.props.product;
            form.card_identificator = product.card_identificator;
            const validate = await GiftCardPurchaseValidation.validate(
                form,
                parseFloat(product.min_value),
                parseFloat(product.max_value),
            );

            if (validate !== true) {
                this.setState({
                    erros: validate,
                });

                this.context.parent.setState({
                    inLoad: false,
                    messagens: MessageUtil.makeToValidationField(
                        new GeneralTranslation(validate).translate()
                    ),
                });
                return;
            }

            await this.giftCardService.doBuy(form);

            this.context.parent.setState({
                selectProduct: null,
                selectProductLines: null,
                inLoad: false,
                messagens: MessageUtil.make({
                    title: 'Sucesso',
                    body: 'Voucher comprado',
                }),
            });
        } catch (e) {
            this.context.parent.setState({
                inLoad: false,
                messagens: MessageUtil.make({
                    title: 'Atenção',
                    body: e,
                }),
            });
        }
    }

    doCalcTotal = () => {
        try {
            const value = parseFloat(this.state.value * this.state.amount).toFixed(2) || 0;

            if (isNaN(value)) {
                return 0;
            }

            return value;
        } catch (e) {
            return 0;
        }
    }

    render() {
        if (!this.props.product) {
            return <></>;
        }
        const product = this.props.product;
        const productLines = this.props.productLines;
        return (
            <GiftCardPurchaseModalContext.Provider value={{
                parent: this,
            }}>
                <ParentModalGiftCardStyled>
                    <ModalGiftCardStyled>
                        <ContentModalGiftCardStyled elevation={3}>
                            <h1 onClick={this.close}>Fechar</h1>
                            <SpacerComponent height={30}/>
                            <PreviewGiftCard>
                                <PaperItemStyled elevation={3}>
                                    <img src={productLines.logo_url}/>
                                </PaperItemStyled>
                            </PreviewGiftCard>
                            <SpacerComponent height={30}/>
                            <Typography variant={'body1'}
                                        color={'primary'}>
                                Valor min R$ {StringUtil.money(product.min_value)} e max
                                R$ {StringUtil.money(product.max_value)}
                            </Typography>
                            <TextFieldDefault label={'Valor *'}
                                              name={'value'}
                                              type={'number'}
                                              fullWidth={false}
                                              forceLabel={true}
                                              money={true}
                                              moneyPrefix={'R$ '}
                                              disabled={this.props.product.min_value === this.props.product.max_value}
                                              value={this.state?.value}
                                              onChange={(event) => this.handleChange(event)}
                                              erros={this.state?.erros}/>
                            <TooltipDefault text={TooltipMessageUtil.GIFT_MSG2}
                                            changePosition={true}
                                            top={220}
                                            right={190}/>
                            <TextFieldDefault label={'Quantidade *'}
                                              name={'amount'}
                                              type={'number'}
                                              fullWidth={false}
                                              value={this.state?.amount}
                                              onChange={(event) => this.handleChange(event)}
                                              erros={this.state?.erros}/>
                            <TooltipDefault text={TooltipMessageUtil.GIFT_MSG3}
                                            changePosition={true}
                                            top={300}
                                            right={190}/>
                            <SpacerComponent height={30}/>
                            <ValorTotalContentStyle>
                                <Typography variant={'h5'}
                                            color={'error'}>Valor Total: <span>R$ {this.doCalcTotal()}</span></Typography>
                            </ValorTotalContentStyle>
                            <FormGiftCardPaymentScreen erros={this.state?.erros}/>
                        </ContentModalGiftCardStyled>
                    </ModalGiftCardStyled>
                </ParentModalGiftCardStyled>
            </GiftCardPurchaseModalContext.Provider>
        );
    }
}

export default ModalGiftCard;