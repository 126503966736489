import React, {Component} from 'react';
import HeaderComponent from "../../../Components/HeaderComponent/HeaderComponent";
import {Typography} from "@material-ui/core";
import SpacerComponent from "../../../Components/SpacerComponent/SpacerComponent";
import MessageUtil from "../../../Utils/MessageUtil";
import ListNewAwardScreen from "../NewAward/ListNewAwardScreen";
import FormGiftCardScreen from "./FormGiftCardScreen";
import AwardService from "../../../Services/AwardService";
import {CashbackContext} from "../../../Contexts/CashbackContext";
import GeneralTranslation from "../../../Translations/FormValidations/GeneralTranslation";
import GiftCardValidation from "../../../FormValidations/GiftCardValidation";

class GiftCardScreen extends Component {
    static ROUTE = '/gift-card';

    constructor(props) {
        super(props);

        this.state = {
            inLoad: false,
            messagens: null,
            fidelity: [],
            companies: [],
            action: 'create',
            actionCurrent: 'Cadastro de novo Voucher.',
        };

        this.awardService = new AwardService();
    }

    componentDidMount() {
        this.isEdit();
    }

    doCreate = async (object) => {
        try {
            console.log(object);
            const validate = await GiftCardValidation.validate(object);

            if (validate !== true) {
                this.setState({
                    erros: validate,
                    messagens: MessageUtil.makeToValidationField(
                        new GeneralTranslation(validate).translate()
                    ),
                });
                return;
            }

            this.setState({
                inLoad: true,
            });


            if (this.state.action === 'create') {
                object.active = 1;
                await this.awardService.create(object);
            }

            if (this.state.action === 'edit') {

                if (object.withoutValidDate) {
                    object.start_validity = null;
                    object.end_validity = null;
                }
                await this.awardService.update(this.state.formEdit.uuid, object);
            }

            this.setState({
                inLoad: false,
                messagens: MessageUtil.make({
                    title: 'Sucesso',
                    body: 'Voucher cadastrado clique em ok para voltar.',
                    okBtnToRoute: ListNewAwardScreen.ROUTE,
                }),
            });

        } catch (e) {
            this.setState({
                inLoad: false,
                messagens: MessageUtil.make({
                    title: 'Atenção',
                    body: e,
                }),
            });
        }
    }

    async isEdit() {

        try {
            if (this.props.match.params.edit === undefined) {
                this.setState({
                    isEdit: false,
                });
                return;
            }

            this.setState({
                inLoad: true,
            });

            const response = await this.awardService.show(this.props.match.params.edit);

            this.setState({
                isEdit: true,
                inLoad: false,
                formEdit: response.data.data,
                action: 'edit',
                actionCurrent: 'Alterar Voucher',
            });

        } catch (e) {
            this.setState({
                inLoad: false,
                messagens: MessageUtil.make({
                    title: 'Atenção',
                    body: e,
                }),
            });
        }
    }

    confirmDelete = () => {
        this.setState({
            messagens: MessageUtil.make({
                title: 'Atenção',
                body: 'Tem certeza que deseja deletar esse Voucher? Essa ação não poderá ser desfeita.',
                okButtonText: 'Sim',
                okButtonFunction: this.doDelete,
                cancelButtonText: 'Não',
            }),
        },);
    }

    doDelete = async () => {
        try {
            this.setState({
                inLoad: true,
            });

            const result = await this.awardService.deleteConfirm(this.state.formEdit.uuid);

            if (!result.data.result) {
                this.setState({
                    inLoad: false,
                    messagens: MessageUtil.make({
                        title: 'Atenção',
                        body: result.data.message,
                    }),
                });
                return;
            }

            await this.awardService.delete(this.state.formEdit.uuid);

            this.setState({
                inLoad: false,
                messagens: MessageUtil.make({
                    title: 'Sucesso',
                    body: 'Voucher deletado, clique em ok para voltar.',
                    okBtnToRoute: ListNewAwardScreen.ROUTE,
                }),
            });
        } catch (e) {
            this.setState({
                inLoad: false,
                messagens: MessageUtil.make({
                    title: 'Atenção',
                    body: e,
                }),
            });
        }
    }

    render() {
        return (
            <CashbackContext.Provider value={{
                parent: this,
            }}>
                <HeaderComponent title={'Registrar Voucher'}
                                 menu={true}
                                 inLoad={this.state.inLoad}
                                 messagens={this.state.messagens}/>
                <Typography align={'center'}
                            variant={'h6'}
                            color={'primary'}>
                    {this.state.actionCurrent}
                </Typography>
                <SpacerComponent height={40}/>
                <FormGiftCardScreen doCreate={this.doCreate}
                                    confirmDelete={this.confirmDelete}
                                    erros={this.state.erros}
                                    formEdit={this.state.formEdit}
                                    isEdit={this.state.isEdit}/>
            </CashbackContext.Provider>
        );
    }
}

export default GiftCardScreen;